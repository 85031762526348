import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WeaponTraceInfo from "../../../interfaces/WeaponTraceInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import GunTraceIcon from "../../../assets/Images/GunOffender.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import { formatDT, formatDate } from '../../../services/formatDate.service';

interface PanelGunOffenderProps {
    person: PersonInfo,
    GunInfo: any[],
    isLoading:boolean
}

const PanelGunOffender: React.FC<PanelGunOffenderProps> = ({ person, GunInfo, isLoading }) => {
    const [FormatData, setFormatData] = React.useState(0);

    let today = new Date();

    const handleDataTyping = () => {
        let tempP: any = [...GunInfo]
        for (let p of tempP) {
            if (p.SupervisionEndDateCSOSA) {
                p.SupervisionEndDateCSOSA = new Date(p.SupervisionEndDateCSOSA)
            }
            if (p.ScheduledAnnualUpdate) {
                p.ScheduledAnnualUpdate = new Date(p.ScheduledAnnualUpdate)
            }
            if (p.DateRegisteredGORU) {
                p.DateRegisteredGORU = new Date(p.DateRegisteredGORU)
            }
        }

        console.log("GunInfo: ", tempP)
        setFormatData(tempP)
    }

    //const dateTemplate = (rowData: any) => {
    //    if (!rowData?.HomeVisitDueDate || rowData.HomeVisitDueDate.length === 0) {
    //        return <span>No Visits</span>;
    //    }

    //    return (
    //        <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
    //            {rowData.HomeVisitDueDate.map((visit: any, index: number) => {
    //                return (
    //                    <li key={index} style={{
    //                        color: visit?.Date
    //                            ? new Date(visit.Date) < today && visit?.Complete !== true
    //                                ? 'red'
    //                                : (new Date(visit.Date) >= today && new Date(visit.Date) <= new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000))
    //                                    ? 'orange'
    //                                    : 'black'
    //                            : 'black'
    //                    }}>
    //                        {visit.Date ? formatDate(new Date(visit.Date + "T00:00:00Z")) : "No Date"}

    //                    </li>

    //                )
    //            })}
    //        </ul>
    //    );
    //};

    React.useEffect(() => {
        handleDataTyping()
    }, [GunInfo])

    return (
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Gun Offender <img src={GunTraceIcon} alt={''} width={25} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {GunInfo?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='SupervisionEndDateCSOSA' headerText='Supervision End Date CSOSA' format={{ type: 'date', format: 'MM/dd/yyyy' }} />
                                    <ColumnDirective field='ScheduledAnnualUpdate' headerText='Scheduled Annual Update' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    {/*<ColumnDirective template={dateTemplate} headerText="Visit Dates" />*/}
                                    <ColumnDirective field='DateRegisteredGORU' headerText='Date Registered GORU' format={{ type: 'date', format: 'MM/dd/yyyy' }} />
                                    <ColumnDirective field='CourtSentence' headerText='Court Sentence' />
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/tools/gun_offender_entry?ID=' + item?.ID, '_blank') }} >View</Button>)} headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]} />
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelGunOffender;