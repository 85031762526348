import React from 'react'
import {
    Alert, AlertColor,
    ButtonGroup,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Snackbar,
    Switch,
    Tooltip
} from "@mui/material";
import { DateToString } from '../../services/formatDate.service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { RunGetPOI, RunSetPOI, RunGetPersonPOIHit } from "../../services/account.service";
import UpdatePOI from "./UpdatePOI";
import {
    Column,
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    DetailRow,
    ExcelExport,
    ExcelExportProperties,
    Filter,
    Grid,
    GridComponent,
    Inject,
    Page,
    PdfExport,
    Reorder,
    Resize,
    Search,
    Sort,
    Toolbar
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { canEditPOI, isUserPermission } from '../../services/auth.service';
import { enableNJCAP5, SiteName } from "../../services/config.service";
import { getValue } from "@syncfusion/ej2-base";
import { GetStringNameForSite } from "../../interfaces/config.interface";
import { DataUtil } from '@syncfusion/ej2-data';

interface SearchResultsProps {
    POIs: any[]
    NewPOI: any
}

const SearchResults: React.FC<SearchResultsProps> = ({ POIs, NewPOI }) => {
    const [poiSelectList, setPoiSelectList] = React.useState<any[]>([]);
    const [poi, setPOI] = React.useState<boolean>(false)
    const [POIID, setPOIID] = React.useState<string>("0")
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")
    const [poiGridData, setPoiGridData] = React.useState<any>([])
        
    const windowWidth = () => {
        return (String(window.innerWidth) + 'px');
    }

    const CloseBox = (poiObj: any, action: string) => {
        setPOI(false)
        if (action === 'Add/Edit' && poiObj) {
            let temp = { ...poiObj }
            if (grid) {
                // @ts-ignore
                let poiList: any = [...grid?.dataSource]
                if (temp.StartDate) {
                    temp.StartDate = new Date(temp.StartDate)
                }
                if (temp.ExpiryDate) {
                    temp.ExpiryDate = new Date(temp.ExpiryDate)
                }
                if (temp.DOB) {
                    temp.DOB = new Date(temp.DOB)
                }
                if (temp.UniqueID && temp.UniqueID != 0) {
                    let index: number = poiList.findIndex((e: any) => e.UniqueID === temp.UniqueID)
                    if (index >= 0) {
                        poiList[index] = temp
                        grid.dataSource = poiList
                        grid?.refresh()
                    }
                    else {
                        grid.dataSource = poiList.concat(temp)
                        grid?.refresh()
                    }
                }
            }
        }
        else if (action === 'Delete' && poiObj) {
            let temp = { ...poiObj }
            if (grid) {
                // @ts-ignore
                let poiList: any = [...grid?.dataSource]
                if (temp.UniqueID && temp.UniqueID != 0) {
                    let index: number = poiList.findIndex((e: any) => e.UniqueID === temp.UniqueID)
                    if (index >= 0) {
                        poiList.splice(index, 1)
                        grid.dataSource = poiList
                        grid?.refresh()
                    }
                }
            }
        }
    }

    const editPOI = (id: string) => {
        setPOIID(id)
        setPOI(true)
    }

    React.useEffect(() => {
        if (NewPOI)
            CloseBox(NewPOI, 'Add/Edit')
    }, [NewPOI])

    const deletePOI = async (poi: any) => {
        let p = { ...poi }     
        //
        p.ExpiryDate = ToLocalFormat(p.ExpiryDate)
        p.StartDate = ToLocalFormat(p.StartDate)
        //
        if (window.confirm("Remove This POI Record Permanently?")) {
            p.EditStatus = "Delete"
            let r = await RunSetPOI(p)
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0) {
                setAlertOpen(true)
                setAlertText("Delete Successful")
                setAlertType('success')
                CloseBox(r.AnyData, 'Delete')
            }
            else {
                alert(r.ErrorMessage)
            }
        }
    }

    const ToLocalFormat = (datePoi: any) => {
        if (typeof datePoi != typeof new Date()) {
            datePoi = new Date(datePoi)
        }
        let offset = datePoi.getTimezoneOffset() * 60000   //in milliseconds
        return new Date(datePoi - offset).toISOString().slice(0, -1);
    } 


    const ActivateDeactivatePOI = async (poiT: any) => {
        let temp = [...poiGridData]
        let poi = temp[poiT.index]         
        //
        poi.ExpiryDate = ToLocalFormat(poi.ExpiryDate)
        poi.StartDate = ToLocalFormat(poi.StartDate)        
        //
        poi.EditStatus = poi.Active === "Active" ? "Inactive" : "Active"
        let r = await RunSetPOI(poi)
        if (r.AnyData?.IsValid) {
            setAlertOpen(true)
            poi.Active = poi.Active === "Active" ? "Inactive" : "Active"
            temp[poiT.index] = poi
            setPoiGridData([])
            setPoiGridData(temp)
        }
    }

    const handleDataTyping = () => {
        if (POIs) {
            let tempP: any = [...POIs]
            for (let p of tempP) {
                if (p.StartDate) {
                    p.StartDate = new Date(p.StartDate)
                }
                if (p.ExpiryDate) {
                    p.ExpiryDate = new Date(p.ExpiryDate)
                }
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
            }
            setPoiGridData(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
        //Every time we get new POIs, we need to reset the selected list
        setPoiSelectList([])
    }, [POIs])

    let grid: Grid | null;

    const autoRenewPOI = async () => {
        if (!poiSelectList || poiSelectList?.length === 0) {
            setAlertOpen(true)
            setAlertText("Please select at least 1 POI to Renew")
            setAlertType('warning')
            return;
        }

        for (let p of poiSelectList) {
            try {
                if (!p && !p.UniqueID)
                    continue;
                let poi = (await RunGetPOI({ "uniqueID": p.UniqueID }))?.AnyData
                if (!poi)
                    continue

                let purposeList = []
                if (poi.ReasonAlertList?.length > 0)
                    purposeList.push(...poi.ReasonAlertList)
                if (poi.ReasonCovertList?.length > 0)
                    purposeList.push(...poi.ReasonCovertList)
                if (poi.ReasonLEPurposeList?.length > 0)
                    purposeList.push(...poi.ReasonLEPurposeList)
                if (poi.ReasonNCICList?.length > 0)
                    purposeList.push(...poi.ReasonNCICList)

                let selectedPurpose = purposeList.find((p: any) => p.Key === poi.Reason)
                let incDays = selectedPurpose && selectedPurpose.Additional ? parseInt(selectedPurpose.Additional) : 30

                let d = new Date()
                d.setDate(d.getDate() + incDays);
                d.setHours(23)
                d.setMinutes(59)
                d.setSeconds(59)
                poi.ExpiryDate = DateToString(d)
                await RunSetPOI(poi).then((res) => {
                    if (res?.AnyData && res?.AnyData?.IsValid)
                        CloseBox(res?.AnyData, 'Add/Edit')
                })
            } catch (e) {
                console.log(e)
            }
        }
    }

    const ignoreWarningSelectedPOIs = async () => {
        if (!poiSelectList || poiSelectList?.length === 0) {
            setAlertOpen(true)
            setAlertText("Please select at least 1 POI to Remove Warning")
            setAlertType('warning')
            return;
        }

        for (let p of poiSelectList) {
            try {
                if (!p && !p.UniqueID)
                    continue;
                let poi = (await RunGetPOI({ "uniqueID": p.UniqueID }))?.AnyData
                if (!poi)
                    continue

                poi.IgnoreExpiryWarning = true

                await RunSetPOI(poi)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const handlePOISelect = (poi: any) => {
        let tmpList: any[] = poiSelectList
        let idx = tmpList.findIndex((p: any) => p.UniqueID === poi.UniqueID)
        if (idx > -1)
            tmpList.splice(idx, 1)
        else
            tmpList.push(poi)
        setPoiSelectList(tmpList)
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'POI.xlsx';

            (grid.getColumnByField("action") as Column).visible = false;
            (grid.getColumnByField("status") as Column).visible = false;
            (grid.getColumnByField("plates") as Column).visible = false;
            (grid.getColumnByField("person") as Column).visible = false;
            (grid.getColumnByField("other") as Column).visible = false;

            (grid.getColumnByField("Justification") as Column).visible = true;
            (grid.getColumnByField("PlateNumber3") as Column).visible = true;
            (grid.getColumnByField("PlateNumber") as Column).visible = true;
            (grid.getColumnByField("PlateSt") as Column).visible = true;
            (grid.getColumnByField("PlateNumber2") as Column).visible = true;
            (grid.getColumnByField("PlateSt2") as Column).visible = true;
            (grid.getColumnByField("PlateNumber3") as Column).visible = true;
            (grid.getColumnByField("PlateSt3") as Column).visible = true;
            (grid.getColumnByField("VIN") as Column).visible = true;
            (grid.getColumnByField("FName") as Column).visible = true;
            (grid.getColumnByField("MName") as Column).visible = true;
            (grid.getColumnByField("LName") as Column).visible = true;
            (grid.getColumnByField("DLNo") as Column).visible = true;
            (grid.getColumnByField("DLSt") as Column).visible = true;
            (grid.getColumnByField("SSN") as Column).visible = true;
            (grid.getColumnByField("FelonyVehicle") as Column).visible = true;
            (grid.getColumnByField("FelonyVehicleReason") as Column).visible = true;
            (grid.getColumnByField("SearchNotes") as Column).visible = true;
            (grid.getColumnByField("CaseNo") as Column).visible = true;
            (grid.getColumnByField("AlertOption") as Column).visible = true;
            (grid.getColumnByField("AdditionalEmail") as Column).visible = true;

            grid.excelExport(excelProp);
        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        } else if (grid && args.item.text === 'Renew Selected POIs') {
            autoRenewPOI();
        } else if (grid && args.item.text === 'Ignore Expiry Warning For Selected POIs') {
            ignoreWarningSelectedPOIs();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("action") as Column).visible = true;
            (grid.getColumnByField("status") as Column).visible = true;
            (grid.getColumnByField("plates") as Column).visible = true;
            (grid.getColumnByField("person") as Column).visible = true;
            (grid.getColumnByField("other") as Column).visible = true;

            (grid.getColumnByField("Justification") as Column).visible = false;
            (grid.getColumnByField("PlateNumber") as Column).visible = false;
            (grid.getColumnByField("PlateSt") as Column).visible = false;
            (grid.getColumnByField("PlateNumber2") as Column).visible = false;
            (grid.getColumnByField("PlateSt2") as Column).visible = false;
            (grid.getColumnByField("PlateNumber3") as Column).visible = false;
            (grid.getColumnByField("PlateSt3") as Column).visible = false;
            (grid.getColumnByField("VIN") as Column).visible = false;
            (grid.getColumnByField("FName") as Column).visible = false;
            (grid.getColumnByField("MName") as Column).visible = false;
            (grid.getColumnByField("LName") as Column).visible = false;
            (grid.getColumnByField("DLNo") as Column).visible = false;
            (grid.getColumnByField("DLSt") as Column).visible = false;
            (grid.getColumnByField("SSN") as Column).visible = false;
            (grid.getColumnByField("FelonyVehicle") as Column).visible = false;
            (grid.getColumnByField("FelonyVehicleReason") as Column).visible = false;
            (grid.getColumnByField("SearchNotes") as Column).visible = false;
            (grid.getColumnByField("CaseNo") as Column).visible = false;
            (grid.getColumnByField("AlertOption") as Column).visible = false;
            (grid.getColumnByField("AdditionalEmail") as Column).visible = false;
        }
    }

    const gridTemplate = () => {
        return (
            <div className="custom-grid mt-5 ml-5" ></div>
        );
    };

    const detailDataBound = async (e: { data: any, detailElement: any; }) => {
        let wid = windowWidth()
        let r = await RunGetPersonPOIHit({ "ReqIDs": String(e.data.UniqueID).split(',') })
        let detail = new GridComponent({
            dataSource: r.AnyData,
            allowTextWrap: true,
            allowPaging: true,
            width: wid,
            pageSettings: { pageSize: 25, pageSizes: [10, 25, 50, 100] },
            textWrapSettings: { wrapMode: 'Both' },
            selectionSettings: { mode: 'Cell' },
            columns: [
                { field: "Origin", headerText: "Origin", width: 100, minWidth: 100, maxWidth: 100 },
                { field: "RunDate", headerText: "Run Date", type: 'date', format: 'MM/dd/yyyy HH:mm', width: 160, minWidth: 160, maxWidth: 160 },
                { field: "Run_DeptName", headerText: "Dept Name", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "Run_Device", headerText: "Device", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "PlateNumber", headerText: "Plate", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "PlateSt", headerText: "Plate State", width: 100, minWidth: 100, maxWidth: 100 },
                { field: "DLNo", headerText: "DL", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "DLSt", headerText: "DL State", width: 100, minWidth: 100, maxWidth: 100 },
                { field: "VIN", headerText: "VIN", width: 200, minWidth: 200, maxWidth: 200 },
                { field: "FName", headerText: "First Name", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "MName", headerText: "MI", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "LName", headerText: "Last Name", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "DOB", headerText: "DOB", type: 'date', format: 'MM/dd/yyyy', width: 140, minWidth: 140, maxWidth: 140 },
                { field: "FBINo", headerText: "FBI", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "SBINo", headerText: "SBI", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "Latitude", headerText: "Latitude", width: 140, minWidth: 140, maxWidth: 140 },
                { field: "Longitude", headerText: "Longitude", width: 160, minWidth: 160, maxWidth: 160 },
                { field: "Longitude", headerText: "Longitude", width: 160, minWidth: 160, maxWidth: 160 }
            ]
        });
        detail.appendTo(e.detailElement.querySelector('.custom-grid.mt-5.ml-5'));
        detail.autoFitColumns()
    };

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
            if (!isUserPermission('POI')) {
                grid.detailTemplate = ''
            }
        }
    }

    const statusTemplate = (poi: any): any => {
        return (
            <span>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel disabled={!canEditPOI(poi)}
                            control={
                                <Switch onChange={() => ActivateDeactivatePOI(poi)} checked={poi.Active === "Active"} />
                            }
                            label={poi.Active === "Active" ? "Active" : "Inactive"}
                        />
                    </FormGroup>
                </FormControl>
            </span>);
    }

    const canRenewPOI = (poi: any) => {
        let res = true
        if (poi?.ExpiryDate < new Date())
            res = false
        else if (poi?.IgnoreExpiryWarning)
            res = false
        return res;
    }

    const actionTemplate = (poi: any): any => {
        return (
            <span>
                <ButtonGroup>
                    <Checkbox disabled={(!canEditPOI(poi)) || !canRenewPOI(poi)} onClick={() => { handlePOISelect(poi) }} />

                    <Tooltip title="Edit" placement="top" arrow>
                        <IconButton disabled={!canEditPOI(poi)} onClick={() => editPOI(poi.UniqueID)}><EditIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top" arrow>
                        <IconButton disabled={!canEditPOI(poi)} onClick={() => deletePOI(poi)}><DeleteIcon /></IconButton>
                    </Tooltip>
                </ButtonGroup>
            </span>);
    }

    return (
        <div className={" pr-10"}>
            <Snackbar open={alertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            {poiGridData && poiGridData.length > 0 ? <>
                <h4 className={"font-bold pl-5"}> {poiGridData?.length} Records Found</h4>
                <GridComponent
                    dataSource={poiGridData}
                    dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={[
                        'ExcelExport',
                        { text: 'Renew Selected POIs', tooltipText: 'Renews the selected POI(s) for the default expiration time', prefixIcon: 'e-repeat', id: 'btn_renew_poi' },
                        { text: 'Ignore Expiry Warning For Selected POIs', tooltipText: 'Ignore Expiry Warning For Selected POIs', prefixIcon: 'e-recurrence-edit', id: 'btn_ignore_poi' },

                        'Search'
                    ]}
                    toolbarClick={GridToolsClick}
                    ref={(g) => { if (g) { grid = g } }}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        checkboxOnly: true,
                        mode: "Row"
                    }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    //width='auto'
                    excelExportComplete={excelExportComplete}
                    rowDataBound={(args: any) => {
                        if (args.row) {
                            let warning = new Date();
                            warning.setDate(warning.getDate() + 10);
                            if (getValue('ExpiryDate', args.data) < new Date()) {
                                args.row.classList.add('red');
                            } else if (getValue('ExpiryDate', args.data) < warning && getValue('ExpiryDate', args.data) > new Date()) {
                                args.row.classList.add('orange');
                            }
                        }
                    }}
                    rowSelected={(event: any) => { console.log(event) }}
                    rowDeselected={(event: any) => { console.log(event) }}
                    detailTemplate={gridTemplate}
                    detailDataBound={detailDataBound}
                >
                    <ColumnsDirective>
                        {/*<ColumnDirective field='Select' type='checkbox' width={25} customAttributes={{ class: ['e-checkbox-custom'] }}/>*/}
                        <ColumnDirective field='action' template={actionTemplate} headerText='Action' width={140} minWidth={140} maxWidth={140}
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='status' template={statusTemplate} headerText='Status' width={140} minWidth={140} maxWidth={140}
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='ReasonType' headerText={enableNJCAP5 ? "Type" : "Reason Type"} width={160} minWidth={160} maxWidth={160}
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Reason' headerText={enableNJCAP5 ? "Purpose" : "Reason"} width={140} minWidth={140} maxWidth={140}
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Justification' headerText='Justification' visible={false} />
                        <ColumnDirective field='StartDate' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm ' }} headerText='Start Date'
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='ExpiryDate' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm ' }} headerText='Expiry Date'
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='NICNum' headerText='NIC#' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FelonyVehicle' headerText='Felony Vehicle' type={'boolean'}
                            template={(poi: any) => (<div>{poi?.FelonyVehicle ? 'Yes' : 'No'}</div>)} visible={false} />
                        <ColumnDirective field='FelonyVehicleReason' headerText='Felony Vehicle Reason'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='PlateNumber' width={110} minWidth={110} maxWidth={110} headerText='Plate Nuber 1'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='PlateSt' width={110} minWidth={110} maxWidth={110} headerText='Plate State 1'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='PlateNumber2' width={110} minWidth={110} maxWidth={110} headerText='Plate Nuber 2'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='PlateSt2' width={110} minWidth={110} maxWidth={110} headerText='Plate State 2'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='PlateNumber3' width={110} minWidth={110} maxWidth={110} headerText='Plate Nuber 3'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='PlateSt3' width={110} minWidth={110} maxWidth={110} headerText='Plate State 3'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='VIN' width={110} minWidth={110} maxWidth={110} headerText='VIN #'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FName' width={110} minWidth={110} maxWidth={110} headerText='First Name'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='MName' width={110} minWidth={110} maxWidth={110} headerText='MI'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='LName' width={110} minWidth={110} maxWidth={110} headerText='Last Name'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='DOB' format={{ type: 'dateTime', format: 'MM/dd/yyyy' }} width={110} minWidth={110} maxWidth={110} headerText='DOB'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='DLNo' width={110} minWidth={110} maxWidth={110} headerText='DL #'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='DLSt' width={110} minWidth={110} maxWidth={110} headerText='DL State'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SSN' width={110} minWidth={110} maxWidth={110} headerText='SSN'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FBINo' headerText='FBI#' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='SBINo' headerText='SBI#' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='plates' template={(poi: any) => (<>{poi?.PlateNumber} {poi?.PlateSt}{poi?.PlateNumber2 ? ', ' + poi?.PlateNumber2 : ''}
                            {poi?.PlateSt2}{poi?.PlateNumber3 ? ', ' + poi?.PlateNumber3 : ''} {poi?.PlateSt3}{poi?.VIN ? ', ' + poi?.VIN : ''}</>)}
                            width={250} minWidth={250} maxWidth={300} headerText='Vehicle (Plate#, State, Vin#)' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='person' template={(poi: any) => (<>{poi?.FName} {poi?.MName} {poi?.LName}</>)} width={250} minWidth={250} maxWidth={300}
                            headerText='POI (First Name, MI, Last Name)' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='other' template={(poi: any) => (<>{poi?.DLNo}{poi?.DLSt ? ', ' + poi?.DLSt : ''}{poi?.SSN ? ', ' + poi.SSN : ''}</>)}
                            width={200} minWidth={200} maxWidth={300} headerText='Other (DL#, State, SSN)' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='ConfidentialPOI' type={'boolean'} template={(poi: any) => (<div>{poi?.ConfidentialPOI ? 'Yes' : 'No'}</div>)}
                            headerText='Confidential' />
                        <ColumnDirective field='EmpID' headerText='Employee' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DeptName' headerText='Department' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DeptUnitName' headerText='Dept Unit' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DeptCounty' headerText='Dept County' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='SearchNotes' width={110} minWidth={110} maxWidth={110} headerText='Comments'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='AuthorizedBy' headerText='Supervisor' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='CaseNo' width={110} minWidth={110} maxWidth={110} headerText={GetStringNameForSite(SiteName, "Case #")}
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='AlertOption' width={110} minWidth={110} maxWidth={110} headerText='Alert Option'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='AdditionalEmail' width={110} minWidth={110} maxWidth={110} headerText='Notification Emails'
                            customAttributes={{ class: ['e-attr'] }} visible={false} />
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
                </GridComponent>
            </> : <></>}
            <Dialog
                open={poi}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UpdatePOI ID={POIID} CloseBox={CloseBox} />
            </Dialog>
        </div>
    )
};

export default SearchResults