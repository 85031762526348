import React from "react";
import { useLocation } from "react-router-dom";
import TowedVehicleDetailInfo from "../../components/Detail/_TowedVehicleDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function TowedVehicleDetail() {
    let query = useQuery();

    return (
        <div>
            <TowedVehicleDetailInfo ID={query.get("ID")} department={query.get("Dept")} />
        </div>
    );
}
export default TowedVehicleDetail;