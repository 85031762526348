import React, {useState} from "react";
import {PersonInfo} from "../../interfaces/getDarInfo_interface";
import {RunGetNCIC, RunPOIList} from "../../services/account.service";
import POIs from "../../components/Admin/POIs";
import SearchResults from "../../components/Admin/_searchResultsPOIs";
import NCIC from "../../components/Admin/NCIC";
import NCICAttributes from "../../interfaces/NCIC.interface";
import {useLocation} from "react-router-dom";
import NCIC_WALES from "../../components/Admin/NCIC_WALES";
import NCIC_WALES2 from "../../components/Admin/NCIC_WALES2";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ManageNCICWALES() {
    let [personID,setPersonID] = React.useState<string>("0")
    let query = useQuery();
    
    //when this gets called grab from query?

    
    
    return (
        <div className="w-full">
            {/*<NCIC_WALES ID={"0"} Hash={query.get("Hash")}/>*/}
            <NCIC_WALES2  ID={"0"} Hash={query.get("Hash")}/>
        </div>
    );
}

export default ManageNCICWALES;