import React, { forwardRef } from "react";

import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup, Checkbox,
    FormControl, FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup, Tooltip
} from "@mui/material";
import Slide, { SlideProps } from '@mui/material/Slide';
import { useHistory } from "react-router-dom";
import { SiteName } from "../../../services/config.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import { getDropDownCity, getDropDownQuery, getEcrawlCarrierList } from "../../../services/getDropdown.service";
import {formatInputDate} from "../../../services/formatDate.service";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import ErrorIcon from "@mui/icons-material/Error";
import {MultiSelect} from "primereact/multiselect";

interface ECrawlearchProps {
    SubmitSearch: any,
    passedData?: any | null,
    RefreshTrigger: any
}

interface ECrawlSearchRef {
    handleSubmit: any
}

const ECrawlReportSearch = forwardRef<ECrawlSearchRef, ECrawlearchProps>(({ SubmitSearch, RefreshTrigger, passedData = null }, ref) => {
    let today: Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date();
    if (toDate.getDay() === 1) {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    else{
        prevDate = new Date(toDate.setDate(toDate.getDate() - 1));
        fromday = prevDate //Default - Previous weekend
        //fromday = toDate;
    }
        
    
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", DOB: "", CarrierName: "", DOT: "", DL: "", Plate: "", VIN: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate:true, systemCall: false })
    const [dateType, setDateType] = React.useState<string>("Contact Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({ResponseCarrier:[]});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [submitError, setSubmitError] = React.useState<string>("")
    const [DDCarrierResponse, setDDCarrierResponse] = React.useState<any[]>([])
    
   
    
    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = { ...params }
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
            getEcrawlCarrierList().then((res)=> setDDCarrierResponse(res))
            
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };
    
    
    
    
    const [stateList, setStateList] = React.useState<any>([]);
    const [cityList, setCityList] = React.useState<any>([]);
    const history = useHistory();

    React.useImperativeHandle(ref, () => ({
        handleSubmit: () => handleClear(),
    }))

    const handleInitialBuild = async () => {
        //await handleStateDropdown()
        let p = { ...params }
        p.systemCall = true
        SubmitSearch(p, dateType, andOrType, dropDownParams)
    }

    React.useEffect(() => {
            let p = params
            if (params.FirstName?.length !== 0)
                p.showDate = false
            if (params.MI?.length !== 0)
                p.showDate = false
            if (params.LastName?.length !== 0)
                p.showDate = false
            if (params.DOB?.length !== 0)
                p.showDate = false       
            // if (params.CarrierName?.length !== 0)
            //     p.showDate = false   
            if(dropDownParams?.ResponseCarrier && dropDownParams?.ResponseCarrier.length !== 0)
                p.showDate = false       
            if (params.DOT?.length !== 0)
                p.showDate = false 
            if (params.DL?.length !== 0)
                p.showDate = false
            if (params.Plate?.length !== 0) 
                p.showDate = false            
            if (params.VIN?.length !== 0)
                p.showDate = false
            setParams(p)
        }, [params.FirstName, params.MI, params.LastName, params.DOB, dropDownParams.ResponseCarrier, params.DOT, params.DL, params.Plate, params.VIN])
    

    // const handleStateDropdown = async () => {
    //     setStateList([])
    //     var state = await getDropDownQuery('State')
    //     if (state && state?.length > 0) {
    //         setStateList(state)
    //     }
    //     var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
    //     let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
    //     const ids = tempList.map(({ City }) => City);
    //     const filtered = tempList.filter(({ City }, index) =>
    //         !ids.includes(City, index + 1));
    //     setCityList(filtered)
    // }

    React.useEffect(() => {
        handleInitialBuild()
    }, [passedData, RefreshTrigger])


    const handleClear = () => {
        dropDownParams.ResponseCarrier=[]
        setParams({ ...params, FirstName: "", MI: "", LastName: "", DOB: "", CarrierName: "", DOT: "", DL: "", Plate: "" , VIN: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate:true, systemCall: false })
        setDateType("Contact Date")
        setAndOrType("AND")
        setClear(clear + 1)
    }
    
   
    const handleSearch = () => {
        let dropDownParam = {...dropDownParams};
        try{
            if(dropDownParam && dropDownParam.City)
                dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        }catch (E){}
        if(handleValidation()){
            SubmitSearch(params, dateType, andOrType, dropDownParam)
        }
    }

    const handleDate = (e: any) => {
        let show = params.showDate
        setParams({ ...params, showDate: !show })
    }

    const handleValidation = ()=>{
        setSubmitError("")
        let errString="Please enter at least one search parameter."
        var val = false
        if(!params?.showDate){
            //if date disabled make sure user provides at least one field to conduct search
            if(params?.FirstName.trim().length > 0)
                val=true
            if(params.MI.trim().length > 0)
                val=true
            if(params.LastName.trim().length > 0)
                val=true
            // if(params.CarrierName.trim().length > 0)
            //     val=true
            if(dropDownParams?.ResponseCarrier && dropDownParams.ResponseCarrier?.length > 0)
                val=true
            if(params.DOT.trim().length > 0)
                val=true
            if(params.DL.trim().length > 0)
                val=true
            if(params.Plate.trim().length > 0)
                val=true
            if(params.VIN.trim().length > 0)
                val=true         
            if (params?.DOB) {
                //check dob is a valid date 
                let dob_date = new Date(params?.DOB).valueOf()
                let minDate = new Date('1900-01-01').valueOf()
                let maxDate = new Date().valueOf()
                if((dob_date > minDate && dob_date < maxDate)){
                    val = true;
                }
                else{
                    errString += " Invalid DOB date."
                }
            }             
        }
        else{
            val=true
        }
        if(!val)
            setSubmitError(errString)
        return val
    }
   

    return (
        <>
            <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
                <div className="grid grid-cols-7 gap-x-4 gap-y-4">
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        exclusive
                        size="small"
                        fullWidth
                        onChange={handleDateType}
                        sx={{mr: 2}}
                    >
                        <ToggleButton value="Contact Date" sx={{pr: 2, pl: 2}}>Contact Date</ToggleButton>
                        {/* <ToggleButton value="Entry Date" sx={{pr: 2, pl: 2}}>Entry Date</ToggleButton> */}
                    </ToggleButtonGroup>
                    {/* <div>
                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                   size={"small"} label="From Date"
                                   InputLabelProps={{shrink: true}}
                                   inputProps={{style: {textAlign: 'center'}}}
                                   value={params.fromDate}
                                   onChange={(e: any) => {
                                       setParams({...params, fromDate: e.target.value})
                                   }}
                        />
                    </div> */}
                    {/* <div>
                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                   size={"small"} label="To Date"
                                   InputLabelProps={{shrink: true}}
                                   inputProps={{style: {textAlign: 'center'}}}
                                   value={params.toDate}
                                   onChange={(e: any) => {
                                       setParams({...params, toDate: e.target.value})
                                   }}
                        />
                    </div> */}
                    <Checkbox checked={params.showDate} icon={<EventBusyOutlinedIcon />} checkedIcon={<EventAvailableOutlinedIcon />} onChange={handleDate} />
                    {(params.showDate) ?
                        <>
                            <div>
                                <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                    size={"small"} label="From Date"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    value={params.fromDate}
                                    onChange={(e: any) => {
                                        setParams({ ...params, fromDate: e.target.value })
                                    }}
                                />
                            </div>
                            <div>
                                <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                    size={"small"} label="To Date"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    value={params.toDate}
                                    onChange={(e: any) => {
                                        setParams({ ...params, toDate: e.target.value })
                                    }}
                                />
                            </div>
                        </>
                        : <></>
                    }
                </div>
                <div className="grid grid-cols-7 gap-x-4 gap-y-4" style={{"paddingTop": "10px", marginTop: '10px'}}>
                    <div><TextField margin='none' size={"small"} fullWidth label="First Name" value={params.FirstName}
                                    onChange={(e: any) => setParams({...params, FirstName: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="Middle Initial" value={params.MI}
                                    onChange={(e: any) => setParams({...params, MI: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="Last Name" value={params.LastName}
                                    onChange={(e: any) => setParams({...params, LastName: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth type="date" label="DOB"
                                    InputLabelProps={{shrink: true}} value={params.DOB}
                                    onChange={(e: any) => setParams({...params, DOB: e.target.value})}/></div>
                    <div className="mr-5" style={{width:'100%'}}>
                        <FormControl className="p-float-label" sx={{minWidth: '100%', maxWidth: '100%'}}>
                            <MultiSelect
                                value={dropDownParams?.ResponseCarrier}
                                onChange={(e) => {setDropDownParams({...dropDownParams, ResponseCarrier: e.value})}}
                                style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                resetFilterOnHide={true} showClear={true} showSelectAll={false}
                                inputId={"dd-ecrawlCarrier"} options={DDCarrierResponse} filter optionLabel="Value" optionValue="Key"
                                placeholder={'Carrier Name'} maxSelectedLabels={1} className="w-full md:w-20rem"/>
                            <label className={"bg-gray-100"} htmlFor="dd-ecrawlCarrier">Carrier</label>
                        </FormControl>
                    </div>
                    <div><TextField margin='none' size={"small"} fullWidth label="USDOT #" value={params.DOT}
                                    onChange={(e: any) => setParams({...params, DOT: e.target.value})}/></div>
                   <div><TextField margin='none' size={"small"} fullWidth label="CDL #" value={params.DL}
                                    onChange={(e: any) => setParams({...params, DL: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="Plate #" value={params.Plate}
                                    onChange={(e: any) => setParams({...params, Plate: e.target.value})}/></div>
                    <div><TextField margin='none' size={"small"} fullWidth label="VIN" value={params.VIN}
                                    onChange={(e: any) => setParams({...params, VIN: e.target.value})}/></div>
                </div>
                {/* <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{"marginTop": "25px"}}>
                    <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"ECrawl"}/></div>
                </div> */}
                <div className={"pt-3"} style={{"display": "flex", "justifyContent": "end"}}>
                    <ButtonGroup size="large" color="inherit">
                        <Button type="submit" onClick={handleSearch}>Search</Button>
                        <Button onClick={handleClear}>CLEAR</Button>
                    </ButtonGroup>
                </div>
                <span style={{ "display": "flex", "justifyContent": "end" }} className="col-span-4">{submitError?.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' />{submitError}</span> : <></>}</span>

            </div>
                      
        </>
    )
})

export default ECrawlReportSearch;
