import React from "react";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Container,
    Dialog,
    Tab,
    Tabs,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material'
import {MailOutline, PhoneIphone} from "@mui/icons-material";
import PinTimer from './_pinTimer'
import SystemNotice from './_systemNotice'
import Agreement from './_agreement'
import {Redirect} from "react-router-dom";
import {Account, LoginRequest, LoginResponse} from "../../../interfaces/auth_interface";
import {AcceptedAgreement, AcceptedSystemNotice, LoginAPI,} from "../../../services/auth.service";
import ErrorIcon from '@mui/icons-material/Error';
import {JAOS} from "../../../services/JAOS.service";
import {RunUserExpiryDays} from "../../../services/account.service";
import {Main_URL, SiteName, THREAT_URL} from "../../../services/config.service";
import {TabContext, TabPanel} from "@mui/lab";
import {GetDeptSSODetails} from "../../../services/sso.service";
import {GetDefaultSSOForSite, GetSSOTypeForSite, SSO_TYPE} from "../../../interfaces/config.interface";


export const SSOLogin = () => {
    const [details, setDetails] = React.useState({ empID: "", password: "", otp: ""});
    const [sendTo, setSendTo] = React.useState<string | null>('Email');
    const [validation, setValidation] = React.useState(false);
    const [error, setError] = React.useState<string | null>("");
    const [attempts, setAttempts] = React.useState(0);
    const [countdown, setCountdown] = React.useState(0);
    const [userAgreement, setUserAgreement] = React.useState(false);
    const [systemNotice, setSystemNotice] = React.useState(false);
    const [authentication, setAuthentication] = React.useState(false);
    const [authToken, setAuthToken] = React.useState("");
    const [empIDError, setEmpIdError] = React.useState<string>("")
    const [passwordError, setPasswordError] = React.useState<string>("")
    const [otpError, setOTPError] = React.useState<string>("")
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [creds, setCreds ] = React.useState<any>({})

    const [tab, setTab] = React.useState<string>('LOCAL')
    const [showLocalLogin, setShowLocalLogin] = React.useState<boolean>(!(GetSSOTypeForSite(SiteName) === SSO_TYPE.DEPT_USERID_PARSE))
    const [ssoDept, SetSsoDept] = React.useState<string>(GetDefaultSSOForSite(SiteName))

    const handleTabChange = (event:any, newValue:any) => {
        setTab(newValue);
    };
    
    new JAOS().clearStack();

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        await setError("")
        setIsLoading(true)

        if(GetSSOTypeForSite(SiteName) === SSO_TYPE.DEPT_USERID_PARSE && !showLocalLogin){
            

            GetDeptSSODetails(details.empID).then((res) => {
                if(res && res.length > 0){
                    setIsLoading(false)
                    if(res[0].SSOType === 'SAML'){
                        const form = document.createElement('form');
                        form.method = 'POST';
                        form.action = res[0].SAML2_IDP_REQ_ENDPOINT;
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = 'SAMLRequest';
                        input.value = res[0].SAML2_AUTH_REQUEST;
                        form.appendChild(input);

                        document.body.appendChild(form);
                        form.submit();
                        document.body.removeChild(form);

                    } else if(res[0].SSOType === 'OIDC'){
                        let sso_url = res[0].OIDC_AuthorizationEndPoint;
                        let sso_client_id = res[0].OIDC_ClientID;
                        let sso_params = `?response_type=code&scope=openid&client_id=${sso_client_id}&redirect_uri=${Main_URL}/auth/oidc/${ssoDept}/login`
                        window.location.href = sso_url + sso_params;
                    } else {
                        setShowLocalLogin(true)
                    }
                } else {
                    setShowLocalLogin(true)
                    setIsLoading(false)
                }
            }).catch(()=>{
                setShowLocalLogin(true)
                setIsLoading(false)
            });
        } 
        else if (handleValidation() && attempts < 6){

            let credsReq:LoginRequest = {
                EmpID: details.empID.trim(),
                Password: details.password,
                OTPType: sendTo,
                OTP: details.otp,
                OTPCountdown: countdown,
                OTPAttempts: attempts,
            }
            let res:LoginResponse = await LoginAPI(credsReq)

            if (res.LoggedUser && res.LoggedUser.UserValidation){
                //setCountdown(result.OTPCountdown)
                setCountdown(300)
                setValidation(true)
            }
            else if(res.LoggedUser)
                setError(res.LoggedUser.ErrorMessage)
            else
                setError("An Error Occurred During Authentication")


            if (res.LoggedUser && res.LoggedUser.LoginValidation)
            {

                if(res.AnyData)
                {
                    setCreds(res.AnyData)
                }
                if(res.AuthToken)
                {
                    let usrObj:Account = JSON.parse(atob(res.AuthToken.split('.')[1]))
                    if(usrObj.DeptAccountType === 'BTAM' && !res.LoggedUser.RedirectLink?.includes('PartialEdit'))
                    {
                        localStorage.setItem('user', res.AuthToken ? res.AuthToken : "")
                        window.location.href= THREAT_URL + "/auth?UserName=" + usrObj.EmpID + "&Dept=" + usrObj.Dept + '&OTP=' + usrObj.eOTP
                        return
                    }
                    else
                    {
                        localStorage.setItem('TA', 'false')
                        setAuthToken(res.AuthToken)
                    }
                }
                if(res.LoggedUser.RedirectLink === 'SystemNotice')
                {
                    setSystemNotice(true)
                }
                else if(res.LoggedUser.RedirectLink === 'Agreement')
                {
                    setUserAgreement(true)
                }
                else
                {
                    localStorage.setItem('user', res.AuthToken ? res.AuthToken : "")
                    window.location.href=res.LoggedUser.RedirectLink ? res.LoggedUser.RedirectLink : "/login"
                }

            }
            else
            {
                if(attempts > 0)
                {
                    if(res.LoggedUser && !res.LoggedUser.LoginValidation){
                        setError(res.LoggedUser.ErrorMessage)
                    }
                }
                setAttempts(attempts+1)
            }
            if(attempts > 5)
            {

                setError("Please Contact Your Agency Manager")
            }
        }
        else if (attempts > 5)
        {
            setError('You have exceeded the number of Login Attempts. Please Reset your Password or Contact your Agency Manager.')
        }
        setIsLoading(false)
    }

    const handleValidation = () => {
        let val = true
        setEmpIdError("")
        setPasswordError("")
        setOTPError("")

        if(details.empID === "")
        {
            setEmpIdError("User ID Can Not Be Blank")
            val = false
        }
        if(details.password === "")
        {
            setPasswordError("Password Can Not Be Blank")
            val = false
        }
        if(details.otp === "" && validation)
        {
            setOTPError("PIN Can Not Be Blank")
            val = false
        }

        return val
    }


    const handleSendTo = (event: React.MouseEvent<HTMLElement>, sendToLocation: string | null) => {
        if(sendToLocation)
            setSendTo(sendToLocation);
    };
    
    const LicenseExpiryDays = async () => {
        let search_query: any = {
            "EmpID": "",
            "DeptCode": ""
        }

        let qRes: any = await RunUserExpiryDays(search_query)
        localStorage.setItem('expiry', qRes?.AnyData)
    }
    

    const AcceptAgreement = (initials: string) => {
        //axios call here SetAgreement store initials
        localStorage.setItem('user', authToken)
        LicenseExpiryDays()
        let tempCreds = creds
        tempCreds.Agreement_Initial = initials
        AcceptedAgreement(tempCreds)
        setUserAgreement(false)
        setAuthentication(true)
    }

    const AcceptSystemNotice = () =>
    {
        localStorage.setItem('user', authToken)
        LicenseExpiryDays()
        //localStorage.setItem('userCreds', creds)
        AcceptedSystemNotice(creds)
        setSystemNotice(false)
        setAuthentication(true)
    }


    const handleSSOSubmit = (e:any) => {
        if(e)
            e.preventDefault();
        
        GetDeptSSODetails(ssoDept).then((res) => {
            if(res && res.length > 0){
                if(res[0].SSOType === 'SAML'){
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = res[0].SAML2_IDP_REQ_ENDPOINT;
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = 'SAMLRequest';
                    input.value = res[0].SAML2_AUTH_REQUEST;
                    form.appendChild(input);

                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);

                } else if(res[0].SSOType === 'OIDC'){
                    let sso_url = res[0].OIDC_AuthorizationEndPoint;
                    let sso_client_id = res[0].OIDC_ClientID;
                    let sso_params = `?response_type=code&scope=openid&client_id=${sso_client_id}&redirect_uri=${Main_URL}/auth/oidc/${ssoDept}/login`
                    window.location.href = sso_url + sso_params;
                }

            }
        }).catch(()=>{});
    };
    return (
        (authentication) ?  <Redirect push to="/" />:
            <div className = "min-w-screen min-h-screen bg-gray-800">
                {/*<div className = "min-w-screen min-h-screen" style ={{"backgroundColor":"#002B7A"}}></div>*/}
                
                <Container component="main" maxWidth="xs" >
                    <Box
                        sx={{
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}

                    >
                        <Card variant = "outlined" 
                              sx={{
                                  marginTop: 15,
                                  boxShadow: 25,
                              }}
                        >
                            {/*<img src = {logo} height = "150" width="400" alt=""/>*/}
                            <TabContext value={tab}>
                                {GetSSOTypeForSite(SiteName) === SSO_TYPE.DEPT_QUESTION &&
                                    <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
                                        <Tab label="BACKTRACE AUTHENTICATION"  value='LOCAL' />
                                        <Tab label="AGENCY AUTHENTICATION"  value='AGENCY' />
                                    </Tabs>
                                }
                            
                                <TabPanel value='AGENCY'  >
                                    <Box component="form" onSubmit={handleSSOSubmit} noValidate>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            label="Agency ID"
                                            autoFocus
                                            onChange={e => SetSsoDept(e.target.value)}
                                            value={ssoDept}
                                        />

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Validate
                                        </Button>
                                    </Box>
                                </TabPanel>
                                <TabPanel value='LOCAL' >
                                    <Box component="form" onSubmit={handleSubmit} noValidate>
                                        <TextField
                                            color = {empIDError.length > 0 ? "error" : "primary"}
                                            focused = {empIDError.length > 0 ? true : undefined}
                                            margin="normal"
                                            fullWidth
                                            label="User ID"
                                            autoFocus
                                            onChange={e => setDetails({...details, empID: e.target.value})}
                                            value={details.empID}
                                        />
                                        {empIDError.length > 0 ? <span style={{ color: "red", fontSize:14 }}><ErrorIcon style={{color:"red"}} fontSize = 'small' />{empIDError}</span> : <></> }

                                        {showLocalLogin && <>
                                            <TextField
                                                color = {passwordError.length > 0 ? "error": "primary"}
                                                focused = {passwordError.length > 0 ? true : undefined}
                                                margin="normal"
                                                fullWidth
                                                label="Password"
                                                type="password"
                                                onChange={e => setDetails({...details, password: e.target.value})}
                                                value={details.password}
                                            />
                                            {passwordError.length > 0 ? <span style={{ color: "red", fontSize:14 }}><ErrorIcon style={{color:"red"}} fontSize = 'small' />{passwordError}</span> : <></>}
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={sendTo}
                                                exclusive
                                                size = "medium"
                                                onChange={handleSendTo}
                                                sx = {{mt: 1}}
                                            >
                                                <ToggleButton value = ''  disabled sx = {{pr: 3.5, pl:2}} >Send PIN To</ToggleButton>
                                                <ToggleButton value="Email" sx = {{pr: 2, pl:3}} >Email<MailOutline sx = {{ml:1}} fontSize = 'small'/></ToggleButton>
                                                <ToggleButton value="Phone" sx = {{pr: 2, pl:3}} >Phone<PhoneIphone sx = {{ml:1}} fontSize = 'small'/></ToggleButton>
                                            </ToggleButtonGroup>
                                            
                                        </>}

                                        {(validation) ?
                                            <span>
                                                    <TextField
                                                        color = {otpError.length > 0 ? "error": "primary"}
                                                        focused = {otpError.length > 0 ? true : undefined}
                                                        margin="normal"
                                                        fullWidth
                                                        label="PIN"
                                                        type="password"
                                                        onChange={e => setDetails({...details, otp: e.target.value})}
                                                        value = {details.otp}
                                                    />
                                                {otpError.length > 0 ? <span style={{ color: "red", fontSize:14 }}><ErrorIcon style={{color:"red"}} fontSize = 'small' />{otpError}</span> : <></>}
                                                <div style = {{"textAlign":"center", "color":"green", "fontSize":"larger"}}>
                                                        <PinTimer countdown={countdown} />
                                                    </div>
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                    >
                                                        Login{(isLoading) ? <CircularProgress size={20} color="inherit" style={{"marginLeft" : "5px"}} /> : <></>}
                                                    </Button>
                                                </span>
                                            :
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 1 }}
                                            >
                                                Validate {(isLoading) ? <CircularProgress size={20} color="inherit" style={{"marginLeft" : "5px"}} /> : <></>}
                                            </Button>
                                        }
                                        <label style = {{"textAlign":"center", "color":"red", "fontSize":"10pt"}}>{error}</label>

                                        {showLocalLogin && <Button
                                            type="button"
                                            fullWidth
                                            style = {{"backgroundColor":"#db4035"}}
                                            variant="contained"
                                            sx={{ mt: 1, mb: 3 }}
                                            onClick={ () => {window.location.href = "/forgot-password"} }
                                        >
                                            Forgot Password?
                                        </Button> }
                                        
                                        {GetSSOTypeForSite(SiteName) === SSO_TYPE.DEPT_HARDCODED && <>
                                            <hr/>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 1, mb: 2 }}
                                                onClick={ () => {handleSSOSubmit(null)} }
                                            >
                                                Login via SAML
                                            </Button>
                                        </>
                                        }

                                        
                                    </Box>
                                </TabPanel>
                            </TabContext>
                            
                        </Card>
                    </Box>

                </Container>
                <Dialog
                    open={systemNotice}
                    maxWidth={'xl'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <SystemNotice AcceptSystemNotice = {AcceptSystemNotice}/>
                </Dialog>
                <Dialog
                    open={userAgreement}
                    maxWidth={'xl'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Agreement AcceptAgreement = {AcceptAgreement}/>
                </Dialog>
            </div>
    );

}
export default SSOLogin