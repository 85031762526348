import './_PersonTimeLineGroupedVertical.css'
import React, {useEffect} from "react";
import {formatDate, formatDT, formatTime} from "../../services/formatDate.service";
import {Card, CardContent} from "@mui/material";
import {GetStringNameForSite, SITE} from "../../interfaces/config.interface";
import {SiteName} from "../../services/config.service";

interface PersonTimeLineGroupedVerticalProps {
    data: any,
}

const  PersonTimeLineGroupedVertical: React.FC<PersonTimeLineGroupedVerticalProps> = ({data})=> {

    const [grpData, setGrpData] = React.useState<any>([])


    function groupByDay(array:any) {
        const groups : any = {};

        array.forEach((obj : any) => {
            try{
                const dateKey = obj.dt ? 'dt' : 'dt1';
                const dateString = obj[dateKey].toISOString().split('T')[0];
                if (!groups[dateString]) {
                    groups[dateString] = [];
                }
                groups[dateString].push(obj);
            }catch(e){
            console.log(e);
            }
        });

        return Object.values(groups);
    }
    
    useEffect(() => {
        setGrpData(groupByDay(data));
    }, [data])

    const createTooltip = (item: any, enableShadow: boolean = true) => {
        const node = item
        const sx: any = {maxWidth: 400, m: 'auto', mb: 1}
        const classNameStr: string = "border-2 border-blue-900 rounded-lg"
        const boxShadow: any = enableShadow? {boxShadow: '5px 5px 5px 3px lightgray'}: {height:'100%'};
        if (node) {
            if (node?.d?.type) {
                switch (node.d.type) {
                    case("PERSON_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>NAME</strong> {node?.d?.data?.FName} {node?.d?.data?.MName} {node?.d?.data?.LName}</div>
                                <div className="box-item"><strong>DOB</strong> {formatDate(node?.d?.data?.DOB)}</div>
                                <div className="box-item"><strong>ADDRESS</strong> {node?.d?.data?.Address}</div>
                                <div className="box-item"><strong>CITY</strong> {node?.d?.data?.City}</div>
                                <div className="box-item"><strong>STATE</strong> {node?.d?.data?.State}</div>
                                <div className="box-item"><strong>FBI</strong> {node?.d?.data?.FBI}</div>
                                <div className="box-item"><strong>{GetStringNameForSite(SiteName,'SBI')}</strong> {node?.d?.data?.SBINo}</div> 
                            </div>
                        )
                        break;
                    case("ARREST_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>AGENCY</strong> {node?.d?.data?.ORIGINATINGORINAME}</div>
                                <div className="box-item"><strong>AGENCY {getSiteFieldLabel('Case No')}</strong> {node?.d?.data?.AgencyCaseNo}</div>
                                <div className="box-item"><strong>STATUTE</strong> {node?.d?.data?.STATUTE} {node?.d?.data?.STATUTEDESCRIPTION}</div>
                                <div className="box-item"><strong>ADDRESS</strong> {node?.d?.data?.IncidentAddress}</div>
                                <div className="box-item"><strong>CITY</strong> {node?.d?.data?.IncidentCity}</div>
                                <div className="box-item"><strong>COUNTY</strong> {node?.d?.data?.IncidentCounty}</div>
                                <div className="box-item"><strong>STATE</strong> {node?.d?.data?.IncidentState}</div>
                                <div className="box-item"><strong>ZIP</strong> {node?.d?.data?.IncidentZip}</div>
                                <div className="box-item"><strong>RESIDENT COUNTY</strong> {node?.d?.data?.ResidentCounty}</div>
                                <div className="box-item"><strong>RESIDENT CITY</strong> {node?.d?.data?.ResidentCity}</div>
                                <div className="box-item"><strong>ARREST STATE</strong> {node?.d?.data?.ArrestedCounty}</div>
                            </div>
                        )
                        break;
                    case("ALPR_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>PLATE</strong> {node?.d?.data?.red_vrm}</div>
                                <div className="box-item"><strong>TIMESTAMP</strong> {node?.d?.data?.red_TimeStamp}</div>
                                <div className="box-item"><strong>SITE NAME</strong> {node?.d?.data?.ste_Name}</div>
                                <div className="box-item"><strong>SOURCE NAME</strong> {node?.d?.data?.src_Name}</div>
                                <div className="box-item"><strong>SOURCE DESCRIPTION</strong> {node?.d?.data?.src_Description}</div>
                                <div className="box-item"><strong>LOCATION</strong> {node?.d?.data?.Latitude} {node?.d?.data?.Longitude} </div>
                            </div>
                        )
                        break;
                    case("ASSOCPEOPLE_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>REASON</strong> {node?.d?.data?.AType ? node?.d?.data?.AType : node?.d?.data?.ROLE}</div>
                                <div className="box-item"><strong>NAME</strong> {node?.d?.data?.FName} {node?.d?.data?.MName} {node?.d?.data?.LName}</div>
                                <div className="box-item"><strong>DOB</strong> {formatDate(node?.d?.data?.DOB)}</div>
                                <div className="box-item"><strong>FBI</strong> {node?.d?.data?.FBI}</div>
                                <div className="box-item"><strong>{getSiteFieldLabel('SBI')}</strong> {node?.d?.data?.SBINo}</div>
                            </div>
                        )
                        break;
                    case("CADRMS_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>NIBRS</strong> {node?.d?.data?.NIBRSDesc}</div>
                                <div className="box-item"><strong>ROLE</strong> {node?.d?.data?.RoleDesc}</div>
                                <div className="box-item"><strong>AGENCY {getSiteFieldLabel('CASE')}:</strong> {node?.d?.data?.AgencyCaseNo}</div>
                                <div className="box-item"><strong>STATUS</strong> {node?.d?.data?.CaseStatus}</div>
                                <div className="box-item"><strong>{getSiteFieldLabel('STATUTE')}</strong> {node?.d?.data?.STATUTE} {node?.d?.data?.STATUTEDESCRIPTION}</div>
                                <div className="box-item"><strong>ADDRESS</strong> {node?.d?.data?.STATUTE} {node?.d?.data?.IncidentAddress}</div>
                                <div className="box-item"><strong>CITY</strong> {node?.d?.data?.STATUTE} {node?.d?.data?.City}</div>
                                <div className="box-item"><strong>STATE </strong> {node?.d?.data?.ST}</div>
                                <div className="box-item"><strong>ZIP</strong> {node?.d?.data?.ZIP}</div>
                            </div>
                        )
                        break;
                    case("DNA_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>AGENCY</strong> {node?.d?.data?.Agency}</div>
                                <div className="box-item"><strong>OFFENSE</strong> {node?.d?.data?.Offense}</div>
                                <div className="box-item"><strong>{getSiteFieldLabel('CASE')} #</strong> {node?.d?.data?.CaseNumber}</div>
                                <div className="box-item"><strong>REPORT </strong> {node?.d?.data?.Profile} / {node?.d?.data?.HitNumber}</div>
                                <div className="box-item"><strong>COUNTY </strong> {node?.d?.data?.County}</div>
                                <div className="box-item"><strong>REGION </strong> {node?.d?.data?.Region} </div>
                                <div className="box-item"><strong>SPECIMEN ID </strong> {node?.d?.data?.LabSpecimenID}</div>
                            </div>
                        )
                        break;
                    case("ETICKET_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>NAME</strong> {node?.d?.data?.eDefFName} {node?.d?.data?.eDefMI} {node?.d?.data?.eDefLName}</div>
                                <div className="box-item"><strong>ISSUED</strong>  {formatDT(node?.d?.data?.eCreated)}</div>
                                <div className="box-item"><strong>AGENCY</strong> {node?.d?.data?.eMunicipality}</div>
                                <div className="box-item"><strong>{getSiteFieldLabel('STATUTE')}</strong> {node?.d?.data?.eOffenseStat} {node?.d?.data?.eViolationDesc}</div>
                                <div className="box-item"><strong>PLATE</strong> {node?.d?.data?.ePlateSt} {node?.d?.data?.ePlateNbr}</div>
                                <div className="box-item"><strong>DL</strong> {node?.d?.data?.eDefDLSt} {node?.d?.data?.eDefDL}</div>
                                <div className="box-item"><strong>TICKET #</strong> {node?.d?.data?.TicketNmbr}</div>
                            </div>
                        )
                        break;
                    case("ETRACE_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>Agency {getSiteFieldLabel('Case No')}</strong> {node?.d?.data?.AgencyCaseNo}</div>
                                <div className="box-item"><strong>MANUFACTURER NAME</strong> {node?.d?.data?.MANUFACTURER_NAME}</div>
                                <div className="box-item"><strong>MODEL</strong> {node?.d?.data?.MODEL} </div>
                                <div className="box-item"><strong>CALIBER</strong> {node?.d?.data?.CALIBER}</div>
                                <div className="box-item"><strong>SERIAL NUMBER</strong> {node?.d?.data?.SERIAL_NUMBER}</div>
                                <div className="box-item"><strong>DESCRIPTION</strong> {node?.d?.data?.W_TYPE_DESC}</div>
                                <div className="box-item"><strong>City</strong> {node?.d?.data?.City} </div>
                                <div className="box-item"><strong>Incident Address</strong> {node?.d?.data?.IncidentAddress}</div>
                                <div className="box-item"><strong>ST</strong> {node?.d?.data?.ST} </div>
                                <div className="box-item"><strong>ZIP</strong> {node?.d?.data?.ZIP} </div>
                                <div className="box-item"><strong>Latitude</strong> {node?.d?.data?.Latitude}</div>
                                <div className="box-item"><strong>Longitude</strong> {node?.d?.data?.Longitude}</div>
                                <div className="box-item"><strong>ATF #</strong> {node?.d?.data?.ATFNo} </div>
                                <div className="box-item"><strong>Crime History</strong> {node?.d?.data?.CrimeHistory}</div>
                                <div className="box-item"><strong>Dealer #</strong> {node?.d?.data?.DealerNo}</div>
                                <div className="box-item"><strong>Dealer Name</strong> {node?.d?.data?.DealerName}</div>
                            </div>
                        )
                        break;
                    case("NIBIN_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>Agency</strong> {node?.d?.data?.Agency} </div>
                                <div className="box-item"><strong>OffenseDate</strong> {formatDT(node?.d?.data?.OffenseDate)}</div>
                                <div className="box-item"><strong>Offense</strong> {node?.d?.data?.Offense}</div>
                                <div className="box-item"><strong>{getSiteFieldLabel('CaseNumber')}</strong> {node?.d?.data?.CaseNumber}</div>
                                <div className="box-item"><strong>Profile</strong> {node?.d?.data?.Profile}</div>
                                <div className="box-item"><strong>NIBINNo</strong> {node?.d?.data?.NIBINNo}</div>
                                <div className="box-item"><strong>HitNumber</strong> {node?.d?.data?.HitNumber}</div>
                                <div className="box-item"><strong>County</strong> {node?.d?.data?.County} </div>
                                <div className="box-item"><strong>City</strong> {node?.d?.data?.City} </div>
                                <div className="box-item"><strong>IncidentAddress</strong> {node?.d?.data?.IncidentAddress}</div>
                                <div className="box-item"><strong>ST</strong> {node?.d?.data?.ST} </div>
                                <div className="box-item"><strong>ZIP</strong> {node?.d?.data?.ZIP} </div>
                                <div className="box-item"><strong>Latitude</strong> {node?.d?.data?.Latitude}</div>
                                <div className="box-item"><strong>Longitude</strong> {node?.d?.data?.Longitude}
                                </div>
                            </div>
                        )
                        break;
                    case("NJPOP_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>Dept</strong> {node?.d?.data?.Dept} </div>
                                <div className="box-item"><strong>Agency {getSiteFieldLabel('Case No')}</strong> {node?.d?.data?.AgencyCaseNo}</div>
                                <div className="box-item"><strong>Timestamp</strong> {formatDT(node?.d?.data?.DateTimeIn)}</div>
                                <div className="box-item"><strong>Case Status</strong> {node?.d?.data?.CaseStatus}</div>
                                <div className="box-item"><strong>City</strong> {node?.d?.data?.City} </div>
                                <div className="box-item"><strong>Incident Address</strong> {node?.d?.data?.IncidentAddress}</div>
                                <div className="box-item"><strong>ST</strong> {node?.d?.data?.ST} </div>
                                <div className="box-item"><strong>ZIP</strong> {node?.d?.data?.ZIP} </div>
                                <div className="box-item"><strong>Role</strong> {node?.d?.data?.RoleDesc} </div>
                                <div className="box-item"><strong>Latitude</strong> {node?.d?.data?.Latitude}</div>
                                <div className="box-item"><strong>Longitude</strong> {node?.d?.data?.Longitude}</div>
                                <div className="box-item"><strong>Incident ID</strong> {node?.d?.data?.IncidentID}</div>
                                <div className="box-item"><strong>Incident Type</strong> {node?.d?.data?.IncidentType}</div>
                            </div>
                        )
                        break;
                    case("NJTRACE_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>Agency {getSiteFieldLabel('Case No')}</strong> {node?.d?.data?.AgencyCaseNo}</div>
                                <div className="box-item"><strong>MANUFACTURER NAME</strong> {node?.d?.data?.MANUFACTURER_NAME}</div>
                                <div className="box-item"><strong>MODEL</strong> {node?.d?.data?.MODEL} </div>
                                <div className="box-item"><strong>CALIBER</strong> {node?.d?.data?.CALIBER}</div>
                                <div className="box-item"><strong>SERIAL NUMBER</strong> {node?.d?.data?.SERIAL_NUMBER}</div>
                                <div className="box-item"><strong>DESCRIPTION</strong> {node?.d?.data?.W_TYPE_DESC}</div>
                                <div className="box-item"><strong>City</strong> {node?.d?.data?.City} </div>
                                <div className="box-item"><strong>Incident Address</strong> {node?.d?.data?.IncidentAddress}</div>
                                <div className="box-item"><strong>ST</strong> {node?.d?.data?.ST} </div>
                                <div className="box-item"><strong>ZIP</strong> {node?.d?.data?.ZIP} </div>
                                <div className="box-item"><strong>Latitude</strong> {node?.d?.data?.Latitude}</div>
                                <div className="box-item"><strong>Longitude</strong> {node?.d?.data?.Longitude}</div>
                                <div className="box-item"><strong>ATF #</strong> {node?.d?.data?.ATFNo} </div>
                                <div className="box-item"><strong>Crime History</strong> {node?.d?.data?.CrimeHistory}</div>
                                <div className="box-item"><strong>Dealer #</strong> {node?.d?.data?.DealerNo}</div>
                                <div className="box-item"><strong>Dealer Name</strong> {node?.d?.data?.DealerName}</div>
                            </div>
                        )
                        break;
                    case("PARKMOBILE_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>ZONE</strong> {node?.d?.data?.agencyshortname} {node?.d?.data?.zoneid}</div>
                                <div className="box-item"><strong>PLATE</strong> {node?.d?.data?.platestate} {node?.d?.data?.plate}</div>
                                <div className="box-item"><strong>PARK TIME</strong> {formatDT(node?.d?.data?.startdatetime)} {formatDT(node?.d?.data?.enddatetime)}</div>
                                <div className="box-item"><strong>LOCATION</strong> {node?.d?.data?.Latitude} {node?.d?.data?.Longitude}</div>
                            </div>
                        )
                        break;
                    case("PAROLE_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>START</strong> {formatDate(node?.d?.data?.Start_Date)}</div>
                                <div className="box-item"><strong>END</strong> {formatDT(node?.d?.data?.End_Date)}</div>
                                <div className="box-item"><strong>STATUS</strong> {formatDT(node?.d?.data?.STATUS)}</div>
                                <div className="box-item"><strong>{getSiteFieldLabel('STATUTE')}</strong> {node?.d?.data?.STATUTE} {node?.d?.data?.STATUTEDESCRIPTION}</div>
                            </div>
                        )
                        break;
                    case("PRISON_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>ADMISSION</strong> {node?.d?.data?.Admission_Date}</div>
                                <div className="box-item"><strong>CONVICTION COUNTY</strong> {node?.d?.data?.CONVICTION_County}</div>
                                <div className="box-item"><strong>BOOKING NO</strong> {node?.d?.data?.BOOKING_NO}</div>
                                <div className="box-item"><strong>OFFENDER BOOK ID</strong> {node?.d?.data?.OFFENDER_BOOK_ID}</div>
                                <div className="box-item"><strong>CASE LOAD</strong> {node?.d?.data?.CASELOAD}</div>
                                <div className="box-item"><strong>RELEASE DATE</strong> {node?.d?.data?.RELEASE_DATE}</div>
                                <div className="box-item"><strong>MOVEMENT REASON CODE</strong> {node?.d?.data?.MOVEMENT_REASON_CODE}</div>
                                <div className="box-item"><strong>PAROLE</strong> {node?.d?.data?.Parole_supr}</div>
                                <div className="box-item"><strong>STATUS</strong> {node?.d?.data?.STATUS}</div>
                                <div className="box-item"><strong>MAX TERM</strong> {node?.d?.data?.MAXTERM}</div>
                            </div>
                        )
                        break;
                    case("PROFESSION_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>NAME</strong> {node?.d?.data?.LicenseName}</div>
                                <div className="box-item"><strong>ISSUANCE DATE</strong> {formatDT(node?.d?.data?.IssueDate)}</div>
                                <div className="box-item"><strong>EXPIRATION DATE</strong> {formatDT(node?.d?.data?.ExpirationDate)}</div>
                                <div className="box-item"><strong>PROFESSION</strong> {node?.d?.data?.ProfessionName}</div>
                                <div className="box-item"><strong>LICENSE #</strong> {node?.d?.data?.LicenseNo}</div>
                                <div className="box-item"><strong>STATUS</strong> {node?.d?.data?.Status}</div>
                            </div>
                        )
                        break;
                    case("VIOLENT_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>START DATE</strong>  {formatDT(node?.d?.data?.Start_Date)}</div>
                                <div className="box-item"><strong>VALIDATION DATE</strong> {formatDT(node?.d?.data?.ValidationDate)}</div>
                                <div className="box-item"><strong>CASE #</strong> {node?.d?.data?.CaseNo}</div>
                                <div className="box-item"><strong>AGENCY</strong> {node?.d?.data?.DeptORI}</div>
                                <div className="box-item"><strong>CRITERIA</strong> {node?.d?.data?.Criteria}</div>
                                <div className="box-item"><strong>NOTES</strong> {node?.d?.data?.Notes}</div>
                                <div className="box-item"><strong>MEDICAL</strong> {node?.d?.data?.MedicalCondition}</div>
                                <div className="box-item"><strong>CODIS</strong> {node?.d?.data?.CODIS_Notes}</div>
                            </div>
                        )
                        break;

                    case("JAIL_NODE"):
                        return (
                            <div>
                                <div className="box-content">
                                    <div className="box-item"><span className="font-bold">COMMITMENT COUNTY: </span> {node.d?.data?.COMMITMENT_COUNTY}</div>
                                    <div className="box-item"><span className="font-bold">COMMITMENT NUMBER: </span> {node.d?.data?.COMMITMENT_NUMBER}</div>
                                    <div className="box-item"><span className="font-bold">COMMITMENT DATE: </span> {formatDate(node.d?.data?.COMMITMENT_DATE)}</div>
                                    <div className="box-item"><span className="font-bold">COMMITMENT TIME: </span> {node.d?.data?.COMMITMENT_TIME}</div>
                                    <div className="box-item"><span className="font-bold">RELEASE DATE: </span> {formatDate(node.d?.data?.RELEASE_DATE)}</div>
                                    <div className="box-item"><span className="font-bold">RELEASE TIME: </span> {node.d?.data?.RELEASE_TIME}</div>
                                    <div className="box-item"><span className="font-bold">RELEASE REASON: </span> {node.d?.data?.RELEASE_REASON}</div>
                                    <div className="box-item"><span className="font-bold">LOCATION COUNTY: </span> {node.d?.data?.LOCATION_COUNTY}</div>
                                    <div className="box-item"><span className="font-bold">LOCATION UNIT: </span> {node.d?.data?.LOCATION_UNIT}</div>
                                    <div className="box-item"><span className="font-bold">LOCATION SECTION: </span> {node.d?.data?.LOCATION_SECTION}</div>
                                    <div className="box-item"><span className="font-bold">LOCATION CELL: </span> {node.d?.data?.LOCATION_CELL}</div>
                                    <div className="box-item"><span className="font-bold">LOCATION BED: </span> {node.d?.data?.LOCATION_BED}</div>
                                    <div className="box-item"><span className="font-bold">LOCATION NUMBER: </span> {node.d?.data?.LOCATION_NUMBER}</div>
                                </div>
                            </div>
                        )
                        break;
                    case("MISSINGPERSON_NODE"):
                        return(
                            <div>
                                <div className="box-content">
                                    <div><span className="box-item"><strong>NAME:</strong> </span> {node.d?.data?.FName + ' ' + node.d?.data?.LName}</div>
                                    <div><span className="box-item"><strong>REPORT DATE:</strong> </span> {formatDate(node.d?.data?.ReportDate)}</div>
                                    <div><span className="box-item"><strong>LAST KNOWN LOCATION:</strong> </span> {node.d?.data?.LastKnownLocation}</div>
                                    <div><span className="box-item"><strong>{GetStringNameForSite(SiteName, "SBI")}:</strong> </span> {node.d?.data?.SBINo}</div>
                                    <div><span className="box-item"><strong>FBI:</strong> </span> {node.d?.data?.FBI}</div>
                                    <div><span className="box-item"><strong>ROLE:</strong> </span> {node.d?.data?.RoleDesc}</div>
                                    <div><span className="box-item"><strong>STATUS:</strong> </span> {node.d?.data?.STATUS}</div>
                                    <div><span className="box-item"><strong>RECORD NUMBER:</strong> </span> {node.d?.data?.recordNumber}</div>
                                </div>
                            </div>
                        )
                        break;
                    case("INFOCOP_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>PLATE</strong> {node?.d?.data?.PlateSt} {node?.d?.data?.PlateNumber}</div>
                                <div className="box-item"><strong>DL</strong> {node?.d?.data?.DLSt} {node?.d?.data?.DLNo}</div>
                                <div className="box-item"><strong>TIMESTAMP</strong> {formatDT(node?.d?.data?.DateTimeIN)}</div>
                                <div className="box-item"><strong>AGENCY</strong> {node?.d?.data?.DeptName}</div>
                            </div>
                        )
                        break;
                    case("PLATE_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>PLATE</strong>  {node?.d?.data?.PlateSt} {node?.d?.data?.PlateNo}</div>
                                <div className="box-item"><strong>SOURCE</strong> {node?.d?.data?.Source}</div>
                                <div className="box-item"><strong>TIMESTAMP</strong> {formatDT(node?.d?.data?.StartDate)}</div>
                            </div>
                        )
                        break;
                    case("FFL_INFO_CONTAINER"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>FFL #</strong> {node?.d?.data?.FFL_Number}</div>
                                <div className="box-item"><strong>NAME</strong> {node?.d?.data?.FFL_NAME} </div>
                                <div className="box-item"><strong>STREET</strong> {node?.d?.data?.FFL_STREET}</div>
                                <div className="box-item"><strong>CITY</strong> {node?.d?.data?.FFL_CITY} </div>
                                <div className="box-item"><strong>COUNTY</strong> {node?.d?.data?.FFL_COUNTY}</div>
                                <div className="box-item"><strong>STATE</strong> {node?.d?.data?.FFL_STATE}</div>
                                <div className="box-item"><strong>ZIP</strong> {node?.d?.data?.FFL_ZIP} </div>
                                <div className="box-item"><strong>COUNTRY</strong> {node?.d?.data?.FFL_COUNTRY}</div>
                                <div className="box-item"><strong>PHONE</strong> {node?.d?.data?.FFL_PHONE}</div>
                            </div>
                        )
                        break;

                    case("WEAPON_ETRACE_FFL_NODE"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>FFL #</strong> {node?.d?.data?.FFL_Number}</div>
                                <div className="box-item"><strong>MAKE</strong> {node?.d?.data?.MAN_CODE} </div>
                                <div className="box-item"><strong>CALIBER</strong> {node?.d?.data?.WPN_CBL}</div>
                                <div className="box-item"><strong>SERIAL</strong> {node?.d?.data?.WPN_SN} </div>
                            </div>
                        )
                        break;

                    case("NIBIN_INCIDENT"):
                        return (
                            <div className="box-content">
                                {node.d.data.Inc_Location &&
                                    <div className="box-item"><strong>Address</strong> {node.d.data.Inc_Location}</div>}
                                {node.d.data.OccurrenceDate &&
                                    <div><span className="font-bold">Date-Time</span> {formatDT(node.d.data.OccurrenceDate)}</div>}
                            </div>
                        )
                        break;

                    case("NIBIN_WEAPON"):
                        return (
                            <div className="box-content">
                                {node.d.data.CALIBER &&
                                    <div className="box-item"><strong>Caliber</strong> {node.d.data.CALIBER}</div>}
                                {node.d.data.MAKE &&
                                    <div className="box-item"><strong>Make</strong> {node.d.data.MAKE}</div>}
                                {node.d.data.W_CLASS &&
                                    <div className="box-item"><strong>Class</strong> {node.d.data.W_CLASS}</div>}
                                {node.d.data.MODEL &&
                                    <div className="box-item"><strong>Model</strong> {node.d.data.MODEL}</div>}
                                {node.d.data.SERIAL_NUMBER &&
                                    <div className="box-item"><strong>Serial Number</strong> {node.d.data.SERIAL_NUMBER}</div>}
                            </div>
                        )
                        break;
                    case("NIBIN_BALLISTIC"):
                        return (
                            <div className="box-content">
                                <div className="box-item"><strong>Ballistic</strong> ({node?.d?.data?.Item_Quantity}) {node.t}</div>
                            </div>
                        )
                        break;

                    default:
                        return (<></>)
                        break;
                }
            }

        } else {
            return (<></>)
        }
    }

    const getSiteFieldLabel = (field:string) => {
        if(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL){
            switch(field) {
                case("SBI"):
                    return("PDID")
                    break;
                case("STATUTE"):
                    return("OFFENSE")
                    break;
                case("CASE NO"):
                case("Case No"):
                case("CASE"):
                case("CaseNumber"):
                    return("CCN")
                    break;
            }
        }
        else{
            return field
        }
    }
    
    const getDateFromGrp = (grp : any) => {
        if (grp && grp.length > 0) {
            try{
                const dateKey = grp[0].dt ? 'dt' : 'dt1';
                return formatDate(grp[0][dateKey]);
            }catch (e) {
                console.log(e)
                return "";
            }
            
        }
        return "";
    }

    const getNodeType = (nodeType: string) => {
        switch (nodeType) {
            case("PERSON_NODE"):
                return ("PERSON")
                break;
            case("ARREST_NODE"):
                return ("ARREST")
                break;
            case("ASSOCPEOPLE_NODE"):
                return ("ASSOCIATED PEOPLE")
                break;
            case("CADRMS_NODE"):
                return (GetStringNameForSite(SiteName,"CAD/RMS").toUpperCase())
                break;
            case("DNA_NODE"):
                return ("CODIS")
                break;
            case("ETICKET_NODE"):
                return ("ETICKET")
                break;
            case("NJPOP_NODE"):
                return (GetStringNameForSite(SiteName,"NJPOP"))
                break;
            case("NJTRACE_NODE"):
                return (GetStringNameForSite(SiteName,"NJTRACE"))
                break;
            case("PARKMOBILE_NODE"):
                return ("PARKMOBILE")
                break;
            case("PAROLE_NODE"):
                return ("PAROLE")
                break;
            case("PRISON_NODE"):
                return ("PRISON")
                break;
            case("PROFESSION_NODE"):
                return ("PROFESSION")
                break;
            case("VIOLENT_NODE"):
                return ("VIOLENT OFFENDER")
                break;
            case("INFOCOP_NODE"):
                return ("INFOCOP")
                break;
            case("PLATE_NODE"):
            case("ALPR_NODE"):
                return ("ALPR")
                break;
            case("FFL_INFO_CONTAINER"):
            case("WEAPON_ETRACE_FFL_NODE"):
            case("ETRACE_NODE"):
                return ("ETRACE")
                break;
            case("NIBIN_NODE"):
            case("NIBIN_INCIDENT"):
            case("NIBIN_WEAPON"):
            case("NIBIN_BALLISTIC"):
                return ("NIBIN")
                break;
            case("JAIL_NODE"):
                return ("JAIL")
                break;
            case("MISSINGPERSON_NODE"):
                return ("MISSING PERSON")
                break;

            default:
                return (<></>)
                break;
        }
    }
    
    return(
        <div style={{height:'70vh', overflowY:'scroll', margin:'2rem', padding:'2rem', border:'1px solid lightblue'}}>
            <div className="container">
                <div className="timeline">
                    <div className="timeline-month">
                        PERSON TIMELINE
                    </div>
                    {grpData?.map((grp : any, index : any) => (
                        <div className="timeline-section" key={index}>
                            <div className="timeline-date">
                                {getDateFromGrp(grp)}
                            </div>
                            <div className="grid grid-cols-4 gap-4">
                                {grp?.map((node : any, idx2 : any) => (
                                    <div key={index+"-"+idx2}>
                                        <div className="timeline-box">
                                            <div className="box-title">
                                                {getNodeType(node?.d?.type)} {node?.dt ? formatTime(node?.dt) : node?.dt1? formatTime(node?.dt1) : ""}
                                            </div>
                                            {createTooltip(node)}
                                            {/*<div className="box-content">
                                                <div className="box-item"><strong>Loss Type</strong>: A/C Leak</div>
                                                <div className="box-item"><strong>Loss Territory</strong>: Texas</div>
                                                <div className="box-item"><strong>Start Date</strong>: 08/22/2018</div>
                                            </div>*/}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    ))}
                    
                </div>
            </div>
        </div>
    );
};

export default PersonTimeLineGroupedVertical;