import React, { forwardRef } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup, Checkbox,
    FormControl, FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup, Tooltip
} from "@mui/material";
import Slide, { SlideProps } from '@mui/material/Slide';
import { useHistory } from "react-router-dom";
import { SiteName } from "../../../services/config.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import { getDropDownCity, getDropDownQuery, getOffenderRatingList, getOffenderUserList } from "../../../services/getDropdown.service";
import { formatInputDate } from "../../../services/formatDate.service";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import { MultiSelect } from "primereact/multiselect";
import { TreeSelect } from "primereact/treeselect";

interface GunOffenderSearchProps {
    SubmitSearch: any,
    passedData?: any | null,
    RefreshTrigger: any
}

interface GunOffenderSearchRef {
    handleSubmit: any
}

const GunOffenderSearch = forwardRef<GunOffenderSearchRef, GunOffenderSearchProps>(({ SubmitSearch, RefreshTrigger, passedData = null }, ref) => {
    let today: Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date();
    if (toDate.getDay() === 1) {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    else {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 1));
        fromday = prevDate //Default - Previous weekend
        //fromday = toDate;
    }
    let futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 30)

    const [DDRatingResponse, setDDRatingResponse] = React.useState<any[]>([])
    const [DDUserResponse, setDDUserResponse] = React.useState<any[]>([])
    const [countyList, setCountyList] = React.useState<any>([]);
    const [DDCity, setDDCity] = React.useState<any>([])
    const [DDIncidentCity, setDDIncidentCity] = React.useState<any[]>([])
    const [params, setParams] = React.useState({ FirstName: "", LastName: "", DOB: "", PDID: "", CaseNo: "", Address: "", OffenderLevelRating: "", PSA: "", District: "", Users: "", toDate: formatInputDate(futureDate), fromDate: formatInputDate(today), systemCall: false })
    const [dateType, setDateType] = React.useState<string>("GORU Search");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    const handleFieldChange = (fieldName: string, value: any) => {
        setParams({ ...params, [fieldName]: value });
        setDateType("GORU Search"); // Update dateType when any field is typed into
    };


    const getValueTemplate = (selected: any) => {
        if (selected && selected.length > 0) {
            let count = 0;
            for (const statute of selected) {
                if (!statute || !statute?.key?.includes('BT_UNIQUE_GROUP'))
                    count++;
            }
            let countStr: string = count.toString();
            if (countStr === "0") {
                countStr = "None"
            }
            return (countStr + " selected");
        }
        else
            return ("")
    }

    const parseCityGlobal = (data: any): any => {
        let city = []
        let count = 1;
        for (const [key, options] of Object.entries(data)) {
            let options1: any = options;
            let cityItem: any = {
                key: "BT_UNIQUE_GROUP" + count.toString(),
                label: key,
                children: []
            }
            for (const item of options1) {
                cityItem.children.push({
                    key: item.Key,
                    label: item.Value,
                })
            }
            city.push(cityItem)
            count++;
        }
        return city;
    }

    const parseCity = (data: any): any => {
        let city = parseCityGlobal(data)
        setDDCity(city)
        setDDIncidentCity(city)
    }

    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = { ...params }
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
            getOffenderRatingList().then((res) => setDDRatingResponse(res))
            getOffenderUserList().then((res) => setDDUserResponse(res))
            getDropDownQuery('ResidentCounty').then(counties => {
                if (counties && counties?.length > 0) {
                    setCountyList(counties)
                }
            }).catch(e => { console.log(e) })
            getDropDownQuery('City').then((res) => { parseCity(res) })
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };




    const [stateList, setStateList] = React.useState<any>([]);
    const [cityList, setCityList] = React.useState<any>([]);
    const history = useHistory();

    React.useImperativeHandle(ref, () => ({
        handleSubmit: () => handleClear(),
    }))

    const handleInitialBuild = async () => {
        await handleStateDropdown()
        let p = { ...params }
        p.systemCall = true
        SubmitSearch(p, dateType, andOrType, dropDownParams)
    }

    const handleStateDropdown = async () => {
        setStateList([])
        var state = await getDropDownQuery('State')
        if (state && state?.length > 0) {
            setStateList(state)
        }
        var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
        let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
        const ids = tempList.map(({ City }) => City);
        const filtered = tempList.filter(({ City }, index) =>
            !ids.includes(City, index + 1));
        setCityList(filtered)
    }

    React.useEffect(() => {
        handleInitialBuild()
    }, [passedData, RefreshTrigger])


    const handleClear = () => {
        setParams({ FirstName: "", LastName: "", DOB: "", PDID: "", CaseNo: "", Address: "", OffenderLevelRating: "", PSA: "", District: "", Users: "", toDate: formatInputDate(futureDate), fromDate: formatInputDate(today), systemCall: false })
        setAndOrType("AND")
        setClear(clear + 1)
    }


    const handleSearch = () => {
        let dropDownParam = { ...dropDownParams };
        try {
            if (dropDownParam && dropDownParam.City)
                dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        } catch (E) { }

        const searchParams = { ...params };
        if (dateType === "GORU Search") {
            searchParams.fromDate = "";
            searchParams.toDate = "";
        }
        else {
            searchParams.fromDate = params.fromDate
            searchParams.toDate = params.toDate
        }

        SubmitSearch(searchParams, dateType, andOrType, dropDownParam);
    }



    return (
        <>
            <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
                <div className="grid grid-cols-7 gap-x-4 gap-y-4">
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        exclusive
                        size="small"
                        fullWidth
                        onChange={handleDateType}
                        sx={{ mr: 2 }}
                    >
                        <ToggleButton value="GORU Search" sx={{ pr: 2, pl: 2 }}>GORU Search</ToggleButton>
                        <ToggleButton value="Visit Date" sx={{ pr: 2, pl: 2} }>Search By Visit Dates</ToggleButton>
                    </ToggleButtonGroup>
                    {(dateType !== "GORU Search") && (
                        <>
                            <div>
                                <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                    size={"small"} label="From Date"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    value={params.fromDate}
                                    onChange={(e: any) => {
                                        setParams({ ...params, fromDate: e.target.value })
                                    }}
                                />
                            </div>
                            <div>
                                <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                    size={"small"} label="To Date"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    value={params.toDate}
                                    onChange={(e: any) => {
                                        setParams({ ...params, toDate: e.target.value })
                                    }}
                                />
                            </div>
                        </>
                    ) }
                </div>
                <div className="grid grid-cols-7 gap-x-4 gap-y-4" style={{ "paddingTop": "10px", marginTop: '10px' }}>
                    <div>
                        <TextField margin='none' size={"small"} fullWidth label="First Name" value={params.FirstName}
                            onChange={(e: any) => handleFieldChange("FirstName", e.target.value)} />
                    </div>
                    <div>
                        <TextField margin='none' size={"small"} fullWidth label="Last Name" value={params.LastName}
                            onChange={(e: any) => handleFieldChange("LastName", e.target.value)} />
                    </div>
                    <div>
                        <TextField margin='none' size={"small"} fullWidth type="date" label="DOB"
                            InputLabelProps={{ shrink: true }} value={params.DOB}
                            onChange={(e: any) => handleFieldChange("DOB", e.target.value)} />
                    </div>
                    <div>
                        <TextField margin='none' size={"small"} fullWidth label="PDID" value={params.PDID}
                            onChange={(e: any) => handleFieldChange("PDID", e.target.value)} />
                    </div>
                    <div>
                        <TextField margin='none' size={"small"} fullWidth label="Case #" value={params.CaseNo}
                            onChange={(e: any) => handleFieldChange("CaseNo", e.target.value)} />
                    </div>
                    <div>
                        <TextField margin='none' size={"small"} fullWidth label="Address" value={params.Address}
                            onChange={(e: any) => handleFieldChange("Address", e.target.value)} />
                    </div>

                    <div>
                    </div>
                    {(dateType !== "GORU Search") && (
                        <div>
                            <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                                <MultiSelect
                                    value={params.OffenderLevelRating}
                                    onChange={(e: any) => { setParams({ ...params, OffenderLevelRating: e.target.value }) }}
                                    style={{ border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start' }}
                                    resetFilterOnHide={true} showClear={true} showSelectAll={false}
                                    inputId={"dd-offenderlevelrating"} options={DDRatingResponse} filter optionLabel="Value" optionValue="Key"
                                    placeholder={'Offender Level Rating'} maxSelectedLabels={1} className="w-full md:w-20rem" />
                                <label className={"bg-gray-100"} htmlFor="dd-rating">Rating</label>
                            </FormControl>
                        </div>
                    ) }
                    <div>
                        <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                            <MultiSelect
                                value={params.District}
                                onChange={(e: any) => { setParams({ ...params, District: e.target.value }) }}
                                style={{ border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start' }}
                                resetFilterOnHide={true} showClear={true} showSelectAll={false}
                                inputId={"dd-district"} options={countyList} filter optionLabel="Value" optionValue="Key"
                                placeholder={'District'} maxSelectedLabels={1} className="w-full md:w-20rem" />
                            <label className={"bg-gray-100"} htmlFor="dd-district">District</label>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                            <TreeSelect value={params?.PSA} options={DDCity} filter dropdownIcon={"pi none"}
                                resetFilterOnHide={true}
                                onChange={(e: any) => { setParams({ ...params, PSA: e.target.value }) }}
                                selectionMode="checkbox" showClear
                                placeholder={`${GetStringNameForSite(SiteName, 'CITY')}`}
                                inputId={"dd-city"}
                                style={{ border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start' }}
                                valueTemplate={(option: any) => getValueTemplate(option)}
                            ></TreeSelect>
                            <label className={"bg-gray-100"} htmlFor="dd-city">PSA</label>
                        </FormControl>
                    </div>
                    {dateType !== "GORU Search" && (
                        <div>
                            <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                                <MultiSelect
                                    value={params.Users}
                                    onChange={(e: any) => { setParams({ ...params, Users: e.target.value }) }}
                                    style={{ border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start' }}
                                    resetFilterOnHide={true} showClear={true} showSelectAll={false}
                                    inputId={"dd-users"} options={DDUserResponse} filter optionLabel="Value" optionValue="Key"
                                    placeholder={'Users'} maxSelectedLabels={1} className="w-full md:w-20rem" />
                                <label className={"bg-gray-100"} htmlFor="dd-users">Users</label>
                            </FormControl>
                        </div>
                    ) }
                </div>
                {/* <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{"marginTop": "25px"}}>
                    <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"ECrawl"}/></div>
                </div> */}
                <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>
                    <ButtonGroup size="large" color="inherit">
                        <Button type="submit" onClick={handleSearch}>Search</Button>
                        <Button onClick={handleClear}>CLEAR</Button>
                    </ButtonGroup>
                </div>

            </div>

        </>
    )
})

export default GunOffenderSearch;
