import React, { useState } from 'react';
import { SiteName } from '../../services/config.service';
import GunOffenderSearchResults from '../../components/tools/gun_offender_entry/_searchResultsGunOffender';
import GunOffenderSearch from '../../components/tools/gun_offender_entry/_gunOffenderSearch';
import { GetDarInfo_Query, getDarInfoResponse } from '../../interfaces/getDarInfo_interface';
import { RunDarQuery } from '../../services/getDar.service';
import { Account } from '../../interfaces/auth_interface';
import { handleSearchTime } from '../../services/formatDate.service';
import { getUser } from '../../services/auth.service';
import { SearchGunOffender } from "../../services/gunoffender.service";
import SearchResults from "../../components/tools/arrest/_searchResultsArrest";

function GunOffender() {

    let [secondaryTable, setSecondaryTable] = useState<any[]>([])
    let [searchResults, setSearchResults] = useState<any[]>([])
    let [darTbl, setDarTbl] = useState<any[]>([])
    let [formData, setFormData] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)
    let [hideJail, setHideJail] = useState<boolean>(false)
    let [searchType, setSearchType] = useState<any>("GORU Search")
    const [reloadTriggerCount, setReloadTriggerCount] = useState(0);

    const handleSearchSubmit = async (params: any, dateType: any, andOrType: any, dropDownParams: any) => {
        setSearchType(dateType)

        let d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var c = new Date(year - 3, month, day);


        let search_query: GetDarInfo_Query = {
            "QueryType": "\0",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "FromDate": handleSearchTime(c, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": [],
            "Address": "",
            "Cities": params.PSA ? Object.keys(params.PSA).filter(key => params.PSA[key].checked) : [],
            "IncidentCities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs": [],
            "SBIs": params.PDID ? params.PDID.split(',') : [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": [],
            "InfocopDept": [],
            "Indicators": params.Indicators ? params.Indicators : ["Gun Offender (Active)", "Gun Offender (Inactive)"],
            "IndicatorOccurrence": null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": params.District ? params.District : [],
            "ArrestedCounty": [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "SSN": [],
            "PlaceOfBirth": [],
            "AgeRangeStart": params.AgeRangeStart,
            "AgeRangeEnd": params.AgeRangeEnd,
            "FTSIDs": [],
            "ShootingPersonTypes": [],
            "PhoneNumbers": [],
            "SystemCall": params.systemCall ?? true,
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)


        setSecondaryTable(qRes?.SecondaryTable)
        setDarTbl([])
        setDarTbl(qRes.PersonList)
        if (qRes?.PersonList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
        

        if (dateType !== "GORU Search") {
            SearchGunOffender({
                SearchType: andOrType,
                OffenderLevelRating: (Array.isArray(params?.OffenderLevelRating) ? params.OffenderLevelRating.join(", ") : params?.OffenderLevelRating) ?? null,
                District: (Array.isArray(params?.District) ? params.District.join(", ") : params?.District) ?? null,
                PSA: (params.PSA ? Object.keys(params.PSA).filter(key => params.PSA[key].checked).join(", ") : null) ?? null,
                CreateUser: (Array.isArray(params?.Users) ? params.Users.join(", ") : params?.Users) ?? null,
                DateType: dateType === "Visit Date" ? true : false,
                FromDate: params?.fromDate ?? null,
                ToDate: params?.toDate ?? null,
                FirstName: params?.FirstName ?? null,
                LastName: params?.LastName ?? null,
                CaseNumber: params?.CaseNo ?? null,
                PDID: params?.PDID ?? null,
                DOB: params?.DOB,
                Address: params?.Address,
            }).then((r: any) => {
                setFormData(r ?? []);
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    const reloadData = () => {
        setReloadTriggerCount(reloadTriggerCount + 1);
    }

    return (
        <div id="ToolsArrest">
            <GunOffenderSearch SubmitSearch={handleSearchSubmit} RefreshTrigger={reloadTriggerCount} />
                
            <GunOffenderSearchResults Records={formData} RefreshTrigger={reloadData} Persons={darTbl} SecondaryTable={secondaryTable} showPlate={false} searchType={searchType} />
            
        </div>
    );
}

export default GunOffender;
