import {PersonInfo} from "../../interfaces/getDarInfo_interface";
import React, {useEffect, useState} from "react";

import PersonPhotoIcon from "../../assets/Images/photo1.png";
import ArrestIcon from "../../assets/Images/ai1.png";
import InfoCopIcon from "../../assets/Images/ic1.png";
import ETicketIcon from "../../assets/Images/et1.png";
import ParkMobileIcon from "../../assets/Images/pm1.png";
import ALPRIcon from "../../assets/Images/alpr1.png";
import CADRMSIcon from "../../assets/Images/Opencase1.png";
import CODISIcon from "../../assets/Images/DNA1.png";
import TowingIcon from "../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../assets/Images/jail1.png";
import GunTraceIcon from "../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../assets/Images/NjGunTrace1.png";
import NJGunTraceRecentIcon from "../../assets/Images/NjGunTraceRecent.png";
import PrisonParoleInactiveIcon from "../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../assets/Images/parole1.png";
import JailIcon from "../../assets/Images/jail_door.png";
import IncarceratedIcon from "../../assets/Images/Jail.png";
import JailRecentIcon from "../../assets/Images/jailRecent.png";
import ViolentIcon from "../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../assets/Images/profession1.png";
import ReferralIcon from "../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../assets/Images/ReferralRearrested1.png";
import ArrestRecentIcon from "../../assets/Images/aiRecent.png";
import PrisonReleaseRecentIcon from "../../assets/Images/jailRecent.png";
import GunTraceRecentIcon from "../../assets/Images/WeaponTraceTTC.png";
import NJGunTraceTriggerLockIcon from "../../assets/Images/NjGunTraceTrigerLock.png";
import CarJackingIcon from "../../assets/Images/carjacking.png";
import CarTheft from "../../assets/Images/carTheft.png";
import CarTheftRecent from "../../assets/Images/recentCarTheft.png";
import DomViolenceIcon from "../../assets/Images/domesticviolence.png";
import SexCrimeIcon from "../../assets/Images/sexcrime.png";
import GunArrestIcon from "../../assets/Images/GunArrest.png";
import GunStatIcon from "../../assets/Images/GunStat.png";
import GunOffenderIcon from "../../assets/Images/GunOffender.png";
import GunOffenderIconRed from "../../assets/Images/GunOffender_Red.png";
import GunOffenderIconA from "../../assets/Images/GunOffender_A.png";
import GunOffenderIconB from "../../assets/Images/GunOffender_B.png";
import GunOffenderIconC from "../../assets/Images/GunOffender_C.png";
import GunOffenderIconARed from "../../assets/Images/GunOffender_A_Red.png";
import GunOffenderIconBRed from "../../assets/Images/GunOffender_B_Red.png";
import GunOffenderIconCRed from "../../assets/Images/GunOffender_C_Red.png";
import GunRegistrationIcon from "../../assets/Images/GunRegistration.png"; 
import BOLOIcon from "../../assets/Images/BOLO.png";
import BOLORedIcon from "../../assets/Images/bolo_active.png";
import NJSTADIcon from "../../assets/Images/njstad.png";
import GunArrestRecentIcon from "../../assets/Images/GunArrestRecent.png";
import {Avatar, Tooltip} from "@mui/material";
import {green} from "@mui/material/colors";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import {GetStringNameForSite, SITE} from "../../interfaces/config.interface";
import {SiteName} from "../../services/config.service";
import GunTracePurchaser from "../../assets/Images/GunTracePurchaser.png";
import NJTracePossessor from "../../assets/Images/NJTracePossessor.png";
import NJTraceAssociate from "../../assets/Images/NJTraceAssociate.png";
import NJTraceGhostGun from "../../assets/Images/NJTraceGhostGun.png";
import TSCIcon from '../../assets/Images/TSC-button.png';
import HomeBurglaryIcon from "../../assets/Images/HomeBurglary.png";
import HomeInvasionIcon from "../../assets/Images/HomeInvasion.png";
import PassportIcon from "../../assets/Images/passport.png";
import {isUserPermission} from "../../services/auth.service";


interface BTPersonGridIndicatorsProps {
    person: any,
}

const BTPersonIndicatorGrid: React.FC<BTPersonGridIndicatorsProps> = ({ person}) => {


    return (<>
        <Tooltip title={"Photo"} arrow>{(person.FoundPhoto > 0) ? <img src={PersonPhotoIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"Arrest"} arrow>{(person.FoundArrest === 1) ? <img src={ArrestIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"Arrest[Gun/Murder/AggAssault](Last 1 year)"} arrow>{(person.FoundArrest === 2) ? <img src={ArrestRecentIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={"InfoCop"} arrow>{(person.FoundInfoCop > 0) ? <img src={InfoCopIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip> }
        <Tooltip title={"ETicket"} arrow>{(person.FoundETicket > 0) ? <img src={ETicketIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"ParkMobile"} arrow>{(person.FoundParkMobile > 0) ? <img src={ParkMobileIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"ALPR"} arrow>{(person.FoundALPR > 0) ? <img src={ALPRIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={GetStringNameForSite(SiteName, "CAD/RMS")} arrow>{(person.FoundCADRMS > 0) ? <img src={CADRMSIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"CODIS"} arrow>{(person.FoundCODIS > 0) ? <span style={{ "marginRight": "3px" }}> <img src={CODISIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundCODIS} </span> : <span />}</Tooltip>
        <Tooltip title={"Towing"} arrow>{(person.FoundTowing > 0) ? <img src={TowingIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"Prison Release"} arrow>{(person.FoundPrisonRelease === 1) ? <img src={PrisonReleaseIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"Prison Release(Last 1 year)"} arrow>{(person.FoundPrisonRelease === 2) ? <img src={PrisonReleaseRecentIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"GunTrace"} arrow>{(person.FoundGunTrace > 0) && (person?.ETraceTTCYear === 'N') ? <span style={{ "marginRight": "3px" }}> <img src={GunTraceIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundGunTrace} </span> : <span />}</Tooltip>
        <Tooltip title={"GunTrace(TTC less than year)"} arrow>{(person.FoundGunTrace > 0) && (person?.ETraceTTCYear === 'Y') ? <span style={{ "marginRight": "3px" }}> <img src={GunTraceRecentIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundGunTrace} </span> : <span />}</Tooltip>
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={"GunTrace Purchaser"} arrow>{(person.FoundETrace_Pur  > 0) ? <span style={{ "marginRight": "3px" }}> <img src={GunTracePurchaser} style={{ "display": "inline" }} alt={""} />{person.FoundETrace_Pur}</span> : <span />}</Tooltip>}
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={"NJGunTrace Possessor"} arrow>{(person.FoundNJTrace_Pos  > 0) ? <span style={{ "marginRight": "3px" }}> <img src={NJTracePossessor} style={{ "display": "inline" }} alt={""} />{person.FoundNJTrace_Pos}</span> : <span />}</Tooltip>}
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={"NJGunTrace Associate"} arrow>{(person.FoundNJTrace_Asc  > 0) ? <span style={{ "marginRight": "3px" }}> <img src={NJTraceAssociate} style={{ "display": "inline" }} alt={""} />{person.FoundNJTrace_Asc}</span> : <span />}</Tooltip>}
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={"NJGunTrace Ghost Gun"} arrow>{(person.FoundNJTrace_Ghg  > 0) ? <span style={{ "marginRight": "3px" }}> <img src={NJTraceGhostGun} style={{ "display": "inline" }} alt={""} />{person.FoundNJTrace_Ghg}</span> : <span />}</Tooltip>}
        <Tooltip title={"Shooting Suspect(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'S') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
        <Tooltip title={"Shooting Law Enforcement(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'L') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
        <Tooltip title={"Shooting Victim(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
        <Tooltip title={"Shooting Subject(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'B') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
        <Tooltip title={"Shooting Other(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'O') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
        <Tooltip title={"Shooting Witness(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'W') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
        <Tooltip title={"Shooting"} arrow>{(person?.FoundShooting > 0) && !(person?.RecentShooting === 'S' || person?.RecentShooting === 'V' || person?.RecentShooting === 'B' || person?.RecentShooting === 'W' || person?.RecentShooting === 'L' || person?.RecentShooting === 'O') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
        <Tooltip title={"NIBIN"} arrow>{(person?.FoundNIBIN > 0) ? <span style={{ "marginRight": "3px" }}><img src={NibinIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundNIBIN}</span> : <span />}</Tooltip>
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={`${GetStringNameForSite(SiteName,'NJGUNTRACE')}`} arrow>{(person.FoundNJGunTrace > 0) ? <span style={{ "marginRight": "3px" }}> <img src={NJGunTraceIcon} style={{ "display": "inline" }} alt={""} />{person.FoundNJGunTrace}</span> : <span />}</Tooltip>}
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<Tooltip title={`${GetStringNameForSite(SiteName,'NJGUNTRACE')}(Trigger Lock)`} arrow>{(person.FoundTriggerLock > 0) ? <span> <img src={NJGunTraceTriggerLockIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> </span> : <span />}</Tooltip>}
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={`${GetStringNameForSite(SiteName,'NJGUNTRACE')}(Recent)`} arrow>{(person.FoundNJTraceRecent > 0) ? <span style={{ "marginRight": "3px" }}> <img src={NJGunTraceRecentIcon} style={{ "display": "inline" }} alt={""} /> </span> : <span />}</Tooltip>}
        <Tooltip title={"Inactive Parole"} arrow>{(person.FoundPrisonParole === 1) ? <img src={PrisonParoleInactiveIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"Active Parole"} arrow>{(person.FoundPrisonParole === 2) ? <img src={PrisonParoleActiveIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"Violent"} arrow>{(person.FoundViolent > 0) ? <img src={ViolentIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"Human Traffic"} arrow>{(person.FoundHumanTraffic > 0) ? <img src={HumanTrafficIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"Carjacking"} arrow>{(person?.FoundCarJacking > 0) ? <span style={{"marginRight": "3px"}}> <img src={CarJackingIcon} style={{"display": "inline"}} alt={""}/> {person.FoundCarJacking} </span> : <span/>}</Tooltip>
        <Tooltip title={"Gun Arrest (Recent)"} arrow>{(person?.FoundGunArrests > 0 && person?.FoundGunArrestsRecent === 'Y') ? <span style={{"marginRight": "3px"}}> <img src={GunArrestRecentIcon} style={{"display": "inline"}} alt={""}/> {person.FoundGunArrests} </span> : <span/>}</Tooltip>
        <Tooltip title={"Gun Arrest"} arrow>{(person?.FoundGunArrests > 0 && person?.FoundGunArrestsRecent != 'Y') ? <span style={{"marginRight": "3px"}}> <img src={GunArrestIcon} style={{"display": "inline"}} alt={""}/> {person.FoundGunArrests} </span> : <span/>}</Tooltip>
        <Tooltip title={"Profession"} arrow>{(person.FoundProfession > 0) ? <img src={ProfesionIcon} style={{"display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        <Tooltip title={"Referral"} arrow>{(person?.FoundReferral === 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralIcon} style={{ "display": "inline" }} alt={""} /></span> : <span />}</Tooltip>
        <Tooltip title={"Referral (Rearrested)"} arrow>{(person?.FoundReferral > 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralRearrestedIcon} style={{ "display": "inline" }} alt={""} /> {(person.FoundReferral - 1)} </span> : <span />}</Tooltip>
        <Tooltip title={"Profile Report"} arrow>{(person?.FoundProfile > 0) ? <span style={{ "marginRight": "3px" }}><Avatar  sx={{ bgcolor: green[700], height:30, width:30, display:"-webkit-inline-box" }}><ContactPageOutlinedIcon /></Avatar> {person.FoundProfile}</span> : <span />}</Tooltip>
        <Tooltip title={"Domestic Violence"} arrow>{(person?.FoundDomViolence > 0) ? <span style={{ "marginRight": "3px" }}><img src={DomViolenceIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundDomViolence}</span> : <span />}</Tooltip>
        <Tooltip title={"Sex Crime"} arrow>{(person?.FoundSexCrime > 0) ? <span style={{ "marginRight": "3px" }}><img src={SexCrimeIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundSexCrime}</span> : <span />}</Tooltip>
        <Tooltip title={"JAIL"} arrow>{(person?.FoundJail === 1) ? <span style={{ "marginRight": "3px", "width": "25px" }}><img src={JailIcon} style={{ "display": "inline" }} width={25} alt={""} /></span> : <span />}</Tooltip>
        {/*<Tooltip title={"Gun Registration"} arrow>{(person?.FoundJail === 1) ? <span style={{ "marginRight": "3px", "width": "25px" }}><img src={GunRegistrationIcon} style={{ "display": "inline" }} width={25} alt={""} /></span> : <span />}</Tooltip>*/}
        <Tooltip title={"INCARCERATED"} arrow>{(person?.FoundJail === 2) ? <span style={{ "marginRight": "3px", "width": "25px" }}><img src={IncarceratedIcon} width={22} style={{ "display": "inline" }} alt={""} /></span> : <span />}</Tooltip>
        {person?.FoundCarTheftRecent >= 2 ? <Tooltip title={"Car Theft (Multiple Within Last 90 Days)"} arrow>{person?.FoundCarTheftRecent >= 2 ? <span style={{ "marginRight": "3px" }}> <img src={CarTheftRecent} style={{ "display": "inline" }} alt={""} /> {person.FoundCarTheftRecent} </span> : <span />}</Tooltip>
        : <Tooltip title={"Car Theft"} arrow>{(person?.FoundCarTheft > 0) ? <span style={{ "marginRight": "3px" }}> <img src={CarTheft} style={{ "display": "inline" }} alt={""} /> {person.FoundCarTheft} </span> : <span />}</Tooltip> }
        <Tooltip title={"Gun Stat"} arrow>{(person?.FoundGunStat > 0) ? <span style={{ "marginRight": "3px" }}> <img src={GunStatIcon} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"Gun Offender (Inactive)"} arrow>{(person?.FoundGunOffender  === 1) ? <span style={{ "marginRight": "3px" }}> <img src={GunOffenderIcon} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"Gun Offender (Active) "} arrow>{(person?.FoundGunOffender  === 2) ? <span style={{ "marginRight": "3px" }}> <img src={GunOffenderIconRed} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"Gun Offender A (Inactive)"} arrow>{(person?.FoundGunOffender  === 3) ? <span style={{ "marginRight": "3px" }}> <img src={GunOffenderIconA} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"Gun Offender A (Active)"} arrow>{(person?.FoundGunOffender  === 4) ? <span style={{ "marginRight": "3px" }}> <img src={GunOffenderIconARed} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"Gun Offender B (Inactive)"} arrow>{(person?.FoundGunOffender  === 5) ? <span style={{ "marginRight": "3px" }}> <img src={GunOffenderIconB} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"Gun Offender B (Active)"} arrow>{(person?.FoundGunOffender  === 6) ? <span style={{ "marginRight": "3px" }}> <img src={GunOffenderIconBRed} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"Gun Offender C (Inactive)"} arrow>{(person?.FoundGunOffender  === 7) ? <span style={{ "marginRight": "3px" }}> <img src={GunOffenderIconC} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"Gun Offender C (Active)"} arrow>{(person?.FoundGunOffender  === 8) ? <span style={{ "marginRight": "3px" }}> <img src={GunOffenderIconCRed} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"BOLO (Active)"} arrow>{(person?.FoundBOLO === 1) ? <span style={{ "marginRight": "3px" }}> <img src={BOLORedIcon} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        <Tooltip title={"BOLO (Inactive)"} arrow>{(person?.FoundBOLO === 2) ? <span style={{ "marginRight": "3px" }}> <img src={BOLOIcon} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={"NJSTAD"} arrow>{(person?.FoundNJSTAD > 0) ? <span style={{ "marginRight": "3px" }}> <img src={NJSTADIcon} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip> }
        {isUserPermission('TSC') && <Tooltip title={"TSC"} arrow>{(person?.FoundTSC > 0) ? <span style={{ "marginRight": "3px" }}> <img src={TSCIcon} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>}
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={"Home Burglary"} arrow>{(person.FoundHomeBurglary > 0) ? <span style={{ "marginRight": "3px" }}> <img src={HomeBurglaryIcon} style={{ "display": "inline" }} alt={""} />{person.FoundHomeBurglary}</span> : <span />}</Tooltip>}
        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <Tooltip title={"Home Invasion"} arrow>{(person.FoundHomeInvasion > 0) ? <span style={{ "marginRight": "3px" }}> <img src={HomeInvasionIcon} style={{ "display": "inline" }} alt={""} />{person.FoundHomeInvasion}</span> : <span />}</Tooltip>}
        {isUserPermission('Indicator_ForeignNational') && <Tooltip title={"Foreign National"} arrow>{(person?.FoundForeignNational > 0) ? <span style={{ "marginRight": "3px" }}> <img src={PassportIcon} style={{ "display": "inline" }} width={25} alt={""} />  </span> : <span />}</Tooltip>}
    </>)
};

export default BTPersonIndicatorGrid;