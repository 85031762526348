import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {ArrestInfo} from "../../../interfaces/ArrestInfo.interface";
import {TablePagination, CircularProgress, Button} from "@mui/material";
import { Link } from "react-router-dom";
import icon_arrest from "../../../assets/Images/ai1.png"
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

interface PanelArrestProps {
    person: PersonInfo,
    ArrestInfo: ArrestInfo[],
    isLoading:boolean
    crumbArrest:any
}

const  PanelArrest: React.FC<PanelArrestProps> = ({person,ArrestInfo, isLoading, crumbArrest})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...ArrestInfo]
        for (let p of tempP) {
            if (p.DTOFARREST) {
                p.DTOFARREST = new Date(p.DTOFARREST)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [ArrestInfo])

    return(
        <div className={"m-5 border"}>
            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                       <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">                    
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Arrest Infomation <img src={icon_arrest} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()}&nbsp;&nbsp; Arrests: {crumbArrest} &nbsp;&nbsp;{(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)? 'Charges':'Records'}: {ArrestInfo?.length}</span>
                           </span>
                       </Disclosure.Button>
                    
                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                   {/* <ColumnDirective field='DTOFARREST' headerText='Arrest Date' />*/}
                                    <ColumnDirective field='DTOFARREST' headerText='Arrest Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} />
                                    <ColumnDirective field='ORIGINATINGORINAME' headerText='Agency Arrested'/>
                                    <ColumnDirective field='ARREST_TYPE' headerText='Arrest Type'/>
                                    <ColumnDirective field='AgencyCaseNo' headerText={GetStringNameForSite(SiteName,"AGENCY CASE #")}/>
                                    <ColumnDirective field='STATUTE' headerText={`${GetStringNameForSite(SiteName,'STATUTE')}`}/>
                                    <ColumnDirective field='STATUTEDESCRIPTION' headerText={`${GetStringNameForSite(SiteName,'STATUTE')} Desc`}/>
                                    <ColumnDirective field='ResidentCity' headerText={`Resident ${GetStringNameForSite(SiteName,'CITY')}`}/>
                                    <ColumnDirective field='ResidentCounty' headerText={`Resident ${GetStringNameForSite(SiteName,'COUNTY')}`}/>
                                    <ColumnDirective field='ArrestedCounty' headerText={`Arrested ${GetStringNameForSite(SiteName,'COUNTY')}`}/>
                                    <ColumnDirective template={(arrest: any) => (<>{arrest?.IncidentAddress} {arrest?.IncidentCity} {arrest?.IncidentState} {arrest?.IncidentZip}</>)}  headerText='Incident Address'/>
                                    <ColumnDirective template={(item : any ) => (<Button onClick={ () => {window.open('/detail/arrest?ID=' + item?.ID + '&Dept=' + item?.ORIGINATINGORINAME, '_blank') }} >View</Button>)}  headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
                            
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
        
    )
};

export default PanelArrest;