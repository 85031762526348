import {
  Alert,
  Box,
  breadcrumbsClasses,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import {
  DropDown,
  InvestigationObject,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { AnalystObject } from "../../../interfaces/NJPORT/analyst.interface";
import { InvestigationDropDown } from "../../../services/njport.service";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: AnalystObject | null;
}

const AnalystInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
}) => {
  const [report, setReport] = React.useState<AnalystObject>({});
  const [invStatusDD, setInvStatusDD] = React.useState<DropDown[]>([]);
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [analyDD, setanalyDD] = React.useState<DropDown[]>([]);
  const [defaultstatus, setDefaultStatus] = React.useState<string>('');

  const [unitDD, setUnitDD] = React.useState<DropDown[]>([]);
  const [defaultUnit, setDefaultUnit] = React.useState<string>('');

  React.useEffect(() => {
 
    analyDropdown();
    unitDropdown();
    if (editReport) setReport(editReport);
    defaultStatusDD(analyDD);
    defaultUnitDD(unitDD);
  }, []);

  const defaultStatusDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.AnalystRequestID)?.Value! : ''
    setDefaultStatus(v);
  }


  const defaultUnitDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.InvestigatorUnitID)?.Value! : ''
    setDefaultUnit(v);
  }


  const handleValueChange = (field: string, val: any) => {
    let temp = { ...report };
    switch (field) {

      case "caseNo":
        temp.CaseNo = val;
        break;
      case "InvestigatorName":
        temp.InvestigatorName = val;
        break;
        case "notes":
          temp.Notes = val;
          break;  
      case "date_Received":
        temp.Date_Received = val;
        break;
      case "Project":
        temp.Project = val;
        break;
      case "InvestigatorUnit":
        temp.InvestigatorUnitID = val;
        setDefaultUnit(unitDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "analystRequest":
        temp.AnalystRequestID = val;
        setDefaultStatus(analyDD.find(d => d.Key === val)?.Value ?? '');
        break;
        
    }
    if(!temp.Date_Received){
      temp.Date_Received=convertDate(report.Date_Received);
    }


    setReport(temp);
  };

  const handleSubmit = async () => {
    let data = report;
    if (editReport === null) data.EditStatus = "ADD";
    else data.EditStatus = "EDIT";
    if (!handleValidation()) handleReportSubmit(data);
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (!temp.CaseNo || temp.CaseNo.trim().length === 0) {
        flag = true;
        text = "Request";
      } else if (!temp.InvestigatorName || temp.InvestigatorName.length === 0) {
        flag = true;
        text = "investigatior Name";
      } else if (!temp.InvestigatorUnit || temp.InvestigatorUnit.length === 0) {
        flag = true;
        text = "Unit";
      } else if (!temp.Project || temp.Project.trim().length === 0) {
        flag = true;
        text = "Project";
      }
      if (flag) {
        setAlertText("Please complete Mandatory fields" + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };




  const invStatusDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_INVSTATUS",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setInvStatusDD(response);
  };


  const analyDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_ANALYSTREQUEST",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setanalyDD(response);
    defaultStatusDD(response);
  };


  const unitDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_ANALYSTDEPTUNIT",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setUnitDD(response);
    defaultUnitDD(response);
  };

  const convertDate = (dateInput: Date | string | null | undefined) => {
    if (!dateInput) {
      const today = new Date();
      return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    }
  
    // If dateInput is a string, try to parse it to a Date object
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  
    if (date instanceof Date && !isNaN(date.getTime())) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }
  
    // If dateInput is invalid, fallback to today's date
    const today = new Date();
    return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  };
  

  return (
    <Box className="pt-3" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Case
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Request #"
                value={report.CaseNo}
                onChange={(e) =>
                  handleValueChange("caseNo", e.target.value)
                }
                error={report.CaseNo ? false : true}
                helperText={report.CaseNo ? "" : "Required"}
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Trooper/Investigator"
                value={report.InvestigatorName}
                onChange={(e) =>
                  handleValueChange("InvestigatorName", e.target.value)
                }
                error={report.InvestigatorName ? false : true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date"
                value={convertDate(report.Date_Received)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_Received", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="invUnit-Label">Unit</InputLabel>
                <Select
                  labelId="invUnit-Label"
                  id="invUnit-select"
                  value={defaultUnit}
                  label="invUnit"
                  renderValue={() => defaultUnit}
                  onChange={(e: any) =>
                    handleValueChange("InvestigatorUnit", e.target.value)
                  }
                  error={report.InvestigatorUnit ? false : true}
                >
                  {unitDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Project
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Project"
                value={report.Project}
                onChange={(e) => handleValueChange("Project", e.target.value)}
                error={report.Project ? false : true}
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="analyst-Label">Analysts Request</InputLabel>
                <Select
                  labelId="analyst-Label"
                  id="analyst-select"
                  value={defaultstatus}
                  label="analyst"
                  renderValue={() => defaultstatus}
                  onChange={(e: any) =>
                    handleValueChange("analystRequest", e.target.value)
                  }
                  error={report.AnalystRequest ? false : true}
                >
                  {analyDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Notes"
                value={report.Notes}
                onChange={(e) => handleValueChange("notes", e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalystInputForm;
