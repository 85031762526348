import React, { forwardRef } from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
import {
  DropDown,
  InvestigationQuery,
  PortFileOrImage,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { InvestigationDropDown, SexDropdown, RaceDropdown, SetNJPortFileLoad } from "../../../services/njport.service";
import { MultiSelect } from "primereact/multiselect";
//import FilePreview from "././filePreview";
import { isError } from "util";
import FilePreview from "../shipentry/filePreview";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { isUserPermission } from "../../../services/auth.service";


const InvestigationReportSearch: React.FC<any> = ({ SubmitSearch }) => {
  let today: Date = new Date();
  var fromday: Date = new Date();
  if (today.getDay() === 1) {
    fromday.setDate(today.getDate() - 2);
  }

  const [invdata, setInvData] = React.useState<InvestigationQuery>({});
  const [initialRun, setInitialRun] = React.useState<boolean>(false);
  const [params, setParams] = React.useState({
    FirstName: "",
    MI: "",
    LastName: "",
    DOB: "",
    FBI: "",
    SBI: "",
    AgeRangeStart: "",
    AgeRangeEnd: "",
    toDate: "",
    fromDate: "",
    Gender: "",
    Status: "",
    ReportNum: "",
    SystemCall: false,
  });
  const [dateType, setDateType] = React.useState<string>("Entry Date");
  const [invStatusDD, setInvStatusDD] = React.useState<DropDown[]>([]);
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [pacCardDD, setPacCardDD] = React.useState<DropDown[]>([]);
  const [sexDD, setsexDD] = React.useState<DropDown[]>([]);
  const [raceDD, setRaceDD] = React.useState<any[]>([]);
  const [invStatus, setInvStatus] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [pacCard, setPacCard] = React.useState("");
  const [portFiles, setportFiles] = React.useState<PortFileOrImage[]>([{ PortType: 'INVESTIGATION' }]);
  const [refreshCount, setRefreshCount] = React.useState<number>(0);


  React.useEffect(() => {
    if (!initialRun) {
      setInitialRun(true);
      SubmitSearch(invdata);
      invStatusDropdown();
      jobTitleDropdown();
      pacCardDropdown();
      getSexDropdown();
      getRaceDropdown();
      setDateValues();
    }
  });

  const convertDate = (date: Date) => {
    let a = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let b =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let d = date.getFullYear() + "-" + b + "-" + a;
    return d;
  };

  const setDateValues = () => {
    let data = { ...invdata };
    data.fromDate = convertDate(fromday);
    data.toDate = convertDate(today);
    setInvData(data);
  };

  const handleChange = (field: string, val: any) => {
    let data = { ...invdata };
    switch (field) {
      case "fromDate":
        data.fromDate = val;
        break;
      case "toDate":
        data.toDate = val;
        break;
    }
    setInvData(data);
  };

  const handleSubmit = () => {
    SubmitSearch(invdata);
  };

  const handleDateType = (
    event: React.MouseEvent<HTMLElement>,
    dateType: string
  ) => {
    if (dateType === "Entry Date") {
      setInvData({ ...invdata, dateTypeValue: 0 });
    } else {
      setInvData({ ...invdata, dateTypeValue: 1 });
    }
    if (dateType) {
      setDateType(dateType);
    }
  };

  const invStatusDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_INVSTATUS",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setInvStatusDD(response);
  };

  const jobTitleDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_JOBCODE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
  };

  const pacCardDropdown = async () => {
    let data = {
      params: {
        DropDownName: "YESNO",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPacCardDD(response);
  };

  const getSexDropdown = async () => {
    // let response: DropDown[] = await SexDropdown();
    let data = {
      params: {
        DropDownName: "PORT_GENDER",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setsexDD(response);
  };

  const getRaceDropdown = async () => {
    let response: any[] = await RaceDropdown();
    setRaceDD(response.map(val => {
      return { Key: val.ID, Value: val.Description }
    }))
  };

  const handleClear = async () => {
    let empty : InvestigationQuery = {
      fNames: [],
      mNames: [],
      lNames: [],
      doBs: [],
      caseNumbers: [],
      ssn: [],
      fromDate: convertDate(fromday),
      toDate: convertDate(today)
    }
    setInvData(empty);
    setParams({...params, SBI : ""})
    setInvStatus("")
    setJobTitle("")
    setPacCard("")
    }

    const handleAdditionalFiles = (e: any, fileIndex: number = 0) => {        
        let files = e.files;        
        const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
        for (var i = 0; i < files.length; i++) {
            if (files[i]?.size && files[i].size < maxSizeInBytes) {
                portFiles[fileIndex].imgSizeError = ''
                handleChangeFile(files[i], fileIndex)
            }
            else {
                portFiles[fileIndex].imgSizeError = 'File size exceeds the 5MB limit.'
            }
        }
    };

    const handleChangeFile = (file: any, fileIndex: number = 0) => {
        let fileData = new FileReader();
        fileData.onloadend = (e) => handleFile(e, file, fileIndex);
        fileData.readAsDataURL(file);
    }

    const handleFile = async (e: any, file: any, fileIndex: number = 0) => {
        let portFileList: any = [];
        portFileList.push({
            FileContent: e.target.result.split('base64,')[1],
            FileName: file.name,
            FileEncoding: file.type,
            FileDescription: 'Excel Spreadsheet',
            GUISequence: fileIndex,
            ID: 0,
            PortType: 'INVESTIGATION',
        });

        setportFiles(portFileList);

        let r = await SetNJPortFileLoad(portFileList[0])
        console.log('handleFile:', r);
        if (r.IsValid === true) {
            alert("File upload Successful")
        } else if (r.ErrorMessages) {
            alert(r.ErrorMessages)
        }

        setRefreshCount(refreshCount + 1);
    }

    return (
    <div className="mt-5 ml-5 mr-5 p-1">
      <Box className="pt-3" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} sx={{ pl: 2 }}>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <ToggleButtonGroup
              color="primary"
              value={dateType}
              exclusive
              fullWidth
              onChange={handleDateType}
            >
              <ToggleButton value="Report Date">Report Date</ToggleButton>
              <ToggleButton value="Entry Date">Entry Date</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              name="fromDate"
              label="From Date"
              defaultValue={convertDate(fromday)}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange("fromDate", e.target.value)}
            />
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              name="toDate"
              label="To Date"
              defaultValue={convertDate(today)}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange("toDate", e.target.value)}
            />
          </Grid>

          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="First Name"
              value={invdata.fNames}
              onChange={(e: any) =>
                setInvData({ ...invdata, fNames: [e.target.value] })
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="Middle Initial"
              value={invdata.mNames}
              onChange={(e: any) =>
                setInvData({ ...invdata, mNames: [e.target.value] })
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="Last Name"
              value={invdata.lNames}
              onChange={(e: any) =>
                setInvData({ ...invdata, lNames: [e.target.value] })
              }
            />
          </Grid>

        </Grid>

        <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>

          <Grid item xs={3} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              label="DOB"
              InputLabelProps={{ shrink: true }}
              value={invdata.doBs}
              onChange={(e: any) =>
                setInvData({ ...invdata, doBs: [e.target.value] })
              }
            />
          </Grid>
          <Grid item xs={3} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="Case #"
              value={invdata.caseNumbers}
              onChange={(e: any) =>
                setInvData({ ...invdata, caseNumbers: [e.target.value] })
              }
            />
          </Grid>
          <Grid item xs={3} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="SSN"
              value={invdata.ssn}
              onChange={(e: any) =>
                setInvData({ ...invdata, ssn: [e.target.value] })
              }
            />
          </Grid>
          <Grid item xs={3} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="Sponser"
              value={params.SBI}
              onChange={(e: any) =>
                setParams({ ...params, SBI: e.target.value })
              }
            />
          </Grid>

        </Grid>
        <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
          
          <Grid item xs={4} sx={{ pr: 2 }}>
      
            <FormControl className={"p-float-label"} sx={{ minWidth: '100%', maxWidth: '100%' }}>
              <MultiSelect
                value={invStatus}
                onChange={(e) => { setInvStatus(e.value) }}
                style={{ border: '1px solid #b9b9bb', height: '100%', textAlign: 'start' }}
                resetFilterOnHide={true} showClear={true} showSelectAll={false}
                inputId={"dd-pob"} options={invStatusDD} filter optionLabel="Value" optionValue="Key"
                placeholder="Place Of Birth" maxSelectedLabels={1} className="w-full md:w-20rem" />
              <label htmlFor="dd-pob">InvStatus</label>
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>

            <FormControl className={"p-float-label"} sx={{ minWidth: '100%', maxWidth: '100%' }}>
              <MultiSelect
                value={jobTitle}
                onChange={(e) => { setJobTitle(e.value) }}
                style={{ border: '1px solid #b9b9bb', height: '100%', textAlign: 'start' }}
                resetFilterOnHide={true} showClear={true} showSelectAll={false}
                inputId={"dd-pob"} options={jobTitleDD} filter optionLabel="Value" optionValue="Key"
                placeholder="Place Of Birth" maxSelectedLabels={1} className="w-full md:w-20rem" />
              <label htmlFor="dd-pob">Job Title</label>
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            
            <FormControl className={"p-float-label"} sx={{ minWidth: '100%', maxWidth: '100%' }}>
              <MultiSelect
                value={pacCard}
                onChange={(e) => { setPacCard(e.value) }}
                style={{ border: '1px solid #b9b9bb', height: '100%', textAlign: 'start' }}
                resetFilterOnHide={true} showClear={true} showSelectAll={false}
                inputId={"dd-pob"} options={pacCardDD} filter optionLabel="Value" optionValue="Key"
                placeholder="Place Of Birth" maxSelectedLabels={1} className="w-full md:w-20rem" />
              <label htmlFor="dd-pob">PAC Issued</label>
            </FormControl>
          </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}> 
      {isUserPermission("NJPort") ? (
        <Grid item xs={2} sx={{ pr: 2 }}>          
        <FormControl sx={{ mt: 1 }}>
            <label
                style={{ border: '1px solid lightgray', borderColor: '#bfbfbf', padding: '2px 2px 1.1px 8px', borderRadius: '3px' }}
                htmlFor={"icon"}>
                <input
                    id={"icon"}
                    hidden
                    className="input"
                    onChange={(e) => handleAdditionalFiles(e.target, 0)}
                    type="file"
                    accept=".xlsx, .xls, .csv, .ods"
                    value=''
                />
                Upload Data                                   
                <IconButton color="primary" component="span">
                    <AttachFileIcon />
                </IconButton>
            </label>
            {portFiles[0].FileName}
        </FormControl>                  
        </Grid>
      ) : (
        <></>
      )
      }
          <Grid item xs={8} sx={{ pr: 2 }}></Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>          
            <ButtonGroup fullWidth size="large" color="inherit">
              <Button type="submit" onClick={handleSubmit}>
                Search
              </Button>
              <Button onClick={handleClear}>CLEAR</Button>
            </ButtonGroup>
          </Grid>
      </Grid>
      </Box>
    </div>
  );
};

export default InvestigationReportSearch;
