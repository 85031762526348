import { PersonInfo } from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TVInfo from "../../../interfaces/TVInfo.interface";
import { CircularProgress, Button, Tooltip } from "@mui/material";
import TowedVehicleIcon from "../../../assets/Images/towing1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Inject,
    Page,
    Sort,
    Filter
} from '@syncfusion/ej2-react-grids';
import { GetStringNameForSite, SITE } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

interface PanelTowedVehicleProps {
    person: PersonInfo,
    TVInfos: TVInfo[],
    isLoading: boolean
}

const PanelTowedVehicle: React.FC<PanelTowedVehicleProps> = ({ person, TVInfos, isLoading }) => {
    //Towed Vehicle
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        if (TVInfos) {
            let tempP: any = [...TVInfos]
            for (let p of tempP) {
                if (p.DATETIMEIN) {
                    p.DATETIMEIN = new Date(p.DATETIMEIN)
                }
                if (p.CREATEDATE) {
                    p.CREATEDATE = new Date(p.CREATEDATE)
                }
            }
            setFormatData(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [TVInfos])

    return (
        <div className={"m-5 border"}>
            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        {/*<Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left" onClick={() => { ALPRTrigger() }}>*/}
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    {/*<span className={"font-bold"}>ALPR <img src={ALPRIcon} alt={''} style={{ display: 'inline' }} /> (Click here to get Latest ALPR)
                                        {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} />  : <></>}</span>*/}

                                    <span className={"font-bold"}>Towed Vehicle <img src={TowedVehicleIcon} alt={''} style={{ display: 'inline' }} />
                                        {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "10px" }} /> : <></>}
                                    </span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()}
                                    &nbsp;&nbsp;Records: {TVInfos?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='ID' headerText='' visible={false} />
                                    <ColumnDirective field='DATETIMEIN' headerText='DateTime In' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }} />
                                    <ColumnDirective field='CASENUMBER' headerText={GetStringNameForSite(SiteName, "AGENCY CASE #")} />
                                    <ColumnDirective field='REASON' headerText='Reason' />
                                    <ColumnDirective field='PLATE' headerText='Plate #' />
                                    {/*<ColumnDirective field='PLATESTATE' headerText='Plate St' />*/}
                                    {/*<ColumnDirective field='PLATEYEAR' headerText='Plate Year' />*/}
                                    {/*<ColumnDirective field='VIN' headerText='VIN #' />*/}
                                    {/*<ColumnDirective field='VEHMAKE' headerText='Vehicle Make' />*/}
                                    {/*<ColumnDirective field='VEHYEAR' headerText='Vehicle Year' />*/}
                                    {/*<ColumnDirective field='VEHMODEL' headerText='Vehicle Model' />*/}
                                    {/*<ColumnDirective field='VEHSTYLE' headerText='Vehicle Style' visible={false} />*/}
                                    {/*<ColumnDirective field='VEHCOLOR' headerText='Vehicle Color' visible={false} />*/}
                                    {/*<ColumnDirective field='LAT' headerText='Latitude' visible={false} />*/}
                                    {/*<ColumnDirective field='LNG' headerText='Longitude' visible={false} />*/}
                                    {/*<ColumnDirective field='STREETNUMBER' headerText='Street#' visible={false} />*/}
                                    <ColumnDirective field='STREETNAME' headerText='Address' />
                                    <ColumnDirective field='CITY' headerText='City' />
                                    {/*<ColumnDirective field='ZIP' headerText='Zip' />*/}
                                    {/*<ColumnDirective field='REQID' headerText='ReqID' visible={false} />*/}
                                    {/*<ColumnDirective field='NOTES' headerText='Notes' visible={false} />*/}
                                    {/*<ColumnDirective field='IPADDRESS' headerText='IP Address' visible={false} />*/}
                                    {/*<ColumnDirective field='DEVICEORI' headerText='Device ORI' visible={false} />*/}
                                    {/*<ColumnDirective field='CREATEDATE' headerText='Create Date' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }} />                                    */}
                                    <ColumnDirective field='DEPT' headerText='Dept.' />
                                    {/*<ColumnDirective field='STATE' headerText='State' />*/}
                                    {/*<ColumnDirective field='VENDOR' headerText='Vendor' />*/}
                                    <ColumnDirective
                                        template={(item: any) =>
                                            (<Button onClick={() => { window.open('/detail/TowedVehicle?ID=' + item?.ID + '&Dept=' + item?.DEPT, '_blank') }} >View</Button>)}
                                        headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]} />
                            </GridComponent>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
};
export default PanelTowedVehicle;