/* eslint-disable no-loop-func */
import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Tab, Tooltip, Snackbar, Alert, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, Button, TextField, Typography, DialogActions, Container, Checkbox, RadioGroup, Radio, DialogContentText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Grid as MUIGrid } from '@mui/material';
import { DateTimePicker, LocalizationProvider, TabContext, TabList, TabPanel } from '@mui/lab';
import {PostAdd, Close, FormatListBulleted, ExpandMore, PictureAsPdf} from '@mui/icons-material';
import { GetDefaultStateForSite, GetStringNameForSite, IsPanelEnabled, PERSON_PANEL, SITE } from "../../../interfaces/config.interface";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    GetFileContentPersonMain,
    RunGetGRAILReferral,
    RunPerson_AddressPhoneQuery,
    RunPerson_AKAName,
    RunPerson_ArrestQuery, RunPerson_BOLOQuery,
    RunPerson_CADRMSQuery,
    RunPerson_CodisDNAQuery,
    RunPerson_CrumbScore,
    RunPerson_DLQuery,
    RunPerson_ETicketQuery,
    RunPerson_ETraceQuery, RunPerson_FirearmQuery, RunPerson_GunOffenderQuery, RunPerson_GunStatQuery,
    RunPerson_ImageQuery,
    RunPerson_InfocopQuery,
    RunPerson_JailQuery, RunPerson_M43PersonQuery, RunPerson_MissingPersonQuery,
    RunPerson_NIBINQuery,
    RunPerson_NJPOPQuery,
    RunPerson_njtraceQuery,
    RunPerson_ParkMobileQuery,
    RunPerson_ParoleQuery,
    RunPerson_Plate,
    RunPerson_PrisonQuery,
    RunPerson_ProfessionQuery,
    RunPerson_ProfileReport,
    RunPerson_Referral, RunPerson_SaveSupplementalFile, RunPerson_SupplementalFile,
    RunPerson_TSC,
    RunPerson_ViolentQuery
} from "../../../services/getDar.service";
import {
    GetPersonRelatedPIR,
    GetPersonRelatedTSC,
    GetProfileReportPDF,
    HasProfileReport,
    RunCorrection,
    RunExpiryToken,
    RunGetPersonMainRecord,
    RunPersonSummaryPDF,
    RunPromisGavel,
    RunSocialMedia
} from "../../../services/account.service";
import { RunPerson_ALPRQuery } from "../../../services/getAlpr.service";
import { ENABLED_PANELS, Main_URL, showCrumbScore, SiteName } from "../../../services/config.service";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search,
    ExcelExportProperties,
    Resize,
    Reorder,
    SortSettingsModel,
    CommandColumn,
    setChecked
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { GetDarInfo_Query, getDarInfoResponse } from "../../../interfaces/getDarInfo_interface";
import { base64_to_blob, GetDeviceToken, RunShotSpotterQuery } from "../../../services/detail.service";
import { RunDarQuery } from "../../../services/getDar.service";
import { handleSearchTime } from "../../../services/formatDate.service";
import { getUser, isUserPermission } from '../../../services/auth.service';
import { Account } from '../../../interfaces/auth_interface';
import './styles.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import PreliminaryReportSearch from "./_preliminaryReportSearch";
import PreliminaryReportInputForm from './_preliminaryReportInputForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { PIRReport } from '../../../interfaces/PIR.interface';
import { PersonInfo } from "../../../interfaces/getDarInfo_interface";
import {GetPIR, GetPIRPDF, SetPIRReport, SetPIRSupplement, RunGetPersonInfo, RunPersonSummaryPDFDataOnly} from "../../../services/pir.service";
import DifferenceIcon from '@mui/icons-material/Difference';
import QueueIcon from '@mui/icons-material/Queue';
import {formatDT} from "../../../services/formatDate.service";
import Swal from "sweetalert2";
import PreliminarySupplementReportInputForm from "./_PreliminarySupplementInputForm";
import { SearchDL } from '../../../interfaces/searchDL.interface';

interface PIRSearchResultsProps {
    Records: PIRReport[]
    RefreshTrigger: any
}

const PreliminaryReportSearchResults: React.FC<PIRSearchResultsProps> = ({ Records, RefreshTrigger }) => {
    const [user, setUser] = React.useState<Account>(getUser());
    const [shotSpotterData, setShotSpotterData] = React.useState<{ [key: string]: any[] }>({});
    const [typedRecords, setTypedRecords] = React.useState<any[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [tabValue, setTabValue] = React.useState('2');
    const [tabModLabelValue, setTabModLabelValue] = React.useState('CREATE NEW');
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<any>("success")
    const [editingRecord, setEditingRecord] = React.useState<null>(null);
    const [personDetails, setPersonDetails] = React.useState<null>(null);
    const [dyrsPersonList, setDyrsPersonList] = React.useState<any[]>([]);
    const [dyrsSearchList, setDyrsSearchList] = React.useState<any[]>([]);

    const [expandedRows, setExpandedRows] = React.useState<Set<number>>(new Set());
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [currentRowData, setCurrentRowData] = React.useState<any>();
    const [currentSupplementRowData, setCurrentSupplementRowData] = React.useState<any>();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);


    const handleDataTyping = async () =>
    {
        if (Records) {
            let completedRecords = Records.filter(record => (record?.Completed !== false) || (record?.Completed == null))
            let tempR: any = [...completedRecords]
            setIsLoading(true)
            await new Promise(r => setTimeout(r, 1000)); //Why I need to sleep... This fixes the syncfusion no rerender on my machine
            setTypedRecords(tempR)
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        
        handleDataTyping()
    }, [Records])

    React.useEffect(() => {
        console.log(dyrsSearchList)
    }, [dyrsSearchList])

    React.useEffect(() => {
        console.log(dyrsPersonList)
    }, [dyrsPersonList])
    
 
    const openDeleteConfirmation = (rowData: any) => {
        setCurrentRowData(rowData);
        setOpenDeleteDialog(true);
    };

    const handleDeleteSupplement = () => {
        const updatedResults = typedRecords.map(row => {
            if (row.ccn === currentRowData.ccn) {
                const updatedRow = { ...row, supplementData: undefined };
                return updatedRow;
            }
            return row;
        });
        setTypedRecords(updatedResults);
        setOpenDeleteDialog(false);
    };

    const handleAssociatedPeople = (response: any, associatedPeople: any) => {
        let temp: any[] = []

        if (response.Association) {
            associatedPeople.push(...response.Association)
        }
        if (response.Association1) {
            associatedPeople.push(...response.Association1)
        }
        if (response.Association2) {
            associatedPeople.push(...response.Association2)
        }
        if (response.Association3) {
            associatedPeople.push(...response.Association3)
        }
        if (response.Association4) {
            associatedPeople.push(...response.Association4)
        }
        return associatedPeople
    }

    const openPDF = async (row:any) => {
        if (row && row.ID) {
            let pdids: any = []
            for (var dyrs_hit of row.DYRSVeritracks_List) {
                if (dyrs_hit.DYRS_PDID && dyrs_hit.DYRS_PDID != null && dyrs_hit.DYRS_BTReportIncluded) {
                    pdids.push(dyrs_hit.DYRS_PDID)
                } 
            }
            // Create a new Date object for today
            let today = new Date();

            // Set the time to 12:00 AM using UTC methods
            let startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);

            // Set the time to 11:59 PM using UTC methods
            let endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

            let search_query: GetDarInfo_Query = {
                QueryType: "E",
                Dept: user.Dept,
                EmpID: user.EmpID,
                DeptORI: user.DeptORI,
                DateRange: false,
                DateTypeValue: 0,
                FromDate: formatDT(startOfDay),
                ToDate: formatDT(endOfDay),
                SearchType: "AND",
                IndicatorOn: false,
                TopArrestNumber: 0,
                FNames: [],
                MNames: [],
                LNames: [],
                DOBs: [],
                SEX: [],
                Address: "",
                Cities: [],
                States: [],
                Zip: "",
                Statutes: [],
                Gang: [],
                FBIs: [],
                SBIs: pdids ?? [],
                DLNos: [],
                PlateNos: [],
                VINs: [],
                AgencyArrested: [],
                InfocopDept: [],
                Indicators: [],
                IndicatorOccurrence: null,
                Latitude: null,
                Longitude: null,
                Radius: 0,
                ResidentCounty: [],
                ArrestedCounty: [],
                Arrest_Type: [],
                CaseNumbers: [],
                FTSIDs: [],
                SystemCall: false,
            };

            var personList: any[] = []
            var btReports: any[] = []
            let qRes: getDarInfoResponse = await RunDarQuery(search_query)
            for (var resPerson of qRes.PersonList) {
                personList.push(resPerson)
            }

            console.log("qRes: ", qRes)

            for (var person of personList) {
                console.log("Just before queries")
                let today: string = new Date().toLocaleDateString();
                let search: SearchDL = {
                    Dept: user.Dept,
                    DeptORI: user.DeptORI,
                    EmpID: user.EmpID,
                    FromDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today,
                    ToDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today,
                    FBI: person?.FBI,
                    SBINo: person?.SBINo,
                    DLNo: person?.DLNo,
                    DLSt: person?.DLSt,
                    FirstName: person?.FName,
                    MiddleName: person?.MName,
                    LastName: person?.LName,
                    DOB: person?.DOB ? (person?.DOB)?.toLocaleString() : "",
                    LoggedEmpID: null,
                    LoggedDept: null,
                    SEX: person?.SEX,
                    City: person?.City,
                    GANGRELATED: person?.GANGRELATED,
                    PersonID: person?.PersonID,
                    QueryType: null,
                    DarID: person?.DarID ? person?.DarID.toString() : null,
                    CrumbScorePercentile: person?.CrumbScorePercentile,
                    ETicket: null,
                }

                var personImageInfo: any[] = []
                var associatedPeople: any[] = []
                var crumbScoreInfo: any[] = []
                var arrestInfo: any[] = []
                var NJPOPInfo: any[] = []
                var codisDNAInfo: any[] = []
                var infocopInfo: any[] = []
                var ETicketInfo: any[] = []
                var CADRMSInfo: any[] = []
                var ParkMobileInfo: any[] = []
                var ETraceInfo: any[] = []
                var NJTraceInfo: any[] = []
                var NIBINInfo: any[] = []
                var PrisonInfo: any[] = []
                var ParoleInfo: any[] = []
                var MissingPersonInfo: any[] = []
                var GunStatInfo: any[] = []
                var GunOffenderInfo: any[] = []
                var BOLOInfo: any[] = []
                var SupplementalFilesInfo: any[] = []
                var FirearmInfo: any[] = []
                var m43PersonInfo: any[] = []
                var phoneStringArray: any[] = []
                var nicknamesStringArray: any[] = []
                var JailInfo: any[] = []
                var ViolentInfo: any[] = []
                var ProfessionInfo: any[] = []
                var tscInfo: any[] = []
                var ReferralInfo: any[] = []
                var AKAInfo: any[] = []
                var ProfileReportInfo: any[] = []
                var alprInfo: any[] = []
                var DLNo: any[] = []
                var PlateInfo: any[] = []
                var phoneAddressInfo: any = null;

                let tscPerson: any = {
                    FName: search.FirstName,
                    LName: search.LastName,
                    DOB: search.DOB
                }

                var alprRan: boolean = false;

                let mark43PersonInfo = null
                let caseNumberList = []

                // eslint-disable-next-line no-loop-func
                let response = await RunPerson_ImageQuery(search)
                if (response.AnyData)
                    personImageInfo = response.AnyData 
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);
                
                response = await RunPerson_CrumbScore(search)
                if (response.AnyData) 
                    crumbScoreInfo = response.AnyData[0]
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);

                response = await RunPerson_ArrestQuery(search)
                if (response.AnyData)
                    arrestInfo = response.AnyData
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople)
                
                response = await RunPerson_NJPOPQuery(search)
                if (response.AnyData)
                    NJPOPInfo = response.AnyData
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);
                
                response = await RunPerson_CodisDNAQuery(search)
                if (response.AnyData)
                    codisDNAInfo = response.AnyData
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);
                
                response = await RunPerson_InfocopQuery(search)
                if (response.AnyData)
                    infocopInfo = response.AnyData
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);
                
                response = await RunPerson_ETicketQuery(search);
                if (response.AnyData)
                    ETicketInfo = response.AnyData;
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);

                response = await RunPerson_CADRMSQuery(search);
                if (response.AnyData)
                    CADRMSInfo = response.AnyData;
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);

                response = await RunPerson_ParkMobileQuery(search);
                if (response.AnyData)
                    ParkMobileInfo = response.AnyData;
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);

                response = await RunPerson_ETraceQuery(search);
                if (response.AnyData)
                    ETraceInfo = response.AnyData;
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);

                response = await RunPerson_njtraceQuery(search);
                if (response.AnyData)
                    NJTraceInfo = response.AnyData;
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);

                response = await RunPerson_NIBINQuery(search);
                if (response.AnyData)
                    NIBINInfo = response.AnyData;
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);

                response = await RunPerson_PrisonQuery(search);
                if (response.AnyData)
                    PrisonInfo = response.AnyData;
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);

                response = await RunPerson_ParoleQuery(search);
                if (response.AnyData)
                    ParoleInfo = response.AnyData;
                if (response)
                    associatedPeople = handleAssociatedPeople(response, associatedPeople);

                if (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) {
                    response = await RunPerson_MissingPersonQuery(search);
                    if (response.AnyData)
                        MissingPersonInfo = response.AnyData;
                    if (response)
                        associatedPeople = handleAssociatedPeople(response, associatedPeople);

                    response = await RunPerson_GunStatQuery(search);
                    if (response.AnyData)
                        GunStatInfo = response.AnyData;

                    response = await RunPerson_GunOffenderQuery(search);
                    if (response.AnyData)
                        GunOffenderInfo = response.AnyData;

                    response = await RunPerson_BOLOQuery(search);
                    if (response.AnyData)
                        BOLOInfo = response.AnyData;

                    response = await RunPerson_SupplementalFile(search);
                    if (response.AnyData)
                        SupplementalFilesInfo = response.AnyData;

                    response = await RunPerson_FirearmQuery(search);
                    if (response.AnyData)
                        FirearmInfo = response.AnyData;

                    response = await RunPerson_M43PersonQuery(search);
                    if (response.AnyData) {
                        m43PersonInfo = response.AnyData;
                        if (response.AnyData.length > 0) {
                            let tPhones = new Set();
                            let tNicknames = new Set();
                            for (let i = 0; i < response.AnyData.length; i++) {
                                if (response.AnyData[i].phoneNumber) {
                                    tPhones.add(response.AnyData[i].phoneNumber);
                                }
                                if (response.AnyData[i].nicknames) {
                                    tNicknames.add(response.AnyData[i].nicknames);
                                }
                            }
                            phoneStringArray = Array.from(tPhones);
                            nicknamesStringArray = Array.from(tNicknames);
                        }
                    }

                    response = await RunPerson_JailQuery(search);
                    if (response.AnyData) {
                        JailInfo = response.AnyData.sort((a: any, b: any) => {
                            return (new Date(b?.COMMITMENT_DATETIME).getTime() - new Date(a?.COMMITMENT_DATETIME).getTime());
                        });
                    }
                    if (response)
                        associatedPeople = handleAssociatedPeople(response, associatedPeople);

                    response = await RunPerson_ViolentQuery(search);
                    if (response.AnyData)
                        ViolentInfo = response.AnyData;
                    if (response)
                        associatedPeople = handleAssociatedPeople(response, associatedPeople);

                    response = await RunPerson_ProfessionQuery(search);
                    if (response.AnyData)
                        ProfessionInfo = response.AnyData;
                    if (response)
                        associatedPeople = handleAssociatedPeople(response, associatedPeople);

                    response = await RunPerson_Referral(search);
                    if (response.AnyData)
                        ReferralInfo = response.AnyData;

                    response = await RunPerson_AKAName(search);
                    if (response.AnyData)
                        AKAInfo = response.AnyData;

                    response = await RunPerson_ProfileReport(search);
                    if (response.AnyData)
                        ProfileReportInfo = response.AnyData;
                }

                if (search) {
                    let ALPRQuery = { ...search, QueryType: "ALPR_NOAPICALL" ?? "3" };
                    if (!alprRan || ALPRQuery?.QueryType === "ALPR_NOAPICALL") {
                        try {
                            alprRan = ALPRQuery?.QueryType === "3";
                            let date = new Date();
                            if (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) {
                                date.setDate(new Date().getDate() - 90);
                            } else {
                                date.setDate(new Date().getDate() - 364);
                            }
                            ALPRQuery.FromDate = date.toLocaleString();
                            ALPRQuery.ToDate = new Date().toLocaleString();
                            response = await RunPerson_ALPRQuery(ALPRQuery);
                            if (response.AnyData) {
                                alprInfo = response.AnyData;
                            }
                        } catch (e) {
                            alprRan = false;
                        }
                    }
                }

                response = await RunPerson_DLQuery(search);
                if (response.AnyData)
                    DLNo = response.AnyData;

                response = await RunPerson_AddressPhoneQuery(search);
                if (response)
                    phoneAddressInfo = response;

                response = await RunPerson_Plate(search);
                if (response.AnyData)
                    PlateInfo = response.AnyData;


                if (m43PersonInfo && m43PersonInfo.length > 0) {
                    mark43PersonInfo = m43PersonInfo[0]
                    mark43PersonInfo.AdditionalPhoneInfo = phoneStringArray.join() ?? ""
                    mark43PersonInfo.AdditionalAKAInfo = nicknamesStringArray.join() ?? ""
                    for (var item of m43PersonInfo) {
                        caseNumberList.push(item.reportNumber)
                    }
                }

                let pdfPerson: any = {
                    PersonInfo: person,
                    ImageInfo: personImageInfo,
                    SCORInfo: {},
                    ArrestInfo: arrestInfo,
                    NJPOPInfo: NJPOPInfo,
                    DNAInfo: codisDNAInfo,
                    InfoCopInfo: infocopInfo,
                    ETicketInfo: ETicketInfo,
                    CADRMSInfo: CADRMSInfo,
                    ParkMobileInfo: ParkMobileInfo,
                    ETraceInfo: ETraceInfo,
                    NJTraceInfo: NJTraceInfo,
                    NIBINInfo: NIBINInfo,
                    PrisonInfo: PrisonInfo,
                    ParoleInfo: ParoleInfo,
                    JailInfo: JailInfo,
                    MissingPersonInfo: MissingPersonInfo,
                    GunStatInfo: GunStatInfo,
                    GunOffenderInfo: GunOffenderInfo,
                    BOLOInfo: BOLOInfo,
                    Mark43PersonInfo: mark43PersonInfo ?? null,
                    ViolentInfo: ViolentInfo,
                    ProfessionInfo: ProfessionInfo,
                    ALPRInfo: alprInfo,
                    DLInfo: DLNo,
                    AddressInfo: phoneAddressInfo?.AnyData,
                    PhoneInfo: phoneAddressInfo?.AdditionalData,
                    PlateInfo: PlateInfo,
                    AssociatedPeopleInfo: associatedPeople,
                    SiteState: GetDefaultStateForSite(SiteName),
                    AKAInfo: AKAInfo,
                    caseNumberList: caseNumberList,
                    SupplementalFiles: SupplementalFilesInfo
                }

                if (isUserPermission('TSC')) {
                    var tscList = await GetPersonRelatedTSC(pdfPerson)
                    console.log("TSC list: ", tscList)
                    pdfPerson.TSCInfo = tscList;
                }

                let personReport = await RunPersonSummaryPDFDataOnly(pdfPerson)
                if (personReport)
                    btReports.push(personReport)
            }
            await GetPIRPDF(row.ID, btReports)
        }
    }


    let grid: Grid | null;

    const handleTabStateChange = (tab:any, edit:boolean = false)=>{
        if(tab === '2'){
            setTabModLabelValue('CREATE NEW')
            setEditingRecord(null)
        } else if (tab === '1'){
            if(!edit) {
                setEditingRecord(null)
                setTabModLabelValue('CREATE NEW')
            }else {
                setTabModLabelValue('EDIT')
            }
        }
       
        setTabValue(tab)
    }
    const handleFormSubmit = (formData: any) => {
        if(formData)
            RefreshTrigger()
        
        setEditingRecord(null)
        handleTabStateChange('2')

        setAlertType('success')
        setAlertText("Your report has successfully been submitted.")
        setAlertOpen(true);
    };

    const handleSuppSubmit = (event: any) => {
        if(event)
            RefreshTrigger()
        
        setOpenAddDialog(!openAddDialog);
    };
    
    let sortSettings: SortSettingsModel = {
        columns: [{ field: 'ccn', direction: 'Descending' }]
    };


    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        handleTabStateChange(newValue)
    }

    const handleEditPIR = (rowData: any) => {
        if(rowData && rowData.ID) {
            setEditingRecord(rowData?.ID)
            handleTabStateChange('1',true)
        }

    };
    const handleDeletePIR = (rowData: any) => {
        if (window.confirm("Are you sure you would like to delete this PIR? This will delete PIR with CCN: " + rowData.Summary_CCN)) {
            let temp = {...rowData}
            temp.deleted = true
            SetPIRReport(temp)
            if(grid) {
                // @ts-ignore
                let dSource:any = [...grid?.dataSource]
                if(temp.ID && temp.ID != 0) {
                    let index:number = dSource.findIndex((e: any) => e.ID === temp.ID)
                    if(index >= 0)
                    {
                        dSource.splice(index,1)
                        grid.dataSource = dSource
                        setTypedRecords(dSource)
                        grid?.refresh()
                    }
                }
            }
        }
        
    };
    
    const handleEditSupplement = (rowData: any) => {
        setCurrentSupplementRowData(rowData)
        setOpenAddDialog(!openAddDialog)

    };
    const actionTemplate = (rowData: any) => {
        return (
            <ButtonGroup>
                <Tooltip title="View PDF" placement="top" arrow>
                    <IconButton onClick={() => openPDF(rowData)}>
                        <PictureAsPdf fontSize='small' color='inherit'/>
                    </IconButton>
                </Tooltip>
    
                <Tooltip title="Add Supplement" placement="top" arrow>
                    <IconButton onClick={() => openAddSupplementDialog(rowData)}>
                        <QueueIcon fontSize='small' color='success'/>
                    </IconButton>
                </Tooltip>
    
                <Tooltip title="Edit Preliminary Investigative Report" placement="top" arrow>
                    <IconButton onClick={() => handleEditPIR(rowData)}>
                        <EditIcon fontSize='small' color='primary'/>
                    </IconButton>
                </Tooltip>
    
                <Tooltip title="Delete Preliminary Investigative Report" placement="top" arrow>
                    <IconButton onClick={() => handleDeletePIR(rowData)}>
                        <DeleteIcon fontSize='small' color='error'/>
                    </IconButton>
                </Tooltip>
    
            </ButtonGroup>
        );
        
    };
    const actionTemplateSupplement = (rowData: any) => {
        return (
            <ButtonGroup>
                <Tooltip title="Edit Supplement" placement="top" arrow>
                    <IconButton onClick={() => handleEditSupplement(rowData)}>
                        <EditIcon fontSize='small' color='primary' />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>
        );
    };



    const openAddSupplementDialog = (rowData: any) => {
        setCurrentRowData(rowData);
        setCurrentSupplementRowData(null);
        setOpenAddDialog(true);
    };

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text == "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Preliminary_Report.xlsx';

            grid.excelExport(excelProp);
        }
    }

    const playShotSound = async (FileID: any, ShotID: any, FileMeme: any) => {
        let res: any = await RunShotSpotterQuery(FileID, ShotID);
        let snd = new Audio("data:" + FileMeme + ";base64," + res.AudioFile);
        snd.play();
    }

    const downloadShotFile = async (FileID: any, ShotID: any, FileMeme: any) => {
        let res: any = await RunShotSpotterQuery(FileID, ShotID);
        if (res.AudioFile) {
            base64_to_blob(res.AudioFile, FileMeme).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank');
            });
        }
    }
    
    const AccordionSupplements = ({ supplements }: any) => {

        return (
            <>
                <GridComponent
                    dataSource={supplements}
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='CreateDate' allowSorting={false} template={(r: any) => (formatDT(r.CreateDate))} width={140} minWidth={140} maxWidth={140} headerText='Supplement Created' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Summary_DateTime' allowSorting={false}  template={(r:any)=>(formatDT(r.Summary_DateTime))} width={140} minWidth={140} maxWidth={140} headerText='Supplement Date' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Summary_CCN' visible={true} width={140} minWidth={140} maxWidth={140} headerText='CCN' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Summary_Type' width={140} minWidth={140} maxWidth={140} headerText='Incident Type' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Summary_Location' width={140} minWidth={140} maxWidth={140} headerText='Location' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Background_CaseRelationship' width={140} minWidth={140} maxWidth={140} headerText='Case Relationship' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective template={actionTemplateSupplement} headerText="Actions" width="100" textAlign="Center" />
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder, CommandColumn]} />
                </GridComponent>
                
            </>
        );
    };


    const renderDetailTemplate = (rowData: any) => {
        var shotSpotterDetails:any[] =  [];
        if(rowData.ShotSpotter_List && rowData.ShotSpotter_List.length > 0){
            for(var s of rowData.ShotSpotter_List){
                if(s?.ShotSpotterFiles && s?.ShotSpotterFiles.length > 0)
                {
                    shotSpotterDetails = shotSpotterDetails.concat(s?.ShotSpotterFiles)
                }
            }
        }
        
        return (
            <div>
                {rowData.SupplementReports && rowData.SupplementReports.length > 0 && (
                    <div className={"mt-2 font-bold"}>
                        Supplements: {rowData.SupplementReports.length}
                        <AccordionSupplements supplements={rowData.SupplementReports} />
                    </div>
                )}

                {/* Add ShotSpotter Audio Files */}
                {shotSpotterDetails && shotSpotterDetails.length > 0 ? (
                    <div className={"mt-5 font-bold"}>
                         ShotSpotter Files: {shotSpotterDetails?.length}
                        {shootingAudioFiles(shotSpotterDetails)}
                    </div>
                ) : (
                    <Typography>No ShotSpotter data found.</Typography>
                )}
            </div>
        );
    };

    const shootingAudioFiles = (shotSpotterDetails: any[]) => {
        return (
            <div className="border-2 border-gray-100">
                <GridComponent
                    dataSource={shotSpotterDetails}
                    allowPaging={true}
                    pageSettings={{ pageSize: 10, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={{ columns: [{ field: 'location', direction: 'Descending' }] }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field="location" headerText="Associated Shot Spotter" />
                        <ColumnDirective field="sensor_distance" headerText="Sensor Distance (Meters)" />
                        <ColumnDirective field="mime" headerText="Sensor Media Type" />
                        <ColumnDirective
                            template={(shot: any) => (
                                <span>
                                    <Tooltip title="Play Audio File">
                                        <Button onClick={() => playShotSound(shot.ID, shot.shotid, shot.mime)}>
                                            Play
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Download Audio File">
                                        <Button onClick={() => downloadShotFile(shot.ID, shot.shotid, shot.mime)}>
                                            Download
                                        </Button>
                                    </Tooltip>
                                </span>
                            )}
                            headerText="Actions"
                        />
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter]} />
                </GridComponent>
            </div>
        );
    };


    
    return (
        <div className="m-5 p-5">
            <Box className="" style={{ justifyContent: 'center' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} variant="fullWidth">
                            <Tab icon={<FormatListBulleted />} label="LIST VIEW" value={'2'} />
                            <Tab icon={<PostAdd />} label={`${tabModLabelValue} REPORT`} value={'1'} />
                        </TabList>
                    </Box>
                    <TabPanel value='2'>
                        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                            <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%', height: '25%', fontSize: '1.2rem' }}>
                                {alertText}
                            </Alert>
                        </Snackbar>
                        <div className={"pl-10 pr-10 mt-5"}>
                            <h4 className={"font-bold pl-5"}>
                                {typedRecords?.length} Records Found</h4>

                            {!isLoading && typedRecords && typedRecords.length>0 && 
                                <GridComponent
                                    dataSource={typedRecords}
                                    detailTemplate={renderDetailTemplate}
                                    allowPaging={true}
                                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                                    allowSorting={true}
                                    sortSettings={sortSettings}
                                    allowMultiSorting={true}
                                    allowFiltering={true}
                                    filterSettings={{ type: 'CheckBox' }}
                                    allowTextWrap={true}
                                    textWrapSettings={{ wrapMode: 'Both' }}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbar={['ExcelExport', 'Search']}
                                    toolbarClick={GridToolsClick}
                                    ref={(g) => { if (g) { grid = g } }}
                                    allowSelection={true}
                                    allowResizing={true}
                                    rowHeight={24}
                                    detailDataBound={renderDetailTemplate}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective field='CreateDate' visible={true} width={140} template={(r: any) => (formatDT(r.CreateDate))} minWidth={140} maxWidth={140} headerText='Report Created' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_CCN' visible={true} width={140} minWidth={140} maxWidth={140} headerText='CCN' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_SpecialistFirstName' width={140} minWidth={140} maxWidth={140} headerText='First Name' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_SpecialistLastName' width={140} minWidth={140} maxWidth={140} headerText='Last Name' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_DateTime' allowSorting={false}  template={(r:any)=>(formatDT(r.Summary_DateTime))} width={140} minWidth={140} maxWidth={140} headerText='Incident Date' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_Type' width={140} minWidth={140} maxWidth={140} headerText='Incident Type' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='Summary_Location' width={120} minWidth={120} maxWidth={120} headerText='Location' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective template={actionTemplate} headerText="Actions" width="135" textAlign="Center" />
                                    </ColumnsDirective>
                                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder, CommandColumn]} />
                                </GridComponent>
                            }
                        </div>
                        <Dialog open={openAddDialog} fullWidth={true} maxWidth="lg" onClose={() => { setOpenAddDialog(false); }}>
                            <DialogTitle>Add Supplement</DialogTitle>
                            <DialogContent>
                                <PreliminarySupplementReportInputForm handleReportSubmit={handleSuppSubmit} ParentReportId={currentRowData?.ID} Record={currentSupplementRowData} btPerson={null} drawerDisplay={false} HandleSuppClose={null} SummaryInfo={currentRowData} ccnList={null} />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={openDeleteDialog}
                            onClose={() => setOpenDeleteDialog(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete this supplement?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleDeleteSupplement} color="primary" autoFocus>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </TabPanel>
                    <TabPanel value='1'>
                        {tabValue === '1' && <PreliminaryReportInputForm handleReportSubmit={handleFormSubmit} editReport={editingRecord} personDetails={personDetails} currentTab={tabValue} />}
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
};

export default PreliminaryReportSearchResults;