import React, {useState, useEffect} from "react";
import {TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Tooltip} from "@mui/material";
import ArrestDetail from "../../interfaces/Detail/ArrestDetail.interface"
import { RunArrestDetailQuery } from "../../services/detail.service";
import {formatDate, formatSSN, hideSSN} from "../../services/formatDate.service"
import './Detail.css'
import DetailMap from "./_DetailMap";
import { SiteName } from "../../services/config.service";
import { GetStringNameForSite } from "../../interfaces/config.interface";

interface DetailProps {
    ID: string | null
    department: string | null
}


const ArrestDetailInfo: React.FC<DetailProps> = ({ID, department}) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<ArrestDetail>({})
    const [pin, setPin] = React.useState<any>({})

    const loadData = async () =>{        
        if (!DataLoaded){
            setDataLoaded(true)
            let res = await RunArrestDetailQuery(ID, department);
            setData(res)
            createPin(res)
        }
    }

    React.useEffect(() => {
        if (!DataLoaded){
            setDataLoaded(true)
            loadData()   
        }
    });

    const createPin = async (Data:any) => {
        let tPin:any = {}
        if (Data && Data?.Inc_Latitude && Data?.Inc_Longitude) {
            tPin = ({
                lat: Data?.Inc_Latitude,
                lng: Data?.Inc_Longitude,
                address: Data?.IncidentStreetNo + " " + Data?.IncidentStreetName,
                city: Data?.IncidentCity,
                county: Data?.IncidentCounty,
                state: Data?.IncidentState,
                zip: Data?.IncidentZip,
                date: Data?.DTOFARREST,
                statute: Data?.STATUTE,
                arrestedBy: Data?.ORIGINATINGORINAME,
                detail:"arrest"
            })
        }
        setPin(tPin)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
                <Card sx = {{maxWidth: 900, m:'auto', mb:1}} >
                    <CardContent>
                        <h3 className = "mb-4 font-bold font text-xl text-blue-400">Statute Information</h3>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Data?.ID}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Arrest Date</span><span className ="DetailTableData">{formatDate(Data.DTOFARREST)}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Offense Date</span><span className ="DetailTableData">{formatDate(Data.DTOFOFFENSE)}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Offense Code</span><span className ="DetailTableData">{Data.GENOFFCD}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Offense Desc</span><span className ="DetailTableData">{Data.GENOFFCDDESC}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Statute Code</span><span className ="DetailTableData">{Data.STATUTE}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Statute Description</span><span className ="DetailTableData">{Data.STATUTEDESCRIPTION}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Gang Related</span><span className ="DetailTableData">{Data.GANGRELATED}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Domestic Violence</span><span className ="DetailTableData">{Data.DOMESTICVIOLENCE}</span></div>
                    </CardContent>
                </Card>
                
                <Card sx = {{maxWidth: 900, m:'auto', mb:1 }}>
                    <CardContent>
                        <h3 className = "mb-5 font-bold font text-xl text-blue-400">Person Information</h3>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">FBI</span><span className ="DetailTableData">{Data.FBI}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">SBI</span><span className ="DetailTableData">{Data.SBINO}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">{GetStringNameForSite(SiteName, "CASE #") }</span><span className ="DetailTableData">{Data.AgencyCaseNo}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">First Name</span><span className ="DetailTableData">{Data.FIRSTNAME}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Middle Initial</span><span className ="DetailTableData">{Data.MI}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Last Name</span><span className ="DetailTableData">{Data.LASTNAME}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">DOB</span><span className ="DetailTableData">{formatDate(Data.DOB)}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Sex</span><span className ="DetailTableData">{Data.SEX}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Race</span><span className ="DetailTableData">{Data.RACE}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">AKA First Name</span><span className ="DetailTableData">{Data.AKAFIRST}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">AKA Last Name</span><span className ="DetailTableData">{Data.AKALAST}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Place of Birth</span><span className ="DetailTableData">{Data.PLACEOFBIRTH}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">SSN1</span><Tooltip placement="left" title={"" + formatSSN(Data?.SSN1)}><span className="DetailTableData">{Data?.SSN1 ? hideSSN(Data?.SSN1) : ""}</span></Tooltip></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">SSN2</span><Tooltip placement="left" title={"" + formatSSN(Data?.SSN2)}><span className="DetailTableData">{Data?.SSN2 ? hideSSN(Data?.SSN2) : ""}</span></Tooltip></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">SSN3</span><Tooltip placement="left" title={"" + formatSSN(Data?.SSN3)}><span className="DetailTableData">{Data?.SSN3 ? hideSSN(Data?.SSN3) : ""}</span></Tooltip></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">SSN4</span><Tooltip placement="left" title={"" + formatSSN(Data?.SSN4)}><span className="DetailTableData">{Data?.SSN4 ? hideSSN(Data?.SSN4) : ""}</span></Tooltip></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">SSN5</span><Tooltip placement="left" title={"" + formatSSN(Data?.SSN5)}><span className="DetailTableData">{Data?.SSN5 ? hideSSN(Data?.SSN5) : ""}</span></Tooltip></div>
                    </CardContent>
                </Card>
                <Card sx = {{maxWidth: 900, m:'auto', mb:1}}>
                    <CardContent>
                        <h3 className = "mb-4 font-bold font text-xl text-blue-400">Person Address</h3>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street #</span><span className ="DetailTableData">{Data.STREETNO}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street Name</span><span className ="DetailTableData">{Data.STREETNAME}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">City</span><span className ="DetailTableData">{Data.CITY}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">County</span><span className ="DetailTableData">{Data.RESIDENCECOUNTYNAME}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">State</span><span className ="DetailTableData">{Data.STATE}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">ZIP</span><span className ="DetailTableData">{Data.ZIP}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Latitude</span><span className ="DetailTableData">{Data.Latitude}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Longitude</span><span className ="DetailTableData">{Data.Longitude}</span></div>
                    </CardContent>
                </Card>
                <Card sx = {{maxWidth: 900, m:'auto', mb:1}}>
                    <CardContent>
                        <h3 className = "mb-4 font-bold font text-xl text-blue-400">Agency Information</h3>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Municipality Code</span><span className ="DetailTableData">{Data.MUNCD}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Municipality Name</span><span className ="DetailTableData">{Data.MUNICIPALITYNAME}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Originator ORI</span><span className ="DetailTableData">{Data.ORIGINATINGORI}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Originator Name</span><span className ="DetailTableData">{Data.ORIGINATINGORINAME}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Booking ORI</span><span className ="DetailTableData">{Data.BOOKINGORI}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Booking Name</span><span className ="DetailTableData">{Data.BOOKINGORINAME}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">County Code</span><span className ="DetailTableData">{Data.COUNTYOFARREST}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">County Name</span><span className ="DetailTableData">{Data.COUNTYOFARRESTNAME}</span></div>
                    </CardContent>
                </Card>
                <Card sx = {{maxWidth: 900, m:'auto', mb:1}}>
                    <CardContent>
                        <h3 className = "mb-4 font-bold font text-xl text-blue-400">Incident Address</h3>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street #</span><span className ="DetailTableData">{Data.IncidentStreetNo}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Street Name</span><span className ="DetailTableData">{Data.IncidentStreetName}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">City</span><span className="DetailTableData">{Data.IncidentCity}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">County</span><span className="DetailTableData">{Data.IncidentCounty}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">State</span><span className ="DetailTableData">{Data.IncidentState}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">ZIP</span><span className="DetailTableData">{Data.IncidentZip}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Latitude</span><span className ="DetailTableData">{Data.Inc_Latitude}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Longitude</span><span className="DetailTableData">{Data.Inc_Longitude}</span></div>
                        {pin && pin.lat && pin.lng ? <div className="DetailTableRow border-2 border-black"> <DetailMap data={pin} detail="arrest" /> </div> : <></>}
                    </CardContent>
                </Card>
                <Card sx = {{maxWidth: 900, m:'auto', mb:1}}>
                    <CardContent>
                        <h3 className = "mb-4 font-bold font text-xl text-blue-400">Other Information</h3>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Recorded Date</span><span className ="DetailTableData">{Data.DTOFUPDATE}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Expunge Date</span><span className ="DetailTableData">{Data.DTOFEXPUNGE}</span></div>
                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Source</span><span className ="DetailTableData">{Data.ARREST_TYPE}</span></div>
                    </CardContent>
                </Card>
        </div>
    );
}

export default ArrestDetailInfo;