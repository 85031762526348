import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import PrisonReleaseDetail from "../../interfaces/Detail/PrisonReleaseDetail.interface"
import {formatDate, formatDT} from "../../services/formatDate.service"

import './Detail.css'
import {JAOS} from "../../services/JAOS.service";
import {GetStringNameForSite} from "../../interfaces/config.interface";
import {SiteName} from "../../services/config.service";

interface DetailProps {
    ID: string | null
    department: string | null
}


const GunRegistrationDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<any>({})

    const loadData = async () => {

        if (!DataLoaded) {
            setDataLoaded(true)
            if(ID){
                let rec = new JAOS().objFromStack(ID)
                setData(rec)
            }
                
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">

            <Card sx = {{maxWidth: 900, m:'auto', mb:1}} >
                <CardContent>
                    <h3 className = "mb-4 font-bold font text-xl text-blue-400">RECORD INFORMATION</h3>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">ID</span><span className ="DetailTableData">{Data?.ID}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">TYPE</span><span className ="DetailTableData">{Data?.RECORD_TYPE}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">ACTION</span><span className ="DetailTableData">{Data?.RECORD_ACTION}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">DATETIME</span><span className ="DetailTableData">{formatDT(Data?.RECORD_DATETIME)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">CUSTODY STATUS</span><span className ="DetailTableData">{Data?.CUSTODY_STATUS}</span></div>
                </CardContent>
            </Card>

            <Card sx = {{maxWidth: 900, m:'auto', mb:1}} >
                <CardContent>
                    <h3 className = "mb-4 font-bold font text-xl text-blue-400">COMMITMENT INFORMATION</h3>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">{`${GetStringNameForSite(SiteName,'JURISDICTION')}`}</span><span className ="DetailTableData">{Data?.COMMITMENT_COUNTY}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">NUMBER</span><span className ="DetailTableData">{Data?.COMMITMENT_NUMBER}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">DATE</span><span className ="DetailTableData">{formatDate(Data?.COMMITMENT_DATE)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">TIME</span><span className ="DetailTableData">{Data?.COMMITMENT_TIME}</span></div>
                </CardContent>
            </Card>

            <Card sx = {{maxWidth: 900, m:'auto', mb:1}} >
                <CardContent>
                    <h3 className = "mb-4 font-bold font text-xl text-blue-400">RELEASE INFORMATION</h3>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">DATE</span><span className ="DetailTableData">{formatDate(Data?.RELEASE_DATE)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">TIME</span><span className ="DetailTableData">{Data?.RELEASE_TIME}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">REASON</span><span className ="DetailTableData">{Data?.RELEASE_REASON}</span></div>
                </CardContent>
            </Card>

            <Card sx = {{maxWidth: 900, m:'auto', mb:1}} >
                <CardContent>
                    <h3 className = "mb-4 font-bold font text-xl text-blue-400">LOCATION INFORMATION</h3>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">{`${GetStringNameForSite(SiteName,'JURISDICTION')}`}</span><span className ="DetailTableData">{Data?.LOCATION_COUNTY}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">UNIT</span><span className ="DetailTableData">{Data?.LOCATION_UNIT}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">SECTION</span><span className ="DetailTableData">{Data?.LOCATION_SECTION}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">CELL</span><span className ="DetailTableData">{Data?.LOCATION_CELL}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">BED</span><span className ="DetailTableData">{Data?.LOCATION_BED}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">NUMBER</span><span className ="DetailTableData">{Data?.LOCATION_NUMBER}</span></div>
                </CardContent>
            </Card>

            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-5 font-bold font text-xl text-blue-400">PERSON INFORMATION</h3>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">INMATE ID</span><span className ="DetailTableData">{Data?.INMATE_ID}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">INMATE NUMBER</span><span className ="DetailTableData">{Data?.INMATE_NUMBER}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">FIRST NAME</span><span className ="DetailTableData">{Data?.INMATE_FIRSTNAME}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">MIDDLE NAME</span><span className ="DetailTableData">{Data?.INMATE_MIDDLENAME}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">LAST NAME</span><span className ="DetailTableData">{Data?.INMATE_LASTNAME}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">DOB</span><span className ="DetailTableData">{formatDate(Data?.INMATE_DOB)}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">FBI</span><span className ="DetailTableData">{Data?.INMATE_FBI}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">{`${GetStringNameForSite(SiteName,'SBI')}`}</span><span className ="DetailTableData">{Data?.INMATE_SBI}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">BCI</span><span className ="DetailTableData">{Data?.INMATE_BCI}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">SEX</span><span className ="DetailTableData">{Data?.INMATE_GENDER}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">RACE</span><span className ="DetailTableData">{Data?.INMATE_RACE}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">HEIGHT</span><span className ="DetailTableData">{Data?.INMATE_HEIGHT}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">WEIGHT</span><span className ="DetailTableData">{Data?.INMATE_WEIGHT}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">ADDRESS</span><span className ="DetailTableData">{Data?.INMATE_STREET_NAME}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">ADDRESS LINE 2</span><span className ="DetailTableData">{Data?.INMATE_STREET_NAME_ADDL}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">CITY</span><span className ="DetailTableData">{Data?.INMATE_CITY}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">STATE</span><span className ="DetailTableData">{Data?.INMATE_STATE}</span></div>
                    <div className ="DetailTableRow" ><span className = "DetailTableLabel">COUNTY</span><span className ="DetailTableData">{Data?.INMATE_COUNTY}</span></div>
                  </CardContent>
            </Card>
        </div>
    );
}

export default GunRegistrationDetailInfo;