import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";
import { InvestigationDropDown } from "../../../services/njport.service";
import { DropDown } from "../../../interfaces/NJPORT/InvestigationLog.interface";


interface ApplicationByProcessAvgProps {
    HandleRefreshCount: any
    RefreshCount: number
    StartDate: Date,
    EndDate: Date
}

const ApplicationByProcessAvg: React.FC<ApplicationByProcessAvgProps> = ({
    HandleRefreshCount,
    RefreshCount,
    StartDate,
    EndDate
}) => {
    const [data, setData] = useState<any[]>([]);
    const [option, setOption] = useState<any>({});
    const [jobTitle, setjobTitle] = useState<any>({});

    useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_PROCESSAVG_YEARALL",
            dateTypeValue: 1,
            FromDate: StartDate,
            ToDate: EndDate,
        };

        NJPortDashboardDataApi(payload).then((qRes) => {
            setData(qRes?.AnyTable);
            setGraph(qRes?.AnyTable);
        });
    }, [RefreshCount, StartDate, EndDate]);

  
    const jobTitleDropDown = async () => {
        const payload = {
            params: {
                DropDownName: "PORT_JOBCODE",
                State: "NJ",
            },
        };
        let qRes = await InvestigationDropDown(payload);
        return qRes;
    };

    const setGraph = async (maindata: any) => {        
        const xAxisData = await handleFormatXAxis(maindata);   
        await handleSeries(maindata, xAxisData);        
    }

    const handleFormatXAxis = async (maindata: any) => {
        let xAxis: any = []
        maindata.forEach((item: any) => {
            ////if (xAxis?.includes(item?.SubGroup) === false) {
            ////    xAxis.push(item.SubGroup);
            ////}
            if (xAxis?.includes(item?.SubGroup_Desc) === false) {
                xAxis.push(item.SubGroup_Desc);
            }
        });
        return xAxis;
    }

    const handleSeries = async (maindata: any, xAxis: any[]) => {
        let series: any = [];
        let ProcessTime: any = ['Average days between Fingerprint Return & PAC Issued', 'Average days between Application Received & Fingerprint Return'];  
        //console.log('handleSeries xaxis', xAxis);
        ProcessTime?.forEach((xprocessitem: any) => {
            let total: any = [];
            xAxis?.forEach((xjobitem: any) => {
                let bFound = false;
                for (let i = 0; i < maindata?.length; i++) {
                    console.log('Series Data inside loop: ', maindata[i]?.SubGroup_Desc, xjobitem);
                    if (maindata[i]?.SubGroup_Desc === xjobitem) {
                        if (xprocessitem === 'Average days between Fingerprint Return & PAC Issued')
                            total.push(maindata[i]?.AvgPACIssue);
                        else if (xprocessitem === 'Average days between Application Received & Fingerprint Return')
                            total.push(maindata[i]?.AvgFNGPrtReturn);
                        else
                            total.push(0);
                        bFound = true;
                        break;
                    }
                }
                if (bFound === false)
                    total.push(0);
            });
            //console.log('Series Data: ', xprocessitem, total);
            series.push({
                name: xprocessitem,
                type: 'bar',
                stack: 'total',
                //stack: true,
                //color: 'blue',
                label: {
                    show: true,
                    position: "inside",
                },
                emphasis: {
                    focus: 'series'
                },
                data: total
            },)
        });

        setOption({
            legend: {
                selectedMode: false,
                orient: "horizontal",
                bottom: "bottom",
            },
            grid: {
                left: "1%",
                right: "1%",
                bottom: "15%",
                top: "1%",
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: xAxis,
            },
            yAxis: {
                type: 'value'
            },
            series: series,
        });
        console.log('handleSeries 2 success', series);
        return series;
    };

    return (
        <>
            {data && data.length !== 0 ? (
                <div>
                    <ReactECharts option={option} style={{ height: "400px" }} />
                </div>
            ) : (
                <div
                    className={"text-center text-base xl:text-3xl mt-6 mb-1"}
                    style={{ color: "red", fontWeight: 500 }}
                >
                    No Data
                </div>
            )}
        </>
    );
};

export default ApplicationByProcessAvg;