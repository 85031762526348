import React from 'react'
import {
    Tooltip, Alert, AlertColor, Avatar, Button, ButtonGroup, Checkbox, Snackbar,
} from "@mui/material";
import { DCGunReginfo } from "../../../interfaces/getDarInfo_interface";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListRounded from '@mui/icons-material/FilterListRounded';

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';


import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, SortSettingsModel, ExcelExportProperties, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {showCrumbScore, SiteName} from '../../../services/config.service';
import BTPersonGridImage from "../../shared/_PersonGridImage";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import { green } from '@mui/material/colors';
import { JAOS } from '../../../services/JAOS.service';
import NCICIcon from '../../../assets/Images/NCIC.png';
import { isUserPermission } from '../../../services/auth.service';
import {getValue} from "@syncfusion/ej2-base";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";


interface SearchResultsProps {
    Persons: DCGunReginfo[]
    hideJail?: boolean
}

const SearchResults: React.FC<SearchResultsProps> = ({ Persons, hideJail = false }) => {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("error")
    const [ncicList, setNCICList] = React.useState<any[]>([])

    let grid: Grid | null;
    let selectedRows: any[] = [];

    const handleDataTyping = () => {
        if (Persons) {
            let tempP: any = [...Persons]
            if (hideJail) {
                tempP = tempP.filter((e: any) => e.FoundJail !== 2)
            }
            if (grid)
                grid.dataSource = tempP

            //reset NCIClist if they search again
            setNCICList([])
            for (let p of tempP) {
                if (p.dateOfBirth) {
                    p.dateOfBirth = new Date(p.dateOfBirth)
                }
                if (p.RegistrationDate) {
                    p.RegistrationDate = new Date(p.RegistrationDate)
                }
                if (p.Start_Date) {
                    p.Start_Date = new Date(p.Start_Date)
                }
                if (p.End_Date) {
                    p.End_Date = new Date(p.End_Date)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [Persons, hideJail])

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }
    
    // const expandRow = (args: any) => {
    //     //expand if a row is selected except select checkbox
    //     if(args?.name === 'rowSelected' && args?.target.ariaLabel != null || args?.target.alt){
    //         if(grid){
    //             const selectedrowindex= grid.getSelectedRowIndexes()
    //             for (let idx of selectedrowindex) {
    //                 grid.detailRowModule.expand(idx)
    //             }
    //         }
    //     }
    // }

    // const rowDeselect = (args: any) => {
    //     if(args?.name === 'rowDeselected'){
    //         if(grid){
    //             grid.detailRowModule.collapseAll()
    //         }
    //     }
    // }

    const handleNCICSearch = () => {
        if (ncicList.length > 0) {
            let l = new JAOS()
            let hashID = l.objToStack(ncicList);
            window.open('/tools/ncic_multiple_occurrence?person=' + hashID, '_blank')
        }
        else {
            setAlertType("error");
            setAlertText("Please select at least one offender to run NCIC search");
            setAlertOpen(true);
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'firstName', direction: 'Ascending' }
        ] };


    const miniPersonImage = (person: any): any => {
        return (
            <BTPersonGridImage person={person} />
        )
    }
    const gridTemplate = (person: any): any => {
        return (
            <BTPersonIndicatorGrid person={person} />
        );
    }

    const selectTemplate = (person: any): any => {
        return (
            <>
                <Checkbox  onClick={() => { buildNCICList(person) }} />
            </>);
    }

    const buildNCICList = (person: any) =>{
        //Not all records have PersonID so it also checks for TableID
        let NCICListTemp = ncicList
        if (person){
            if(person.PersonID){
                var result = NCICListTemp.findIndex(x => x.PersonID === person?.PersonID)

                if (result === -1) {
                    NCICListTemp.push(person)
                }
                else {
                    NCICListTemp.splice(result, 1)
                }
            }
            else if (person.TableID){
                var result = NCICListTemp.findIndex(x => x.TableID === person?.TableID)

                if (result === -1) {
                    NCICListTemp.push(person)
                }
                else {
                    NCICListTemp.splice(result, 1)
                }
            }
        }
        setNCICList(NCICListTemp)
    }

    const getPersonSummary = (p: any) => {
        return (<BTPersonReport person={p} />)
    }

    // const GridToolsClick = (args: ClickEventArgs) => {

    //     if (grid && args.item.text === "Excel Export") {

    //         let excelProp: ExcelExportProperties = {}
    //         excelProp.fileName = 'Gun_Registration.xlsx';

    //         (grid.getColumnByField("image") as Column).visible = false;
    //         (grid.getColumnByField("FBI") as Column).visible = true;
    //         (grid.getColumnByField("SBINo") as Column).visible = true;
    //         (grid.getColumnByField("FoundALPR") as Column).visible = true;
    //         (grid.getColumnByField("FoundArrest") as Column).visible = true;
    //         (grid.getColumnByField("FoundCADRMS") as Column).visible = true;
    //         (grid.getColumnByField("FoundDomViolence") as Column).visible = true;
    //         (grid.getColumnByField("FoundNIBIN") as Column).visible = true;
    //         (grid.getColumnByField("FoundPhoto") as Column).visible = true;
    //         (grid.getColumnByField("FoundSexCrime") as Column).visible = true;
    //         (grid.getColumnByField("FoundShooting") as Column).visible = true;

    //         if(!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)){
    //             (grid.getColumnByField("FoundCODIS") as Column).visible = true;
    //             (grid.getColumnByField("FoundETicket") as Column).visible = true;
    //             (grid.getColumnByField("FoundGunTrace") as Column).visible = true;
    //             (grid.getColumnByField("FoundHumanTraffic") as Column).visible = true;
    //             (grid.getColumnByField("FoundInfoCop") as Column).visible = true;
    //             (grid.getColumnByField("FoundGunRegistration") as Column).visible = true;
    //             (grid.getColumnByField("FoundNJGunTrace") as Column).visible = true;
    //             (grid.getColumnByField("FoundParkMobile") as Column).visible = true;
    //             (grid.getColumnByField("FoundPrisonParole") as Column).visible = true;
    //             (grid.getColumnByField("FoundPrisonRelease") as Column).visible = true;
    //             (grid.getColumnByField("FoundProfession") as Column).visible = true;
    //             (grid.getColumnByField("FoundProfile") as Column).visible = true;
    //             (grid.getColumnByField("FoundReferral") as Column).visible = true;
    //             (grid.getColumnByField("FoundTowing") as Column).visible = true;
    //             (grid.getColumnByField("FoundViolent") as Column).visible = true;
    //             (grid.getColumnByField("FoundNJSTAD") as Column).visible = true;
    //             (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = true;
    //             (grid.getColumnByField("FoundNJTrace_Asc") as Column).visible = true;
    //             (grid.getColumnByField("FoundETrace_Pur") as Column).visible = true;
    //             (grid.getColumnByField("FoundNJTrace_Ghg") as Column).visible = true;
    //             (grid.getColumnByField("FoundHomeBurglary") as Column).visible = true;
    //             (grid.getColumnByField("FoundHomeInvasion") as Column).visible = true;
    //         }
    //         else if(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL){
    //             (grid.getColumnByField("FoundCarJacking") as Column).visible = true;
    //             (grid.getColumnByField("FoundGunArrests") as Column).visible = true;
    //         }
    //         if (isUserPermission('Indicator_ForeignNational')) {
    //             (grid.getColumnByField("FoundForeignNational") as Column).visible = true;
    //         } 
    //         grid.excelExport(excelProp);

    //     } else if (grid && args.item.text === 'PDF Export') {
    //         grid.pdfExport();
    //     }
    // }

    // const excelExportComplete = () => {
    //     if (grid) {
    //         (grid.getColumnByField("image") as Column).visible = true;
    //         (grid.getColumnByField("FBI") as Column).visible = false;
    //         (grid.getColumnByField("SBINo") as Column).visible = false;
    //         (grid.getColumnByField("firstName") as Column).visible = false;
    //         (grid.getColumnByField("FoundArrest") as Column).visible = false;
    //         (grid.getColumnByField("FoundCADRMS") as Column).visible = false;
    //         (grid.getColumnByField("FoundDomViolence") as Column).visible = false;
    //         (grid.getColumnByField("FoundNIBIN") as Column).visible = false;
    //         (grid.getColumnByField("FoundPhoto") as Column).visible = false;
    //         (grid.getColumnByField("FoundSexCrime") as Column).visible = false;
    //         (grid.getColumnByField("FoundShooting") as Column).visible = false;

    //         if(!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)){
    //             (grid.getColumnByField("FoundCODIS") as Column).visible = false;
    //             (grid.getColumnByField("FoundETicket") as Column).visible = false;
    //             (grid.getColumnByField("FoundGunTrace") as Column).visible = false;
    //             (grid.getColumnByField("FoundHumanTraffic") as Column).visible = false;
    //             (grid.getColumnByField("FoundInfoCop") as Column).visible = false;
    //             (grid.getColumnByField("FoundGunRegistration") as Column).visible = false;
    //             (grid.getColumnByField("FoundNJGunTrace") as Column).visible = false;
    //             (grid.getColumnByField("FoundParkMobile") as Column).visible = false;
    //             (grid.getColumnByField("FoundPrisonParole") as Column).visible = false;
    //             (grid.getColumnByField("FoundPrisonRelease") as Column).visible = false;
    //             (grid.getColumnByField("FoundProfession") as Column).visible = false;
    //             (grid.getColumnByField("FoundProfile") as Column).visible = false;
    //             (grid.getColumnByField("FoundReferral") as Column).visible = false;
    //             (grid.getColumnByField("FoundTowing") as Column).visible = false;
    //             (grid.getColumnByField("FoundViolent") as Column).visible = false;
    //             (grid.getColumnByField("FoundNJSTAD") as Column).visible = false;
    //             (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = false;
    //             (grid.getColumnByField("FoundNJTrace_Asc") as Column).visible = false;
    //             (grid.getColumnByField("FoundETrace_Pur") as Column).visible = false;
    //             (grid.getColumnByField("FoundNJTrace_Ghg") as Column).visible = false;
    //             (grid.getColumnByField("FoundHomeBurglary") as Column).visible = false;
    //             (grid.getColumnByField("FoundHomeInvasion") as Column).visible = false;
    //         }
    //         else if(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL){
    //             (grid.getColumnByField("FoundCarJacking") as Column).visible = false;
    //             (grid.getColumnByField("FoundGunArrests") as Column).visible = false;
    //         }
    //         if (isUserPermission('Indicator_ForeignNational')) {
    //             (grid.getColumnByField("FoundForeignNational") as Column).visible = false;
    //         } 
    //     }
    // }
    

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length > 0 ? <>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }}>
                    <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                        {alertText}
                    </Alert>
                </Snackbar>
                <span style={{ display: "inline-block" }} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Offender(s) Found</h4>
                    {isUserPermission('NCIC') ?
                        <ButtonGroup size="medium" color="inherit" className='mb-2 mr-2'>
                            <Tooltip title={`Search ${GetStringNameForSite(SiteName,'NCIC')} Warrant Details for Select Offender(s)`} placement="top" arrow>
                                <Button variant="outlined" onClick={handleNCICSearch}>
                                    <img src={NCICIcon} className={"mr-2"} alt={""} />{GetStringNameForSite(SiteName,'NCIC')}  Search
                                </Button>
                            </Tooltip>
                        </ButtonGroup> : <></>
                    }
                </span>
                <GridComponent
                    dataSource={typedPersons}
                    dataBound={dataBound }
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    // detailTemplate={getPersonSummary}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport', 'Search']}
                    // toolbarClick={GridToolsClick}
                    // excelExportComplete={excelExportComplete}
                    ref={g => grid = g}
                    selectionSettings={{
                        //persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"
                    }}
                    //rowSelecting={(event: any) => { }}
                    // rowSelected={(event: any) => { expandRow(event) }}
                    // rowDeselected={(event: any) => { rowDeselect(event) }}
                    // allowSelection={true}
                    allowResizing={true}
                    // rowHeight={24}
                    // rowDataBound={(args : any) => {
                    //     if (args.row) {
                    //         if (getValue('FoundGunRegistration', args.data)  === 2){
                    //             args.row.classList.add('red');
                    //         }
                    //     }
                    // }}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='PersonID' width={140} minWidth={140} maxWidth={140} headerText='Person ID' visible={false} isPrimaryKey={true} />
                        {/* {isUserPermission('NCIC') ? <ColumnDirective type='checkbox' width='50' customAttributes={{ class: ['e-checkbox-custom'] }} /> : <></>} */}
                        {/* { isUserPermission('NCIC') ? <ColumnDirective template={selectTemplate} headerText='Select' width={100} /> : <></>} */}
                        <ColumnDirective field='image' template={miniPersonImage} maxWidth={120} headerText='Image' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='firstName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='middleName' headerText='MI' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='lastName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='dateOfBirth' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FBI' width={110} minWidth={110} maxWidth={110} headerText='FBI' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SBINo' width={110} minWidth={110} maxWidth={110} headerText={`${GetStringNameForSite(SiteName,'SBI #')}`} customAttributes={{ class: ['e-attr'] }} visible={false} />
                        
                        <ColumnDirective field='Start_Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Commitment Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='End_Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Release Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>

                        {/* { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText={`${GetStringNameForSite(SiteName,'SCOR_TBL')}`} customAttributes={{ class: ['e-attr'] }}/>:<></>} */}
                        <ColumnDirective field='PersonCity' headerText={`${GetStringNameForSite(SiteName,'CITY')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PersonState' headerText='State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        {/* <ColumnDirective template={gridTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/> */}
                        <ColumnDirective field='RegistrationDate' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Commitment Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='RegistrationExpirationDate' headerText='Registration Expiration' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Serial#' headerText='Serial Number' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Make' headerText='Make' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='RegistrationHouseNo' headerText='RegistrationHouseNo' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='RegistrationStreet_Name' headerText='Registration Street Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='RegistrationCity' headerText='Registration City' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='RegistrationState' headerText='RegistrationState' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>                       
                        <ColumnDirective field='RegistrationZipCode' headerText='Registration Zip Code' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>                        
                        <ColumnDirective field='RegistrationQuad' headerText='Registration Quads' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='RegistrationDistrict' headerText='RegistrationDistrict' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='RegistrationPSA' headerText='Registration PSA' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='CWPIssuedDate' headerText='CWP Issued Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='CWPRenewalDate' headerText='CWP Renewal Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='CWPExpirationDate' headerText='CWP Expiration Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='CWPApplicationStatus' headerText='CWP Application Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PersonHouseNo' headerText='Person House No' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PersonStreet_Name' headerText='Person Street Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PersonCity' headerText='Person City' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PersonState' headerText='Person State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PersonZipcode' headerText='Person Zip code' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PersonDistrict' headerText='Person District' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PersonPSA' headerText='Person PSA' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search]} />
                </GridComponent>

            </> : <></>}
        </div>
    );
};

export default SearchResults;