import React from "react";
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import noFile from '../../assets/Images/noFile.png';

interface FileData {
    ID?: number;
    FileName: string;
    Content: string;
    FileEncoding: string;
    Size: string;
    deleted?: boolean;
}

interface FileHandlerProps {
    fileListProps: any[];
    onFileRemoved: (file: FileData) => void;
}

const FileHandler: React.FC<FileHandlerProps> = ({ fileListProps, onFileRemoved }) => {
    const noFileImage = noFile; 

    const removeFile = (file: FileData) => {
        if (window.confirm(`Remove this file permanently? ${file.FileName}`)) {
            onFileRemoved(file);
        }
    };

    const downloadFile = (file: FileData) => {
        if (!file.Content || !file.FileEncoding) return;

        // Decode base64 properly
        const byteCharacters = atob(file.Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const fileBlob = new Blob([byteArray], { type: file.FileEncoding });

        // Create download link
        const fileURL = URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = file.FileName;
        document.body.appendChild(link); 
        link.click();
        document.body.removeChild(link); 

        URL.revokeObjectURL(fileURL);
    };

    return (
        <div className="main">
            {fileListProps?.filter((file: any) => !file.deleted)
                .map((file: any, idx: any) => (
                    <div key={`file${idx}`}>
                        <div className="max-h-20 min-h-20 h-1/2 border rounded shadow">
                            <div className="flex">
                                {/* Image */}
                                <img
                                    className="w-16 h-20 object-contain"
                                    src={
                                        file.Content &&
                                            file.Content.length > 0 &&
                                            file.FileEncoding?.includes("image")
                                            ? `data:image/jpeg;base64,${file.Content}`
                                            : noFileImage
                                    }
                                    alt="File Thumbnail"
                                />

                                {/* File Info */}
                                <div className="flex-grow flex flex-col justify-between p-2">
                                    <div className="flex justify-between items-center">
                                        <span className="font-bold text-lg">{file.FileName}</span>
                                        <span>
                                            {/* Download Button */}
                                            <Tooltip title="Download File">
                                                <IconButton
                                                    color={"info"}
                                                    className="mr-2"
                                                    onClick={() => downloadFile(file)}
                                                    title="Download File"
                                                >
                                                    <FileDownloadIcon />
                                                </IconButton>
                                            </Tooltip>

                                            {/* Remove Button */}
                                            <Tooltip title="Remove File">
                                                <IconButton
                                                    color={"error"}
                                                    onClick={() => removeFile(file)}
                                                    title="Remove File"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </span>
                                    </div>

                                    <div className="flex justify-between">
                                        <span className="font-bold">{file.Size}</span>
                                        {file.ID ? (
                                            <span className="text-green-600">Saved!</span>
                                        ) : (
                                            <span className="text-yellow-600">Pending...</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default FileHandler;
