import React from 'react';
import { Container, Box, Button, ButtonGroup, TextField, Grid, Typography, Autocomplete, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, AutocompleteRenderInputParams, IconButton, Tooltip } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {
	AffiliateObject,
	CarrierObject,
	eCRAWL
} from "../../../interfaces/eCRAWL.interface";
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { getATFStreetSuffix, getEcrawlStateList, getEcrawlCarrierList, getEcrawlCountryList } from '../../../services/getDropdown.service';
import './ecrawl-styles.css';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Today } from '@mui/icons-material';
import { ToISOLocal } from '../../../services/formatDate.service';
import { GetCarrierInfo } from '../../../services/ecrawl.service';
import ClearIcon from '@mui/icons-material/Clear';
import { auto } from '@popperjs/core';

interface ECrawlProps {
	handleReportSubmit: any,
	editForm: any,
	ecrawl?: any
}

const ECrawlInput: React.FC<ECrawlProps> = ({ handleReportSubmit, editForm = null, ecrawl = null }) => {
	var today = new Date();
	var todayDate = today?.toISOString()?.split('T')[0];
	const [CountryList, setCountryList] = React.useState<any>([])
	const [stateList, setStateList] = React.useState<any>([]);
	const [checkEdit, setCheckEdit] = React.useState<boolean>(false)
	const [carrierList, setCarrierList]= React.useState<any>([])

	const [report, setReport] = React.useState<eCRAWL>({
		CarrierDataList: [{ Origination: 'Carrier', Address_Country:'US', AddressStateList:stateList , AffiliateList:[] }],
		DriverDataList: [{ Origination: 'Driver' , DL_Country:'US' , StateList:stateList, Address_Country:'US', AddressStateList:stateList}],
		PassengerList: [{ Origination: 'Passenger', DL_Country:'US', StateList:stateList, Address_Country:'US', AddressStateList:stateList}],
		TractorList: [{ Origination: 'Tractor', Plate_Country:'US', PlateStateList:stateList }],
		TrailerList: [{ Origination: 'Trailer', Plate_Country:'US', PlateStateList:stateList, Origin_Country:'US', OriginStateList:stateList, Destination_Country:'US', DestinationStateList:stateList}],
		ContactList: [{ Origination: 'Contact' }]
	});

	const [streetSuffixList, setStreetSuffixList] = React.useState<any>([]);

	const [saveOpen, setSaveOpen] = React.useState(false);
	const [clearOpen, setClearOpen] = React.useState(false);
	const [contentDialog, setContentDialog] = React.useState<string>("Are you sure you would like to submit a new record with this data?")   

	const handleClear = () => {
		setReport({
			CarrierDataList: [{ Origination: 'Carrier', Address_Country:'US', AddressStateList:stateList, AffiliateList:[]  }],
			DriverDataList: [{ Origination: 'Driver', DL_Country:'US', StateList:stateList , Address_Country:'US', AddressStateList:stateList}],
			PassengerList: [{ Origination: 'Passenger', DL_Country:'US', StateList:stateList , Address_Country:'US', AddressStateList:stateList}],
			TractorList: [{ Origination: 'Tractor', Plate_Country:'US', PlateStateList:stateList }],
			TrailerList: [{ Origination: 'Trailer', Plate_Country:'US', PlateStateList:stateList, Origin_Country:'US', OriginStateList:stateList, Destination_Country:'US', DestinationStateList:stateList}],
			ContactList: [{ Origination: 'Contact' }]
		})
	}

	const handleSubmit = () => {
		setSaveOpen(false)
		handleReportSubmit(report)
	}

	const deleteTableRow = (table: string, item: any) => {
		var temp: any = { ...report }
		if (table?.length > 0 && item) {
			if (temp[table]) {
				let index = temp[table]?.findIndex((f: any) => f === item)
				if ((index as any) >= 0) {
					if (temp[table][index]?.ID && (temp[table][index].ID as any) > 0) {
						temp[table][index].Deleted = true
					} else {
						temp[table]?.splice(index, 1)
					}
					setReport(temp)
				}
			}
		}
	};

	const fillCarrierData = async (dotNumber: string, index: number) => {
		let temp: any = { ...report }
		if(dotNumber && dotNumber.length > 0){
			let carrier = await GetCarrierInfo(dotNumber)
			temp.CarrierDataList[index].Carrier_Name = carrier.Carrier_Name;
			temp.CarrierDataList[index].Address_Street = carrier.Address_Street;
			temp.CarrierDataList[index].Address_City = carrier.Address_City;
			temp.CarrierDataList[index].Address_State = carrier.Address_State;
			temp.CarrierDataList[index].Address_ZIP = carrier.Address_ZIP;
			temp.CarrierDataList[index].Address_Country = carrier.Address_Country;
			temp.CarrierDataList[index].Carrier_Phone = carrier.Carrier_Phone;
			if(carrier.AffiliateList && carrier.AffiliateList.length > 0) {
				temp.CarrierDataList[index].AffiliateList = carrier.AffiliateList ?? []
			}
			setReport(temp)
		}		
	}

	const addTableRow = (table: string, field: string = "", value: string = "") => {
		var temp: any = { ...report }
		if (!Array.isArray(temp[table])) {
			temp[table] = [];
		}

		if (field && value) {
			let newRow = { [field]: value };
			newRow = buildRow(newRow)
			temp[table].push(newRow);
		} else {
			temp[table].push({});

		}
		setReport(temp)
	};

	const buildRow = (row:any) => {
		let tempRow=row
		switch (row['Origination']) {
            //handling dropdowns when new one is added
            case 'Carrier':
                tempRow = {...row, Address_Country:'US',  AddressStateList:stateList, AffiliateList:[]}
                break;
			case 'Passenger': 
				tempRow = {...row,DL_Country:'US', StateList:stateList, Address_Country:'US', AddressStateList:stateList }
				break;
			case 'Tractor':
				tempRow = {...row , Plate_Country:'US', PlateStateList:stateList }
				break;
			case 'Trailer':
				tempRow = {...row , Plate_Country:'US', PlateStateList:stateList, Origin_Country:'US', OriginStateList:stateList, Destination_Country:'US', DestinationStateList:stateList }
				break;
		}
		return tempRow
	}

	const addAffiliate = (carrierIndex:number, table: string, field: string = "", value: string = "" ) => {
		let temp = {...report}
		if(temp?.CarrierDataList && temp?.CarrierDataList.length > 0){
			let tempCarrierList = {...temp?.CarrierDataList}
			if(tempCarrierList[carrierIndex]){
				if (!Array.isArray(tempCarrierList[carrierIndex].AffiliateList)) {
					tempCarrierList[carrierIndex].AffiliateList = [];
				}
				
				if (field && value) {
					let newRow = { [field]: value };
					tempCarrierList[carrierIndex].AffiliateList.push(newRow);
				} else {
					tempCarrierList[carrierIndex].AffiliateList.push({});

				}

			}
		}
		setReport(temp)
	}

	const deleteAffiliates = (carrierIndex:number, table: string, item: any , deleteAll: boolean = false) =>{
		let temp = {...report}
		if(temp?.CarrierDataList && temp?.CarrierDataList.length > 0){
			let tempCarrierList = {...temp?.CarrierDataList}
			if(tempCarrierList[carrierIndex]){
				if (tempCarrierList[carrierIndex].AffiliateList) {
					let index = tempCarrierList[carrierIndex].AffiliateList?.findIndex((f: any) => f === item)
					if ((index as any) >= 0) {
						if (tempCarrierList[carrierIndex].AffiliateList[index]?.ID && (tempCarrierList[carrierIndex].AffiliateList[index].ID as any) > 0) {
							tempCarrierList[carrierIndex].AffiliateList[index].Deleted = true
						} else {
							tempCarrierList[carrierIndex].AffiliateList?.splice(index, 1)
						}
						
					}
				}
			}			
		}
		setReport(temp)		
	}

	const handleClearForm = () => {
		handleClear()
		setClearOpen(false)
	}

	const handleSaveOpen = () => {
		if(report?.ID && report?.ID > 0){
			setContentDialog("Are you sure you would like to save the record with this data?")
		}
		setSaveOpen(true);
	};

	const handleSaveClose = () => {
		setSaveOpen(false);
	};

	const handleClearOpen = () => {
		setClearOpen(true);
	};

	const handleClearClose = () => {
		setClearOpen(false);
	};

	const handleGetDDLabel = (list: any[], option: any, listType: number) => {
		if (!option || option === '' || option === undefined)
			return ''
		if (listType && list && list.length > 0) {
			switch (listType) {
				case 1: //ID, Description
					if (option && option.hasOwnProperty('Description')) {
						return option.Description
					}
					else {
						let x = list.find((e: any) => { return (e.ID === option) })
						if (x && x.hasOwnProperty('Description')) {
							return x.Description
						}
						else {
							return option
						}
					}
					break
				case 2:
					if (option && option.hasOwnProperty('Value')) {
						return option.Value
					}
					else {
						let x = list.find((e: any) => e.Key === option)
						if (x && x.hasOwnProperty('Value')) {
							return x.Value
						}
						else {
							return option
						}
					}
					break
				case 3: //ID, Description
					if (option && option.hasOwnProperty('Description')) {
						let str = option.Description
						if (option.hasOwnProperty('Country')) {
							str += " (" + option.Country + ")"
						}
						return str
					}
					else {
						let x = list.find((e: any) => { return (e.ID === option) })
						if (x && x.hasOwnProperty('Description')) {
							let str = x.Description
							if (x.hasOwnProperty('Country')) {
								str += " (" + x.Country + ")"
							}
							return str
						}
						else {
							return option
						}
					}
					break
			}
		}
		return option
	}

	const handleInputChange = async (e: { target: { name: string; value: any; }; }, table: string = "", item: any = null , parentIndx: number=0, affilateIndx:number=0) => {
		const { name, value } = e.target;
		let temp: any = { ...report }
		if (table?.length > 0 && item) {
			//handle nested table  CarrierDataList ->  AffiliateList
			if(table === "AffiliateList"){
				if(temp['CarrierDataList'][parentIndx][table] &&  temp['CarrierDataList'][parentIndx][table][affilateIndx]){
					if(name === 'Affiliate_Name'){
						temp['CarrierDataList'][parentIndx][table][affilateIndx][name]= value?.Value ? value?.Value : value
						if(value?.Key) //assign only if dd is used
							temp['CarrierDataList'][parentIndx][table][affilateIndx]['Affiliate_DOT_Num']= value?.Key
					}
					else{
						temp['CarrierDataList'][parentIndx][table][affilateIndx][name]= value?.ID ? value?.ID : value
					}
				}
			}
			let index = temp[table]?.findIndex((f: any) => f === item)
			if (index >= 0) {
				temp[table][index][name] = (value instanceof Date ? ToISOLocal(value) : value?.ID ? value?.ID : value)
				//handle state dropdown based on selected country
				if(name === 'DL_Country'){
					temp[table][index]['DL_State']='' 
					temp[table][index]['StateList']= await getEcrawlStateList(value?.ID ?? 'US') 
				}
				else if (name === 'Address_Country'){
					temp[table][index]['Address_State']='' 
					temp[table][index]['AddressStateList']= await getEcrawlStateList(value?.ID ?? 'US') 
				}
				else if (name === 'Plate_Country'){
					temp[table][index]['Plate_State']='' 
					temp[table][index]['PlateStateList']= await getEcrawlStateList(value?.ID ?? 'US') 
				}
				else if (name === 'Origin_Country'){
					temp[table][index]['Origin_State']='' 
					temp[table][index]['OriginStateList']= await getEcrawlStateList(value?.ID ?? 'US') 
				}
				else if (name === 'Destination_Country'){
					temp[table][index]['Destination_State']='' 
					temp[table][index]['DestinationStateList']= await getEcrawlStateList(value?.ID ?? 'US') 
				}
			}
		}
		else {
			temp[name] = (value instanceof Date ? ToISOLocal(value) : value?.ID ? value?.ID : value)
		}
		setReport(temp)
	};

	const handleDropdowns = async () => {
		let temp={...report}
		setCountryList([])
		setStateList([]);
		setCarrierList([])

		var country = await getEcrawlCountryList();
		var state = await getEcrawlStateList('US')
		var carrier = await getEcrawlCarrierList();

		if (country && country?.length > 0) {
            setCountryList(country)
        }
		if (state && state?.length > 0) {
			setStateList(state);
		}
		if(carrier && carrier?.length > 0){
			setCarrierList(carrier)
		}

	}

	const handleInitialBuild =  () => {
		handleDropdowns()		
	}

	const handleEditData = async () => {
		if (editForm) {
			//EDITING FORM
			//for all sections initialize all lists /nested lists if not set 	
			//for all sections set default US state list if country is US else call getEcrawlStateList with foreign country 	
			let temp = { ...editForm }	
			if (!temp.CarrierDataList) {
				temp.CarrierDataList = [{Origination: 'Carrier', Address_Country:'US', AddressStateList:stateList , AffiliateList:[]}]
			}
			else{
				let item = [...temp.CarrierDataList]
				for(let carrier of item){
					if(!carrier.AffiliateList){
						carrier.AffiliateList = []
					}
					
					carrier.AddressStateList= (carrier?.Address_Country && carrier?.Address_Country !== 'US') ? await getEcrawlStateList(carrier?.Address_Country) : stateList 
					
				}
			}	
			if (!temp.DriverDataList) {
				temp.DriverDataList = [{Origination: 'Driver' , DL_Country:'US' , StateList:stateList, Address_Country:'US', AddressStateList:stateList}]
			}
			else {
				let items = [...temp.DriverDataList]
				for (let driver of items) {
					if (driver?.DOB && driver?.DOB.length > 0) {
						let newDOB = new Date(driver.DOB)
						driver.DOB = (newDOB.getFullYear() + '-' + ((newDOB.getMonth() + 1).toString()).padStart(2, '0') + '-' + newDOB.getDate().toString().padStart(2, '0')).toString()
					}
					driver.StateList = (driver?.DL_Country && driver?.DL_Country !== 'US') ? await getEcrawlStateList(driver?.DL_Country) : stateList
					driver.AddressStateList = (driver?.Address_Country && driver?.Address_Country !== 'US') ? await getEcrawlStateList(driver?.Address_Country ?? 'US') : stateList
					
				}
			}
			if (!temp.PassengerList) {
				temp.PassengerList = [{ Origination: 'Passenger', DL_Country:'US', StateList:stateList , Address_Country:'US', AddressStateList:stateList}]
			}
			else {
				let items = [...temp.PassengerList]
				for (let passenger of items) {
					if (passenger?.DOB && passenger?.DOB.length > 0) {
						let newDOB = new Date(passenger.DOB)
						passenger.DOB = (newDOB.getFullYear() + '-' + ((newDOB.getMonth() + 1).toString()).padStart(2, '0') + '-' + newDOB.getDate().toString().padStart(2, '0')).toString()
					}
					passenger.StateList = (passenger?.DL_Country && passenger?.DL_Country !== 'US') ? await getEcrawlStateList(passenger?.DL_Country ?? 'US') : stateList
					passenger.AddressStateList = (passenger?.Address_Country && passenger?.Address_Country !== 'US') ? await getEcrawlStateList(passenger?.Address_Country) : stateList
				}
			}
			if (!temp.TractorList) {
				temp.TractorList = [{Origination: 'Tractor', Plate_Country:'US', PlateStateList:stateList}]
			}
			else{
				let items = [...temp.TractorList]
				for(let tractor of items){
					tractor.PlateStateList = (tractor?.Plate_Country && tractor?.Plate_Country !== 'US') ? await getEcrawlStateList(tractor?.Plate_Country ?? 'US') : stateList
				}
			}
			if (!temp.TrailerList) {
				temp.TrailerList = [{ Origination: 'Trailer', Plate_Country:'US', PlateStateList:stateList, Origin_Country:'US', OriginStateList:stateList, Destination_Country:'US', DestinationStateList:stateList}]
			}
			else{
				let items = [...temp.TrailerList]
				for(let trailer of items){
					trailer.PlateStateList = (trailer?.Plate_Country && trailer?.Plate_Country !== 'US') ? await getEcrawlStateList(trailer?.Plate_Country ?? 'US') : stateList
					trailer.OriginStateList = (trailer?.Origin_Country && trailer?.Origin_Country !== 'US') ? await getEcrawlStateList(trailer?.Origin_Country ?? 'US') : stateList
					trailer.DestinationStateList = (trailer?.Destination_Country && trailer?.Destination_Country !== 'US') ? await getEcrawlStateList(trailer?.Destination_Country) : stateList
				}
			}
			if (!temp.ContactList) {
				temp.ContactList = [{Origination: 'Contact'}]
			}
			else {
				let items = [...temp.ContactList]
				for (let contact of items) {
					if (contact?.Date && contact?.Date.length > 0) {
						let newDOB = new Date(contact.Date)
						contact.Date = (newDOB.getFullYear() + '-' + ((newDOB.getMonth() + 1).toString()).padStart(2, '0') + '-' + newDOB.getDate().toString().padStart(2, '0')).toString()
					}
				}
			}
			setReport(temp)
		}	
		else{
			//FIRST TIME ENTERING FORM HANDLE DROPDOWN LISTS
			let temp={...report}
			//set default state list to US states
			if(temp?.CarrierDataList && temp?.CarrierDataList.length > 0){
				for(let carrier of temp?.CarrierDataList){
					if(carrier?.AddressStateList && carrier.AddressStateList.length === 0){
						carrier.AddressStateList = stateList
					}
				}
			}
			if(temp?.DriverDataList && temp?.DriverDataList.length > 0){
				for(let driver of temp?.DriverDataList){
					if(driver?.StateList && driver.StateList.length === 0){
						driver.StateList = stateList
					}
					if(driver?.AddressStateList && driver?.AddressStateList.length ===0){
						driver.AddressStateList = stateList
					}
				}
			}
			if(temp?.PassengerList && temp?.PassengerList.length > 0){
				for(let passenger of temp?.PassengerList){
					if(passenger?.StateList && passenger.StateList.length === 0){
						passenger.StateList = stateList
					}
					if(passenger?.AddressStateList && passenger?.AddressStateList.length === 0){
						passenger.AddressStateList = stateList
					}
				}
			}
			if(temp?.TractorList && temp?.TractorList.length > 0){
				for(let tractor  of temp?.TractorList){
					if(tractor?.PlateStateList && tractor?.PlateStateList.length === 0){
						tractor.PlateStateList = stateList
					}
				}
			}
			if(temp?.TrailerList && temp?.TrailerList.length > 0){
				for(let tractor  of temp?.TrailerList){
					if(tractor?.PlateStateList && tractor?.PlateStateList.length === 0){
						tractor.PlateStateList = stateList
					}
					if(tractor?.OriginStateList && tractor?.OriginStateList.length === 0){
						tractor.OriginStateList = stateList
					}
					if(tractor?.DestinationStateList && tractor?.DestinationStateList.length === 0){
						tractor.DestinationStateList = stateList
					}
				}
			}			
			setReport(temp)
		}
	}

	React.useEffect(() => {
		handleInitialBuild()
	}, [ecrawl])

	React.useEffect(() => {
		if (!checkEdit) {
			if (CountryList?.length > 0 && stateList?.length > 0) {
				setCheckEdit(true)
				handleEditData()
			}
		}

	}, [ CountryList,stateList])

	return (
		<Container>
			<div className="">
				{report?.CarrierDataList?.filter((f: any) => !f.Deleted).map((carrier: any, indx) => {
					return (
						<Box className="section" sx={{borderRadius: 1} }>
							<Box className="header">
								<Typography variant="h5">
									Carrier Data {indx > 0 ? indx + 1 : ""}
									{indx !== 0 && (
										<>
											<IconButton size={"small"} onClick={() => deleteTableRow("CarrierDataList", carrier)}
												color={"error"} title={"Remove Carrier"}><DeleteIcon /></IconButton>
										</>
									)}
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="USDOT #" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="DOT_Num"
											value={carrier?.DOT_Num || ''}
											onChange={(e) => handleInputChange(e, "CarrierDataList", carrier)}
											error={!carrier?.DOT_Num || carrier?.DOT_Num?.length === 0}
											onBlur={async (e) => {
												if (!editForm)
													fillCarrierData(e.target.value, indx);
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Carrier Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Carrier_Name"
											value={carrier?.Carrier_Name || ''}
											onChange={(e) => handleInputChange(e, "CarrierDataList", carrier)}
											error={!carrier?.Carrier_Name || carrier?.Carrier_Name?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Carrier Phone" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Carrier_Phone"
											value={carrier?.Carrier_Phone || ''}
											onChange={(e) => handleInputChange(e, "CarrierDataList", carrier)}
											error={!carrier?.Carrier_Phone || carrier?.Carrier_Phone?.length === 0}
										/>
									</Grid>
									{/* <Grid item xs={6}>
										<TextField fullWidth label="Affiliates" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Affiliates"
											value={carrier?.Affiliates || ''}
											onChange={(e) => handleInputChange(e, "CarrierDataList", carrier)}
											error={!carrier?.Affiliates || carrier?.Affiliates?.length === 0}
										/>
									</Grid> */}
									<Grid item xs={6}>
										<TextField fullWidth label="Street" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Address_Street"
											value={carrier?.Address_Street || ''}
											onChange={(e) => handleInputChange(e, "CarrierDataList", carrier)}
											error={!carrier?.Address_Street || carrier?.Address_Street === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="City" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={carrier?.Address_City || ''}
											name="Address_City"
											onChange={(e) => handleInputChange(e, "CarrierDataList", carrier)}
											error={!carrier?.Address_City || carrier?.Address_City?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="DL State"
											disableClearable
											size={"small"}
											options={carrier?.AddressStateList}
											getOptionLabel={(option: any) => handleGetDDLabel(carrier?.AddressStateList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Address_State", value: newValue as string } }, "CarrierDataList", carrier)}
											value={carrier?.Address_State ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!carrier?.Address_State || carrier?.Address_State?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="ZIP" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											value={carrier?.Address_ZIP}
											name="Address_ZIP"
											onChange={(e) => handleInputChange(e, "CarrierDataList", carrier)}
											error={!carrier?.Address_ZIP || carrier?.Address_ZIP?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Address Country"
											disableClearable
											size={"small"}
											options={CountryList}
											getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Address_Country", value: newValue as string } }, "CarrierDataList", carrier)}
											value={carrier?.Address_Country ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Country"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!carrier?.Address_Country || carrier?.Address_Country?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="h6">Affiliate Information</Typography>
									</Grid>	
									<Grid item xs={7}>
										<div className="col-span-1 pr-10">
											<ButtonGroup>
												<Button
													color="inherit"
													variant="contained"
													startIcon={<AddIcon />}
													onClick={() => addAffiliate(indx,"AffiliateList", "Origination", "CarrierAffiliate")}
												>
													Add Affiliate
												</Button>												
											</ButtonGroup>
										</div>
									</Grid>
									<Grid item xs={12}>										
										<div className="">
											{carrier?.AffiliateList.filter((f:any)=> !f.Deleted && f.Origination === 'CarrierAffiliate').map((affiliate:any, affilateIndx:number) => {
													return (
													
														<Box className="section-content">
															
															<Grid container spacing={1}>
																
																<Grid item xs={5}>
																<TextField fullWidth label="Affiliate USDOT #" size="small"
																	inputProps={{ style: { textAlign: 'center' } }}
																	InputLabelProps={{ shrink: true }}
																	name="Affiliate_DOT_Num"
																	value={affiliate?.Affiliate_DOT_Num}
																	onChange={(e) => handleInputChange(e, "AffiliateList", affiliate, indx , affilateIndx)}
																	error={!affiliate?.Affiliate_DOT_Num || affiliate?.Affiliate_DOT_Num?.length === 0}
																/>
																</Grid>
																<Grid item xs={5}>													
																<Autocomplete
																	//freeSolo
																	popupIcon=""
																	id="Affiliate_Name"
																	//disableClearable
																	size={"small"}
																	options={carrierList}
																	getOptionLabel={(option: any) => handleGetDDLabel(carrierList, option, 2)}
																	onChange={(event, newValue: any) =>handleInputChange({ target: { name: "Affiliate_Name", value: newValue as string } }, "AffiliateList", affiliate,indx , affilateIndx)}
																	onInputChange={(e:any) => e ? handleInputChange({target:{name:"Affiliate_Name" , value: e?.target?.value}},'AffiliateList', affiliate , indx, affilateIndx) : () => {} } //allow user's input 

																	renderInput={(p) => (
																		<TextField
																			{...p}
																			label="Affiliate Carrier Name"
																			InputProps={{
																				...p.InputProps,
																				type: 'input',
																				style: {textAlign: 'center'},
																			}}
																			error={!affiliate?.Affiliate_Name || affiliate?.Affiliate_Name?.length === 0}
																		/>
																	)}
																	value={affiliate?.Affiliate_Name ? affiliate?.Affiliate_Name : ''}
																/>
																</Grid>
																<Grid item xs={1}>
																	<div className="col-span-2 flex items-center justify-center">
																	<Tooltip title="Delete Affiliate">
																		<IconButton
																			aria-label="delete"
																			onClick={() => deleteAffiliates(indx,"AffiliateList",affiliate, false)}
																		>
																			<DeleteIcon sx={{ color: "red" }} />
																		</IconButton>
																	</Tooltip>
																</div>
																</Grid>
															</Grid>																	
														</Box>																																	
													)
											})}										
										</div>
									</Grid>																	
								</Grid>
							</Box>
							<div className="mx-4 mb-2">
								<IconButton size={"large"} onClick={() => addTableRow("CarrierDataList", "Origination", "Carrier")} color={"inherit"}
									title={"Add Additional Carrier"}> <AddIcon />
								</IconButton>
								To add an additional carrier, click here
							</div>
						</Box>
					)
				})}
			</div>

			<div className="">
				{report?.DriverDataList?.filter((f: any) => !f.Deleted).map((driver: any, indx) => {
					return (
						<Box className="section" sx={{borderRadius: 1} }>
							<Box className="header">
								<Typography variant="h5">
									Driver Data {indx > 0 ? indx + 1 : ""} 
									{indx !== 0 && (
										<>
											<IconButton size={"small"} onClick={() => deleteTableRow("DriverDataList", driver)}
												color={"error"} title={"Remove Driver"}><DeleteIcon /></IconButton>
										</>
									)}
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="First Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="First_Name"
											value={driver?.First_Name}
											onChange={(e) => handleInputChange(e, "DriverDataList", driver)}
											error={!driver?.First_Name || driver?.First_Name?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Middle Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Middle_Name"
											value={driver?.Middle_Name}
											onChange={(e) => handleInputChange(e, "DriverDataList", driver)}
											error={!driver?.Middle_Name || driver?.Middle_Name?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Last Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Last_Name"
											value={driver?.Last_Name}
											onChange={(e) => handleInputChange(e, "DriverDataList", driver)}
											error={!driver?.Last_Name || driver?.Last_Name?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField variant="outlined" margin='none' type={"date"} fullWidth
											size={"small"} label="Date of Birth"
											name="DOB"
											InputLabelProps={{ shrink: true }}
											inputProps={{ style: { textAlign: 'center' }, max: todayDate }}
											value={driver?.DOB}
											onChange={(e) => handleInputChange(e, "DriverDataList", driver)}
											error={!driver?.DOB || driver?.DOB?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="CDL #" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="DL_Number"
											value={driver?.DL_Number || ''}
											onChange={(e) => handleInputChange(e, "DriverDataList", driver)}
											error={!driver?.DL_Number || driver?.DL_Number?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="DL Country"
											disableClearable
											size={"small"}
											options={CountryList}
											getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "DL_Country", value: newValue as string } }, "DriverDataList", driver)}
											value={driver?.DL_Country ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="CDL Country"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!driver?.DL_Country || driver?.DL_Country?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="DL State"
											disableClearable
											size={"small"}
											options={driver?.StateList}
											getOptionLabel={(option: any) => handleGetDDLabel(driver?.StateList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "DL_State", value: newValue as string } }, "DriverDataList", driver)}
											value={driver?.DL_State ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="CDL State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!driver?.DL_State || driver?.DL_State?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="h6">Driver Address Information</Typography>
									</Grid>									
									<Grid item xs={6}>
										<TextField fullWidth label="Street" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Address_Street"
											value={driver?.Address_Street || ''}
											onChange={(e) => handleInputChange(e, "DriverDataList", driver)}
											error={!driver?.Address_Street || driver?.Address_Street === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="City" size="small"
											name="Address_City"
											inputProps={{ style: { textAlign: 'center' } }}
											value={driver?.Address_City || ''}
											onChange={(e) => handleInputChange(e, "DriverDataList", driver)}
											error={!driver?.Address_City || driver?.Address_City?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Driver State"
											disableClearable
											size={"small"}
											options={driver?.AddressStateList}
											getOptionLabel={(option: any) => handleGetDDLabel(driver?.AddressStateList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Address_State", value: newValue as string } }, "DriverDataList", driver)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!driver?.Address_State || driver?.Address_State?.length === 0}
												/>
											)}
											value={driver?.Address_State || ''} />
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="ZIP" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Address_ZIP"
											value={driver?.Address_ZIP || ''}
											onChange={(e) => handleInputChange(e, "DriverDataList", driver)}
											error={!driver?.Address_ZIP || driver?.Address_ZIP?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Address Country"
											disableClearable
											size={"small"}
											options={CountryList}
											getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Address_Country", value: newValue as string } }, "DriverDataList", driver)}
											value={driver?.Address_Country ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Country"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!driver?.Address_Country || driver?.Address_Country?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Phone" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Phone_Num"
											value={driver?.Phone_Num || ''}
											onChange={(e) => handleInputChange(e, "DriverDataList", driver)}
											error={!driver?.Phone_Num || driver?.Phone_Num?.length === 0}
										/>
									</Grid>
								</Grid>
							</Box>
							{/* <div className="mx-4 mb-2">
								<IconButton size={"large"} onClick={() => addTableRow("DriverDataList", "Origination", "Driver")} color={"inherit"}
									title={"Add Additional Driver"}> <AddIcon />
								</IconButton>
								To add an additional driver, click here
							</div> */}
						</Box>
					)

				})}
			</div>

			<div className="">
				{report?.PassengerList?.filter((f: any) => !f.Deleted).map((passenger: any, indx) => {
					return (
						<Box className="section" sx={{borderRadius: 1} }>
							<Box className="header">
								<Typography variant="h5">
									Co-Driver/Passenger Data {indx > 0 ? indx + 1 : ""}

									{indx !== 0 && (
										<>
											<IconButton size={"small"} onClick={() => deleteTableRow("PassengerList", passenger)}
												color={"error"} title={"Remove Passenger"}><DeleteIcon /></IconButton>
										</>
									)}
								</Typography>

							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="First Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="First_Name"
											value={passenger?.First_Name || ''}
											onChange={(e) => handleInputChange(e, "PassengerList", passenger)}
											error={!passenger?.First_Name || passenger?.First_Name?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Middle Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Middle_Name"
											value={passenger?.Middle_Name || ''}
											onChange={(e) => handleInputChange(e, "PassengerList", passenger)}
											error={!passenger?.Middle_Name || passenger?.Middle_Name?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Last Name" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Last_Name"
											value={passenger?.Last_Name || ''}
											onChange={(e) => handleInputChange(e, "PassengerList", passenger)}
											error={!passenger?.Last_Name || passenger?.Last_Name?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField variant="outlined" margin='none' type={"date"} fullWidth
											size={"small"} label="Date of Birth"
											InputLabelProps={{ shrink: true }}
											name="DOB"
											inputProps={{ style: { textAlign: 'center' }, max: todayDate }}
											value={passenger?.DOB || ''}
											onChange={(e) => handleInputChange(e, "PassengerList", passenger)}
											error={passenger?.DOB === undefined || passenger?.DOB?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="CDL #" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="DL_Number"
											value={passenger?.DL_Number || ''}
											onChange={(e) => handleInputChange(e, "PassengerList", passenger)}
											error={!passenger?.DL_Number || passenger?.DL_Number?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Passenger CDL Country"
											disableClearable
											size={"small"}
											options={CountryList}
											getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "DL_Country", value: newValue as string } }, "PassengerList", passenger)}
											value={passenger?.DL_Country ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="CDL Country"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!passenger?.DL_Country || passenger?.DL_Country?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Passenger CDL State"
											disableClearable
											size={"small"}
											options={passenger?.StateList}
											getOptionLabel={(option: any) => handleGetDDLabel(passenger?.StateList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "DL_State", value: newValue as string } }, "PassengerList", passenger)}
											value={passenger?.DL_State ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="CDL State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!passenger?.DL_State || passenger?.DL_State?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="h6">Co-Driver/Passenger Address Information</Typography>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Street" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Address_Street"
											value={passenger?.Address_Street || ''}
											onChange={(e) => handleInputChange(e, "PassengerList", passenger)}
											error={!passenger?.Address_Street || passenger?.Address_Street === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="City" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Address_City"
											value={passenger?.Address_City || ''}
											onChange={(e) => handleInputChange(e, "PassengerList", passenger)}
											error={!passenger?.Address_City || passenger?.Address_City?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Passenger State"
											disableClearable
											size={"small"}
											options={passenger?.AddressStateList}
											getOptionLabel={(option: any) => handleGetDDLabel(passenger?.AddressStateList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Address_State", value: newValue as string } }, "PassengerList", passenger)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														style: { textAlign: 'center' },
													}}
													error={!passenger?.Address_State || passenger?.Address_State?.length === 0}
												/>
											)}
											value={passenger?.Address_State || ''} />
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="ZIP" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Address_ZIP"
											value={passenger?.Address_ZIP || ''}
											onChange={(e) => handleInputChange(e, "PassengerList", passenger)}
											error={!passenger?.Address_ZIP || passenger?.Address_ZIP?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Driver Country"
											disableClearable
											size={"small"}
											options={CountryList}
											getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Address_Country", value: newValue as string } }, "PassengerList", passenger)}
											value={passenger?.Address_Country ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Country"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!passenger?.Address_Country || passenger?.Address_Country?.length === 0}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</Box>
							<div className="mx-4 mb-2">
								<IconButton size={"large"} onClick={() => addTableRow("PassengerList", "Origination", "Passenger")} color={"inherit"}
									title={"Add Additional Passenger"}> <AddIcon />
								</IconButton>
								To add an additional passenger, click here
							</div>
						</Box>
					)
				})}
			</div>

			<div className="">
				{report?.TractorList?.filter((f: any) => !f.Deleted).map((tractor: any, indx) => {
					return (
						<Box className="section" sx={{borderRadius: 1} }>
							<Box className="header">
								<Typography variant="h5">
									Tractor Data {indx > 0 ? indx + 1 : ""}

									{indx !== 0 && (
										<>
											<IconButton size={"small"} onClick={() => deleteTableRow("TractorList", tractor)}
												color={"error"} title={"Remove Tractor"}><DeleteIcon /></IconButton>
										</>
									)}
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="Tractor Identifiers" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Identifiers"
											value={tractor?.Identifiers || ''}
											onChange={(e) => handleInputChange(e, "TractorList", tractor)}
											error={!tractor?.Identifiers || tractor?.Identifiers?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Tractor VIN" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="VIN"
											value={tractor?.VIN || ''}
											onChange={(e) => handleInputChange(e, "TractorList", tractor)}
											error={!tractor?.VIN || tractor?.VIN?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Tractor Plate" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Plate_Num"
											value={tractor?.Plate_Num || ''}
											onChange={(e) => handleInputChange(e, "TractorList", tractor)}
											error={!tractor?.Plate_Num || tractor.Plate_Num?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Plate Country"
											disableClearable
											size={"small"}
											options={CountryList}
											getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Plate_Country", value: newValue as string } }, "TractorList", tractor)}
											value={tractor?.Plate_Country ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Plate Country"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!tractor?.Plate_Country || tractor?.Plate_Country?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Plate State"
											disableClearable
											size={"small"}
											options={tractor?.PlateStateList}
											getOptionLabel={(option: any) => handleGetDDLabel(tractor?.PlateStateList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Plate_State", value: newValue as string } }, "TractorList", tractor)}
											value={tractor?.Plate_State ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Plate State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!tractor?.Plate_State || tractor?.Plate_State?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Tractor Color" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Color"
											value={tractor?.Color || ''}
											onChange={(e) => handleInputChange(e, "TractorList", tractor)}
											error={!tractor?.Color || tractor?.Color?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Tractor Make" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Make"
											value={tractor?.Make || ''}
											onChange={(e) => handleInputChange(e, "TractorList", tractor)}
											error={!tractor?.Make || tractor?.Make?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Tractor Model" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Model"
											value={tractor?.Model || ''}
											onChange={(e) => handleInputChange(e, "TractorList", tractor)}
											error={!tractor?.Model || tractor?.Model?.length === 0}
										/>
									</Grid>
								</Grid>
							</Box>
							<div className="mx-4 mb-2">
								<IconButton size={"large"} onClick={() => addTableRow("TractorList", "Origination", "Tractor")} color={"inherit"}
									title={"Add Additional Tractor"}> <AddIcon />
								</IconButton>
								To add an additional tractor, click here
							</div>
						</Box>
					)
				})}
			</div>

			<div className="">
				{report?.TrailerList?.filter((f: any) => !f.Deleted).map((trailer: any, indx) => {
					return (
						<Box className="section" sx={{borderRadius: 1} }>
							<Box className="header">
								<Typography variant="h5">
									Trailer Data {indx > 0 ? indx + 1 : ""}

									{indx !== 0 && (
										<>
											<IconButton size={"small"} onClick={() => deleteTableRow("TrailerList", trailer)}
												color={"error"} title={"Remove Trailer"}><DeleteIcon /></IconButton>
										</>
									)}
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField fullWidth label="Trailer Identifiers" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Identifiers"
											value={trailer?.Identifiers || ''}
											onChange={(e) => handleInputChange(e, "TrailerList", trailer)}
											error={!trailer?.Identifiers || trailer?.Identifiers?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Trailer VIN" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="VIN"
											value={trailer?.VIN || ''}
											onChange={(e) => handleInputChange(e, "TrailerList", trailer)}
											error={!trailer?.VIN || trailer?.VIN?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Trailer Plate" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Plate_Num"
											value={trailer?.Plate_Num || ''}
											onChange={(e) => handleInputChange(e, "TrailerList", trailer)}
											error={!trailer?.Plate_Num || trailer?.Plate_Num?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Plate Country"
											disableClearable
											size={"small"}
											options={CountryList}
											getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Plate_Country", value: newValue as string } }, "TrailerList", trailer)}
											value={trailer?.Plate_Country ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Plate Country"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!trailer?.Plate_Country || trailer?.Plate_Country?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Plate State"
											disableClearable
											size={"small"}
											options={trailer?.PlateStateList}
											getOptionLabel={(option: any) => handleGetDDLabel(trailer?.PlateStateList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Plate_State", value: newValue as string } }, "TrailerList", trailer)}
											value={trailer?.Plate_State ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Plate State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!trailer?.Plate_State || trailer?.Plate_State?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Commodity" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Commodity"
											value={trailer?.Commodity || ''}
											onChange={(e) => handleInputChange(e, "TrailerList", trailer)}
											error={!trailer?.Commodity || trailer?.Commodity?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Brokered By" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Broker"
											value={trailer?.Broker || ''}
											onChange={(e) => handleInputChange(e, "TrailerList", trailer)}
											error={!trailer?.Broker || trailer?.Broker?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Shipper" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Shipper"
											value={trailer?.Shipper || ''}
											onChange={(e) => handleInputChange(e, "TrailerList", trailer)}
											error={!trailer?.Shipper || trailer?.Shipper?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Origin City" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Origin_City"
											value={trailer?.Origin_City || ''}
											onChange={(e) => handleInputChange(e, "TrailerList", trailer)}
											error={!trailer?.Origin_City || trailer?.Origin_City?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Origin State"
											disableClearable
											size={"small"}
											options={trailer?.OriginStateList}
											getOptionLabel={(option: any) => handleGetDDLabel(trailer?.OriginStateList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Origin_State", value: newValue as string } }, "TrailerList", trailer)}
											value={trailer?.Origin_State ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Origin State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!trailer?.Origin_State || trailer?.Origin_State?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Origin Country"
											disableClearable
											size={"small"}
											options={CountryList}
											getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Origin_Country", value: newValue as string } }, "TrailerList", trailer)}
											value={trailer?.Origin_Country ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Origin Country"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!trailer?.Origin_Country || trailer?.Origin_Country?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Destination City" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Destination_City"
											value={trailer?.Destination_City || ''}
											onChange={(e) => handleInputChange(e, "TrailerList", trailer)}
											error={!trailer?.Destination_City || trailer?.Destination_City?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Destination State"
											disableClearable
											size={"small"}
											options={trailer?.DestinationStateList}
											getOptionLabel={(option: any) => handleGetDDLabel(trailer?.DestinationStateList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Destination_State", value: newValue as string } }, "TrailerList", trailer)}
											value={trailer?.Destination_State ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Destination State"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!trailer?.Destination_State || trailer?.Destination_State?.length === 0}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											popupIcon=""
											id="Destination Country"
											disableClearable
											size={"small"}
											options={CountryList}
											getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
											onChange={(event, newValue) => handleInputChange({ target: { name: "Destination_Country", value: newValue as string } }, "TrailerList", trailer)}
											value={trailer?.Destination_Country ?? ''} renderInput={(params) => (
												<TextField
													{...params}
													label="Destination Country"
													InputProps={{
														...params.InputProps,
														type: 'search',
														sx: {
															textAlign: "center", // Align placeholder text
															"& input": { textAlign: "center" }, // Align the input value
														},
													}}
													error={!trailer?.Destination_Country || trailer?.Destination_Country?.length === 0}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</Box>
							<div className="mx-4 mb-2">
								<IconButton size={"large"} onClick={() => addTableRow("TrailerList", "Origination", "Trailer")} color={"inherit"}
									title={"Add Additional Trailer"}> <AddIcon />
								</IconButton>
								To add an additional trailer, click here
							</div>
						</Box>
					)
				})}
			</div>

			<div className="">
				{report?.ContactList?.filter((f: any) => !f.Deleted).map((contact: any, indx) => {
					return (
						<Box className="section" sx={{borderRadius: 1} }>
							<Box className="header">
								<Typography variant="h5">
									Contact Data {indx > 0 ? indx + 1 : ""}

									{indx !== 0 && (
										<>
											<IconButton size={"small"} onClick={() => deleteTableRow("ContactList", contact)}
												color={"error"} title={"Remove Contact"}><DeleteIcon /></IconButton>
										</>
									)}
								</Typography>
							</Box>
							<Box className="section-content">
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<TextField variant="outlined" margin='none' type={"date"} fullWidth
											size={"small"} label="Contact Date"
											name="Date"
											InputLabelProps={{ shrink: true }}
											inputProps={{ style: { textAlign: 'center' }, max: todayDate }}
											value={contact?.Date || ''}
											onChange={(e) => handleInputChange(e, "ContactList", contact)}
											error={contact?.Date === undefined || contact?.Date?.length === 0}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth label="Contact Location" size="small"
											inputProps={{ style: { textAlign: 'center' } }}
											name="Location"
											value={contact?.Location || ''}
											onChange={(e) => handleInputChange(e, "ContactList", contact)}
											error={!contact?.Location || contact?.Location?.length === 0}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Items of Interest" size="small"
											inputProps={{ style: { textAlign: 'left' } }}
											multiline
											rows={12}
											name="Items_Interest"
											value={contact?.Items_Interest || ''}
											onChange={(e) => handleInputChange(e, "ContactList", contact)}
											error={!contact?.Items_Interest || contact?.Items_Interest?.length === 0}
										/>
									</Grid>
								</Grid>
							</Box>
							<div className="mx-4 mb-2">
								<IconButton size={"large"} onClick={() => addTableRow("ContactList", "Origination", "Contact")} color={"inherit"}
									title={"Add Additional Contact"}> <AddIcon />
								</IconButton>
								To add an additional contact, click here
							</div>
						</Box>
					)
				})}
				<Box sx={{ mt: 4, textAlign: 'right' }}>
					<ButtonGroup size="large">
						<Button onClick={handleClearOpen} sx={{ bgcolor: 'white', color: 'red', border: 1, borderColor: 'black' }}>Cancel</Button>
						<Dialog
							open={clearOpen}
							onClose={handleClearClose}
						>
							<DialogTitle>
								{"Cancel submission?"}
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Are you sure you would like to cancel your submission and clear the form?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClearClose}>No</Button>
								<Button onClick={handleClearForm} autoFocus>
									Yes
								</Button>
							</DialogActions>
						</Dialog>
						<Button onClick={handleSaveOpen} sx={{ bgcolor: "white", color: "blue", border: 1, borderColor: "black" }}>Save</Button>
						<Dialog
							open={saveOpen}
							onClose={handleSaveClose}
						>
							<DialogTitle>
								{"Save record?"}
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
									{contentDialog}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleSaveClose}>No</Button>
								<Button onClick={handleSubmit} autoFocus>
									Yes
								</Button>
							</DialogActions>
						</Dialog>
					</ButtonGroup>
				</Box>
			</div>
		</Container>
	);
};

export default ECrawlInput;