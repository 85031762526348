import React, {useState} from "react";
import {Account} from "../../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../../services/auth.service";
import {RunGetDailyStats} from "../../../services/dashboard.service";
import {getDarInfoResponse} from "../../../interfaces/getDarInfo_interface";
import INDashboardLayout from "../../../components/Dashboard/INVESTIGATION/_dashboardLayout";
import {
    NJPortDashboardDataApi
} from "../../../services/njport.dashboard.service";

function InDashboard() {
    let [user, setUser] = useState<Account>(getUser());
    const [data, setData] = useState<any>({});
    const [njPortData, setNJPortData] = useState<any>([]);
    const [queryList, setQueryList] = useState<string[]>([
        "INVESTIGATION_HEATMAP",
    ]);

    const GetStats = async (params: any) => {
        for (let i of queryList) {
            let temp = { ...params };
            temp.queryType = i;
            switch (temp.queryType) {
                case "INVESTIGATION_HEATMAP":
                    NJPortDashboardDataApi(temp).then((qRes) => {
                        setNJPortData(qRes);
                    });
                    break;
            }
        }
    };

    React.useEffect(() => {
        handleDashboardData();
    }, [njPortData]);

    const handleDashboardData = () => {
        let data: any = {};
        data.njPortData = njPortData
        setData(data);
    };

    return (
        <div>
            {isUserPermission("NJPort") ? (
                <>
                    <INDashboardLayout GetStats={GetStats} data={data} />
                </>
            ) : (
                <></>
            )}
        </div>
    );
}

export default InDashboard;