import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";


interface ApplicationByStatusProps {
    HandleRefreshCount: any
    RefreshCount: number
    StartDate: Date,
    EndDate: Date
}

const ApplicationByStatus: React.FC<ApplicationByStatusProps> = ({
    HandleRefreshCount,
    RefreshCount,
    StartDate,
    EndDate
}) => {
    const [data, setData] = useState<any>([]);
    //const [refreshCount, setrefreshCount] = React.useState<number>(0);

    useEffect(() => {        
        const payload = {
            queryType: "INVESTIGATION_STATUS_YEARALL",  //whole dataset since it is based on status, not year/month
            dateTypeValue: 1,
            FromDate: StartDate,
            ToDate: EndDate,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setData(qRes?.AnyTable);
        });
    }, [RefreshCount, StartDate, EndDate]);    

    const formattedData = (maindata: any) => {
        let groupdData: any = [];
        maindata.forEach((item: any) => {
            groupdData.push({ name: item.SubGroup_Desc, value: item.Total });
        });
        //console.log('formattedData 2', { groupdData });
        return groupdData;
    }

    const handleSeries = () => {
        return [
            {
                name: "Status",
                type: "pie",
                radius: "50%",
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
                ////toolTipContent: "{b}:{@Type} \n({d}%)",
                ////indexLabel: "{d}%",
                ////indexLabelPlacement: "inside",
                label: {
                    formatter: '{b}:{@Type} \n({d}%)'
                },
                data: formattedData(data),
            },
        ];
    };

    const option = {
        minheight: '200',
        grid: {
            left: '3%',
            right: '5%',
            bottom: '3%',
            top: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        tooltip: {
            trigger: 'item'
        },
        /*color: ['#eb13ca', '#2448bf'], //Blue:#2448bf  Pink: #eb13ca*/
        series: handleSeries()
    };

    return (
        <>
            {data && data.length !== 0 ? (
                <div>
                    <ReactECharts option={option} style={{ height: "400px" }} />
                </div>
            ) : (
                <div
                    className={"text-center text-base xl:text-3xl mt-6 mb-1"}
                    style={{ color: "red", fontWeight: 500 }}
                >
                    No Data
                </div>
            )}
        </>
    );
};

export default ApplicationByStatus;