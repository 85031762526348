import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
//mui
import {
    Alert, AlertColor, Avatar,
    Button, ButtonGroup,
    Chip,
    Dialog, Drawer,
    IconButton,
    Paper,
    Snackbar,
    Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip
} from "@mui/material";
//mui icons
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import DoubleArrowSharpIcon from '@mui/icons-material/DoubleArrowSharp';
import InsightsIcon from '@mui/icons-material/Insights';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import MapIcon from '@mui/icons-material/Map';
import PrintIcon from '@mui/icons-material/Print';
import { green } from '@mui/material/colors';
import { HelpOutline } from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import UploadFileIcon from '@mui/icons-material/UploadFile';
//asset/images
import CorrStat from '../../assets/Images/CorrStat.png';
import EditIcon from '../../assets/Images/edit.png';
import GunOffenderIcon from '../../assets/Images/GunOffender.png';
import CorrectionIcon from '../../assets/Images/Jail.png';
import MainRecordIcon from '../../assets/Images/MainRecord.png';
import NCICIcon from '../../assets/Images/NCIC.png';
import PIRIcon from '../../assets/Images/pir.png';
import RTCC from '../../assets/Images/ROIC2.png';
import ShareLinkIcon from '../../assets/Images/ShareLink.png';
import POIIcon from '../../assets/Images/target.png';
import TSCIcon from '../../assets/Images/TSC-button.png';
import PIRSupplement from "../../components/tools/preliminary_report/_PreliminarySupplementInputForm";
//Interfaces
import { Account } from "../../interfaces/auth_interface";
import { PersonInfo } from "../../interfaces/getDarInfo_interface";
import { SearchDL } from "../../interfaces/searchDL.interface";
import AgencyInteractionInfo from "../../interfaces/AgencyInteractionInfo.interface";
import ALPRInfo from "../../interfaces/ALPRInfo.interface";
import { ArrestInfo } from "../../interfaces/ArrestInfo.interface";
import CADRMSInfo from "../../interfaces/CADRMSInfo.interface";
import DNAInfo from "../../interfaces/DNAInfo.interface";
import ETInfo from "../../interfaces/ETInfo.interface";
import NIBINInfo from "../../interfaces/NIBINInfo.interface";
import PDInfo from "../../interfaces/PDInfo.interface";
import PersonImageInfo from "../../interfaces/PersonImageInfo.interface";
import PrisonerParoleInfo from "../../interfaces/PrisonerParoleInfo.interface";
import { PrisonerReleaseInfo } from "../../interfaces/PrisonerReleaseInfo.interface";
import ProfessionInfo from "../../interfaces/ProfessionInfo.interface";
import { ShootingInfo } from "../../interfaces/ShootingInfo.interface";
import ViolentInfo from "../../interfaces/ViolentInfo.interface";
import WeaponTraceInfo from "../../interfaces/WeaponTraceInfo.interface";
import { GetDefaultStateForSite, GetStringNameForSite, IsPanelEnabled, PERSON_PANEL, SITE } from "../../interfaces/config.interface";
import { GRAILReferral } from "../../interfaces/GRAILReferral.interface";
import { PersonFileOrImage } from "../../interfaces/UpdatePersonMainRecord.interface";
import TVInfo from "../../interfaces/TvInfo.interface";

//Services
import {
    GetPersonRelatedPIR,
    GetPersonRelatedTSC,
    GetProfileReportPDF,
    HasProfileReport,
    RunCorrection,
    RunExpiryToken,
    RunGetPersonMainRecord,
    RunPersonSummaryPDF,
    RunPromisGavel,
    RunSocialMedia
} from "../../services/account.service";
import {
    GetFileContentPersonMain,
    RunGetGRAILReferral,
    RunPerson_AddressPhoneQuery, RunPerson_AKAName, RunPerson_ArrestQuery, RunPerson_BOLOQuery,
    RunPerson_CADRMSQuery, RunPerson_CodisDNAQuery, RunPerson_CrumbScore,
    RunPerson_DLQuery,
    RunPerson_ETicketQuery, RunPerson_ETraceQuery,
    RunPerson_FirearmQuery,
    RunPerson_GunOffenderQuery, RunPerson_GunRegistrationQuery, RunPerson_GunStatQuery,
    RunPerson_ImageQuery, RunPerson_InfocopQuery,
    RunPerson_JailQuery,
    RunPerson_M43PersonQuery, RunPerson_MissingPersonQuery,
    RunPerson_NIBINQuery, RunPerson_NJPOPQuery, RunPerson_njtraceQuery,
    RunPerson_ParkMobileQuery, RunPerson_ParoleQuery, RunPerson_Plate, RunPerson_PrisonQuery, RunPerson_ProfessionQuery, RunPerson_ProfileReport,
    RunPerson_Referral,
    RunPerson_SaveSupplementalFile, RunPerson_SupplementalFile,
    RunPerson_TowedVehicle, RunPerson_TSC,
    RunPerson_ViolentQuery
} from "../../services/getDar.service";
import { DateToString, formatDate, formatDT, readFileAsDataURL } from '../../services/formatDate.service';
import { getUser, isMainRecordEditor, isUserPermission } from "../../services/auth.service";
import { RunPerson_ALPRQuery } from "../../services/getAlpr.service";
import { JAOS } from "../../services/JAOS.service";
import { ENABLED_PANELS, Main_URL, showCrumbScore, SiteName } from "../../services/config.service";
import { base64_to_blob, OpenSCORPDF } from "../../services/detail.service";
import { GetPIRPDFOnly } from "../../services/pir.service";
//Syncfusion
import {
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    DetailRow,
    ExcelExport,
    Filter,
    Grid,
    GridComponent,
    Inject,
    Page,
    PdfExport,
    Reorder,
    Resize,
    Sort,
    Toolbar
} from '@syncfusion/ej2-react-grids';
//Shared components
import GeoCode from "./GeoCodes/_GeoCode_Display";
import FileUpload from "./_FileUpload";
import BTPersonNavigator from "./_PersonNavigator";
//Panels
import PanelALPR from "./BTPersonReportPanels/_PanelALPR";
import PanelArrest from "./BTPersonReportPanels/_PanelArrest";
import PanelAssociatedFirearms from "./BTPersonReportPanels/_PanelAssociatedFirearm";
import PanelAssociatedPeople from "./BTPersonReportPanels/_PanelAssociatedPeople";
import PanelBOLO from "./BTPersonReportPanels/_PanelBOLO";
import PanelCADRMS from "./BTPersonReportPanels/_PanelCADRMS";
import PanelCODIS from "./BTPersonReportPanels/_PanelCodis";
import PanelETrace from "./BTPersonReportPanels/_PanelETrace";
import PanelEticket from "./BTPersonReportPanels/_PanelEticket";
import PanelGunOffender from "./BTPersonReportPanels/_PanelGunOffender";
import PanelGunRegistration from "./BTPersonReportPanels/_PanelGunRegistration";
import PanelGunStat from "./BTPersonReportPanels/_PanelGunStat";
import PanelINFOCOP from "./BTPersonReportPanels/_PanelInfocop";
import PanelJail from "./BTPersonReportPanels/_PanelJail";
import PanelMissingPerson from "./BTPersonReportPanels/_PanelMissingPerson";
import PanelNIBIN from "./BTPersonReportPanels/_PanelNIBIN";
import PanelNJGunTrace from "./BTPersonReportPanels/_PanelNJGunTrace";
import PanelParkMobile from "./BTPersonReportPanels/_PanelParkMobile";
import PanelParole from "./BTPersonReportPanels/_PanelParole";
import PanelPrison from "./BTPersonReportPanels/_PanelPrison";
import PanelProfession from "./BTPersonReportPanels/_PanelProfession";
import PanelProfileReport from "./BTPersonReportPanels/_PanelProfileReport";
import PanelReferral from "./BTPersonReportPanels/_PanelGVRTFReferral";
import PanelShooting from "./BTPersonReportPanels/_PanelShooting";
import PanelStopData from "./BTPersonReportPanels/_PanelStopData";
import PanelSupplement from "./BTPersonReportPanels/_PanelSupplement";
import PanelTSC from "./BTPersonReportPanels/_PanelTSC";
import PanelTowedVehicle from "./BTPersonReportPanels/_PanelTowedVehicle";
import PanelViolent from "./BTPersonReportPanels/_PanelViolent";

interface BTPersonReportProps {
    person: PersonInfo,
    PersonSummaryInfos?: any,
    PrintMode?: boolean
}

interface PhoneAddressInterface {
    AdditionalData: any[],
    Association: any,
    Association1: any,
    Association2: any,
    Association3: any,
    Association4: any,
    AuthToken: any,
    LoggedUser: any,
    AnyData: any[]
}

const BTPersonReport: React.FC<BTPersonReportProps> = ({ person, PersonSummaryInfos, PrintMode = false }) => {
    let [user, setUser] = useState<Account>(getUser())
    let [crumbScoreInfo, setCrumbScoreInfo] = useState<any>([])
    let [arrestInfo, setArrestInfo] = useState<ArrestInfo[]>([])
    let [personImageInfo, setPersonImageInfo] = useState<PersonImageInfo[]>([])
    let [njpopInfo, setNJPOPInfo] = useState<ShootingInfo[]>([])
    let [alprInfo, setALPRInfo] = useState<ALPRInfo[]>([])
    let [codisDNAInfo, setCodisDNAInfo] = useState<DNAInfo[]>([])
    let [infocopInfo, setInfoCopInfo] = useState<AgencyInteractionInfo[]>([])
    let [eTicketInfo, setETicketInfo] = useState<ETInfo[]>([])
    let [cadrmsInfo, setCADRMSInfo] = useState<CADRMSInfo[]>([])
    let [stopDataInfo, setStopDataInfo] = useState<CADRMSInfo[]>([])
    let [parkMobileInfo, setParkMobiletInfo] = useState<PDInfo[]>([])
    let [eTraceInfo, setETraceInfo] = useState<WeaponTraceInfo[]>([])
    let [njTraceInfo, setNJTraceInfo] = useState<WeaponTraceInfo[]>([])
    let [nibinInfo, setNIBINInfo] = useState<NIBINInfo[]>([])
    let [prisonInfo, setPrisonInfo] = useState<PrisonerReleaseInfo[]>([])
    let [paroleInfo, setParoleInfo] = useState<PrisonerParoleInfo[]>([])
    let [jailInfo, setJailInfo] = useState<any[]>([])
    let [missingPersonInfo, setMissingPersonInfo] = useState<any[]>([])
    let [gunStatInfo, setGunStatInfo] = useState<any[]>([])
    let [gunOffenderInfo, setGunOffenderInfo] = useState<any[]>([])
    let [gunRegistrationInfo, setGunRegistrationInfo] = useState<any[]>([])
    let [boloInfo, setBOLOInfo] = useState<any[]>([])
    let [firearmInfo, setFirearmInfo] = useState<any[]>([])
    let [m43PersonInfo, setm43PersonInfo] = useState<any[]>([])
    let [supplementFilesInfo, setSupplementFilesInfo] = useState<any[]>([])
    let [violentInfo, setViolentInfo] = useState<ViolentInfo[]>([])
    let [professionInfo, setProfessionInfo] = useState<ProfessionInfo[]>([])
    let [phoneAddressInfo, setPhoneAddressInfo] = useState<PhoneAddressInterface>()
    let [associatedPeopleInfo, setAssociatedPeopleInfo] = useState<any[]>([])
    let [referralInfo, setReferralInfo] = useState<any[]>([])
    let [tscInfo, setTscInfo] = useState<any[]>([])
    let [profileReportInfo, setProfileReportInfo] = useState<any[]>([])
    let [akaInfo, setAKAInfo] = useState<any[]>([])
    let [DLNo, setDLNo] = useState<any[]>([])
    let [plateInfo, setPlateInfo] = useState<any[]>([])
    let [query, setQuery] = useState<any>({})
    let [phoneStringArray, setPhoneStringArray] = useState<any>([])
    let [nicknamesStringArray, setNicknamesStringArray] = useState<any>([])
    let [towedVehicleInfo, setTowedVehicleInfo] = useState<any[]>([])

    let [sbiState, setSbiState] = useState<any>("");
    let [streetNum, setStreetNum] = useState<any>("");
    let [streetName, setStreetName] = useState<any>("");
    let [city, setCity] = useState<any>("");
    let [state, setState] = useState<any>("");
    let [zip, setZip] = useState<any>("");

    let [arrestLoading, setArrestLoading] = useState<boolean>(false)
    let [njpopLoading, setNJPOPLoading] = useState<boolean>(false)
    let [codisDNALoading, setCodisDNALoading] = useState<boolean>(false)
    let [infocopLoading, setInfoCopLoading] = useState<boolean>(false)
    let [eTicketLoading, setETicketLoading] = useState<boolean>(false)
    let [cadrmsLoading, setCADRMSLoading] = useState<boolean>(false)
    let [parkMobileLoading, setParkMobiletLoading] = useState<boolean>(false)
    let [eTraceLoading, setETraceLoading] = useState<boolean>(false)
    let [njTraceLoading, setNJTraceLoading] = useState<boolean>(false)
    let [nibinLoading, setNIBINLoading] = useState<boolean>(false)
    let [prisonLoading, setPrisonLoading] = useState<boolean>(false)
    let [paroleLoading, setParoleLoading] = useState<boolean>(false)
    let [jailLoading, setJailLoading] = useState<boolean>(false)
    let [missingPersonLoading, setMissingPersonLoading] = useState<boolean>(false)
    let [supplementFilesLoading, setSupplementFilesLoading] = useState<boolean>(false)
    let [gunStatLoading, setGunStatLoading] = useState<boolean>(false)
    let [gunOffenderLoading, setGunOffenderLoading] = useState<boolean>(false)
    let [gunRegistrationLoading, setGunRegistrationLoading] = useState<boolean>(false)
    let [boloLoading, setBOLOLoading] = useState<boolean>(false)
    let [firearmLoading, setFirearmLoading] = useState<boolean>(false)
    let [violentLoading, setViolentLoading] = useState<boolean>(false)
    let [professionLoading, setProfessionLoading] = useState<boolean>(false)
    let [associatedPeopleLoading, setAssociatedPeopleLoading] = useState<boolean>(false)
    let [alprLoading, setALPRLoading] = useState<boolean>(false)
    let [towedVehicleLoading, setTowedVehicleLoading] = useState<boolean>(false)
    let [alprRan, setALPRRan] = useState<boolean>(false)

    let [referralLoading, setReferralLoading] = useState<boolean>(false)
    let [tscLoading, setTscLoading] = useState<boolean>(false)
    let [profileReportLoading, setProfileReportLoading] = useState<boolean>(false)
    let [dPlate, setDPlate] = useState<any[]>([])
    let [ETicketPlate, setETicketPlate] = useState<any[]>([])
    let [allAddresses, setAllAddresses] = useState<any[]>([])
    let [dAddress, setDAddress] = useState<any[]>([])
    let [arrestAddress, setArrestAddress] = useState<any[]>([])
    let [profileReportNum, setProfileReportNum] = useState<number>(0)

    let associatedPeople: any[] = []
    let dmvPlates: any[] = []
    let eTicketPlates: any[] = []
    let dmvAddress: any[] = []
    let aAddress: any[] = []

    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [allPins, setAllPins] = React.useState<any[]>([])

    //For Image Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1);

    const [showPIR, setShowPIR] = React.useState(false)
    const [pirPerson, setPirPerson] = React.useState<any>({})
    const [pirCCNList, setPirCCNList] = React.useState<any>([])

    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")

    let [shareLink, setShareLink] = useState<string>()
    const [expiryOpen, setExpiryOpen] = React.useState<boolean>(false)
    const [navigatorOpen, setNavigatorOpen] = React.useState<boolean>(false)

    const [fileSupplementalOpen, setFileSupplementalOpen] = React.useState<boolean>(false)
    const [fileSupplementalList, setFileSupplementalList] = React.useState<any[]>([])

    const [navigatorType, setNavigatorType] = React.useState<string>('')
    let [MainRecordFileOrImageInfo, setMainRecordFileOrImageInfo] = useState<PersonFileOrImage[]>([])
    let [MainRecordFileOrImageLoading, setMainRecordFileOrImageLoading] = useState<boolean>(false)

    useEffect(() => {
        if (PersonSummaryInfos != null) {
            setArrestInfo(PersonSummaryInfos.ArrestInfo)
            setPersonImageInfo(PersonSummaryInfos.PersonImageInfo)
            setNJPOPInfo(PersonSummaryInfos.NJPopInfo)
            setALPRInfo(PersonSummaryInfos.ALPRInfo)
            setCodisDNAInfo(PersonSummaryInfos.CodisDNAInfo)
            setInfoCopInfo(PersonSummaryInfos.InfoCopInfo)
            setETicketInfo(PersonSummaryInfos.ETicketInfo)
            setCADRMSInfo(PersonSummaryInfos.CADRMSInfo)
            setParkMobiletInfo(PersonSummaryInfos.ParkMobileInfo)
            setETraceInfo(PersonSummaryInfos.ETraceInfo)
            setNJTraceInfo(PersonSummaryInfos.NJTraceInfo)
            setNIBINInfo(PersonSummaryInfos.NibinInfo)
            setPrisonInfo(PersonSummaryInfos.PrisonInfo)
            setParoleInfo(PersonSummaryInfos.ParoleInfo)
            setJailInfo(PersonSummaryInfos.JailInfo)
            setGunRegistrationInfo(PersonSummaryInfos.GunRegistrationInfo)
            setViolentInfo(PersonSummaryInfos.ViolentInfo)
            setProfessionInfo(PersonSummaryInfos.ProfessionInfo)
            setPhoneAddressInfo(PersonSummaryInfos.PhoneAddressInfo)
            setAssociatedPeopleInfo(PersonSummaryInfos.AssociatedPeopleInfo)
            setCrumbScoreInfo(PersonSummaryInfos.CrumbScoreInfo)
            setDLNo(PersonSummaryInfos.DLNosInfo)
            setPlateInfo(PersonSummaryInfos.PlateInfo)
            //setReferralInfo(PersonSummaryInfos.ReferralInfo)
            setMainRecordFileOrImageInfo([])
            setTowedVehicleInfo(PersonSummaryInfos.TowedVehicleInfo)
        }
    }, [PersonSummaryInfos]);

    useEffect(() => {
        if (!PersonSummaryInfos) {
            loadPersonSummary()
        }
    }, [person]);

    const loadPersonSummary = async () => {
        let today: string = new Date().toLocaleDateString();
        let query: SearchDL = {
            City: person?.City,
            DLNo: person?.DLNo,
            DLSt: person?.DLSt,
            DOB: person?.DOB ? (person?.DOB)?.toLocaleString() : "",
            DarID: person?.DarID ? person?.DarID.toString() : null,
            Dept: user.Dept,
            DeptORI: user.DeptORI,
            ETicket: null,
            EmpID: user.EmpID,
            FBI: person?.FBI,
            FirstName: person?.FName,
            FromDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today,
            ToDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today,
            GANGRELATED: person?.GANGRELATED,
            LastName: person?.LName,
            LoggedDept: null,
            LoggedEmpID: null,
            MiddleName: person?.MName,
            PersonID: person?.PersonID,
            QueryType: null,
            CrumbScorePercentile: person?.CrumbScorePercentile,
            SBINo: person?.SBINo,
            SEX: person?.SEX
        }

        setQuery(query)

        //set loading circles
        setArrestLoading(true)
        setNJPOPLoading(true)
        setCodisDNALoading(true)
        setInfoCopLoading(true)
        setETicketLoading(true)
        setCADRMSLoading(true)
        setParkMobiletLoading(true)
        setETraceLoading(true)
        setNJTraceLoading(true)
        setNIBINLoading(true)
        setPrisonLoading(true)
        setParoleLoading(true)
        setJailLoading(true)
        setGunRegistrationLoading(true)
        setViolentLoading(true)
        setProfessionLoading(true)
        setAssociatedPeopleLoading(true)
        //setALPRLoading(true)
        setReferralLoading(true)
        setProfileReportLoading(true)
        setMainRecordFileOrImageLoading(true)
        setMissingPersonLoading(true)
        setSupplementFilesLoading(true)
        setGunStatLoading(true)
        setFirearmLoading(true)
        setGunOffenderLoading(true)
        setBOLOLoading(true)
        setTowedVehicleLoading(true)

        RunPerson_ImageQuery(query).then(response => {
            if (response.AnyData)
                setPersonImageInfo(response.AnyData);
            handleAssociatedPeople(response);
        })
        RunPerson_CrumbScore(query).then(response => {
            if (response.AnyData) {
                setCrumbScoreInfo(response.AnyData[0]);
            }
        })
        RunPerson_ArrestQuery(query).then(response => {
            if (response.AnyData)
                setArrestInfo(response.AnyData);
            handleAssociatedPeople(response);
            setArrestLoading(false)
        })
        RunPerson_NJPOPQuery(query).then(response => {
            if (response.AnyData)
                setNJPOPInfo(response.AnyData);
            handleAssociatedPeople(response);
            setNJPOPLoading(false)
        })
        RunPerson_CodisDNAQuery(query).then(response => {
            if (response.AnyData)
                setCodisDNAInfo(response.AnyData);
            handleAssociatedPeople(response);
            setCodisDNALoading(false)
        })
        RunPerson_InfocopQuery(query).then(response => {
            if (response.AnyData)
                setInfoCopInfo(response.AnyData);
            handleAssociatedPeople(response);
            setInfoCopLoading(false)
        })
        RunPerson_ETicketQuery(query).then(response => {
            if (response.AnyData)
                setETicketInfo(response.AnyData);
            handleAssociatedPeople(response);
            setETicketLoading(false)
        })
        RunPerson_CADRMSQuery(query).then(response => {
            if (response.AnyData) {
                if (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) {
                    setCADRMSInfo(response.AnyData?.filter((f: any) => f.STATUTEDESCRIPTION?.toUpperCase() !== 'STOP | INCIDENT'));
                    setStopDataInfo(response.AnyData?.filter((f: any) => f.STATUTEDESCRIPTION?.toUpperCase() === 'STOP | INCIDENT'));
                } else {
                    setCADRMSInfo(response.AnyData);
                }
            }

            handleAssociatedPeople(response);
            setCADRMSLoading(false)
        })
        RunPerson_ParkMobileQuery(query).then(response => {
            if (response.AnyData)
                setParkMobiletInfo(response.AnyData);
            handleAssociatedPeople(response);
            setParkMobiletLoading(false)
        })
        RunPerson_ETraceQuery(query).then(response => {
            if (response.AnyData)
                setETraceInfo(response.AnyData);
            handleAssociatedPeople(response);
            setETraceLoading(false)
        })
        RunPerson_njtraceQuery(query).then(response => {
            if (response.AnyData)
                setNJTraceInfo(response.AnyData);
            handleAssociatedPeople(response);
            setNJTraceLoading(false)
        })
        RunPerson_NIBINQuery(query).then(response => {
            if (response.AnyData)
                setNIBINInfo(response.AnyData);
            handleAssociatedPeople(response);
            setNIBINLoading(false)
        })
        RunPerson_PrisonQuery(query).then(response => {
            if (response.AnyData)
                setPrisonInfo(response.AnyData);
            handleAssociatedPeople(response);
            setPrisonLoading(false)
        })
        RunPerson_ParoleQuery(query).then(response => {
            if (response.AnyData)
                setParoleInfo(response.AnyData);
            handleAssociatedPeople(response);
            setParoleLoading(false)
        })
        RunPerson_TowedVehicle(query).then(response => {
            if (response.AnyData)
                setTowedVehicleInfo(response.AnyData);
            handleAssociatedPeople(response);
            setTowedVehicleLoading(false)
        })
        if (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) {
            RunPerson_MissingPersonQuery(query).then(response => {
                if (response.AnyData)
                    setMissingPersonInfo(response.AnyData);
                handleAssociatedPeople(response);
                setMissingPersonLoading(false)
            })
            RunPerson_GunStatQuery(query).then(response => {
                if (response.AnyData)
                    setGunStatInfo(response.AnyData);
                setGunStatLoading(false)
            })
            RunPerson_GunOffenderQuery(query).then(response => {
                if (response.AnyData)
                    setGunOffenderInfo(response.AnyData);
                setGunOffenderLoading(false)
            })
            RunPerson_GunRegistrationQuery(query).then(response => {
                if (response.AnyData) {
                    let GunRegistrationData = response.AnyData.sort((a: any, b: any) => {
                        return (new Date(b?.COMMITMENT_DATETIME).getTime() - new Date(a?.COMMITMENT_DATETIME).getTime())
                    })
                    setGunRegistrationInfo(GunRegistrationData);
                }
                handleAssociatedPeople(response);
                setGunRegistrationLoading(false)
            })
            RunPerson_BOLOQuery(query).then(response => {
                if (response.AnyData)
                    setBOLOInfo(response.AnyData);
                setBOLOLoading(false)
            })
            RunPerson_SupplementalFile(query).then(response => {
                if (response.AnyData)
                    setSupplementFilesInfo(response.AnyData);
                setSupplementFilesLoading(false)
            })
            RunPerson_FirearmQuery(query).then(response => {
                if (response.AnyData)
                    setFirearmInfo(response.AnyData);
                setFirearmLoading(false)
            })
            RunPerson_M43PersonQuery(query).then(response => {
                if (response.AnyData) {
                    setm43PersonInfo(response.AnyData);
                    if (response.AnyData.length > 0) {
                        let tPhones = new Set();
                        let tNicknames = new Set();
                        //Get all the phones from the M43PersonInfo
                        for (let i = 0; i < response.AnyData.length; i++) {
                            if (response.AnyData[i].phoneNumber) {
                                tPhones.add(response.AnyData[i].phoneNumber);
                            }
                            if (response.AnyData[i].nicknames) {
                                tNicknames.add(response.AnyData[i].nicknames);
                            }
                        }
                        setPhoneStringArray(Array.from(tPhones));
                        setNicknamesStringArray(Array.from(tNicknames));
                    }
                }
                setFirearmLoading(false)
            })
        } else {
            setMissingPersonLoading(false)
            setGunStatLoading(false)
            setFirearmLoading(false)
            setGunOffenderLoading(false)
            setBOLOLoading(false)
            setSupplementFilesLoading(false)
        }
        RunPerson_JailQuery(query).then(response => {
            if (response.AnyData) {
                let jailData = response.AnyData.sort((a: any, b: any) => {
                    return (new Date(b?.COMMITMENT_DATETIME).getTime() - new Date(a?.COMMITMENT_DATETIME).getTime())
                })
                setJailInfo(jailData);
            }
            handleAssociatedPeople(response);
            setJailLoading(false)
        })
        RunPerson_ViolentQuery(query).then(response => {
            if (response.AnyData)
                setViolentInfo(response.AnyData);
            handleAssociatedPeople(response);
            setViolentLoading(false)
        })
        RunPerson_ProfessionQuery(query).then(response => {
            if (response.AnyData)
                setProfessionInfo(response.AnyData);
            handleAssociatedPeople(response);
            setProfessionLoading(false)
        })
        if (isUserPermission('TSC')) {
            RunPerson_TSC(person).then(response => {
                if (response)
                    setTscInfo(response);
                setTscLoading(false)
            })
        }
        RunPerson_Referral(query).then(response => {
            if (response.AnyData)
                setReferralInfo(response.AnyData);
            setReferralLoading(false)
        })
        RunPerson_AKAName(query).then(response => {
            if (response.AnyData)
                setAKAInfo(response.AnyData);
        })
        RunPerson_ProfileReport(query).then(response => {
            if (response.AnyData)
                setProfileReportInfo(response.AnyData);
            setProfileReportLoading(false);
        })
        RunGetPersonMainRecord(query).then(response => {
            if (response?.FileOrImages)
                setMainRecordFileOrImageInfo(response?.FileOrImages);
            if (response?.Main?.SBIState) {
                setSbiState(response?.Main?.SBIState);
            }
            if (response?.Main?.STREETNo) {
                setStreetNum(response?.Main?.STREETNo);
            }
            if (response?.Main?.STREETNAME) {
                setStreetName(response?.Main?.STREETNAME);
            }
            if (response?.Main?.CITY) {
                setCity(response?.Main?.CITY);
            }
            if (response?.Main?.STATE) {
                setState(response?.Main?.STATE);
            }
            if (response?.Main?.ZIP) {
                setZip(response?.Main?.ZIP);
            }
        })

        RunALPRQuery("ALPR_NOAPICALL", query);

        RunPerson_DLQuery(query).then(response => setDLNo(response.AnyData))
        RunPerson_AddressPhoneQuery(query).then(response => { setPhoneAddressInfo(response); handleAddressInfo(response.AnyData) })
        RunPerson_Plate(query).then((response => { setPlateInfo(response.AnyData); handlePlateInfo(response.AnyData) }))
        var reportNum = await HasProfileReport(person?.FBI ? person?.FBI : "", person?.SBINo ? person?.SBINo : "", person?.FName ? person?.FName : "", person?.LName ? person?.LName : "", person?.DOB ? (person?.DOB)?.toLocaleString() : "")
        setProfileReportNum(reportNum.ProfileID)
    }

    const RunALPRQuery = (QueryType: any, queryAlpr: any) => {
        //console.log("QueryType: ", QueryType, alprRan, queryAlpr);
        if (queryAlpr) {
            let ALPRQuery = queryAlpr ?? query;
            ALPRQuery.QueryType = QueryType ?? "3";  //3: Latest plate data, "ALPR_NOAPICALL": Existing plate data
            if (alprRan === false || ALPRQuery?.QueryType === "ALPR_NOAPICALL") {
                try {
                    setALPRRan(ALPRQuery?.QueryType === "3")
                    setALPRLoading(true);
                    let date: any = new Date()
                    if (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)
                        date.setDate(new Date().getDate() - 90)  //DC 90 Days, NJ 1 Year
                    else
                        date.setDate(new Date().getDate() - 364)
                    ALPRQuery.FromDate = date.toLocaleString(); //Serach ALPR for 1 year *364 Days 11/22/2023 -Kiran*
                    ALPRQuery.ToDate = (new Date()).toLocaleString();
                    RunPerson_ALPRQuery(ALPRQuery).then(response => { setALPRInfo(response.AnyData); setALPRLoading(false); setAssociatedPeopleLoading(false) })
                } catch (e) {
                    setALPRRan(false);
                    setALPRLoading(false);
                    setAssociatedPeopleLoading(false)
                }
            }
        }
    }

    const handleAddressInfo = (address: any) => {
        for (let i = 0; i < address?.length; i++) {
            if (address[i]?.Address.length > 0) {
                if (address[i].AddressType === 'DMV') {
                    dmvAddress.push(address[i].Address + ' ' + address[i].City + ' ' + address[i].State + ' ' + address[i].Zip)
                }
                else {
                    aAddress.push(address[i].Address + ' ' + address[i].City + ' ' + address[i].State + ' ' + address[i].Zip)
                }
            }
        }
        setAllAddresses(address)
        setDAddress(dmvAddress)
        setArrestAddress(aAddress)
    }

    const handlePlateInfo = (plates: any) => {
        for (let i = 0; i < plates?.length; i++) {
            if (plates[i]?.PlateNo.length > 0) {
                if (plates[i].Source === 'DMV') {
                    dmvPlates.push(plates[i].PlateNo + " " + plates[i].PlateSt)
                }
                else {
                    eTicketPlates.push(plates[i].PlateNo + " " + plates[i].PlateSt)
                }
            }
        }
        setDPlate(dmvPlates);
        setETicketPlate(eTicketPlates);
    }

    const handleAssociatedPeople = (response: any) => {
        let temp: any[] = []

        if (response.Association) {
            associatedPeople.push(...response.Association)
        }
        if (response.Association1) {
            associatedPeople.push(...response.Association1)
        }
        if (response.Association2) {
            associatedPeople.push(...response.Association2)
        }
        if (response.Association3) {
            associatedPeople.push(...response.Association3)
        }
        if (response.Association4) {
            associatedPeople.push(...response.Association4)
        }
        setAssociatedPeopleInfo(associatedPeople)
    }

    const handleNavigatorEvent = (cancel: boolean = false) => {
        //if cancel = true then don't navigate.

        if (!cancel) {
            switch (navigatorType) {
                case 'Print':
                    handlePrint()
                    break;
                case 'Map':
                    handleMap()
                    break;
                case 'POI':
                    handlePOI()
                    break;
                case 'GUN':
                    handleGunOffender()
                    break;
                case 'NCIC':
                    handleNCIC()
                    break;
                case 'GVRTF':
                    handleGRAIL()
                    break;
                case 'LinkAnalysis':
                    handleAnalysis()
                    break;
                case 'TimeLine':
                    handleTimeLine()
                    break;
                case 'ProfileReport':
                    handleProfileReport()
                    break;
                case 'PIR':
                    handlePIR()
                    break;
                case 'TSC':
                    handleTSC()
                    break;
            }
        }
        setNavigatorType('')
        setNavigatorOpen(false)
    }
    const handleNavigator = (type: string) => {
        setNavigatorType(type)
        setNavigatorOpen(true)
    }
    const handlePrint = async () => {
        let mark43PersonInfo = null
        let caseNumberList = []
        if (m43PersonInfo && m43PersonInfo.length > 0) {
            mark43PersonInfo = m43PersonInfo[0]
            mark43PersonInfo.AdditionalPhoneInfo = phoneStringArray.join() ?? ""
            mark43PersonInfo.AdditionalAKAInfo = nicknamesStringArray.join() ?? ""
            for (var item of m43PersonInfo) {
                caseNumberList.push(item.reportNumber)
            }
        }
        let pdfPerson: any = {
            PersonInfo: person,
            ImageInfo: personImageInfo,
            SCORInfo: crumbScoreInfo,
            ArrestInfo: arrestInfo,
            NJPOPInfo: njpopInfo,
            DNAInfo: codisDNAInfo,
            InfoCopInfo: infocopInfo,
            ETicketInfo: eTicketInfo,
            CADRMSInfo: cadrmsInfo,
            StopDataInfo: stopDataInfo,
            ParkMobileInfo: parkMobileInfo,
            TowedVehicleInfo: towedVehicleInfo,
            ETraceInfo: eTraceInfo,
            NJTraceInfo: njTraceInfo,
            NIBINInfo: nibinInfo,
            PrisonInfo: prisonInfo,
            ParoleInfo: paroleInfo,
            JailInfo: jailInfo,
            MissingPersonInfo: missingPersonInfo,
            GunStatInfo: gunStatInfo,
            GunOffenderInfo: gunOffenderInfo,
            GunRegistrationInfo: gunRegistrationInfo,
            BOLOInfo: boloInfo,
            Mark43PersonInfo: mark43PersonInfo ?? null,
            ViolentInfo: violentInfo,
            ProfessionInfo: professionInfo,
            ALPRInfo: alprInfo,
            DLInfo: DLNo,
            AddressInfo: phoneAddressInfo?.AnyData,
            PhoneInfo: phoneAddressInfo?.AdditionalData,
            PlateInfo: plateInfo,
            AssociatedPeopleInfo: associatedPeopleInfo,
            SiteState: GetDefaultStateForSite(SiteName),
            AKAInfo: akaInfo,
            caseNumberList: caseNumberList,
            SupplementalFiles: supplementFilesInfo,
            TVInfos: towedVehicleInfo
        }
        if (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) {
            var pirList = await GetPersonRelatedPIR(pdfPerson)
            pdfPerson.PIRInfo = pirList;

            if (pirList?.length > 0) {
                var pdfList = []
                for (item in pirList) {
                    pdfList.push(await GetPIRPDFOnly(item.ID))
                }
            }
        }

        if (isUserPermission('TSC')) {
            var tscList = await GetPersonRelatedTSC(pdfPerson)
            console.log("TSC list: ", tscList)
            pdfPerson.TSCInfo = tscList;
        }

        RunPersonSummaryPDF(pdfPerson)
    }

    const handleGRAIL = async () => {
        try {
            let checkGRAIL: GRAILReferral = {
                PersonID: person.PersonID,
                TblGunPersonID: null,
                Adopted: null,
                Analytic_Notes: null,
                Arrest: null,
                CODIS: null,
                CaseExplorer: null,
                City: null,
                Comments: null,
                CreateDate: null,
                CreateUID: null,
                Custody_County: null,
                Custody_Status: null,
                Distribution_List: null,
                DLNo: null,
                DLState: null,
                DOB: person?.DOB,
                EditStatus: null,
                FBI: person?.FBI,
                eCDR: null,
                FIRSTNAME: person?.FName,
                Felony_Convictions: null,
                Gang: null,
                Gun_Arrests: null,
                ID: null,
                InfoShare: null,
                NJPopNum: null,
                Judge: null,
                LASTNAME: person.LName,
                MI: null,
                NIBIN: null,
                NJPOP: null,
                Parole: null,
                Pending_Charges: null,
                Pending_Charges_Count: null,
                Pending_GunCharge: null,
                Pending_GunCharge_Count: null,
                Photos: null,
                Prosecution_Outcome: null,
                Prosecutor: null,
                RTCC: null,
                Referral_County: null,
                Resident_County: null,
                SBINo: person?.SBINo,
                SBIState: null,
                SSN: null,
                Shooting_Suspect: null,
                Shooting_Victim: null,
                Status: null,
                SubmitDate: null,
                Total_Arrests: null,
                UpdDate: null,
                UpdUID: null,
                isCODIS: null,
                isCaseExplorer: null,
                isGang: null,
                isInfoShare: null,
                isShootingArrest: null,
                isNIBIN: null,
                isNJPOP: null,
                isParole: null,
                isViolentPerson: null,
                NJPopNumOld: null,
                isGunOffender: null,
                isGunStat: null
            }

            let response = await RunGetGRAILReferral(checkGRAIL)
            let grail = response?.AnyTable
            if (grail == null || (grail && grail.length == 0)) {
                let personObj: any = {
                    PersonInfo: person,
                    ImageInfo: personImageInfo,
                    SCORInfo: crumbScoreInfo,
                    ArrestInfo: arrestInfo,
                    NJPOPInfo: njpopInfo,
                    DNAInfo: codisDNAInfo,
                    InfoCopInfo: infocopInfo,
                    ETicketInfo: eTicketInfo,
                    CADRMSInfo: cadrmsInfo,
                    StopDataInfo: stopDataInfo,
                    ParkMobileInfo: parkMobileInfo,
                    TowedVehicleInfo: towedVehicleInfo,
                    ETraceInfo: eTraceInfo,
                    NJTraceInfo: njTraceInfo,
                    NIBINInfo: nibinInfo,
                    GunStatInfo: gunStatInfo,
                    GunOffenderInfo: gunOffenderInfo,
                    GunRegistration: gunRegistrationInfo,
                    PrisonInfo: prisonInfo,
                    ParoleInfo: paroleInfo,
                    JailInfo: jailInfo,
                    ViolentInfo: violentInfo,
                    ProfessionInfo: professionInfo,
                    ALPRInfo: alprInfo,
                    DLInfo: DLNo,
                    AddressInfo: phoneAddressInfo?.AnyData,
                    PhoneInfo: phoneAddressInfo?.AdditionalData,
                    PlateInfo: plateInfo,
                    AssociatedPeopleInfo: associatedPeopleInfo,
                }
                let l = new JAOS()
                let hashID = l.objToStack(personObj)
                window.open('/GRAIL?Hash=' + hashID, '_blank')
            }
            else {
                setAlertType("error")
                setAlertText(("An Incomplete Referral has already been created for ") + person?.FName + ' ' + person?.LName + " Please Edit through referral search.")
                setAlertOpen(true)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const handleAnalysis = () => {
        let personObj: any = {
            PersonInfo: person,
            ImageInfo: personImageInfo,
            SCORInfo: crumbScoreInfo,
            ArrestInfo: arrestInfo,
            NJPOPInfo: njpopInfo,
            DNAInfo: codisDNAInfo,
            InfoCopInfo: infocopInfo,
            ETicketInfo: eTicketInfo,
            CADRMSInfo: cadrmsInfo,
            StopDataInfo: stopDataInfo,
            ParkMobileInfo: parkMobileInfo,
            TowedVehicleInfo: towedVehicleInfo,
            ETraceInfo: eTraceInfo,
            NJTraceInfo: njTraceInfo,
            NIBINInfo: nibinInfo,
            PrisonInfo: prisonInfo,
            ParoleInfo: paroleInfo,
            JailInfo: jailInfo,
            MissingPersonInfo: missingPersonInfo,
            GunStatInfo: gunStatInfo,
            GunOffenderInfo: gunOffenderInfo,
            GunRegistration: gunRegistrationInfo,
            BOLOInfo: boloInfo,
            ViolentInfo: violentInfo,
            ProfessionInfo: professionInfo,
            ALPRInfo: alprInfo,
            DLInfo: DLNo,
            AddressInfo: phoneAddressInfo?.AnyData,
            PhoneInfo: phoneAddressInfo?.AdditionalData,
            PlateInfo: plateInfo,
            AssociatedPeopleInfo: associatedPeopleInfo,
        }

        let l = new JAOS()
        let hashID = l.objToStack(personObj)
        window.open('/PersonAnalysis?Hash=' + hashID, '_blank')
    }
    const handleTimeLine = () => {
        let personObj: any = {
            PersonInfo: person,
            ImageInfo: personImageInfo,
            SCORInfo: crumbScoreInfo,
            ArrestInfo: arrestInfo,
            NJPOPInfo: njpopInfo,
            DNAInfo: codisDNAInfo,
            InfoCopInfo: infocopInfo,
            ETicketInfo: eTicketInfo,
            CADRMSInfo: cadrmsInfo,
            StopDataInfo: stopDataInfo,
            ParkMobileInfo: parkMobileInfo,
            TowedVehicleInfo: towedVehicleInfo,
            ETraceInfo: eTraceInfo,
            NJTraceInfo: njTraceInfo,
            NIBINInfo: nibinInfo,
            PrisonInfo: prisonInfo,
            ParoleInfo: paroleInfo,
            JailInfo: jailInfo,
            MissingPersonInfo: missingPersonInfo,
            GunStatInfo: gunStatInfo,
            GunOffenderInfo: gunOffenderInfo,
            GunRegistration: gunRegistrationInfo,
            BOLOInfo: boloInfo,
            ViolentInfo: violentInfo,
            ProfessionInfo: professionInfo,
            ALPRInfo: alprInfo,
            DLInfo: DLNo,
            AddressInfo: phoneAddressInfo?.AnyData,
            PhoneInfo: phoneAddressInfo?.AdditionalData,
            PlateInfo: plateInfo,
            AssociatedPeopleInfo: associatedPeopleInfo,
        }

        let l = new JAOS()
        let hashID = l.objToStack(personObj)
        window.open('/PersonTimeLine?Hash=' + hashID, '_blank')
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    const HandleSuppClose = () => {
        setPirPerson({})
        setPirCCNList([])
        setShowPIR(false)
    }

    const handleMap = () => {
        //todo make all pins. send to geocodeDisplay
        let pins: any[] = []
        //make address pins
        if (allAddresses) {
            for (let p of allAddresses) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        address: p.Address,
                        city: p.City,
                        zip: p.Zip,
                        tool: "home"
                    })
                }
            }
        }
        //make arrest pins
        if (arrestInfo) {
            for (let p of arrestInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DTOFARREST,
                        statute: p.STATUTE,
                        arrestedBy: p.ORIGINATINGORINAME,
                        tool: "arrest"
                    })
                }
            }
        }
        //make njpop pins
        if (njpopInfo) {
            for (let p of njpopInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIn,
                        offense: p.IncidentID,
                        weapon: p.W_Status + ' ' + p.W_Class + ' ' + p.W_Type,
                        tool: "njpop"
                    })
                }
            }
        }
        //make infocop pins
        if (infocopInfo) {
            for (let p of infocopInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIN,
                        offense: p.ReqID,
                        arrestedBy: p.Dept,
                        tool: "infocop"
                    })
                }
            }
        }
        //make eticket pins
        if (eTicketInfo) {
            for (let p of eTicketInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.eCreated,
                        dept: p.eMunicipality,
                        plate: p.ePlateNbr,
                        tool: "eticket"
                    })
                }
            }
        }
        //make cadrms pins
        if (cadrmsInfo) {
            for (let p of cadrmsInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIn,
                        offense: p.AgencyCaseNo,
                        role: p.RoleDesc,
                        tool: "cadrms"
                    })
                }
            }
        }
        if (stopDataInfo) {
            for (let p of stopDataInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIn,
                        offense: p.AgencyCaseNo,
                        role: p.RoleDesc,
                        tool: "cadrms"
                    })
                }
            }
        }
        //make etrace pins
        if (eTraceInfo) {
            for (let p of eTraceInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIn,
                        offense: p.AgencyCaseNo,
                        weapon: p.W_TYPE_DESC,
                        tool: "etrace"
                    })
                }
            }
        }
        //make park data pins
        if (parkMobileInfo) {
            for (let p of parkMobileInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        city: p.zoneid,
                        date: p.startdatetime,
                        tool: "parkmobile"
                    })
                }
            }
        }
        //make towed vehicle data pins
        if (towedVehicleInfo) {
            for (let p of towedVehicleInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        //city: p.zoneid,
                        date: p.startdatetime,
                        tool: "towedvehicle"
                    })
                }
            }
        }
        /*//make njguntrace data pins
        if(parkMobileInfo) {
            for (let p of parkMobileInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999) ){
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        city: p.zoneid,
                        date: p.startdatetime,
                        tool: "njtrace"
                    })
                }
            }
        }*/
        //make alpr data pins
        if (alprInfo) {
            for (let p of alprInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.red_TimeStamp,
                        name: p.src_Description,
                        plate: p.red_vrm,
                        tool: "alpr"
                    })
                }
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const handlePOI = () => {
        var POI = {
            PlateNumber: '',
            PlateSt: '',
            PlateNumber2: '',
            PlateSt2: '',
            PlateNumber3: '',
            PlateSt3: '',
            VIN: '',
            FName: '',
            MName: '',
            LName: '',
            DOB: '',
            DLNo: '',
            DLSt: '',
            SSN: '',
            FBINo: '',
            SBINo: '',
            EmpID: user.EmpID,
            DeptCode: user.Dept,
            EditStatus: 'PartialEdit',
            UniqueID: 0,
        }
        if (DLNo) {
            //what does this even do very inefficent?
            for (var i = 0; i < DLNo?.length; i++) {
                let data = DLNo[i];
                POI.DLNo = data.DLNo;
                POI.DLSt = data.DLSt;
                break;
            }
        }

        if (plateInfo) {
            for (i = 0; i < plateInfo?.length; i += 1) {
                var data = plateInfo[i];
                if (data.PlateNo) {
                    if (!POI.PlateNumber) {
                        POI.PlateNumber = data.PlateNo;
                        POI.PlateSt = data.PlateSt;
                        continue;
                    }
                    if (data.PlateNo !== POI.PlateNumber && !POI.PlateNumber2) {
                        POI.PlateNumber2 = data.PlateNo;
                        POI.PlateSt2 = data.PlateSt;
                        continue;
                    }
                    if (data.PlateNo !== POI.PlateNumber && data.PlateNo !== POI.PlateNumber2 && !POI.PlateNumber3) {
                        POI.PlateNumber3 = data.PlateNo;
                        POI.PlateSt3 = data.PlateSt;
                        //once all 3 are poluated come out of the loop
                        break;
                    }
                }
            }
        }

        if (eTicketInfo) {
            for (i = 0; i < eTicketInfo?.length; i += 1) {
                data = eTicketInfo[i];
                if (data.ePlateNbr) {
                    if (!POI.PlateNumber) {
                        POI.PlateNumber = data.ePlateNbr;
                        POI.PlateSt = data.ePlateSt;
                        continue;
                    }
                    if (data.ePlateNbr !== POI.PlateNumber && !POI.PlateNumber2) {
                        POI.PlateNumber2 = data.ePlateNbr;
                        POI.PlateSt2 = data.ePlateSt;
                        continue;
                    }
                    if (data.ePlateNbr !== POI.PlateNumber && data.ePlateNbr !== POI.PlateNumber2 && !POI.PlateNumber3) {
                        POI.PlateNumber3 = data.ePlateNbr;
                        POI.PlateSt3 = data.ePlateSt;
                        //once all 3 are poluated come out of the loop
                        break;
                    }
                }
            }
        }

        POI.FName = person?.FName ? person?.FName : "";
        POI.MName = person?.MName ? person?.MName : "";
        POI.LName = person?.LName ? person?.LName : "";
        POI.DOB = person?.DOB ? person?.DOB : '';
        if (person?.SSN1) POI.SSN = person?.SSN1;
        //if (person?.VIN) POI.VIN = person?.VIN;
        if (person?.FBI) POI.FBINo = person?.FBI;
        if (person?.SBINo) POI.SBINo = person?.SBINo;

        let l = new JAOS()
        let hashID = l.objToStack(POI)
        window.open('/addPOI?Hash=' + hashID, '_blank')
    }
    const handleSocial = async () => {
        //Call API  Account / Social
        // retrieve RedirectLink1, 2
        //Call 2 new Tabs
        let r = await RunSocialMedia(query)
        window.open(r.RedirectLink, '_blank')
        window.open(r.RedirectLink_Additional, '_blank')
    }

    const handleCorrection = async () => {
        //todo
        let r = await RunCorrection(query)
        window.open(r.RedirectLink, '_blank')
    }

    const handlePromisGavel = async () => {
        //todo
        let r = await RunPromisGavel(query)
        window.open(r.RedirectLink, '_blank')
    }

    const handleShareLink = async () => {
        let r = await RunExpiryToken(query)
        let link = Main_URL + "/SharedPerson/" + r.AnyData;  //need to set demo/prod
        navigator.clipboard.writeText(link)
        setShareLink(link);
        setExpiryOpen(true)
    }

    const handleNCIC = () => {
        var NCIC = {
            UniqueID: 0,
            PlateNumber: '',
            PlateSt: '',
            PlateNumber2: '',
            PlateSt2: '',
            PlateNumber3: '',
            PlateSt3: '',
            PlateType: '',
            VehicleType: '',
            DLNo: '',
            DLSt: '',
            SSN: '',
            VIN: '',
            FBINo: '',
            SBINo: '',
            SBISt: '',
            FName: '',
            MName: '',
            LName: '',
            DOB: '',
            SEX: '',
            CaseNo: '',
            SearchNotes: '',
            Response: '',
            SearchType: '',
            EmpID: user.EmpID,
            DeptID: user.Dept,
            DeviceORI: '',
            SiteName: '',
            CreateUID: '',
            CreateDate: null,
            UpdUID: '',
            UpdDate: null,
            Active: '',
            EditStatus: 'PartialEdit',
        }
        if (DLNo) {
            //what does this even do very inefficent?
            for (var i = 0; i < DLNo?.length; i++) {
                let data = DLNo[i];
                NCIC.DLNo = data.DLNo;
                NCIC.DLSt = data.DLSt;
                break;
            }
        }

        if (plateInfo) {
            for (i = 0; i < plateInfo?.length; i += 1) {
                var data = plateInfo[i];
                if (data.PlateNo) {
                    if (!NCIC.PlateNumber) {
                        NCIC.PlateNumber = data.PlateNo;
                        NCIC.PlateSt = data.PlateSt;
                        continue;
                    }
                    if (data.PlateNo !== NCIC.PlateNumber && !NCIC.PlateNumber2) {
                        NCIC.PlateNumber2 = data.PlateNo;
                        NCIC.PlateSt2 = data.PlateSt;
                        continue;
                    }
                    if (data.PlateNo !== NCIC.PlateNumber && data.PlateNo !== NCIC.PlateNumber2 && !NCIC.PlateNumber3) {
                        NCIC.PlateNumber3 = data.PlateNo;
                        NCIC.PlateSt3 = data.PlateSt;
                        //once all 3 are poluated come out of the loop
                        break;
                    }
                }
            }
        }

        if (eTicketInfo) {
            for (i = 0; i < eTicketInfo?.length; i += 1) {
                data = eTicketInfo[i];
                if (data.ePlateNbr) {
                    if (!NCIC.PlateNumber) {
                        NCIC.PlateNumber = data.ePlateNbr;
                        NCIC.PlateSt = data.ePlateSt;
                        continue;
                    }
                    if (data.ePlateNbr !== NCIC.PlateNumber && !NCIC.PlateNumber2) {
                        NCIC.PlateNumber2 = data.ePlateNbr;
                        NCIC.PlateSt2 = data.ePlateSt;
                        continue;
                    }
                    if (data.ePlateNbr !== NCIC.PlateNumber && data.ePlateNbr !== NCIC.PlateNumber2 && !NCIC.PlateNumber3) {
                        NCIC.PlateNumber3 = data.ePlateNbr;
                        NCIC.PlateSt3 = data.ePlateSt;
                        //once all 3 are poluated come out of the loop
                        break;
                    }
                }
            }
        }

        NCIC.FName = person?.FName ? person?.FName : "";
        NCIC.MName = person?.MName ? person?.MName : "";
        NCIC.LName = person?.LName ? person?.LName : "";
        NCIC.DOB = person?.DOB ? person?.DOB : '';
        NCIC.SEX = person?.SEX ? person?.SEX : "";
        if (person?.SSN1) NCIC.SSN = person?.SSN1;
        //if (person?.VIN) NCIC.VIN = person?.VIN;
        if (person?.FBI) NCIC.FBINo = person?.FBI;
        if (person?.SBINo) NCIC.SBINo = person?.SBINo;

        let l = new JAOS()
        let hashID = l.objToStack(NCIC)

        //if(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL){
        //    window.open('/wales?Hash=' + hashID, '_blank')
        //}else{
        window.open('/ncic?Hash=' + hashID, '_blank')
        //}
    }

    const handleTSC = () => {
        var TSC = {
            PlateNumbers: [''],
            PlateSt: [''],
            eCitation: '',
            eCitationSource: '',
            PlateType: '',
            VehicleType: '',
            DLNo: '',
            DLSt: '',
            SSN: '',
            VIN: '',
            FINNo: '',
            SINNo: '',
            SBISt: '',
            FName: '',
            MName: '',
            LName: '',
            DOB: '',
            SEX: '',
            CaseNo: '',
            SearchNotes: '',
            Response: '',
            SearchType: '',
            EmpID: user.EmpID,
            DeptID: user.Dept,
            DeviceORI: '',
            SiteName: '',
            CreateUID: '',
            CreateDate: null,
            UpdUID: '',
            UpdDate: null,
            Active: '',
            AKAInfo: akaInfo,
            Address: phoneAddressInfo?.AnyData,
            Phone: phoneAddressInfo?.AdditionalData,
            Arrests: arrestInfo
        }
        if (DLNo && DLNo.length > 0) {
            let data = DLNo[0];
            TSC.DLNo = data.DLNo;
            TSC.DLSt = data.DLSt;
        }
        if (plateInfo && plateInfo.length > 0) {
            for (var i = 0; i < plateInfo?.length; i++) {
                var data = plateInfo[i];
                TSC.PlateNumbers.push(data.PlateNo);
                TSC.PlateSt.push(data.PlateSt);
            }
        }
        if (eTicketInfo && eTicketInfo.length > 0) {
            for (var j = 0; j < eTicketInfo?.length; j++) {
                var eData = eTicketInfo[j];
                if (!TSC.PlateNumbers.includes(eData.ePlateNbr)) {
                    TSC.PlateNumbers.push(eData.ePlateNbr);
                    TSC.PlateSt.push(eData.ePlateSt);
                    TSC.eCitation += eData.TicketNmbr + ", ";
                    TSC.eCitationSource += eData.eMunicipality + ", ";
                }
            }
        }

        if (streetNum !== "" &&
            streetName !== "" &&
            city !== "" &&
            state !== "" &&
            zip !== "") {
            if (TSC?.Address && TSC?.Address?.length > 0) {
                TSC?.Address?.push({
                    Street: streetNum + " " + streetName,
                    City: city,
                    State: state,
                    ZIP: zip,
                    Main: "Yes"
                })
            }
            else {
                TSC.Address = []
                TSC?.Address?.push({
                    Street: streetNum + " " + streetName,
                    City: city,
                    State: state,
                    ZIP: zip,
                    Main: "Yes"
                })
            }
        }

        TSC.FName = person?.FName ?? "";
        TSC.MName = person?.MName ?? "";
        TSC.LName = person?.LName ?? "";
        TSC.DOB = person?.DOB ?? "";
        if (person?.SSN1) TSC.SSN = person?.SSN1;
        if (person?.FBI) TSC.FINNo = person?.FBI;
        if (person?.SBINo) TSC.SINNo = person?.SBINo;
        if (sbiState) TSC.SBISt = sbiState;
        if (person?.DLNo) TSC.DLNo = person?.DLNo;
        if (person?.DLSt) TSC.DLSt = person?.DLSt;

        let l = new JAOS()
        let hashID = l.objToStack(TSC);
        window.open('/tools/terrorist_screening?person=' + hashID, '_blank');
    }

    const handleGunOffender = () => {
        if (!(gunOffenderInfo.length > 0)) {
            var GunOffender = {
                PlateNumbers: [''],
                SCOR: crumbScoreInfo,
                PlateSt: [''],
                eCitation: '',
                eCitationSource: '',
                PlateType: '',
                VehicleType: '',
                DLNo: '',
                DLSt: '',
                SSN: '',
                VIN: '',
                FINNo: '',
                SINNo: '',
                SBISt: '',
                FName: '',
                MName: '',
                LName: '',
                DOB: '',
                SEX: '',
                CaseNo: '',
                SearchNotes: '',
                Response: '',
                SearchType: '',
                EmpID: user.EmpID,
                DeptID: user.Dept,
                DeviceORI: '',
                SiteName: '',
                CreateUID: '',
                CreateDate: null,
                UpdUID: '',
                UpdDate: null,
                Active: '',
                AKAInfo: akaInfo,
                Address: phoneAddressInfo?.AnyData,
                Phone: phoneAddressInfo?.AdditionalData,
                Arrests: arrestInfo
            }

            if (streetNum !== "" &&
                streetName !== "" &&
                city !== "" &&
                state !== "" &&
                zip !== "") {
                if (GunOffender?.Address && GunOffender?.Address?.length > 0) {
                    GunOffender?.Address?.push({
                        Street: streetNum + " " + streetName,
                        City: city,
                        State: state,
                        ZIP: zip,
                        Main: "Yes"
                    })
                }
                else {
                    GunOffender.Address = []
                    GunOffender?.Address?.push({
                        Street: streetNum + " " + streetName,
                        City: city,
                        State: state,
                        ZIP: zip,
                        Main: "Yes"
                    })
                }
            }

            GunOffender.FName = person?.FName ?? "";
            GunOffender.MName = person?.MName ?? "";
            GunOffender.SEX = person?.SEX ?? "";
            GunOffender.LName = person?.LName ?? "";
            GunOffender.DOB = person?.DOB ?? "";
            if (person?.SSN1) GunOffender.SSN = person?.SSN1;
            if (person?.FBI) GunOffender.FINNo = person?.FBI;
            if (person?.SBINo) GunOffender.SINNo = person?.SBINo;
            if (sbiState) GunOffender.SBISt = sbiState;
            if (person?.DLNo) GunOffender.DLNo = person?.DLNo;
            if (person?.DLSt) GunOffender.DLSt = person?.DLSt;

            console.log("Gun Offender Info: ", GunOffender)

            let l = new JAOS()
            let hashID = l.objToStack(GunOffender);
            window.open('/tools/gun_offender_entry?person=' + hashID, '_blank');
        }
        else {
            let gunOffenderData = gunOffenderInfo[0]
            window.open('/tools/gun_offender_entry?ID=' + gunOffenderData?.ID, '_blank')
        }
    }

    const handleUpdatePersonMain = () => {
        let today = new Date()
        let search_query: SearchDL = {
            City: "",
            CrumbScorePercentile: "",
            DLNo: "",
            DLSt: "",
            DOB: "",
            DarID: "",
            Dept: "",
            DeptORI: "",
            ETicket: "",
            EmpID: "",
            FBI: person?.FBI,
            FirstName: "",
            FromDate: today.toLocaleDateString(),
            GANGRELATED: "",
            LastName: "",
            LoggedDept: "",
            LoggedEmpID: "",
            MiddleName: "",
            PersonID: person?.PersonID,
            QueryType: person?.TableType,
            SBINo: (person?.TableID) ? person?.TableID : "",  //Pass UniqueTableID in SBI# (it might be Prison, NJPOP etc)
            SEX: "",
            ToDate: today.toLocaleDateString()
        }

        let l = new JAOS()
        let hashID = l.objToStack(search_query)
        window.open('/PersonMainRecord?Hash=' + hashID, '_blank')
    }

    const handleProfileReport = async () => {
        let profileReport: any = {
            PersonInfo: person,
            ImageInfo: personImageInfo,
            ArrestInfo: arrestInfo,
            SCORInfo: crumbScoreInfo,
            AddressInfo: phoneAddressInfo?.AnyData,
            PhoneInfo: phoneAddressInfo?.AdditionalData,
            DNAInfo: codisDNAInfo,
            NJPOPInfo: njpopInfo,
            InfoCopInfo: infocopInfo,
            ETicketInfo: eTicketInfo,
            CADRMSInfo: cadrmsInfo,
            ParkMobileInfo: parkMobileInfo,
            ETraceInfo: eTraceInfo,
            NJTraceInfo: njTraceInfo,
            NIBINInfo: nibinInfo,
            PrisonInfo: prisonInfo,
            ParoleInfo: paroleInfo,
            JailInfo: jailInfo,
            GunRegistration: gunRegistrationInfo,
            ViolentInfo: violentInfo,
            ProfessionInfo: professionInfo,
            ALPRInfo: alprInfo,
            DLInfo: DLNo,
            PlateInfo: plateInfo,
            AssociatedPeopleInfo: associatedPeopleInfo,
        }

        let l = new JAOS()
        let hash = l.objToStack(profileReport);
        window.open('/tools/profile_report/?person=' + hash);
    }

    const handleProfileReportPrint = async () => {
        if (profileReportNum) {
            GetProfileReportPDF(profileReportNum)
        }
    }

    const handlePIR = async () => {
        //This only works for DC currently due to ccnList being created from mark43List.
        //if you want to make it work on NJ have to walk everything to get all CCNs
        let mark43PersonInfo = null
        let caseNumberList = []
        if (m43PersonInfo && m43PersonInfo.length > 0) {
            mark43PersonInfo = m43PersonInfo[0]
            mark43PersonInfo.AdditionalPhoneInfo = phoneStringArray.join() ?? ""
            mark43PersonInfo.AdditionalAKAInfo = nicknamesStringArray.join() ?? ""
            for (var item of m43PersonInfo) {
                caseNumberList.push(item.reportNumber)
            }
        }
        let pir: any = {
            PersonInfo: person,
            ImageInfo: personImageInfo,
            SCORInfo: crumbScoreInfo,
            ArrestInfo: arrestInfo,
            NJPOPInfo: njpopInfo,
            DNAInfo: codisDNAInfo,
            InfoCopInfo: infocopInfo,
            ETicketInfo: eTicketInfo,
            CADRMSInfo: cadrmsInfo,
            StopDataInfo: stopDataInfo,
            ParkMobileInfo: parkMobileInfo,
            ETraceInfo: eTraceInfo,
            NJTraceInfo: njTraceInfo,
            NIBINInfo: nibinInfo,
            PrisonInfo: prisonInfo,
            ParoleInfo: paroleInfo,
            JailInfo: jailInfo,
            MissingPersonInfo: missingPersonInfo,
            GunStatInfo: gunStatInfo,
            GunOffenderInfo: gunOffenderInfo,
            GunRegistration: gunRegistrationInfo,
            BOLOInfo: boloInfo,
            Mark43PersonInfo: mark43PersonInfo ?? null,
            ViolentInfo: violentInfo,
            ProfessionInfo: professionInfo,
            ALPRInfo: alprInfo,
            DLInfo: DLNo,
            AddressInfo: phoneAddressInfo?.AnyData,
            PhoneInfo: phoneAddressInfo?.AdditionalData,
            PlateInfo: plateInfo,
            AssociatedPeopleInfo: associatedPeopleInfo,
            SiteState: GetDefaultStateForSite(SiteName),
            AKAInfo: akaInfo,
            caseNumberList: caseNumberList
        }
        var pirList = await GetPersonRelatedPIR(pir)
        if (pirList?.length > 0) {
            setPirPerson(pir);
            setPirCCNList(pirList)
            setShowPIR(true);

            setAlertType("info")
            setAlertText("Please Select a CCN to add this person to an existing Preliminary Report")
            setAlertOpen(true)
        }
        else {
            setAlertType("error")
            setAlertText("No CCNs found matching existing Preliminary Reports")
            setAlertOpen(true)
        }
    }

    const handleSuppSubmit = (r: any) => {
        setPirPerson({})
        setPirCCNList([])
        setShowPIR(false)

        setAlertType("success")
        setAlertText("New Supplement Added Under PIR: " + r?.Summary_CCN)
        setAlertOpen(true)
    };

    let grid: Grid | null;

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const pirDropdown = () => {
        <>
            <div>

            </div>
        </>
    }

    const getArrestCount = () => {
        if ((SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName == SITE.NYPROD || SiteName == SITE.NYDEMO || SiteName == SITE.NYLOCAL)) {
            if (arrestInfo && arrestInfo.length > 0) {
                let arrestCount = 0
                let caseNo = []
                for (let arrest of arrestInfo) {
                    if (caseNo.findIndex((a) => a === arrest.AgencyCaseNo) === -1) {
                        arrestCount++
                        caseNo.push(arrest.AgencyCaseNo)
                    }
                }
                return arrestCount
            }
        }
        else {
            return (crumbScoreInfo?.Arrest ?? "")
        }

        return ""
    }

    const downloadFile = async (file: any, index: number) => {
        let temp = []
        if (file.Content != null) {
            await base64_to_blob(file.Content, file.FileEncoding).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
        else if (file.ID) {
            temp = [...MainRecordFileOrImageInfo]
            await GetFileContentPersonMain(temp[index])
        }
    }

    const HandleFileSupplementalValueChange = (idx: any, field: any, value: any) => {
        try {
            let tArray = [...fileSupplementalList]
            tArray[idx][field] = value
            setFileSupplementalList(tArray)
        } catch (e) {
            console.log(e)
        }
    }
    const HandleFileSupplementalSubmit = async () => {
        if (fileSupplementalList && fileSupplementalList.length > 0) {
            for (const file of fileSupplementalList) {
                try {
                    let c: any = await readFileAsDataURL(file)
                    if (c && c?.includes("base64,"))
                        c = c.split("base64,")[1]

                    let record = {
                        PersonID: person?.PersonID,
                        FirstName: person?.FName,
                        MiddleName: person?.MName,
                        LastName: person?.LName,
                        DOB: person?.DOB,
                        SSN: person?.SSN1,
                        SBINo: person?.SBINo,
                        FBI: person?.FBI,
                        FileName: file?.name,
                        FileEncoding: file?.type,
                        Content: c,
                        FileCategory: file?.category,
                        FileCategoryOther: file?.categoryOther,
                        FileDescription: file?.description,
                        FileShare: false
                    }
                    await RunPerson_SaveSupplementalFile(record)
                } catch (e) {
                    console.log(e)
                }
            }
        }

        reloadPersonSupplementFiles()

        setFileSupplementalList([])
        setFileSupplementalOpen(false)
    }

    const reloadPersonSupplementFiles = () => {
        RunPerson_SupplementalFile(query).then(response => {
            if (response.AnyData)
                setSupplementFilesInfo(response.AnyData);
        })
    }

    const checkStatus = () => {
        if (crumbScoreInfo && crumbScoreInfo?.Status) {
            if (crumbScoreInfo?.Status === 'Dead' || crumbScoreInfo?.Status === 'Excercise')
                return true
            if (!jailInfo || jailInfo.length === 0) {
                return true
            }
            return !(!jailInfo[0].RELEASE_DATE || jailInfo[0].RELEASE_DATE?.toString()?.length === 0);
        }
        return false
    }

    const getLoadingStates = () => {
        let state = []
        state.push({ Type: 'Arrest', State: arrestLoading })
        state.push({ Type: 'NJ POP', State: njpopLoading })
        state.push({ Type: 'CODIS', State: codisDNALoading })
        state.push({ Type: 'INFO-COP', State: infocopLoading })
        state.push({ Type: 'E-Ticket', State: eTicketLoading })
        state.push({ Type: 'CAD/RMS', State: cadrmsLoading })
        state.push({ Type: 'Park Data', State: parkMobileLoading })
        state.push({ Type: 'E-Trace', State: eTraceLoading })
        state.push({ Type: 'NJ Gun Trace', State: njTraceLoading })
        state.push({ Type: 'Prison', State: prisonLoading })
        state.push({ Type: 'Parole', State: paroleLoading })
        state.push({ Type: 'Jail', State: jailLoading })
        state.push({ Type: 'Missing Person', State: missingPersonLoading })
        state.push({ Type: 'Person Supplemental', State: supplementFilesLoading })
        state.push({ Type: 'Violent', State: violentLoading })
        state.push({ Type: 'Profession', State: professionLoading })
        state.push({ Type: 'ALPR', State: alprLoading })
        state.push({ Type: 'Referral', State: referralLoading })
        state.push({ Type: 'Profile Report', State: profileReportLoading })
        return state
    }

    return (
        <div className={"mt-5 mb-5 border border-dashed"} style={{ maxWidth: "85vw" }}>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%', height: '25%', fontSize: '1.2rem' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <div className={"w-min-max bg-blue-100 p-2 rounded"}>
                <h4 style={{ "fontWeight": "bold" }}>Backtrace Report</h4>
                {isUserPermission('Logo') ? <>
                    <div style={{ display: 'inline-block' }}>
                        <img src={RTCC} alt={''} style={{ display: 'inline', width: '73px', height: '73px;' }} />
                        <img src={CorrStat} alt={''} style={{ display: 'inline', marginLeft: '5px' }} />
                    </div>
                </> : <></>}
            </div>
            <div className="grid grid-cols-3 gap-x-0.5 gap-y-2">
                <div className="mt-3">
                    <b>Name:</b> {person?.FName} {person?.MName} {person?.LName}
                    <span>
                        {person?.MainRecord === 'Y' ?
                            <Tooltip title="Offender Name/FBI#/SBI# Changed" placement="top" arrow>
                                <IconButton size={"small"} style={{ color: "gray" }}><img src={MainRecordIcon} style={{ height: 22, width: 22, marginRight: 4 }} /></IconButton>
                            </Tooltip>
                            : <></>
                        }
                    </span>
                    <span>
                        {isMainRecordEditor(person.TableType) ?
                            <Tooltip title="Edit Offender Name/FBI#/SBI#" placement="top" arrow>
                                <IconButton size={"small"} style={{ color: "gray" }} onClick={handleUpdatePersonMain}><img src={EditIcon} style={{ height: 22, width: 22, marginRight: 4 }} /></IconButton>
                            </Tooltip>
                            : <></>
                        }
                    </span>
                    <br />
                    <b>DOB:</b> {person?.DOB ? formatDate(DateToString(new Date(person?.DOB)).toLocaleString()) : ""}
                    <br />
                    <b>SEX:</b> {person?.SEX}
                    <br />

                    {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <>
                        <b>City:</b> {person?.City}
                        <br />
                    </>}

                    {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && m43PersonInfo && m43PersonInfo.length > 0 && <>
                        {m43PersonInfo[0].SSN && <><b>SSN:</b> {m43PersonInfo[0].SSN}<br /></>}
                        {m43PersonInfo[0].race && <><b>RACE:</b> {m43PersonInfo[0].race}<br /></>}
                        {m43PersonInfo[0].ethnicity && <><b>ETHNICITY:</b> {m43PersonInfo[0].ethnicity}<br /></>}
                        {m43PersonInfo[0].eyeColor && <><b>EYE COLOR:</b> {m43PersonInfo[0].eyeColor}<br /></>}
                        <b>Last Known Address:</b><br /> {m43PersonInfo[0].streetNumber} {m43PersonInfo[0].streetName} {m43PersonInfo[0].CITY} {m43PersonInfo[0].STATE} {m43PersonInfo[0].Zip}
                        <br />

                    </>}

                    {checkStatus() ? <><b>Status:</b> <span className={crumbScoreInfo?.Status === 'Dead' ? "font-bold text-base bg-red-500" : "font-bold text-base bg-yellow-300"}>
                        {crumbScoreInfo?.Status === 'Dead' ? 'DECEASED' : crumbScoreInfo?.Status === 'Excercise' ? 'EXERCISE' : <span> INCARCERATED till {formatDate(crumbScoreInfo?.FutureReleaseDate)}</span>}</span></> : <></>}
                    {jailInfo && jailInfo.length > 0 ?
                        <div style={{ border: '1px solid lightgray', borderRadius: '6px', marginLeft: '4px', width: '70%', marginTop: '4px', marginBottom: '10px' }}>
                            <div className={"font-bold text-center"} style={{ textDecoration: 'underline' }}>JAIL INFO SUMMARY</div>
                            <div className={"ml-3"}>
                                <span className={!jailInfo[0]?.RELEASE_DATETIME ? "bg-red-500 font-bold" : 'font-bold'} >COMMITMENT DATE/TIME:</span>
                                <span> {formatDT(jailInfo[0]?.COMMITMENT_DATETIME)}</span>
                            </div>
                            <div className={"ml-3"}>
                                <span className={"font-bold"}>RELEASE DATE/TIME:</span>
                                <span> {formatDT(jailInfo[0]?.RELEASE_DATETIME)}</span>
                            </div>
                            {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <div className={"ml-3"}>
                                <span className={"font-bold"}>CUSTODY STATUS:</span>
                                <span> {jailInfo[0]?.CUSTODY_STATUS}</span>
                            </div>}
                            <div className={"ml-3"}>
                                <span className={"font-bold"}>{`${GetStringNameForSite(SiteName, 'JURISDICTION')}`}:</span>
                                <span> {jailInfo[0]?.INMATE_COUNTY}</span>
                            </div>
                        </div>

                        : <></>
                    }
                </div>
                <div>
                    {(personImageInfo) ? personImageInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((image, idx) => (<span><img src={image.Image} height={180} width={150} /></span>)) : <></>}
                    <span>
                        {(personImageInfo && personImageInfo?.length > 1) ?
                            <span>
                                <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} size="small" onClick={() => setPage(0)}><DoubleArrowSharpIcon fontSize="small" className="transform rotate-180" /></Button>
                                <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} onClick={(page - 1 >= 0) ? () => setPage(page - 1) : () => ('')}><KeyboardArrowLeftSharpIcon /></Button>
                                {personImageInfo.map((image, idx) => <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} onClick={() => setPage(idx)}>{idx + 1}</Button>)}
                                <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} onClick={(page + 1 < personImageInfo.length) ? () => setPage(page + 1) : () => ('')}><KeyboardArrowRightSharpIcon /></Button>
                                <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} size="small" onClick={() => setPage(personImageInfo.length - 1)}><DoubleArrowSharpIcon fontSize="small" /></Button>
                            </span>
                            :
                            <></>}
                    </span>
                </div>
                {!PrintMode ? <>
                    <div>
                        <ButtonGroup color="inherit">
                            <Tooltip title="Print Arrest Person Activities" placement="top" arrow >
                                <IconButton size={"small"} style={{ color: "gray" }} onClick={() => { RunALPRQuery("ALPR_NOAPICALL", query); handleNavigator('Print'); }}><PrintIcon color="primary" style={{ height: 22, width: 22, marginRight: 4 }} />{/*<b>Print</b>*/}</IconButton>
                            </Tooltip>

                            {IsPanelEnabled(PERSON_PANEL.PERSON_ATTACHMENT, ENABLED_PANELS) && <>
                                <Tooltip title="Attach Supplemental Report" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={() => { setFileSupplementalOpen(true) }}>
                                        <UploadFileIcon style={{ height: 22, width: 22, marginRight: 4 }} />
                                    </IconButton>
                                </Tooltip>
                            </>}

                            <Tooltip title="Show Arrest Person Activities on Map" placement="top" arrow>
                                <IconButton size={"small"} style={{ color: "gray" }}
                                    onClick={() => handleNavigator('Map')}><MapIcon color="success" style={{
                                        height: 22,
                                        width: 22,
                                        marginRight: 4
                                    }} />{/*<b>Map</b>*/}</IconButton>
                            </Tooltip>

                            {isUserPermission('POI') ?
                                <Tooltip title="Create Person of Interest" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={() => handleNavigator('POI')}><img src={POIIcon} style={{ height: 22, width: 22, marginRight: 4 }} />{/*<b>POI</b>*/}</IconButton>
                                </Tooltip> : <></>}
                            {isUserPermission('NCIC') ?
                                <Tooltip title={`${GetStringNameForSite(SiteName, 'NCIC')} Warrant Details`} placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={() => handleNavigator('NCIC')}><img src={NCICIcon} style={{ height: 22, width: 22, marginRight: 4 }} />{/*<b>NCIC</b>*/}</IconButton>
                                </Tooltip> : <></>}
                            {isUserPermission('NCIC') || (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ?
                                <Tooltip title="GVRTF Referral Form" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={() => handleNavigator('GVRTF')}><img src={RTCC} style={{ height: 22, width: 22, marginRight: 4 }} />{/*<b>GVRTF</b>*/}</IconButton>
                                </Tooltip>
                                : <></>}
                            {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ?
                                <Tooltip title="Add Person to PIR Supplement" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={() => handleNavigator('PIR')}><img src={PIRIcon} style={{ height: 26, width: 26, marginRight: 4 }} />{/*<b>PIR</b>*/}</IconButton>
                                </Tooltip>
                                : <></>}

                            {isUserPermission('TSC') &&
                                <Tooltip title="Create TSC Hit" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={() => handleNavigator('TSC')}><img src={TSCIcon} style={{ height: 22, width: 22, marginRight: 4 }} />{/*<b>TSC</b>*/}</IconButton>
                                </Tooltip>
                            }
                            {isUserPermission('GUNOFFENDER_EDIT') && (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ?
                                <Tooltip title={gunOffenderInfo.length > 0 ? "Edit Gun Offender Entry": "Add Person to Gun Offender Entry"} placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={() => handleNavigator('GUN')}><img src={GunOffenderIcon} style={{ height: 26, width: 26, marginRight: 4 }} />{/*<b>PIR</b>*/}</IconButton>
                                </Tooltip>
                                : <></>}
                        </ButtonGroup>
                        <ButtonGroup color="inherit">
                            {/*{isUserPermission('Arrest') ?*/}
                            {/*    <Tooltip title="Social Media Details" placement="top" arrow>*/}
                            {/*        <IconButton size={"small"} style={{ color: "gray" }} onClick={handleSocial}><img src={SocialIcon} style={{ height: 22, width: 22, marginRight: 4 }} /><b>Social</b></IconButton>*/}
                            {/*    </Tooltip>*/}
                            {/*    : <></>}*/}

                            {isUserPermission('Arrest') && (SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) ?
                                <Tooltip title="Dept Of Correction Details" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={handleCorrection}><img src={CorrectionIcon} style={{ height: 22, width: 22, marginRight: 4 }} />{/*<b>Correction</b>*/}</IconButton>
                                </Tooltip>
                                : <></>}
                            {/*{isUserPermission('Arrest') ?
                            <Tooltip title="Criminal Cases: PROMIS/Gavel Public Access Details" placement="top" arrow>
                                <IconButton size={"small"} style={{ color: "gray" }} onClick={handlePromisGavel}><img src={PromisGavelIcon} style ={{height:22, width:22, marginRight:4}}/><b>PROMIS/Gavel</b></IconButton>
                            </Tooltip>
                                :<></>}*/}
                            {isUserPermission('Arrest') ?
                                <Tooltip title="Person Analysis" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={() => handleNavigator('LinkAnalysis')}><InsightsIcon style={{ height: 22, width: 22, marginRight: 4 }} />{/*<b>Analysis</b>*/}</IconButton>
                                </Tooltip>
                                : <></>}
                            {isUserPermission('Arrest') ?
                                <Tooltip title="Person Time Line" placement="top" arrow>
                                    <IconButton size={"small"} onClick={() => handleNavigator('TimeLine')}><LinearScaleIcon color={'primary'} style={{ height: 22, width: 22, marginRight: 4 }} />{/*<b>Analysis</b>*/}</IconButton>
                                </Tooltip>
                                : <></>}
                            {isUserPermission('Arrest') && (user?.SharedToken == null) ?
                                <Tooltip title="Create Share Link for this Person" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={handleShareLink}><img src={ShareLinkIcon} style={{ height: 22, width: 22, marginRight: 4 }} />{/*<b> Share Link</b>*/}</IconButton>
                                </Tooltip>
                                : <></>}
                            {isUserPermission('NJSP') && (SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) ?
                                <Tooltip title="Add New Profile Report" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={() => handleNavigator('ProfileReport')}><Avatar sx={{ bgcolor: green[700], height: 22, width: 22, marginRight: 4 }}><ContactPageOutlinedIcon sx={{ height: 20, width: 20 }} /></Avatar></IconButton>
                                </Tooltip>
                                : <></>}
                        </ButtonGroup>

                        {/* { user.Dept === 'NJSP' || user.AuthLevel === 'S' || profileReportNum ?
                        <fieldset className = "border border-gray-500 font-bold mt-5 w-96">
                            <legend className ="ml-3 text-sm"> Files </legend>
                            <div className = " grid grid-cols-1 pb-5">
                                <div className = "mt-5 text-xs ml-auto mr-auto"> PROFILE REPORT
                                    <span className = "ml-2 mr-2">
                                        { user.Dept === 'NJSP' || user.AuthLevel === 'S' ?
                                            <Tooltip title="Add or Edit Profile Report" placement="top" arrow>
                                                <Button size={"small"} variant={"outlined"} color = "inherit" style={{ color: "gray" }} onClick={handleProfileReport}><ContactPageIcon /><b>Profile Report</b></Button>
                                            </Tooltip>
                                            : //if report exists show button for other users as print{}
                                            <></>
                                        }
                                    </span>
                                    {profileReportNum ?
                                            <Tooltip title="View Profile Report" placement="top" arrow>
                                                <Button  size={"small"} variant={"outlined"} color = "primary" onClick={handleProfileReportPrint}><b>View</b></Button>
                                            </Tooltip>
                                        : <></>
                                    }
                                </div>
                            </div>
                        </fieldset>
                            : <></>} */}
                    </div>
                </> : <></>}
            </div>
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableBody>
                            {MainRecordFileOrImageInfo && MainRecordFileOrImageInfo.filter(res => res.Deleted === false).length > 0 ?
                                <TableRow style={{ backgroundColor: 'red' }}>
                                    {MainRecordFileOrImageInfo && MainRecordFileOrImageInfo.filter(res => res.Deleted === false).length > 0
                                        ? <TableCell component="th" scope="row" width="60px" className="bg-yellow-400"><span className="text-black">Record Verified</span></TableCell>
                                        : <></>
                                    }
                                    <TableCell align="left">
                                        <span>
                                            <div className="px-4 mb-4">
                                                {MainRecordFileOrImageInfo && MainRecordFileOrImageInfo.filter(res => res.Deleted === false).length > 0
                                                    ? <div className={"font-bold mb-3"}>
                                                        Associated File(s)
                                                    </div>
                                                    : <></>
                                                }
                                                {MainRecordFileOrImageInfo ?
                                                    MainRecordFileOrImageInfo.filter(res => res.Deleted === false).map((file: any, indx: number) => {
                                                        return (
                                                            <span className={"border-2 rounded-lg border-green-500 px-1 mx-1 my-1"}>
                                                                <span>
                                                                    <Tooltip title="Download File" >
                                                                        <IconButton style={{ margin: 0, padding: 0 }} onClick={() => downloadFile(file, indx)}><DownloadIcon /></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <span className={"font-bold text-green-500 mr-1"} onClick={() => downloadFile(file, indx)}>
                                                                    {file.FileName}
                                                                </span>
                                                            </span>
                                                        );
                                                    }) : <></>}
                                            </div>
                                        </span>
                                    </TableCell>
                                </TableRow>
                                : <></>}
                            <TableRow>
                                <TableCell component="th" scope="row" width="60px" className="bg-blue-400">
                                    <span className="text-white">Aliases</span>
                                </TableCell>
                                <TableCell align="left">
                                    {akaInfo?.map((per: any, idx) => ((idx != 0 ? ', ' : '') + per?.FName + ' ' + (per?.MName?.length > 0 ? per?.MName + ' ' : '') + per?.LName))}
                                    {nicknamesStringArray?.map((data: any, indx: any) => (indx != 0 ? ', ' : '') + data)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" width="60px" className="bg-blue-400">
                                    <span className="text-white">FBI#</span>
                                </TableCell>
                                <TableCell align="left">
                                    {person?.FBI}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">{GetStringNameForSite(SiteName, "SBI #")}</span>
                                </TableCell>
                                <TableCell align="left">{person?.SBINo}</TableCell>
                            </TableRow>
                            {showCrumbScore &&
                                <TableRow>
                                    <TableCell component="th" scope="row" className="bg-blue-400">
                                        <span className="text-white">{GetStringNameForSite(SiteName, "SCOR DETAILS") + " Details"}</span>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Disclosure>
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button>
                                                        <KeyboardArrowDownIcon className={`${open ? "transform rotate-180" : ""}`} /> <span className="text-base mr-2"><b>Position within {GetDefaultStateForSite(SiteName)} Offender Population: </b> {crumbScoreInfo?.CrumbScoreRank ? <>{crumbScoreInfo?.CrumbScoreRank} of {crumbScoreInfo?.TotalPeople}</> : <></>}</span>
                                                        <span className="text-base mr-2"><b> {GetStringNameForSite(SiteName, "SCOR")} Date: </b> {formatDate(crumbScoreInfo?.CrumbScoreDate)}</span>
                                                        <span className="text-base mr-2"><b>{GetStringNameForSite(SiteName, "SCOR_TBL_PERSON")}: </b> {crumbScoreInfo?.CrumbScoreDenseRank}</span>
                                                        <Tooltip title={"Show " + GetStringNameForSite(SiteName, "SCOR") + " Calculations PDF"}><Button onClick={() => OpenSCORPDF(person.PersonID, person.Dept)}><HelpOutline /></Button></Tooltip>
                                                        {/* <span className="text-base"><b>SCOR Percentile: </b> {crumbScoreInfo?.CrumbScorePercentile} </span>*/}
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel >
                                                        {crumbScoreInfo && crumbScoreInfo.CrumbScore ?

                                                            <div className={"mt-5"}>
                                                                <GridComponent
                                                                    dataSource={[crumbScoreInfo]}
                                                                    dataBound={dataBound}
                                                                    allowSorting={true}
                                                                    allowMultiSorting={true}
                                                                    allowTextWrap={true}
                                                                    textWrapSettings={{ wrapMode: 'Both' }}
                                                                    allowExcelExport={true}
                                                                    allowPdfExport={true}
                                                                    ref={g => grid = g}
                                                                    selectionSettings={{
                                                                        persistSelection: true,
                                                                        type: "Multiple",
                                                                        //checkboxOnly: true,
                                                                        mode: "Row"
                                                                    }}
                                                                    allowSelection={true}
                                                                    allowResizing={true}
                                                                    rowHeight={24}
                                                                >
                                                                    <ColumnsDirective>
                                                                        <ColumnDirective field='CrumbScore' headerText={GetStringNameForSite(SiteName, "SCOR_DETAILS_TBL")} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                                                        {(SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_11' headerText='Offence #11' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_10' headerText='Offence #10' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_09' headerText='Offence #09' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_08' headerText='Offence #08' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_07' headerText='Offence #07' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_06' headerText='Offence #06' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_05' headerText='Offence #05' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_04' headerText='Offence #04' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_03' headerText='Offence #03' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Point_02' headerText='Offence #02' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {/*<ColumnDirective field='Arrest_Point_01' headerText='Offence #01' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />*/}
                                                                        <ColumnDirective field='Arrest' headerText='Arrest' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Assault' headerText='Agg Assault' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Gun' headerText='Gun Arrest' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Arrest_Murder' headerText='Murder/Manslaughter' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Codis' headerText='Codis Hit' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='NJTrace' headerText='NJ Trace' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='LEInteraction_MT_10' headerText='LE Interaction > 10' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Parole' headerText='Active Parole' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='PrisonRelease' headerText='Prison Release' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        <ColumnDirective field='ShootingSuspect' headerText='Shooting Suspect' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />
                                                                        <ColumnDirective field='ShootingVictim' headerText='Shooting Victim' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='TriggerLock' headerText='Trigger Lock' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='Violent' headerText='Violent Offender' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD || SiteName === SITE.NYLOCAL) && <ColumnDirective field='ShootingSubject' headerText='Shooting Subject' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />}
                                                                        <ColumnDirective field='ShootingLE' headerText='Shooting Law Enforcement' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='GunOffender' headerText='Gun Offender' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='GunStat' headerText='GunStat' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />}
                                                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='ETrace_Pur' headerText='ETrace' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />}
                                                                    </ColumnsDirective>
                                                                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Resize, Reorder]} />
                                                                </GridComponent>
                                                            </div>
                                                            : <div>No SCOR Data</div>}
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">Gang</span>
                                </TableCell>
                                <TableCell align="left">{person?.GANGRELATED}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">DL#</span>
                                </TableCell>
                                <TableCell align="left">{DLNo?.map((dl) => dl.DLNo + ' ' + (dl.DLSt ? dl.DLSt : "")) + ' '}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">Plates</span>
                                </TableCell>
                                <TableCell align="left">
                                    {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) ? <>
                                        <b>DMV Agency:</b> {dPlate.map((plate) => plate + ' ')}
                                        <br />
                                        <b>ETicket: </b> {ETicketPlate.map((plate) => plate + ' ')}
                                    </> : <>

                                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? <>
                                            {plateInfo && plateInfo.map((plate) => (<>
                                                <Chip style={{ marginRight: '3px' }} label={plate.PlateNo + ' ' + plate.PlateSt} variant="outlined" size="small" onClick={() => { { window.open('/detail/CadRms?ID=0&Dept=PLT_' + plate?.PlateNo, '_blank') } }} />
                                            </>
                                            ))}
                                        </> : <>
                                            {dPlate.map((plate) => plate + ' ')} {ETicketPlate.map((plate) => plate + ' ')}
                                        </>}

                                    </>}

                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">Addresses</span>
                                </TableCell>
                                <TableCell align="left">

                                    {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) ? <>
                                        <b>DMV Agency:</b> {dAddress.map((address, indx) => (indx != 0 ? ', ' : '') + address + ' ')}
                                        <br />
                                        <b>Arrest: </b> {arrestAddress.map((address, indx) => (indx != 0 ? ', ' : '') + address + ' ')}
                                    </> : <>
                                        {dAddress.map((address, indx) => (indx != 0 ? ', ' : '') + address + ' ')} {arrestAddress.map((address, indx) => (indx != 0 ? ', ' : '') + address + ' ')}
                                    </>}

                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">Phone#</span>
                                </TableCell>

                                <TableCell align="left">
                                    {phoneAddressInfo?.AdditionalData?.map((data, indx) => data?.Phone ? (indx != 0 ? ', ' : '') + data?.Phone : "" + ' ' + data?.PhoneType ? data?.PhoneType : "" + ' ')}
                                    {phoneStringArray?.map((data: any, indx: any) => (indx != 0 ? ', ' : '') + data)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            { /*Display Panels Below */}
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-400 p-2 w-auto w-full text-left mt-5 rounded">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span className="text-white">
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    Summary Info
                                </span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            {IsPanelEnabled(PERSON_PANEL.ARREST, ENABLED_PANELS) && <PanelArrest person={person} ArrestInfo={arrestInfo} isLoading={arrestLoading} crumbArrest={getArrestCount()} />}
                            {IsPanelEnabled(PERSON_PANEL.NJPOP, ENABLED_PANELS) && <PanelShooting person={person} ShootingInfos={njpopInfo} isLoading={njpopLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.CODIS, ENABLED_PANELS) && <PanelCODIS person={person} DNAInfo={codisDNAInfo} isLoading={codisDNALoading} />}
                            {IsPanelEnabled(PERSON_PANEL.INFOCOP, ENABLED_PANELS) && <PanelINFOCOP person={person} AgencyInteractionInfos={infocopInfo} isLoading={infocopLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.ETICKED, ENABLED_PANELS) && <PanelEticket person={person} ETInfos={eTicketInfo} isLoading={eTicketLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.CADRMS, ENABLED_PANELS) && <PanelCADRMS person={person} CADRMSCases={cadrmsInfo} isLoading={cadrmsLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.STOPDATA, ENABLED_PANELS) && <PanelStopData person={person} CADRMSCases={stopDataInfo} isLoading={cadrmsLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.PARKING, ENABLED_PANELS) && <PanelParkMobile person={person} PDInfos={parkMobileInfo} isLoading={parkMobileLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.TOWED_VEHICLE, ENABLED_PANELS) && <PanelTowedVehicle person={person} TVInfos={towedVehicleInfo} isLoading={towedVehicleLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.GUNSTAT, ENABLED_PANELS) && <PanelGunStat person={person} GunStatInfo={gunStatInfo} isLoading={gunStatLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.GUNOFFENDER, ENABLED_PANELS) && <PanelGunOffender person={person} GunInfo={gunOffenderInfo} isLoading={gunOffenderLoading} />}
                            {/* {IsPanelEnabled(PERSON_PANEL.GUNREGISTRATION, ENABLED_PANELS) && <PanelGunRegistration person={person} GunRegistrationInfo={gunOffenderInfo} isLoading={gunRegistrationLoading} />}           */}
                            {IsPanelEnabled(PERSON_PANEL.BOLO, ENABLED_PANELS) && <PanelBOLO person={person} BOLOInfo={boloInfo} isLoading={boloLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.ETRACE, ENABLED_PANELS) && <PanelETrace person={person} WeaponTraces={eTraceInfo} isLoading={eTraceLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.NJTRACE, ENABLED_PANELS) && <PanelNJGunTrace person={person} NJWeaponTraces={njTraceInfo} isLoading={njTraceLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.NIBIN, ENABLED_PANELS) && <PanelNIBIN person={person} NIBINs={nibinInfo} isLoading={nibinLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.JAIL, ENABLED_PANELS) && <PanelJail person={person} JailInfo={jailInfo} isLoading={jailLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.PRISION, ENABLED_PANELS) && <PanelPrison person={person} PrisonerReleases={prisonInfo} isLoading={prisonLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.PAROLE, ENABLED_PANELS) && <PanelParole person={person} PrisonerParoles={paroleInfo} isLoading={paroleLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.VIOLENT, ENABLED_PANELS) && <PanelViolent person={person} Violents={violentInfo} isLoading={violentLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.REFERRAL, ENABLED_PANELS) && <PanelReferral person={person} Referrals={referralInfo} isLoading={referralLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.PROFILE_REPORT, ENABLED_PANELS) && <PanelProfileReport person={person} ProfileReport={profileReportInfo} isLoading={profileReportLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.PROFESSION, ENABLED_PANELS) && <PanelProfession person={person} Professions={professionInfo} isLoading={professionLoading} />}
                            {isUserPermission('TSC') && IsPanelEnabled(PERSON_PANEL.TSC, ENABLED_PANELS) && <PanelTSC person={person} TSCInfo={tscInfo} isLoading={tscLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.ASSOCIATION_FIREARM, ENABLED_PANELS) && <PanelAssociatedFirearms person={person} firearms={firearmInfo} isLoading={firearmLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.MISSING_PERSON, ENABLED_PANELS) && <PanelMissingPerson person={person} isLoading={missingPersonLoading} MissingPersonInfo={missingPersonInfo} />}
                            {IsPanelEnabled(PERSON_PANEL.ASSOCIATION_PEOPLE, ENABLED_PANELS) && <PanelAssociatedPeople person={person} AssociatedPeople={associatedPeopleInfo} isLoading={associatedPeopleLoading} />}
                            {IsPanelEnabled(PERSON_PANEL.PERSON_ATTACHMENT, ENABLED_PANELS) && <PanelSupplement person={person} files={supplementFilesInfo} isLoading={supplementFilesLoading} reloadTrigger={() => { reloadPersonSupplementFiles(); }} />}
                            {IsPanelEnabled(PERSON_PANEL.ALPR, ENABLED_PANELS) && <PanelALPR person={person} ALPRInfo={alprInfo} isLoading={alprLoading} ALPRTrigger={() => RunALPRQuery("3", query)} />}

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={'PersonSummary'} /></div> : <></>}
            {(showPIR) ? <div><PIRSupplement handleReportSubmit={handleSuppSubmit} Record={null} ParentReportId={null} btPerson={pirPerson} drawerDisplay={true} ccnList={pirCCNList} HandleSuppClose={HandleSuppClose} SummaryInfo={null} /></div> : <></>}

            <Dialog
                open={expiryOpen}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="px-20 pb-5 text-center">
                    <div className="flex justify-end"><Button color={"error"} onClick={() => { setShareLink(""); setExpiryOpen(false) }}><span className="text-xl">X</span></Button></div>
                    <div className="font-bold mb-8 ">Link has been Copied to Clipboard!</div>
                    <div className="text-blue-400"><a href={shareLink}>{shareLink}</a></div>
                    <div className=" mt-8 text-gray-500 text-sm ">Information should only be shared with those that have the right to know and need to know.</div>
                    <div className=" mt-1 font-bold text-sm text-red-800">By sharing this link you take responsibility for the data shared.</div>
                </div>
            </Dialog>

            <Dialog
                open={navigatorOpen}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div>
                    <BTPersonNavigator loadingStates={getLoadingStates()} CloseNavigator={handleNavigatorEvent} />
                </div>
            </Dialog>

            <Drawer anchor={"right"} open={fileSupplementalOpen} onClose={() => { setFileSupplementalOpen(false) }}>
                <div style={{ width: '25vw', margin: '5px' }}>
                    <FileUpload onFilesSelected={setFileSupplementalList} label={"Upload Supplemental File (10MB Limit)"} hint={"Only .PDF, .DOC, .DOCX, .XLX,and .XLSX will be combined on PDF Export. "} />

                    {fileSupplementalList && fileSupplementalList.length > 0 && fileSupplementalList.map((file, indx) => {
                        return (
                            <div className="flex flex-col border border-sky-500 m-5 p-3" key={'upload_supplement_' + indx} >
                                <div className="flex mb-2">
                                    <p className="text-sm text-gray-500 font-semibold flex-1">  {file.name} </p>

                                    <div onClick={() => {
                                        let temp = fileSupplementalList;
                                        temp.splice(indx, 1);
                                        setFileSupplementalList([...temp]);
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            className="w-3 cursor-pointer shrink-0 fill-black hover:fill-red-500"
                                            viewBox="0 0 320.591 320.591">
                                            <path
                                                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                                                data-original="#000000"></path>
                                            <path
                                                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                                                data-original="#000000"></path>
                                        </svg>
                                    </div>

                                </div>

                                <div className="flex-1 mt-2">
                                    <TextField label="Title" variant="outlined" size="small" fullWidth={true} onChange={(event) => { HandleFileSupplementalValueChange(indx, 'category', event?.target?.value) }} />
                                </div>
                                <div className="flex-1 mt-2">
                                    <TextField label="Description" variant="outlined" multiline={true} minRows={3} size="small" fullWidth={true} onChange={(event) => { HandleFileSupplementalValueChange(indx, 'description', event?.target?.value) }} />
                                </div>
                            </div>

                        )
                            ;
                    })}

                    <Button onClick={() => {
                        HandleFileSupplementalSubmit();
                    }} variant="contained" color="primary" className={"float-right mt-2"} disabled={!(fileSupplementalList.length > 0)}>Submit</Button>
                </div>
            </Drawer>

        </div>
    )
};

export default BTPersonReport;