import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";
import { InvestigationDropDown } from "../../../services/njport.service";
import { DropDown } from "../../../interfaces/NJPORT/InvestigationLog.interface";


interface ApplicationByJobTitleProps {
    HandleRefreshCount: any
    RefreshCount: number
    StartDate: Date,
    EndDate: Date
}

const ApplicationByJobTitle: React.FC<ApplicationByJobTitleProps> = ({
    HandleRefreshCount,
    RefreshCount,
    StartDate,
    EndDate
}) => {
    const [data, setData] = useState<any[]>([]);
    const [option, setOption] = useState<any>({});
    const [jobTitle, setjobTitle] = useState<any>({});

    useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_JOBTITLE_YEAR",
            dateTypeValue: 1,
            FromDate: StartDate,
            ToDate: EndDate,
        };
        ////////jobTitleDropDown().then((qRes) => {
        ////////    setjobTitle(qRes);
        ////////});
        NJPortDashboardDataApi(payload).then((qRes) => {
            //let jobTitle = jobTitleDropDown();
            setData(qRes?.AnyTable);
            setGraph(qRes?.AnyTable);
        });
    }, [RefreshCount, StartDate, EndDate]);

    //////useEffect(() => {
    //////    const payload = {
    //////        queryType: "INVESTIGATION_JOBTITLE_YEAR",
    //////        dateTypeValue: 1,
    //////        FromDate: StartDate,
    //////        ToDate: EndDate,
    //////    };
    //////    jobTitleDropDown();
    //////    NJPortDashboardDataApi(payload).then((qRes) => {
    //////        setData(qRes?.AnyTable);
    //////        setGraph(qRes?.AnyTable);
    //////    });
    //////});

    //////const jobTitleDropDown = () => {
    //////    const payload = {
    //////        params: {
    //////            DropDownName: "PORT_JOBCODE",
    //////            State: "NJ",
    //////        },
    //////    };
    //////    InvestigationDropDown(payload).then((qRes) => {
    //////        setjobTitle(qRes);
    //////    });        
    //////};

    const jobTitleDropDown = async () => {
        const payload = {
            params: {
                DropDownName: "PORT_JOBCODE",
                State: "NJ",
            },
        };
        let qRes = await InvestigationDropDown(payload);
        return qRes;
    };

    const setGraph = async (maindata: any) => {        
        const xAxisData = await handleFormatXAxis(maindata);   
        const seriesData = await handleSeries(maindata, xAxisData);

        //console.log('xAxisData:', xAxisData);
        //console.log('yAxisData:', seriesData);   
        //////setOption({
        //////    legend: {
        //////        selectedMode: false
        //////    },
        //////    grid: {
        //////        left: "1%",
        //////        right: "1%",
        //////        bottom: "1%",
        //////        top: "3%",
        //////        containLabel: true,
        //////    },
        //////    yAxis: {
        //////        type: 'value'
        //////    },
        //////    xAxis: {
        //////        type: 'category',
        //////        data: xAxisData,
        //////    },
        //////    series: seriesData,
        //////});


        //////handleSeries(maindata, xAxisData).then((seriesData) => {
        //////    console.log('xAxisData:', xAxisData);
        //////    console.log('yAxisData:', seriesData);           


            /////setOption({
            ////////    tooltip: {
            ////////        trigger: "axis",
            ////////        axisPointer: {
            ////////            type: "shadow",
            ////////        },
            ////////    },
            ////////    toolbox: {
            ////////        feature: {
            ////////            saveAsImage: { show: true },
            ////////        },
            ////////    },
            ////////    grid: {
            ////////        left: "1%",
            ////////        right: "1%",
            ////////        bottom: "1%",
            ////////        top: "3%",
            ////////        containLabel: true,
            ////////    },
            ////////    legend: {
            ////////        //position: 'bottom',
            ////////        //horizontalAlign: 'center',
            ////////        //offsetX: 40,
            ////////        ////orient: "horizontal",
            ////////        ////bottom: "bottom",
            ////////        ////top: "bottom",
            ////////        //////position: "bottom",
            ////////    },
            ////////    xAxis: {
            ////////        type: "category",
            ////////        axisLabel: {
            ////////            interval: 0,
            ////////            rotate: 45,
            ////////            fontSize: 8,
            ////////        },
            ////////        data: xAxisData,
            ////////    },
            ////////    yAxis: {
            ////////        type: "value",
            ////////        axisLabel: {
            ////////            show: false,
            ////////        },
            ////////        axisLine: {
            ////////            show: false,
            ////////        },
            ////////        splitLine: {
            ////////            show: false,
            ////////        },
            ////////        axisTick: {
            ////////            show: false,
            ////////        },
            ////////    },
            ////////    series: seriesData,
            ////////});
        /////});        
    }

    const formattedData = (maindata: any) => {
        let groupdData: any = [];
        maindata.forEach((item: any) => {
            groupdData.push({ name: item.SubGroup_Desc, value: item.Total });
        });
        ////console.log('formattedData 2', { groupdData });
        return groupdData;
    }

    const handleFormatXAxis = async (maindata: any) => {
        let xAxis: any = []
        maindata.forEach((item: any) => {
            if (xAxis?.includes(item?.DateGroup) === false) {
                xAxis.push(item.DateGroup);
            }
        });
        ////console.log('handleFormatXAxis 2', { xAxis });
        return xAxis;
    }

    const handleSeries = async (maindata: any, xAxis: any[]) => {
        //let subGroupGroup: { [key: string]: string }[][];
        let series: any = [];
        /*let jobTitle = await jobTitleDropDown();*/

        jobTitleDropDown().then((jobTitle) => {
            setjobTitle(jobTitle);
            jobTitle?.forEach((xjobitem: any) => {
                let total: any = [];
                xAxis?.forEach((xdateitem: any) => {
                    let bFound = false;
                    for (let i = 0; i < maindata?.length; i++) {
                        if (maindata[i]?.DateGroup === xdateitem && maindata[i]?.SubGroup === xjobitem?.Key) {
                            total.push(maindata[i]?.Total);
                            bFound = true;
                            break;
                        }
                    }
                    if (bFound === false)
                        total.push(0);
                });
                //console.log('handleSeries 2 loop', xjobitem.Key, total);
                series.push({
                    name: xjobitem.Value,
                    type: 'bar',
                    stack: 'total',
                    //stack: true,
                    //color: 'blue',
                    label: {
                        show: true,
                        position: "inside",
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: total
                },)
            });
            //console.log('handleSeries 2 success', series);

            setOption({
                legend: {
                    selectedMode: false,
                    //position: 'bottom',
                    //horizontalAlign: 'center',
                    //offsetX: 40,
                    orient: "horizontal",
                    bottom: "bottom",
                },
                grid: {
                    left: "1%",
                    right: "1%",
                    bottom: "15%",
                    top: "1%",
                    containLabel: true,
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    data: xAxis,
                },
                series: series,
            });

            return series;
        })
            .catch(() => {
                //console.log('handleSeries 2 fail', series);
                return series;
            }); 
    };

    //////const handleSeries = (xAxis: string[]) => {
    //////    return xAxis.map((DateGroup) => {
    //////        let Requested = 0;
    //////        data.forEach((item: any) => {
    //////            if (item?.DateGroup === DateGroup && item?.Requested !== null) {
    //////                Requested += item?.Total;
    //////            }
    //////        });
    //////        return Requested;
    //////    });
    //////};

    return (
        <>
            {data && data.length !== 0 ? (
                <div>
                    <ReactECharts option={option} style={{ height: "400px" }} />
                </div>
            ) : (
                <div
                    className={"text-center text-base xl:text-3xl mt-6 mb-1"}
                    style={{ color: "red", fontWeight: 500 }}
                >
                    No Data
                </div>
            )}
        </>
    );
};

export default ApplicationByJobTitle;