import React from "react";
import { useLocation } from "react-router-dom";
import GunRegistration from "../../components/Detail/_GunRegistrationDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function GunRegistrationDetail() {
    let query = useQuery();

    return (
        <div>
            <GunRegistration ID={query.get("ID")} department={query.get("Dept")} />
        </div>
    );
}

export default GunRegistrationDetail;