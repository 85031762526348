import React, { useState, useEffect } from "react";
import { Card, CardContent, Dialog, Tooltip } from "@mui/material";
import TVInfo from "../../interfaces/TVInfo.interface"
import { RunTowedVehicleDetailQuery } from "../../services/detail.service";
import './Detail.css'
import DetailMap from "./_DetailMap";

interface DetailProps {
    ID?: string | null
    department: string | null
}

const TowedVehicleDetailInfo: React.FC<DetailProps> = ({ ID = null, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<TVInfo>({})
    const [pin, setPin] = React.useState<any>({})

    const loadData = async () => {
        if (!DataLoaded) {
            setDataLoaded(true)
            if (ID) {
                let res = await RunTowedVehicleDetailQuery(ID, department);
                console.log(res)
                setData(res)
                createPin(res)
            }
        }
    }

    const createPin = async (Data: any) => {
        let tPin: any = {}
        if (Data && Data?.LAT && Data?.LNG) {
            tPin = ({
                lat: Data?.LAT,
                lng: Data?.LNG,
                date: Data?.DATETIMEIN,
                plate: Data?.PLATE,
                detail: "towedvehicle"
            })
        }
        setPin(tPin)
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Towing Details</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Data?.ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">DateTimeIn</span><span className="DetailTableData">{Data?.DATETIMEIN}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Dept</span><span className="DetailTableData">{Data?.DEPT}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate #</span><span className="DetailTableData">{Data?.PLATE}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate State</span><span className="DetailTableData">{Data?.PLATESTATE}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate Year</span><span className="DetailTableData">{Data?.PLATEYEAR}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Reason</span><span className="DetailTableData">{Data?.Reason}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Case #</span><span className="DetailTableData">{Data?.CASENUMBER}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">ReqID</span><span className="DetailTableData">{Data?.REQID}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-5 font-bold font text-xl text-blue-400">Vehicle Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">VIN #</span><span className="DetailTableData">{Data?.VIN}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Year</span><span className="DetailTableData">{Data?.VEHYEAR}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Make</span><span className="DetailTableData">{Data?.VEHMAKE}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Model</span><span className="DetailTableData">{Data?.VEHMODEL}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Color</span><span className="DetailTableData">{Data?.VEHCOLOR}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Style</span><span className="DetailTableData">{Data?.VEHSTYLE}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-5 font-bold font text-xl text-blue-400">Location Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Street #</span><span className="DetailTableData">{Data?.STREETNUMBER}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Street Name</span><span className="DetailTableData">{Data?.STREETNAME}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">City</span><span className="DetailTableData">{Data?.CITY}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">State</span><span className="DetailTableData">{Data?.STATE}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Zip</span><span className="DetailTableData">{Data?.ZIP}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Latitude</span><span className="DetailTableData">{Data?.LAT}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Longitude</span><span className="DetailTableData">{Data?.LNG}</span></div>
                    {pin && pin.lat && pin.lng ? <div className="DetailTableRow border-2 border-black"> <DetailMap data={pin} detail="alpr" /> </div> : <></>}
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Other Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">IPAddress</span><span className="DetailTableData">{Data?.IPADDRESS}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Device ORI</span><span className="DetailTableData">{Data?.DEVICEORI}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Notes</span><span className="DetailTableData">{Data?.NOTES}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">CreateDate</span><span className="DetailTableData">{Data?.CREATEDATE}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vendor</span><span className="DetailTableData">{Data?.VENDOR}</span></div>
                </CardContent>
            </Card>
        </div>
    );
}

export default TowedVehicleDetailInfo;