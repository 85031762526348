import React, {useState, useEffect} from "react";

import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    ButtonGroup,
    Tooltip
} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {JAOS} from "../../../services/JAOS.service";
import {formatInputDate} from "../../../services/formatDate.service";
import {SiteName} from "../../../services/config.service";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
//import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
//import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
//import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';

interface ArrestAnalysisSearchProps {
    SubmitSearch: any
}

const ArrestAnalysisSearch: React.FC<ArrestAnalysisSearchProps> = ({ SubmitSearch }) => {
    var toDate = new Date();
    var prevDate = new Date();
    let today: Date = toDate
    var fromday: Date = new Date();
    fromday = new Date(new Date().setFullYear(new Date().getFullYear() - 1))//Default - Last 1 year   

    const [params, setParams] = React.useState({ header: "MONTH", detail: "ARRESTAGENCY", group: "PERSON", fromDate: formatInputDate(fromday), toDate: formatInputDate(today), showDate: true, systemCall:false})
    const [dateType, setDateType] = React.useState<string>("Arrest Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    
    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            let p = {...params}
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
    }, [])


    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleHeaderChange = (e: any) => {
        setParams({ ...params, header: e.target.value })
    }

    const handleDetailChange = (e: any) => {
        setParams({ ...params, detail: e.target.value })
    }

    const handleGroupChange = (e: any) => {
        setParams({ ...params, group: e.target.value })
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };

    const handleClear = () => {
        setParams({ ...params, header: "MONTH", detail: "ARRESTAGENCY", group: "PERSON", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate: true, systemCall:false})
        setDateType("Arrest Date")
        setAndOrType("AND")       
        setClear(clear + 1)
    }
   
    const handleSearch = () => {
        let dropDownParam = {...dropDownParams};
        dropDownParam.Statute = Object?.keys(dropDownParam.Statute)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.AgencyArrested = Object?.keys(dropDownParam.AgencyArrested)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        SubmitSearch(params, dateType, andOrType, dropDownParam)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">            
            <div className="grid grid-cols-6 gap-x-4 gap-y-2">     
                <div style={{width:'100%'}}>   
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        exclusive
                        size="small"
                        fullWidth
                        onChange={handleDateType}
                        sx={{mr: 2 }}
                    >
                        <ToggleButton value="Offense Date" sx={{ pr: 2, pl: 2 }} >Offense Date</ToggleButton>
                        <ToggleButton value="Arrest Date" sx={{ pr: 2, pl: 2 }} >Arrest Date</ToggleButton>
                        {(SiteName==SITE.NJPROD || SiteName==SITE.NJDEMO || SiteName == SITE.NJUAT || SiteName==SITE.LOCAL) &&<ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>}
                    </ToggleButtonGroup>
                </div>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="header">Header</InputLabel>
                        <Select
                            labelId="header"
                            value={params.header}
                            label="Header"
                            size="small"
                            onChange={handleHeaderChange}
                        >
                            <MenuItem value={"DAY"}>DAY</MenuItem>
                            <MenuItem value={"WEEK"}>WEEK</MenuItem>
                            <MenuItem value={"MONTH"}>MONTH</MenuItem>
                            <MenuItem value={"YEAR"}>YEAR</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                               size={"small"} label="From Date"
                               InputLabelProps={{shrink: true}}
                               inputProps={{style: {textAlign: 'center'}}}
                               value={params.fromDate}
                               onChange={(e: any) => {
                                   setParams({...params, fromDate: e.target.value})
                               }}
                    />
                </div>
                <div>
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                               size={"small"} label="To Date"
                               InputLabelProps={{shrink: true}}
                               inputProps={{style: {textAlign: 'center'}}}
                               value={params.toDate}
                               onChange={(e: any) => {
                                   setParams({...params, toDate: e.target.value})
                               }}
                    />
                </div>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="detail">Detail</InputLabel>
                        <Select
                            labelId="detail"
                            value={params.detail}
                            label="Detail"
                            onChange={handleDetailChange}
                            size={"small"}
                        >
                            <MenuItem value={"STATUTE"}>{GetStringNameForSite(SiteName,"STATUTE")?.toUpperCase()}</MenuItem>
                            <MenuItem value={"ARRESTAGENCY"}>ARRESTED AGENCY</MenuItem>
                            <MenuItem value={"ARRESTCOUNTY"}>ARRESTED {GetStringNameForSite(SiteName,"COUNTY")?.toUpperCase()}</MenuItem>
                            <MenuItem value={"RESIDENTCITY"}>RESIDENT {GetStringNameForSite(SiteName,"CITY")?.toUpperCase()}</MenuItem>
                            <MenuItem value={"RESIDENTCOUNTY"}>RESIDENT {GetStringNameForSite(SiteName,"COUNTY")?.toUpperCase()}</MenuItem>
                            <MenuItem value={"INCIDENTCITY"}>INCIDENT {GetStringNameForSite(SiteName, "CITY")?.toUpperCase()}</MenuItem>
                            {/* <MenuItem value={"INCIDENTCOUNTY"}>INCIDENT {GetStringNameForSite(SiteName, "COUNTY")?.toUpperCase()}</MenuItem>*/}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="group">Group</InputLabel>
                        <Select
                            labelId="group"
                            value={params.group}
                            label="Group"
                            onChange={handleGroupChange}
                            size={"small"}
                        >
                            <MenuItem value={"STATUTE"}>{GetStringNameForSite(SiteName,"STATUTE")?.toUpperCase()}</MenuItem>
                            <MenuItem value={"PERSON"}>PERSON</MenuItem>
                            <MenuItem value={"PERSONDateORI"}>PERSON AGENCY</MenuItem>
                        </Select>
                    </FormControl>
                </div>              
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{"marginTop":"25px"}}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"analysis"} /></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default ArrestAnalysisSearch;
