import React from 'react';
import { DateTimePicker, LocalizationProvider, TabContext, TabList, TabPanel } from '@mui/lab';
import { PostAdd, Close, FormatListBulleted, ExpandMore, PictureAsPdf } from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, FormLabel, Tab, Tooltip, Snackbar, Alert, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, Button, TextField, Typography, DialogActions, Container, Checkbox, RadioGroup, Radio, DialogContentText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import {
    GunOffender
} from "../../../interfaces/GunOffender.interface";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GunOffenderInputForm from './_gunOffenderForm';
import SearchResults from "../../../components/tools/arrest/_searchResultsArrest";
import {
    isUserPermission
} from "../../../services/auth.service";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search,
    ExcelExportProperties,
    Resize,
    Reorder,
    SortSettingsModel,
    CommandColumn,
    setChecked
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { formatDT, formatDate } from '../../../services/formatDate.service';
import { SetGunOffender } from '../../../services/gunoffender.service';
import { useLocation } from "react-router-dom";
import { JAOS } from '../../../services/JAOS.service';
import DCLogo from '../../../assets/Images/GORULogo.png';

interface GunOffenderSearchResultsProps {
    Records: GunOffender[],
    RefreshTrigger: any,
    Persons: any,
    SecondaryTable: any,
    showPlate: any,
    searchType: any
}

const GunOffenderSearchResults: React.FC<GunOffenderSearchResultsProps> = ({ Records, RefreshTrigger, Persons = null, SecondaryTable = null, showPlate = false, searchType = "GORU Search" }) => {

    const [tabValue, setTabValue] = React.useState('2');
    const [tabModLabelValue, setTabModLabelValue] = React.useState('CREATE NEW');
    const [editingRecord, setEditingRecord] = React.useState<any>(null);
    const [typedRecords, setTypedRecords] = React.useState<any[]>([])
    const [personDetails, setPersonDetails] = React.useState<null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [submitted, setSubmitted] = React.useState<boolean>(false)

    let [darTbl, setDarTbl] = React.useState<any>(null)

    let today = new Date();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    //Looks in URL for person object and sets darTbl to that object, which then gets sent to input form as prop
    const checkPersonData = () => {
        if (!submitted) {
            let person = query.get("person");
            let data: any;
            if (person) {
                let l = new JAOS()
                data = l.objFromStack(person);
            }
            if (data) {
                    setDarTbl(data); // Set state
                    setTabValue('1');
            }
        }
    }

    let grid: Grid | null;

    //Sets the grid to all fetched records
    const handleDataTyping = async () => {
        if (Records) {
            let tempR: any = [...Records]
            setIsLoading(true)
            await new Promise(r => setTimeout(r, 1000)); //Why I need to sleep... This fixes the syncfusion no rerender on my machine
            setTypedRecords(tempR ?? [])
            setIsLoading(false)
        }
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text == "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Gun_Offender.xlsx';

            grid.excelExport(excelProp);
        }
    }

    const handleEditGunOffender = (rowData: any) => {
        if (rowData && rowData.ID) {
            setEditingRecord(rowData?.ID)
            handleTabStateChange('1', true)
        }

    };

    const handleDeleteGunOffender = (rowData: any) => {
        if (window.confirm("Are you sure you would like to delete this Gun Offender entry?")) {
            let temp = { ...rowData }
            temp.deleted = true
            SetGunOffender(temp)
            if (grid) {
                // @ts-ignore
                let dSource: any = [...grid?.dataSource]
                if (temp.ID && temp.ID != 0) {
                    let index: number = dSource.findIndex((e: any) => e.ID === temp.ID)
                    if (index >= 0) {
                        dSource.splice(index, 1)
                        grid.dataSource = dSource
                        setTypedRecords(dSource)
                        grid?.refresh()
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        handleDataTyping()
        let offenderID = query.get("ID")
        if (offenderID != null) {
            setEditingRecord(offenderID)
            handleTabStateChange('1', true)
        }
        checkPersonData()
    }, [Records])

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        handleTabStateChange(newValue)
    }

    const actionTemplate = (rowData: any) => {
        if (!isUserPermission('GUNOFFENDER_EDIT')) return (
            <ButtonGroup>
                <Tooltip title="View Gun Offender" placement="top" arrow>
                    <IconButton onClick={() => handleEditGunOffender(rowData)}>
                        <EditIcon fontSize='small' color='primary' />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>
        )

        else if (isUserPermission('GUNOFFENDER_EDIT')) {
            return (
                <ButtonGroup>
                    <Tooltip title="Edit Gun Offender" placement="top" arrow>
                        <IconButton onClick={() => handleEditGunOffender(rowData)}>
                            <EditIcon fontSize='small' color='primary' />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete Gun Offender" placement="top" arrow>
                        <IconButton onClick={() => handleDeleteGunOffender(rowData)}>
                            <DeleteIcon fontSize='small' color='error' />
                        </IconButton>
                    </Tooltip>

                </ButtonGroup>
            );
        }

    };

    const dateTemplate = (rowData: any) => {
        if (!rowData?.HomeVisitDueDate || rowData.HomeVisitDueDate.length === 0) {
            return <span>No Visits</span>;
        }

        return (
            <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
                {rowData.HomeVisitDueDate.map((visit: any, index: number) => {
                    return (
                        <li key={index} style={{
                            color: visit?.Date
                                ? new Date(visit.Date) < today && visit?.Complete !== true
                                    ? 'red'
                                    : (new Date(visit.Date) >= today && new Date(visit.Date) <= new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000))
                                        ? 'orange'
                                        : 'black'
                                : 'black'
                        }}>
                            {visit.Date ? formatDate(new Date(visit.Date + "T00:00:00Z")) : "No Date"}

                        </li>

                    )
                })}
            </ul>
        );
    };

    const handleFormSubmit = (formData: any) => {
        setDarTbl(null)
        setSubmitted(true)
        let q_person = query.get("person");
        if (q_person) {
            let l = new JAOS()
            l.delFromStack(q_person);
        }
        if (formData)
            RefreshTrigger()

        setEditingRecord(null)
        handleTabStateChange('2')
    };

    const handleTabStateChange = (tab: any, edit: boolean = false) => {
        if (tab === '2') {
            setTabModLabelValue('CREATE NEW')
            setEditingRecord(null)
        } else if (tab === '1') {
            if (!edit) {
                setEditingRecord(null)
                setTabModLabelValue('CREATE NEW')
            } else {
                if (isUserPermission('GUNOFFENDER_EDIT')) {
                    setTabModLabelValue('EDIT')
                }
                else if (isUserPermission('GUNOFFENDER')) {
                    setTabModLabelValue('VIEW')
                }
            }
        }

        setTabValue(tab)
    }

    return (
        <div className="m-5 p-5">
            <Box className="" style={{ justifyContent: 'center' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} variant="fullWidth">
                            <Tab icon={<FormatListBulleted />} label="LIST VIEW" value={'2'} />
                            <Tab icon={<PostAdd />} label={`${tabModLabelValue} ENTRY`} value={'1'} />
                        </TabList>
                    </Box>
                    <TabPanel value='2'>
                        {searchType === "GORU Search" && (
                            <SearchResults Persons={Persons} SecondaryTable={SecondaryTable} showPlate={false} />
                        ) }

                        {searchType === "Visit Date" && (
                            <div className={"pl-10 pr-10 mt-5"}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        style={{ height: "10%", width: "10%" }}
                                        src={DCLogo}
                                        alt="DC Gun Offender"
                                    />
                                </Box>

                                <h4 className={"font-bold pl-5"}>
                                    {typedRecords?.length} Records Found
                                </h4>
                                <h4 className="text-red-500 font-bold pl-5">
                                    Red visit dates mark visits that are late and incomplete
                                </h4>
                                <p style={{ color: 'orange', paddingLeft: '20px', fontWeight: 'bold' }}>
                                    Orange visit dates mark visits that must be completed within 30 days
                                </p>

                                {!isLoading && typedRecords && typedRecords.length > 0 &&
                                    <GridComponent
                                        dataSource={typedRecords}
                                        allowPaging={true}
                                        pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                                        allowSorting={true}
                                        allowMultiSorting={true}
                                        allowFiltering={true}
                                        filterSettings={{ type: 'CheckBox' }}
                                        allowTextWrap={true}
                                        textWrapSettings={{ wrapMode: 'Both' }}
                                        allowExcelExport={true}
                                        allowPdfExport={true}
                                        toolbar={['ExcelExport', 'Search']}
                                        toolbarClick={GridToolsClick}
                                        ref={(g) => { if (g) { grid = g } }}
                                        allowSelection={true}
                                        allowResizing={true}
                                        rowHeight={24}
                                    >
                                        <ColumnsDirective>
                                            <ColumnDirective field='CreateDate' visible={true} width={140} template={(r: any) => (formatDT(r.CreateDate))} minWidth={140} maxWidth={140} headerText='Report Created' customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective template={dateTemplate} headerText="Visit Dates" width={140} minWidth={140} maxWidth={140} textAlign="Center" />
                                            <ColumnDirective field='FirstName' width={140} minWidth={140} maxWidth={140} headerText='First Name' customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='LastName' width={140} minWidth={140} maxWidth={140} headerText='Last Name' customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='DOB' allowSorting={false} template={(r: any) => (r.DOB)} width={140} minWidth={140} maxWidth={140} headerText='DOB' customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='PDID' width={140} minWidth={140} maxWidth={140} headerText='PDID' customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='OffenderAddress' width={120} minWidth={120} maxWidth={120} headerText='Address' customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective template={actionTemplate} headerText="Actions" width="135" textAlign="Center" />
                                        </ColumnsDirective>
                                        <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder, CommandColumn]} />
                                    </GridComponent>
                                }
                            </div>
                        ) }
                    </TabPanel>
                    <TabPanel value='1'>
                        {tabValue === '1' && <GunOffenderInputForm handleReportSubmit={handleFormSubmit} editForm={editingRecord} gunOffender={darTbl} />}
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}

export default GunOffenderSearchResults;