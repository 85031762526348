import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    FormControlLabel, FormLabel, RadioGroup, Radio,
    TextField, InputLabel, Select, MenuItem, FormGroup, CircularProgress
} from "@mui/material";
import React, { useEffect } from "react";
import NCICAttributes from "../../interfaces/NCIC.interface";
import { RunGetDept, RunGetNCIC, RunSetDept, RunSetNCIC } from "../../services/account.service";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import { JAOS } from "../../services/JAOS.service";
import { formatDate } from "../../services/formatDate.service";
import { CardActionArea } from "@mui/material";
import {SiteName} from "../../services/config.service";
import {SITE} from "../../interfaces/config.interface";
import {MultiSelect} from "primereact/multiselect";

interface NCICProps {
    ID: string | null
    Hash: string | null
}

const NCIC_WALES2: React.FC<NCICProps> = ({ ID, Hash = null }) => {
    const [params, setParams] = React.useState<NCICAttributes>({})

    const [qwa_params, setParamsQwa] = React.useState<NCICAttributes>({})
    const [dq_params, setParamsDq] = React.useState<NCICAttributes>({})
    const [qv_params, setParamsQv] = React.useState<NCICAttributes>({})
    const [rq_params, setParamsRq] = React.useState<NCICAttributes>({})
    const [qg_params, setParamsQg] = React.useState<NCICAttributes>({})
    const [regn_params, setParamsRegn] = React.useState<NCICAttributes>({})
    const [qarn_params, setParamsQarn] = React.useState<NCICAttributes>({})
    const [iii_params, setParamsIii] = React.useState<NCICAttributes>({})


    const [error, setError] = React.useState<string>("")
    const [validation, setValidation] = React.useState<string>("")
    
    const [mke_qwa, setMkeQwa] = React.useState<boolean>(false)
    const [mke_dq, setMkeDq] = React.useState<boolean>(false)
    const [mke_qv, setMkeQv] = React.useState<boolean>(false)
    const [mke_rq, setMkeRq] = React.useState<boolean>(false)
    const [mke_qg, setMkeQg] = React.useState<boolean>(false)
    const [mke_regn, setMkeRegn] = React.useState<boolean>(false)
    const [mke_qarn, setMkeQarn] = React.useState<boolean>(false)
    const [mke_iii, setMkeIii] = React.useState<boolean>(false)

    const [running_qwa, setRunningQwa] = React.useState<boolean>(false)
    const [running_dq, setRunningDq] = React.useState<boolean>(false)
    const [running_qv, setRunningQv] = React.useState<boolean>(false)
    const [running_rq, setRunningRq] = React.useState<boolean>(false)
    const [running_qg, setRunningQg] = React.useState<boolean>(false)
    const [running_regn, setRunningRegn] = React.useState<boolean>(false)
    const [running_qarn, setRunningQarn] = React.useState<boolean>(false)
    const [running_iii, setRunningIii] = React.useState<boolean>(false)
 

    const LoadNCICData = async () => {
        try {
            let obj = new JAOS()
            let res = obj.objFromStack(Hash ? Hash : "")
            let r = await RunGetNCIC({})
            if(r.AnyData){
                 r.AnyData.SEX = r.AnyData.SEX ? r.AnyData.SEX : ''; 
             }
            setParams(r.AnyData)
            
            let tmp_qwa = JSON.parse(JSON.stringify(r.AnyData))
            let tmp_dq = JSON.parse(JSON.stringify(r.AnyData))
            let tmp_qv = JSON.parse(JSON.stringify(r.AnyData))
            let tmp_rq = JSON.parse(JSON.stringify(r.AnyData))
            let tmp_qg = JSON.parse(JSON.stringify(r.AnyData))
            let tmp_regn = JSON.parse(JSON.stringify(r.AnyData))
            let tmp_qarn = JSON.parse(JSON.stringify(r.AnyData))
            let tmp_iii = JSON.parse(JSON.stringify(r.AnyData))
            
            if(res){
                if(res.FName && res.LName){
                    tmp_qwa = { ...tmp_qwa, FName: res.FName, LName: res.LName, DOB: res.DOB }
                    tmp_dq = { ...tmp_dq, FName: res.FName, LName: res.LName, DOB: res.DOB }
                    tmp_regn = { ...tmp_regn, FName: res.FName, LName: res.LName, DOB: res.DOB }
                    tmp_qarn = { ...tmp_qarn, FName: res.FName, LName: res.LName, DOB: res.DOB }
                    tmp_iii = { ...tmp_iii, FName: res.FName, LName: res.LName, DOB: res.DOB }
                    //QWA DQ III REGN QARN
                    setMkeQwa(true)
                    setMkeDq(true)
                    setMkeRegn(true)
                    setMkeQarn(true)
                    setMkeIii(true)
                }
                if(res.DLNo){
                    tmp_dq = { ...tmp_dq, DLNo: res.DLNo }
                    tmp_qarn = { ...tmp_qarn, DLNo: res.DLNo }
                    setMkeDq(true)
                    setMkeQarn(true)
                }
                if(res.FBINo){
                    tmp_iii = { ...tmp_iii, FBINo: res.FBINo }
                    setMkeIii(true)
                }
                if(res.SBINo){
                    tmp_iii = { ...tmp_iii, SBINo: res.SBINo }
                    setMkeIii(true)
                }
                if(res.PlateNumber){
                    tmp_qv = { ...tmp_qv, PlateNumber: res.PlateNumber, PlateSt: 'DC' }
                    tmp_rq = { ...tmp_rq, PlateNumber: res.PlateNumber, PlateSt: 'DC' }
                    setMkeQv(true)
                    setMkeRq(true)
                }
            }
            setParamsQwa(tmp_qwa)
            setParamsDq(tmp_dq)
            setParamsQv(tmp_qv)
            setParamsRq(tmp_rq)
            setParamsQg(tmp_qg)
            setParamsRegn(tmp_regn)
            setParamsQarn(tmp_qarn)
            setParamsIii(tmp_iii)
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
            LoadNCICData()
    }, [Hash]);

    const handleValidation = () => {
        switch (params.SearchType) {
            case "Name":
                if ((params.FName?.length !== 0) &&
                    (params.FName?.length !== undefined) && 
                    (params.LName?.length !== 0) &&
                    (params.LName?.length !== undefined))                  
                {
                    if ((params.DOB?.length !== 0) &&
                        (params.DOB?.length !== undefined)) {
                            return true;
                        }
                        else {
                            setValidation("Please enter a valid DOB")
                            return false;
                        }
                }
                else {
                    setValidation("Please enter First Name and Last Name")
                    return false;
                }

                break;
            case "DL":
                if (params.DLNo?.length !== 0 &&
                    params.DLNo?.length !== undefined &&
                    params.DLSt?.length !== 0 &&
                    params.DLSt?.length !== undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid DL Number and DL State")
                    return false;
                }
                break;
            case "Plate1":
                if (params.PlateNumber?.length !== 0 &&
                    params.PlateNumber?.length !== undefined &&
                    params.PlateSt?.length !== 0 &&
                    params.PlateSt?.length !== undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid Plate and Plate State for Plate #1")
                    return false;
                }
                break;
            case "Plate2":
                if (params.PlateNumber2?.length != 0 &&
                    params.PlateNumber2?.length != undefined &&
                    params.PlateSt2?.length != 0 &&
                    params.PlateSt2?.length != undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid Plate and Plate State for Plate #2")
                    return false;
                }
                break;
            case "Plate3":
                if (params.PlateNumber3?.length !== 0 &&
                    params.PlateNumber3?.length !== undefined &&
                    params.PlateSt3?.length !== 0 &&
                    params.PlateSt3?.length !== undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid Plate and Plate State for Plate #3")
                    return false;
                }
                break;
            case "VIN":
                if (params.VIN?.length !== 0 &&
                    params.VIN?.length !== undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid VIN")
                    return false;
                }
                break;
             case "III (QH)":
             case "III (QR)":
             case "GUN (QG)":
             case "QWA":
             case "DQ":
             case "QV":
             case "RQ":
             case "QG":
             case "REGN":
             case "QARN":
             case "III":
                    return true;
                break;
        }
        return false;
    }

    const handleClearForm = async () => {
        handleClear()
        window.location.reload();
    }

    const handleSearch = async () => {
        //handleClear()

        setRunningQwa(mke_qwa)
        setRunningDq(mke_dq)
        setRunningQv(mke_qv)
        setRunningRq(mke_rq)
        setRunningQg(mke_qg)
        setRunningRegn(mke_regn)
        setRunningQarn(mke_qarn)
        setRunningIii(mke_iii)

        setParamsQwa({ ...qwa_params, FormattedResponse: null })
        setParamsDq({ ...dq_params, FormattedResponse: null })
        setParamsQv({ ...qv_params, FormattedResponse: null })
        setParamsRq({ ...rq_params, FormattedResponse: null })
        setParamsQg({ ...qg_params, FormattedResponse: null })
        setParamsRegn({ ...regn_params, FormattedResponse: null })
        setParamsQarn({ ...qarn_params, FormattedResponse: null })
        setParamsIii({ ...iii_params, FormattedResponse: null })

        if(mke_qwa){
            setRunningQwa(true)
            let r = await RunSetNCIC({ ...params, SearchType: 'QWA' })
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0 && r.AnyData.Response?.length !== 0) {
                setParamsQwa({ ...qwa_params, FormattedResponse: JSON.parse(r.AnyData.Response) })
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",");
                setError(errors)
            }
            setRunningQwa(false)
        }
        if(mke_dq){
            setRunningDq(true)
            let r = await RunSetNCIC({ ...params, SearchType: 'DQ' })
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0 && r.AnyData.Response?.length !== 0) {
                setParamsDq({ ...dq_params, FormattedResponse: JSON.parse(r.AnyData.Response) })
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",");
                setError(errors)
            }
            setRunningDq(false)
        }
        if(mke_qv){
            setRunningQv(true)
            let r = await RunSetNCIC({ ...params, SearchType: 'QV' })
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0 && r.AnyData.Response?.length !== 0) {
                setParamsQv({ ...qv_params, FormattedResponse: JSON.parse(r.AnyData.Response) })
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",");
                setError(errors)
            }
            setRunningQv(false)
        }
        if(mke_rq){
            setRunningRq(true)
            let r = await RunSetNCIC({ ...params, SearchType: 'RQ' })
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0 && r.AnyData.Response?.length !== 0) {
                setParamsRq({ ...rq_params, FormattedResponse: JSON.parse(r.AnyData.Response) })
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",");
                setError(errors)
            }
            setRunningRq(false)
        }
        if(mke_qg){
            setRunningQg(true)
            let r = await RunSetNCIC({ ...params, SearchType: 'QG' })
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0 && r.AnyData.Response?.length !== 0) {
                setParamsQg({ ...qg_params, FormattedResponse: JSON.parse(r.AnyData.Response) })
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",");
                setError(errors)
            }
            setRunningQg(false)
        }
        if(mke_regn){
            setRunningRegn(true)
            let r = await RunSetNCIC({ ...params, SearchType: 'REGN' })
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0 && r.AnyData.Response?.length !== 0) {
                setParamsRegn({ ...regn_params, FormattedResponse: JSON.parse(r.AnyData.Response) })
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",");
                setError(errors)
            }
            setRunningRegn(false)
        }
        if(mke_qarn){
            setRunningQarn(true)
            let r = await RunSetNCIC({ ...params, SearchType: 'QARN' })
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0 && r.AnyData.Response?.length !== 0) {
                setParamsQarn({ ...qarn_params, FormattedResponse: JSON.parse(r.AnyData.Response) })
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",");
                setError(errors)
            }
            setRunningQarn(false)
        }
        if(mke_iii){
            setRunningIii(true)
            let r = await RunSetNCIC({ ...params, SearchType: 'III' })
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0 && r.AnyData.Response?.length !== 0) {
                setParamsIii({ ...iii_params, FormattedResponse: JSON.parse(r.AnyData.Response) })
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",");
                setError(errors)
            }
            setRunningIii(false)
        }
        
    }

    const handleClear = () => {
        setValidation("");
        setError("");
        let p = { ...params }
        p.FormattedResponse = {};
        setParams(p);
    }

    return (
        <div className="ml-5 p-5">
            {(params && params?.NCICSexList && params?.NCICSexList.length > 0 ?
                <>
                    <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto" >
                        <CardContent>
                            <span className="text-blue-400 font-bold text-xl " >Search Based On</span>
                            <br />
                            <span className="ml-5 ">
                               {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={mke_qwa} onChange={()=>{setMkeQwa(!mke_qwa)}} name="QWA" />} label="QWA - Wanted Person - All"/>
                                            <FormControlLabel control={<Checkbox checked={mke_dq} onChange={()=>{setMkeDq(!mke_dq)}} name="DQ" />} label="DQ - Drivers License"/>
                                            <FormControlLabel control={<Checkbox checked={mke_qv} onChange={()=>{setMkeQv(!mke_qv)}} name="QV" />} label="QV - Vehicle"/>
                                            <FormControlLabel control={<Checkbox checked={mke_rq} onChange={()=>{setMkeRq(!mke_rq)}} name="RQ" />} label="RQ - Vehicle Registration"/>
                                            <FormControlLabel control={<Checkbox checked={mke_qg} onChange={()=>{setMkeQg(!mke_qg)}} name="QG" />} label="QG - Gun"/>
                                            <FormControlLabel control={<Checkbox checked={mke_regn} onChange={()=>{setMkeRegn(!mke_regn)}} name="REGN" />} label="REGN - DC Vehicle Registration"/>
                                            <FormControlLabel control={<Checkbox checked={mke_qarn} onChange={()=>{setMkeQarn(!mke_qarn)}} name="QARN" />} label="QARN - NCIC Wanted, DC Person Status and Out of State and DC Drivers Permit"/>
                                            <FormControlLabel control={<Checkbox checked={mke_iii} onChange={()=>{setMkeIii(!mke_iii)}} name="III" />} label="QR/QH - III"/>
                                        </FormGroup>
                                    </FormControl>
                                </>}
                                
                            </span>
                        </CardContent>
                    </Card>


                    <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                        <CardContent>
                            <span className="text-blue-400 font-bold text-xl " >Person Information</span>
                            <br />
                            <div className="grid grid-cols-3 mt-5">
                                <div className="w-full">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"First Name"}
                                        value={params?.FName}
                                        onChange={e => setParams({ ...params, FName: e.target.value })}
                                    />
                                </div>
                                <div className="w-full pr-10 pl-10">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="Middle Initial"
                                        value={params?.MName}
                                        onChange={e => setParams({ ...params, MName: e.target.value })}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"Last Name"}
                                        value={params?.LName}
                                        onChange={e => setParams({ ...params, LName: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        type={"date"}
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"DOB"}
                                        value={params?.DOB?.substring(0, 10)}
                                        onChange={e => setParams({ ...params, DOB: e.target.value })}
                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="DeptGroupLabel" >Sex</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="DeptGroupLabel"
                                            label="Sex"
                                            value={params?.SEX}
                                            onChange={e => setParams({ ...params, SEX: e.target.value })}
                                        >
                                            {params.NCICSexList && params?.NCICSexList.map((sex) => (
                                                <MenuItem value={sex.Key}>{sex.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"DL #"}
                                        value={params?.DLNo}
                                        onChange={e => setParams({ ...params, DLNo: e.target.value })}
                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="DeptGroupLabel" >DL State</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="DeptGroupLabel"
                                            label="DL State"
                                            value={params?.DLSt}
                                            onChange={e => setParams({ ...params, DLSt: e.target.value })}
                                        >
                                            {params.NCICPlateStateList && params?.NCICPlateStateList.map((plate) => (
                                                <MenuItem value={plate.Key}>{plate.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            type={"text"}
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"FBI #"}
                                            value={params?.FBINo}
                                            onChange={e => setParams({ ...params, FBINo: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <TextField
                                            size="small"
                                            type={"text"}
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"SID"}
                                            value={params?.SBINo}
                                            onChange={e => setParams({ ...params, SBINo: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                    <div className="w-full pr-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >Race</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="Race"
                                                value={params?.RAC}
                                                onChange={e => setParams({ ...params, RAC: e.target.value })}
                                            >
                                                <MenuItem value="I">American Indian or Alaskan Native</MenuItem>
                                                <MenuItem value="A">Asian or Pacific Islander</MenuItem>
                                                <MenuItem value="B">Black</MenuItem>
                                                <MenuItem value="W">White</MenuItem>
                                                <MenuItem value="U">Unknown</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </>}

                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                        <CardContent>
                            <span className="text-blue-400 font-bold text-xl " >Vehicle Information</span>
                            <br />
                            <div className="grid grid-cols-2 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"Plate #1"}
                                        value={params?.PlateNumber}
                                        onChange={e => setParams({ ...params, PlateNumber: e.target.value })}
                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="DeptGroupLabel" >State #1</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="DeptGroupLabel"
                                            label="State #1"
                                            value={params?.PlateSt}
                                            onChange={e => setParams({ ...params, PlateSt: e.target.value })}
                                        >
                                            {params.NCICPlateStateList && params?.NCICPlateStateList.map((plate) => (
                                                <MenuItem value={plate.Key}>{plate.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"Plate #2"}
                                            value={params?.PlateNumber2}
                                            onChange={e => setParams({ ...params, PlateNumber2: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >State #2</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="State #2"
                                                value={params?.PlateSt2}
                                                onChange={e => setParams({ ...params, PlateSt2: e.target.value })}
                                            >
                                                {params.NCICPlateStateList && params?.NCICPlateStateList.map((plate) => (
                                                    <MenuItem value={plate.Key}>{plate.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"Plate #3"}
                                            value={params?.PlateNumber3}
                                            onChange={e => setParams({ ...params, PlateNumber3: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >State #3</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="State #3"
                                                value={params?.PlateSt3}
                                                onChange={e => setParams({ ...params, PlateSt3: e.target.value })}
                                            >
                                                {params.NCICPlateStateList && params?.NCICPlateStateList.map((plate) => (
                                                    <MenuItem value={plate.Key}>{plate.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                </>}

                            </div>
                            <div className="grid grid-cols-1 mt-5 gap-y-5">
                                <div className="w-full">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="VIN"
                                        value={params?.VIN}
                                        onChange={e => setParams({ ...params, VIN: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="PlateLabel" >Plate Type</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="PlateLabel"
                                            label="Plate Type"
                                            value={params?.PlateType}
                                            onChange={e => setParams({ ...params, PlateType: e.target.value })}
                                        >
                                            {params.NCICPlateTypeList && params?.NCICPlateTypeList.map((type) => (
                                                <MenuItem value={type.Key}>{type.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="w-full pl-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="VehicleLabel" >Vehicle Type</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="VehicleLabel"
                                            label="Vehicle Type"
                                            value={params?.VehicleType}
                                            onChange={e => setParams({ ...params, VehicleType: e.target.value })}
                                        >
                                            {params.NCICVehicleTypeList && params?.NCICVehicleTypeList.map((type) => (
                                                <MenuItem value={type.Key}>{type.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                            <CardContent>
                                <span className="text-blue-400 font-bold text-xl " >Gun Information</span>
                                <br />
                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"Gun Serial #"}
                                            value={params?.GSERIAL}
                                            onChange={e => setParams({ ...params, GSERIAL: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >Gun Make</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="Gun Make"
                                                value={params?.GMAKE}
                                                onChange={e => setParams({ ...params, GMAKE: e.target.value })}
                                            >
                                                {params.NCICGunMakeList && params?.NCICGunMakeList.map((dd) => (
                                                    <MenuItem value={dd.Key}>{dd.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"Gun Model"}
                                            value={params?.GMODEL}
                                            onChange={e => setParams({ ...params, GMODEL: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >Gun Caliber</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="Gun Caliber"
                                                value={params?.GCAL}
                                                onChange={e => setParams({ ...params, GCAL: e.target.value })}
                                            >
                                                {params.NCICGunCaliberList && params?.NCICGunCaliberList.map((dd) => (
                                                    <MenuItem value={dd.Key}>{dd.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </>}


                    <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                        <CardContent>
                            <span className="text-blue-400 font-bold text-xl " >Notes</span>
                            {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            type={"text"}
                                            disabled={true}
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"Attention"}
                                            value={params?.ATN}
                                            onChange={e => setParams({ ...params, ATN: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="IIIPur" >Purpose</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="IIIPur"
                                                label="Purpose"
                                                value={params?.PUR}
                                                onChange={e => setParams({ ...params, PUR: e.target.value })}
                                            >
                                                <MenuItem value="A">ADMINISTRATIVE FILE MAINTENANCE</MenuItem>
                                                <MenuItem value="C">CRIMINAL</MenuItem>
                                                <MenuItem value="D">DOMESTIC VIOLENCE AND/OR STALKING CASE</MenuItem>
                                                <MenuItem value="F">FIREARMS RELATED CHECKS</MenuItem>
                                                <MenuItem value="J">CRIMINAL JUSTICE EMPLOYMENT</MenuItem>
                                                <MenuItem value="X">EXIGENT PROCEDURES</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="w-full mt-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        margin='none'
                                        className="w-full"
                                        label={"Reason"}
                                        value={params?.REA}
                                        onChange={e => setParams({ ...params, REA: e.target.value })}
                                    />
                                </div>
                            </>}

                            <div className="w-full mt-5">
                                <TextField
                                    size="small"
                                    multiline minRows={4}
                                    InputLabelProps={{ 'shrink': true }}
                                    margin='none'
                                    className="w-full"
                                    label={"Comments"}
                                    value={params?.SearchNotes}
                                    onChange={e => setParams({ ...params, SearchNotes: e.target.value })}
                                />
                            </div>
                            <div className="grid grid-cols-2 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        disabled
                                        variant='filled'
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="EmployeeID"
                                        value={params?.EmpID}
                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <TextField
                                        size="small"
                                        disabled
                                        variant='filled'
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="Department"
                                        value={params?.DeptID}
                                    />
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    
                    

                    {(running_qwa || running_dq || running_qv || running_rq || running_qg || running_regn || running_qarn || running_iii) && <div className="mx-auto mt-5" style={{maxWidth:900, minWidth:900, textAlign:"center"}}><CircularProgress/></div>}
                    {(mke_qwa && qwa_params?.FormattedResponse && Object.keys(qwa_params?.FormattedResponse).length !== 0) &&
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" variant="outlined" >
                            <CardActionArea>
                                <CardHeader className="bg-black text-blue-400 font-bold text-xl " title="WALES RESPONSE (QWA)" />
                                <CardContent className="bg-black">
                                    { qwa_params?.FormattedResponse?.Photos && <>
                                        { qwa_params?.FormattedResponse?.Photos?.Photo &&  qwa_params?.FormattedResponse?.Photos?.Photo.length>0 && <>
                                            { qwa_params?.FormattedResponse?.Photos?.Photo.map((p:any,i:any) => (<>
                                                <img src={`data:image/jpeg;base64,${p}`} alt={''} style={{display:'inline', width:'75px', margin:'3px'}} />
                                            </>))}
                                        </>}
                                    </>}
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{qwa_params?.FormattedResponse?.Keywords}</pre>
                                    <br />
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{qwa_params?.FormattedResponse?.FormattedDMVRsp}</pre>
                                    <br />
                                    <pre className={"text-white pl-5 pr-5 width-100% "} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{qwa_params?.FormattedResponse?.FormattedNCICRsp}</pre>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    }

                    {(mke_dq && dq_params?.FormattedResponse && Object.keys(dq_params?.FormattedResponse).length !== 0) &&
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" variant="outlined" >
                            <CardActionArea>
                                <CardHeader className="bg-black text-blue-400 font-bold text-xl " title="WALES RESPONSE (DQ)" />
                                <CardContent className="bg-black">
                                    { dq_params?.FormattedResponse?.Photos && <>
                                        { dq_params?.FormattedResponse?.Photos?.Photo &&  dq_params?.FormattedResponse?.Photos?.Photo.length>0 && <>
                                            { dq_params?.FormattedResponse?.Photos?.Photo.map((p:any,i:any) => (<>
                                                <img src={`data:image/jpeg;base64,${p}`} alt={''} style={{display:'inline', width:'75px', margin:'3px'}} />
                                            </>))}
                                        </>}
                                    </>}
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{dq_params?.FormattedResponse?.Keywords}</pre>
                                    <br />
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{dq_params?.FormattedResponse?.FormattedDMVRsp}</pre>
                                    <br />
                                    <pre className={"text-white pl-5 pr-5 width-100% "} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{dq_params?.FormattedResponse?.FormattedNCICRsp}</pre>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    }
                    
                    {(mke_qv && qv_params?.FormattedResponse && Object.keys(qv_params?.FormattedResponse).length !== 0) &&
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" variant="outlined" >
                            <CardActionArea>
                                <CardHeader className="bg-black text-blue-400 font-bold text-xl " title="WALES RESPONSE (QV)" />
                                <CardContent className="bg-black">
                                    { qv_params?.FormattedResponse?.Photos && <>
                                        { qv_params?.FormattedResponse?.Photos?.Photo &&  qv_params?.FormattedResponse?.Photos?.Photo.length>0 && <>
                                            { qv_params?.FormattedResponse?.Photos?.Photo.map((p:any,i:any) => (<>
                                                <img src={`data:image/jpeg;base64,${p}`} alt={''} style={{display:'inline', width:'75px', margin:'3px'}} />
                                            </>))}
                                        </>}
                                    </>}
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{qv_params?.FormattedResponse?.Keywords}</pre>
                                    <br />
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{qv_params?.FormattedResponse?.FormattedDMVRsp}</pre>
                                    <br />
                                    <pre className={"text-white pl-5 pr-5 width-100% "} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{qv_params?.FormattedResponse?.FormattedNCICRsp}</pre>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    }
                    
                    {(mke_rq && rq_params?.FormattedResponse && Object.keys(rq_params?.FormattedResponse).length !== 0) &&
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" variant="outlined" >
                            <CardActionArea>
                                <CardHeader className="bg-black text-blue-400 font-bold text-xl " title="WALES RESPONSE (RQ)" />
                                <CardContent className="bg-black">
                                    { rq_params?.FormattedResponse?.Photos && <>
                                        { rq_params?.FormattedResponse?.Photos?.Photo &&  rq_params?.FormattedResponse?.Photos?.Photo.length>0 && <>
                                            { rq_params?.FormattedResponse?.Photos?.Photo.map((p:any,i:any) => (<>
                                                <img src={`data:image/jpeg;base64,${p}`} alt={''} style={{display:'inline', width:'75px', margin:'3px'}} />
                                            </>))}
                                        </>}
                                    </>}
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{rq_params?.FormattedResponse?.Keywords}</pre>
                                    <br />
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{rq_params?.FormattedResponse?.FormattedDMVRsp}</pre>
                                    <br />
                                    <pre className={"text-white pl-5 pr-5 width-100% "} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{rq_params?.FormattedResponse?.FormattedNCICRsp}</pre>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    }
                    
                    {(mke_qg && qg_params?.FormattedResponse && Object.keys(qg_params?.FormattedResponse).length !== 0) &&
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" variant="outlined" >
                            <CardActionArea>
                                <CardHeader className="bg-black text-blue-400 font-bold text-xl " title="WALES RESPONSE (QG)" />
                                <CardContent className="bg-black">
                                    { qg_params?.FormattedResponse?.Photos && <>
                                        { qg_params?.FormattedResponse?.Photos?.Photo &&  qg_params?.FormattedResponse?.Photos?.Photo.length>0 && <>
                                            { qg_params?.FormattedResponse?.Photos?.Photo.map((p:any,i:any) => (<>
                                                <img src={`data:image/jpeg;base64,${p}`} alt={''} style={{display:'inline', width:'75px', margin:'3px'}} />
                                            </>))}
                                        </>}
                                    </>}
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{qg_params?.FormattedResponse?.Keywords}</pre>
                                    <br />
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{qg_params?.FormattedResponse?.FormattedDMVRsp}</pre>
                                    <br />
                                    <pre className={"text-white pl-5 pr-5 width-100% "} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{qg_params?.FormattedResponse?.FormattedNCICRsp}</pre>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    }
                    
                    {(mke_regn && regn_params?.FormattedResponse && Object.keys(regn_params?.FormattedResponse).length !== 0) &&
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" variant="outlined" >
                            <CardActionArea>
                                <CardHeader className="bg-black text-blue-400 font-bold text-xl " title="WALES RESPONSE (REGN)" />
                                <CardContent className="bg-black">
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{regn_params?.FormattedResponse?.Keywords}</pre>
                                    <br />
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{regn_params?.FormattedResponse?.FormattedDMVRsp}</pre>
                                    <br />
                                    <pre className={"text-white pl-5 pr-5 width-100% "} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{regn_params?.FormattedResponse?.FormattedNCICRsp}</pre>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    }
                    
                    {(mke_qarn && qarn_params?.FormattedResponse && Object.keys(qarn_params?.FormattedResponse).length !== 0) &&
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" variant="outlined" >
                            <CardActionArea>
                                <CardHeader className="bg-black text-blue-400 font-bold text-xl " title="WALES RESPONSE (QARN)" />
                                <CardContent className="bg-black">
                                    { qarn_params?.FormattedResponse?.Photos && <>
                                        { qarn_params?.FormattedResponse?.Photos?.Photo &&  qarn_params?.FormattedResponse?.Photos?.Photo.length>0 && <>
                                            { qarn_params?.FormattedResponse?.Photos?.Photo.map((p:any,i:any) => (<>
                                                <img src={`data:image/jpeg;base64,${p}`} alt={''} style={{display:'inline', width:'75px', margin:'3px'}} />
                                            </>))}
                                        </>}
                                    </>}
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{qarn_params?.FormattedResponse?.Keywords}</pre>
                                    <br />
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{qarn_params?.FormattedResponse?.FormattedDMVRsp}</pre>
                                    <br />
                                    <pre className={"text-white pl-5 pr-5 width-100% "} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{qarn_params?.FormattedResponse?.FormattedNCICRsp}</pre>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    }

                    
                    {(mke_iii && iii_params?.FormattedResponse && Object.keys(iii_params?.FormattedResponse).length !== 0) &&
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" variant="outlined" >
                            <CardActionArea>
                                <CardHeader className="bg-black text-blue-400 font-bold text-xl " title="WALES RESPONSE (III)" />
                                <CardContent className="bg-black">
                                    { iii_params?.FormattedResponse?.Photos && <>
                                        { iii_params?.FormattedResponse?.Photos?.Photo &&  iii_params?.FormattedResponse?.Photos?.Photo.length>0 && <>
                                            { iii_params?.FormattedResponse?.Photos?.Photo.map((p:any,i:any) => (<>
                                                <img src={`data:image/jpeg;base64,${p}`} alt={''} style={{display:'inline', width:'75px', margin:'3px'}} />
                                            </>))}
                                        </>}
                                    </>}
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{iii_params?.FormattedResponse?.Keywords}</pre>
                                    <br />
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{iii_params?.FormattedResponse?.FormattedDMVRsp}</pre>
                                    <br />
                                    <pre className={"text-white pl-5 pr-5 width-100% "} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{iii_params?.FormattedResponse?.FormattedNCICRsp}</pre>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    }
                    
                    <div className="mt-8 flex justify-center">
                        <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                            <Button onClick={handleSearch}>Search</Button>
                            <Button onClick={handleClearForm}>Clear</Button>
                        </ButtonGroup>
                    </div>
                    {error?.length !== 0 ? <div className="text-red-400 font-bold text-base text-center mt-1">{error}</div> : <div></div>}
                    {validation?.length !== 0 ? <div className="text-red-400 font-bold text-base text-center mt-1">{validation}</div> : <div></div>}
                </>
                : <div className="text-blue-400 text-6xl font-bold"></div>)}
        </div>
    );
}

export default NCIC_WALES2;
