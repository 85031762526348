import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    FormControlLabel, FormLabel, RadioGroup, Radio,
    TextField, InputLabel, Select, MenuItem, FormGroup
} from "@mui/material";
import React, { useEffect } from "react";
import NCICAttributes from "../../interfaces/NCIC.interface";
import { RunGetDept, RunGetNCIC, RunSetDept, RunSetNCIC } from "../../services/account.service";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import { JAOS } from "../../services/JAOS.service";
import { formatDate } from "../../services/formatDate.service";
import { CardActionArea } from "@mui/material";
import {SiteName} from "../../services/config.service";
import {GetStringNameForSite, SITE} from "../../interfaces/config.interface";
import {MultiSelect} from "primereact/multiselect";

interface NCICProps {
    ID: string | null
    Hash: string | null
}

const NCIC: React.FC<NCICProps> = ({ ID, Hash = null }) => {
    const [params, setParams] = React.useState<NCICAttributes>({})
    const [error, setError] = React.useState<string>("")
    const [validation, setValidation] = React.useState<string>("")
    const [mke_qwa, setMkeQwa] = React.useState<boolean>(false)
    const [mke_dq, setMkeDq] = React.useState<boolean>(false)
    const [mke_qv, setMkeQv] = React.useState<boolean>(false)
    const [mke_rq, setMkeRq] = React.useState<boolean>(false)
    const [mke_qg, setMkeQg] = React.useState<boolean>(false)
    const [mke_regn, setMkeRegn] = React.useState<boolean>(false)
    const [mke_qwarn, setMkeQwarn] = React.useState<boolean>(false)
    const [mke_iii, setMkeIii] = React.useState<boolean>(false)
 

    const LoadNCICData = async () => {
        try {
            let obj = new JAOS()
            let res = obj.objFromStack(Hash ? Hash : "")
            
            let r = await RunGetNCIC(res)
            await setParams(r.AnyData)
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (Hash !== null)
            LoadNCICData()
    }, [Hash]);

    const handleValidation = (p : NCICAttributes) => {
        switch (p.SearchType) {
            case "Name":
                if ((p.FName?.length !== 0) &&
                    (p.FName?.length !== undefined) && 
                    (p.LName?.length !== 0) &&
                    (p.LName?.length !== undefined))                  
                {
                    if ((p.DOB?.length !== 0) &&
                        (p.DOB?.length !== undefined)) {
                            return true;
                        }
                        else {
                            setValidation("Please enter a valid DOB")
                            return false;
                        }
                }
                else {
                    setValidation("Please enter First Name and Last Name")
                    return false;
                }

                break;
            case "DL":
                if (p.DLNo?.length !== 0 &&
                    p.DLNo?.length !== undefined &&
                    p.DLSt?.length !== 0 &&
                    p.DLSt?.length !== undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid DL Number and DL State")
                    return false;
                }
                break;
            case "Plate1":
                if (p.PlateNumber?.length !== 0 &&
                    p.PlateNumber?.length !== undefined &&
                    p.PlateSt?.length !== 0 &&
                    p.PlateSt?.length !== undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid Plate and Plate State for Plate #1")
                    return false;
                }
                break;
            case "Plate2":
                if (p.PlateNumber2?.length != 0 &&
                    p.PlateNumber2?.length != undefined &&
                    p.PlateSt2?.length != 0 &&
                    p.PlateSt2?.length != undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid Plate and Plate State for Plate #2")
                    return false;
                }
                break;
            case "Plate3":
                if (p.PlateNumber3?.length !== 0 &&
                    p.PlateNumber3?.length !== undefined &&
                    p.PlateSt3?.length !== 0 &&
                    p.PlateSt3?.length !== undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid Plate and Plate State for Plate #3")
                    return false;
                }
                break;
            case "VIN":
                if (p.VIN?.length !== 0 &&
                    p.VIN?.length !== undefined) {
                    return true;
                }
                else {
                    setValidation("Please enter a valid VIN")
                    return false;
                }
                break;
             case "III (QH)":
             case "III (QR)":
             case "GUN (QG)":
             case "QWA":
             case "DQ":
             case "QV":
             case "RQ":
             case "QG":
             case "REGN":
             case "QARN":
             case "III":
             case "WALES":
                    return true;
                break;
        }
        return false;
    }

    const handleClearForm = () => {
        handleClear()
        setParams({
            ...params, FName: "", MName: "", LName: "", DOB: "", DLNo: "", PlateNumber: "", PlateNumber2: "", PlateNumber3: "", VIN: "",
            PlateType: "LICTPC", VehicleType: "VEHTAUTO", DLSt: "NJ", SearchType: "DL", ATN:"", REA:"",PUR:"C", GSERIAL:"",GMAKE:"",GMODEL:"",GCAL:""
        })
    }

    const handleSearch = async () => {
        handleClear()

        let p = { ...params }
        
        if(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL){
            p.SearchType='WALES'
        }
        console.log(p)
        
        if (handleValidation(p)) {
            let r = await RunSetNCIC(p)
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0 && r.AnyData.Response?.length !== 0) {
                setParams({ ...params, FormattedResponse: JSON.parse(r.AnyData.Response) })
                
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",");
                setError(errors)
            }
        }
    }

    const handleClear = () => {
        setValidation("");
        setError("");
        let p = { ...params }
        p.FormattedResponse = {};
        setParams(p);
    }

    return (
        <div className="ml-5 p-5">
            {(params && params?.NCICSexList && params?.NCICSexList.length > 0 ?
                <>
                {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&
                    <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto" >
                        <CardContent>
                            <span className="text-blue-400 font-bold text-xl " >Search Based On</span>
                            <br />
                            <span className="ml-5 ">
                                
                                
                             {/*   {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={mke_qwa} onChange={()=>{setMkeQwa(!mke_qwa)}} name="QWA" />} label="QWA - Wanted Person - All"/>
                                            <FormControlLabel control={<Checkbox checked={mke_dq} onChange={()=>{setMkeDq(!mke_dq)}} name="DQ" />} label="DQ - Drivers License"/>
                                            <FormControlLabel control={<Checkbox checked={mke_qv} onChange={()=>{setMkeQv(!mke_qv)}} name="QV" />} label="QV - Vehicle"/>
                                            <FormControlLabel control={<Checkbox checked={mke_rq} onChange={()=>{setMkeRq(!mke_rq)}} name="RQ" />} label="RQ - Vehicle Registration"/>
                                            <FormControlLabel control={<Checkbox checked={mke_qg} onChange={()=>{setMkeQg(!mke_qg)}} name="QG" />} label="QG - Gun"/>
                                            <FormControlLabel control={<Checkbox checked={mke_regn} onChange={()=>{setMkeRegn(!mke_regn)}} name="REGN" />} label="REGN - DC Vehicle Registration"/>
                                            <FormControlLabel control={<Checkbox checked={mke_qwarn} onChange={()=>{setMkeQwarn(!mke_qwarn)}} name="QWARN" />} label="QWARN - NCIC Wanted, DC Person Status and Out of State and DC Drivers Permit"/>
                                            <FormControlLabel control={<Checkbox checked={mke_iii} onChange={()=>{setMkeIii(!mke_iii)}} name="III" />} label="QR/QH - III"/>
                                        </FormGroup>
                                    </FormControl>
                                </>}*/}
                                
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        row
                                        name="row-radio-buttons-group"
                                        value={params?.SearchType}
                                        onChange={e => setParams({ ...params, SearchType: e.target.value })}
                                    >
                                            <FormControlLabel value="Name" control={<Radio />} label="Name" />
                                            <FormControlLabel value="DL" control={<Radio />} label="DL #" />
                                            <FormControlLabel value="Plate1" control={<Radio />} label="Plate 1" />
                                            <FormControlLabel value="Plate2" control={<Radio />} label="Plate 2" />
                                            <FormControlLabel value="Plate3" control={<Radio />} label="Plate 3" />
                                            <FormControlLabel value="VIN" control={<Radio />} label="VIN #" />
                                        
                                            {/*<FormControlLabel value="III (QH)" control={<Radio />} label="III (Name)" />*/}
                                            {/*<FormControlLabel value="III (QR)" control={<Radio />} label="III (FBI/SID)" />*/}
                                            {/*<FormControlLabel value="GUN (QG)" control={<Radio />} label="Gun (Serial #)" />*/}
                                            {/*<FormControlLabel value="QWA" control={<Radio />} label="QWA - Wanted Person - All" />*/}
                                            {/*<FormControlLabel value="DQ" control={<Radio />} label="DQ - Drivers License" />*/}
                                            {/*<FormControlLabel value="QV" control={<Radio />} label="QV - Vehicle" />*/}
                                            {/*<FormControlLabel value="RQ" control={<Radio />} label="RQ - Vehicle Registration" />*/}
                                            {/*<FormControlLabel value="QG" control={<Radio />} label="QG - Gun" />*/}
                                            {/*<FormControlLabel value="REGN" control={<Radio />} label=" REGN - DC Vehicle Registration" />*/}
                                            {/*<FormControlLabel value="QARN" control={<Radio />} label="QWARN - NCIC Wanted, DC Person Status and Out of State and DC Drivers Permit" />*/}
                                            {/*<FormControlLabel value="III" control={<Radio />} label="QR/QH - III" />*/}
                                        </RadioGroup>
                                    </FormControl>
                            </span>
                        </CardContent>
                    </Card>
                }
                    <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                        <CardContent>
                            <span className="text-blue-400 font-bold text-xl " >Person Information</span>
                            <br />
                            <div className="grid grid-cols-3 mt-5">
                                <div className="w-full">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"First Name"}
                                        value={params?.FName}
                                        onChange={e => setParams({ ...params, FName: e.target.value })}
                                    />
                                </div>
                                <div className="w-full pr-10 pl-10">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="Middle Initial"
                                        value={params?.MName}
                                        onChange={e => setParams({ ...params, MName: e.target.value })}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"Last Name"}
                                        value={params?.LName}
                                        onChange={e => setParams({ ...params, LName: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        type={"date"}
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"DOB"}
                                        value={params?.DOB?.substring(0, 10)}
                                        onChange={e => setParams({ ...params, DOB: e.target.value })}
                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="DeptGroupLabel" >Sex</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="DeptGroupLabel"
                                            label="Sex"
                                            value={params?.SEX}
                                            onChange={e => setParams({ ...params, SEX: e.target.value })}
                                        >
                                            {params.NCICSexList && params?.NCICSexList.map((sex) => (
                                                <MenuItem value={sex.Key}>{sex.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"DL #"}
                                        value={params?.DLNo}
                                        onChange={e => setParams({ ...params, DLNo: e.target.value })}
                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="DeptGroupLabel" >DL State</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="DeptGroupLabel"
                                            label="DL State"
                                            value={params?.DLSt}
                                            onChange={e => setParams({ ...params, DLSt: e.target.value })}
                                        >
                                            {params.NCICPlateStateList && params?.NCICPlateStateList.map((plate) => (
                                                <MenuItem value={plate.Key}>{plate.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            type={"text"}
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"FBI #"}
                                            value={params?.FBINo}
                                            onChange={e => setParams({ ...params, FBINo: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <TextField
                                            size="small"
                                            type={"text"}
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"SID"}
                                            value={params?.SBINo}
                                            onChange={e => setParams({ ...params, SBINo: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                    <div className="w-full pr-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >Race</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="Race"
                                                value={params?.RAC}
                                                onChange={e => setParams({ ...params, RAC: e.target.value })}
                                            >
                                                <MenuItem value="I">American Indian or Alaskan Native</MenuItem>
                                                <MenuItem value="A">Asian or Pacific Islander</MenuItem>
                                                <MenuItem value="B">Black</MenuItem>
                                                <MenuItem value="W">White</MenuItem>
                                                <MenuItem value="U">Unknown</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </>}
 
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                        <CardContent>
                            <span className="text-blue-400 font-bold text-xl " >Vehicle Information</span>
                            <br />
                            <div className="grid grid-cols-2 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"Plate #1"}
                                        value={params?.PlateNumber}
                                        onChange={e => setParams({ ...params, PlateNumber: e.target.value })}
                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="DeptGroupLabel" >State #1</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="DeptGroupLabel"
                                            label="State #1"
                                            value={params?.PlateSt}
                                            onChange={e => setParams({ ...params, PlateSt: e.target.value })}
                                        >
                                            {params.NCICPlateStateList && params?.NCICPlateStateList.map((plate) => (
                                                <MenuItem value={plate.Key}>{plate.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"Plate #2"}
                                            value={params?.PlateNumber2}
                                            onChange={e => setParams({ ...params, PlateNumber2: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >State #2</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="State #2"
                                                value={params?.PlateSt2}
                                                onChange={e => setParams({ ...params, PlateSt2: e.target.value })}
                                            >
                                                {params.NCICPlateStateList && params?.NCICPlateStateList.map((plate) => (
                                                    <MenuItem value={plate.Key}>{plate.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label={"Plate #3"}
                                        value={params?.PlateNumber3}
                                        onChange={e => setParams({ ...params, PlateNumber3: e.target.value })}
                                    />
                                </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >State #3</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="State #3"
                                                value={params?.PlateSt3}
                                                onChange={e => setParams({ ...params, PlateSt3: e.target.value })}
                                            >
                                                {params.NCICPlateStateList && params?.NCICPlateStateList.map((plate) => (
                                                    <MenuItem value={plate.Key}>{plate.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    
                                </>}
                               
                            </div>
                            <div className="grid grid-cols-1 mt-5 gap-y-5">
                                <div className="w-full">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="VIN"
                                        value={params?.VIN}
                                        onChange={e => setParams({ ...params, VIN: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="PlateLabel" >Plate Type</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="PlateLabel"
                                            label="Plate Type"
                                            value={params?.PlateType}
                                            onChange={e => setParams({ ...params, PlateType: e.target.value })}
                                        >
                                            {params.NCICPlateTypeList && params?.NCICPlateTypeList.map((type) => (
                                                <MenuItem value={type.Key}>{type.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="w-full pl-5">
                                    <FormControl fullWidth >
                                        <InputLabel id="VehicleLabel" >Vehicle Type</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="VehicleLabel"
                                            label="Vehicle Type"
                                            value={params?.VehicleType}
                                            onChange={e => setParams({ ...params, VehicleType: e.target.value })}
                                        >
                                            {params.NCICVehicleTypeList && params?.NCICVehicleTypeList.map((type) => (
                                                <MenuItem value={type.Key}>{type.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                        <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                            <CardContent>
                                <span className="text-blue-400 font-bold text-xl " >Gun Information</span>
                                <br />
                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"Gun Serial #"}
                                            value={params?.GSERIAL}
                                            onChange={e => setParams({ ...params, GSERIAL: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >Gun Make</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="Gun Make"
                                                value={params?.GMAKE}
                                                onChange={e => setParams({ ...params, GMAKE: e.target.value })}
                                            >
                                                {params.NCICGunMakeList && params?.NCICGunMakeList.map((dd) => (
                                                    <MenuItem value={dd.Key}>{dd.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"Gun Model"}
                                            value={params?.GMODEL}
                                            onChange={e => setParams({ ...params, GMODEL: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="DeptGroupLabel" >Gun Caliber</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="DeptGroupLabel"
                                                label="Gun Caliber"
                                                value={params?.GCAL}
                                                onChange={e => setParams({ ...params, GCAL: e.target.value })}
                                            >
                                                {params.NCICGunCaliberList && params?.NCICGunCaliberList.map((dd) => (
                                                    <MenuItem value={dd.Key}>{dd.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </>}
                    
                    
                    <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" >
                        <CardContent>
                            <span className="text-blue-400 font-bold text-xl " >Notes</span>
                            {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) &&<>
                                <div className="grid grid-cols-2 mt-5 gap-y-5">
                                    <div className="w-full pr-5">
                                        <TextField
                                            size="small"
                                            type={"text"}
                                            disabled={true}
                                            InputLabelProps={{ 'shrink': true }}
                                            className="w-full"
                                            margin='none'
                                            label={"Attention"}
                                            value={params?.ATN}
                                            onChange={e => setParams({ ...params, ATN: e.target.value })}
                                        />
                                    </div>
                                    <div className="w-full pl-5">
                                        <FormControl fullWidth >
                                            <InputLabel id="IIIPur" >Purpose</InputLabel>
                                            <Select
                                                size="small"
                                                labelId="IIIPur"
                                                label="Purpose"
                                                value={params?.PUR}
                                                onChange={e => setParams({ ...params, PUR: e.target.value })}
                                            >
                                                <MenuItem value="A">ADMINISTRATIVE FILE MAINTENANCE</MenuItem>
                                                <MenuItem value="C">CRIMINAL</MenuItem>
                                                <MenuItem value="D">DOMESTIC VIOLENCE AND/OR STALKING CASE</MenuItem>
                                                <MenuItem value="F">FIREARMS RELATED CHECKS</MenuItem>
                                                <MenuItem value="J">CRIMINAL JUSTICE EMPLOYMENT</MenuItem>
                                                <MenuItem value="X">EXIGENT PROCEDURES</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="w-full mt-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        margin='none'
                                        className="w-full"
                                        label={"Reason"}
                                        value={params?.REA}
                                        onChange={e => setParams({ ...params, REA: e.target.value })}
                                    />
                                </div>
                            </>}
                           
                            <div className="w-full mt-5">
                                <TextField
                                    size="small"
                                    multiline minRows={4}
                                    InputLabelProps={{ 'shrink': true }}
                                    margin='none'
                                    className="w-full"
                                    label={"Comments"}
                                    value={params?.SearchNotes}
                                    onChange={e => setParams({ ...params, SearchNotes: e.target.value })}
                                />
                            </div>
                            <div className="grid grid-cols-2 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        disabled
                                        variant='filled'
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="EmployeeID"
                                        value={params?.EmpID}
                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <TextField
                                        size="small"
                                        disabled
                                        variant='filled'
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        margin='none'
                                        label="Department"
                                        value={params?.DeptID}
                                    />
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <div className="mt-8 flex justify-center">
                        <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                            <Button onClick={handleSearch}>Search</Button>
                            <Button onClick={handleClearForm}>Clear</Button>
                        </ButtonGroup>
                    </div>
                    {error?.length !== 0 ? <div className="text-red-400 font-bold text-base text-center mt-1">{error}</div> : <div></div>}
                    {validation?.length !== 0 ? <div className="text-red-400 font-bold text-base text-center mt-1">{validation}</div> : <div></div>}
                    <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto mt-5" variant="outlined" >
                        {params?.FormattedResponse && Object.keys(params?.FormattedResponse).length !== 0 ?
                            <CardActionArea>
                                <CardHeader className="bg-black text-blue-400 font-bold text-xl " title={`${GetStringNameForSite(SiteName,'NCIC')} Response`} />
                                <CardContent className="bg-black">
                                    {/*<span className="text-blue-400 font-bold text-xl " >NCIC Response</span>*/}
                                    {/*<br />*/}
                                    {/*<div className="w-full pt-5">*/}
                                    {/*<TextField*/}
                                    {/*    disabled*/}
                                    {/*    size="small"*/}
                                    {/*    multiline*/}
                                    {/*    minRows={6}*/}
                                    {/*    InputLabelProps={{ 'shrink': true }}*/}
                                    {/*    margin='none'*/}
                                    {/*    className="w-full"*/}
                                    {/*    label="Keywords"*/}
                                    {/*    value={params?.FormattedResponse?.Keywords}*/}
                                    {/*/>*/}
                                    { params?.FormattedResponse?.Photos && <>
                                        { params?.FormattedResponse?.Photos?.Photo &&  params?.FormattedResponse?.Photos?.Photo.length>0 && <>
                                            { params?.FormattedResponse?.Photos?.Photo.map((p:any,i:any) => (<>
                                              <img src={`data:image/jpeg;base64,${p}`} alt={''} style={{display:'inline', width:'75px', margin:'3px'}} />
                                            </>))}
                                        </>}
                                    </>}
                                    
                                    <pre className={"text-white pl-3 pr-3 width-100%"} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
                                        {params?.FormattedResponse?.Keywords}
                                    </pre>
                                    {/*</div>*/}
                                    <br />
                                    {/*<div className="w-full pt-5 flex-shrink-2 " >
                                        {/*<TextField*/}
                                        {/*    disabled*/}
                                        {/*    size="small"*/}
                                        {/*    multiline*/}
                                        {/*    minRows={6}*/}
                                        {/*    InputLabelProps={{ 'shrink': true }}*/}
                                        {/*    margin='none'*/}
                                        {/*    className="w-full"*/}
                                        {/*    label="DMV Response"*/}
                                        {/*    value={params?.FormattedResponse?.FormattedDMVRsp}*/}
                                        {/*/>*/}
                                        <pre className={"text-white pl-3 pr-3 width-100%"} style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>
                                            {params?.FormattedResponse?.FormattedDMVRsp}
                                        </pre>
                                    {/*</div>*/}
                                    <br />
                                    {/*<div className="w-full pt-5 flex-shrink-2">*/}
                                        {/*<TextField*/}
                                        {/*    disabled*/}
                                        {/*    size="small"*/}
                                        {/*    multiline*/}
                                        {/*    minRows={6}*/}
                                        {/*    InputLabelProps={{ 'shrink': true }}*/}
                                        {/*    margin='none'*/}
                                        {/*    className="w-full"*/}
                                        {/*    label="NCIC Response"*/}
                                        {/*    value={params?.FormattedResponse?.FormattedNCICRsp}*/}
                                        {/*/>*/}
                                        <pre className={"text-white pl-5 pr-5 width-100% "} style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
                                            {params?.FormattedResponse?.FormattedNCICRsp}
                                        </pre>
                                    {/*</div>*/}
                                </CardContent>
                            </CardActionArea>
                            : <></>}
                    </Card>
                </>
                : <div className="text-blue-400 text-6xl font-bold"></div>)}
        </div>
    );
}

export default NCIC;
/*


<div className="inline mr-5"><TextField margin='none' size={"small"} label="ID" variant="standard" value={params.UniqueID} onChange={e => setParams({ ...params, UniqueID: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Search Type" variant="standard" value={params.SearchType} onChange={e => setParams({ ...params, SearchType: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Bundle Type" variant="standard" value={params.BundleType} onChange={e => setParams({ ...params, BundleType: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="NJSPI / NYSPI" variant="standard" value={params.ORI} onChange={e => setParams({ ...params, ORI: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Plate #, State" variant="standard" value={params.PlateNumber} onChange={e => setParams({ ...params, PlateNumber: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="State" variant="standard" value={params.PlateSt} onChange={e => setParams({ ...params, PlateSt: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Plate #, State" variant="standard" value={params.PlateNumber2} onChange={e => setParams({ ...params, PlateNumber2: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="State" variant="standard" value={params.PlateSt2} onChange={e => setParams({ ...params, PlateSt2: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Plate #, State" variant="standard" value={params.PlateNumber3} onChange={e => setParams({ ...params, PlateNumber3: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="State" variant="standard" value={params.PlateSt3} onChange={e => setParams({ ...params, PlateSt3: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Plate Type" variant="standard" value={params.PlateType} onChange={e => setParams({ ...params, PlateType: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Vehicle Type" variant="standard" value={params.VehicleType} onChange={e => setParams({ ...params, VehicleType: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="DL #" variant="standard" value={params.DLNo} onChange={e => setParams({ ...params, DLNo: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="DL State" variant="standard" value={params.DLSt} onChange={e => setParams({ ...params, DLSt: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="SSN #" variant="standard" value={params.SSN} onChange={e => setParams({ ...params, SSN: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="VIN" variant="standard" value={params.VIN} onChange={e => setParams({ ...params, VIN: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="FBI #" variant="standard" value={params.FBINo} onChange={e => setParams({ ...params, FBINo: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="SBI #, State" variant="standard" value={params.SBINo} onChange={e => setParams({ ...params, SBINo: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="SBI State" variant="standard" value={params.SBISt} onChange={e => setParams({ ...params, SBISt: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="First Name" variant="standard" value={params.FName} onChange={e => setParams({ ...params, FName: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Middle Initial" variant="standard" value={params.MName} onChange={e => setParams({ ...params, MName: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Last Name" variant="standard" value={params.LName} onChange={e => setParams({ ...params, LName: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="DOB" variant="standard" value={params.DOB} onChange={e => setParams({ ...params, DOB: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Sex" variant="standard" value={params.SEX} onChange={e => setParams({ ...params, SEX: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label={GetStringNameForSite(SiteName,"Case #")} variant="standard" value={params.CaseNo} onChange={e => setParams({ ...params, CaseNo: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Comments" variant="standard" value={params.SearchNotes} onChange={e => setParams({ ...params, SearchNotes: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Response" variant="standard" value={params.Response} onChange={e => setParams({ ...params, Response: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Employee ID" variant="standard" value={params.EmpID} onChange={e => setParams({ ...params, EmpID: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Department" variant="standard" value={params.DeptID} onChange={e => setParams({ ...params, DeptID: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Created By" variant="standard" value={params.CreateUID} onChange={e => setParams({ ...params, CreateUID: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Created Date" variant="standard" value={params.CreateDate} onChange={e => setParams({ ...params, CreateDate: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Updated By" variant="standard" value={params.UpdUID} onChange={e => setParams({ ...params, UpdUID: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Updated Date" variant="standard" value={params.UpdDate} onChange={e => setParams({ ...params, UpdDate: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="DeviceORI" variant="standard" value={params.DeviceORI} onChange={e => setParams({ ...params, DeviceORI: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="SiteName" variant="standard" value={params.SiteName} onChange={e => setParams({ ...params, SiteName: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="Active" variant="standard" value={params.Active} onChange={e => setParams({ ...params, Active: e.target.value })} /></div>
<div className="inline mr-5"><TextField margin='none' size={"small"} label="EditStatus" variant="standard" value={params.EditStatus} onChange={e => setParams({ ...params, EditStatus: e.target.value })} /></div>*/
