import React, { useState } from "react";
import { getUser, isUserPermission } from "../../services/auth.service";
import {
  getDarInfoResponse,
  GetDarInfo_Query,
  PersonInfo,
  ProfileReportPersonInfo,
} from "../../interfaces/getDarInfo_interface";
import { Account } from "../../interfaces/auth_interface";
import { useLocation } from "react-router-dom";
import { RunDarQuery } from "../../services/getDar.service";
import { JAOS } from "../../services/JAOS.service";
import { handleSearchTime } from "../../services/formatDate.service";
import SearchResults from "../../components/njport/analysts/searchResult";
import AnalystReportSearch from "../../components/njport/analysts/analystReportSearch";
import {
  InvestigationObject,
  InvestigationQuery,
  InvestigationResponse,
} from "../../interfaces/NJPORT/InvestigationLog.interface";
import { searchInvestigation } from "../../services/njport.service";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AnalystsReport() {
  let [darTbl, setDarTbl] = useState<InvestigationObject[]>();
  let [user, setUser] = useState<Account>(getUser());
  let query = useQuery();
  let [personData, setPersonData] = useState<any>(null);
  const searchRef = React.useRef<any>(null);
  let [noData, setNoData] = useState<boolean>(false);
  let [initialBuild, setInitialBuild] = useState<boolean>(false);

  React.useEffect(() => {
    if (!initialBuild) {
      setInitialBuild(true);
      checkPersonData();
    }
  }, []);

  const checkPersonData = () => {
    let person = query.get("person");
    let data = {};
    if (person) {
      let l = new JAOS();
      data = l.objFromStack(person);
      l.clearStack();
    }
    if (Object.keys(data).length > 0) {
      setPersonData(data);
    }
    return true;
  };

  const SubmitSearch = async (params: InvestigationQuery) => {
    let search_query: InvestigationQuery = {
      queryType: "ANALYSTREQUEST",
      dateTypeValue: params.dateTypeValue ? params.dateTypeValue : 0,
      dateRange: true,
      dept: "",
      empID: "",
      deptORI: "",
      searchType: "",
      fromDate: params.fromDate,
      toDate: params.toDate,
      indicatorOn: true,
      fNames: params.fNames ? params.fNames[0].split(",") : [],
      mNames: params.mNames ? params.mNames[0].split(",") : [],
      lNames: params.lNames ? params.lNames[0].split(",") : [],
      doBs: params.doBs ? params.doBs : [],
      sex: [],
      cities: [],
      residentCounty: [],
      states: [],
      address: "",
      zip: "",
      statutes: [],
      gang: [],
      fbIs: [],
      sbIs: [],
      dlNos: [],
      plateNos: [],
      viNs: [],
      caseNumbers: params.caseNumbers ? params.caseNumbers[0].split(",") : [],
      serialNumbers: [],
      agencyArrested: [],
      arrestedCounty: [],
      infocopDept: [],
      indicators: [],
      ssn: params.ssn ? params.ssn[0].split(",") : [],
      placeOfBirth: [],
      ageRangeStart: 0,
      ageRangeEnd: 0,
      status: [],
      reportNum: [],
      indicatorOccurrence: {
        foundCODIS: 0,
        foundShooting: 0,
        foundGunTrace: 0,
        foundProfile: 0,
        foundNIBIN: 0,
        foundDomViolence: 0,
        foundSexCrime: 0,
        foundReferral: 0,
        foundCarTheft: 0,
        foundCarTheftRecent: 0,
        FoundNJTrace_Pos: 0,
        FoundNJTrace_Asc: 0,
        FoundETrace_Pur: 0,
        FoundNJTrace_Ghg: 0,
        FoundHomeBurglary: 0,
        FoundHomeInvasion: 0,
      },
      paginationOffset: 0,
      paginationNext: 0,
      paginationEnd: true,
      additionalClause: "",
      shootingPersonTypes: [],
      systemCall: true,
    };
    let result: InvestigationResponse = await searchInvestigation(search_query);
    console.log(result);
    if (result && result.AnyTable && result.AnyTable.length > 0) {
      setDarTbl(result.AnyTable);
    } else {
      setDarTbl([]);
    }
  };

  const setStatusValue = (options: any[]) => {
    options.forEach((option: string, idx, array) => {
      switch (option) {
        case "In Progress":
          array[idx] = "InProgress";
          break;
        case "For Review":
          array[idx] = "ForReview";
          break;
        default:
          break;
      }
    });

    return options;
  };

  const SubmitSearchConnection = async (
    params: any,
    dateType: string,
    andOrType: string,
    dropDowns: any
  ) => {
    let search_query: GetDarInfo_Query = {
      QueryType: "Q",
      Dept: user.Dept,
      EmpID: user.EmpID,
      DeptORI: user.DeptORI,
      DateRange: true,
      DateTypeValue: dateType === "Entry Date" ? 0 : 1,
      FromDate: handleSearchTime(params?.fromDate, "fromDate").toLocaleString(),
      ToDate: handleSearchTime(params?.toDate, "toDate").toLocaleString(),
      SearchType: andOrType,
      IndicatorOn: true,
      TopArrestNumber: 0,
      FNames: params.FirstName ? params.FirstName.split(",") : [],
      MNames: params.MI ? params.MI.split(",") : [],
      LNames: params.LastName ? params.LastName.split(",") : [],
      DOBs: params.DOB ? params.DOB.split(",") : [],
      SEX: params.Gender ? params.Gender.split(",") : [],
      Address: "",
      Cities: dropDowns.City ? dropDowns.City : [],
      States: dropDowns.State ? dropDowns.State : [],
      Zip: "",
      Statutes: dropDowns.Statute ? dropDowns.Statute : [],
      Gang: [],
      FBIs: params.FBI ? params.FBI.split(",") : [],
      SBIs: params.SBI ? params.SBI.split(",") : [],
      DLNos: [],
      PlateNos: [],
      VINs: [],
      AgencyArrested: [],
      InfocopDept: [],
      Indicators: dropDowns.Indicator ? dropDowns.Indicator : [],
      IndicatorOccurrence: dropDowns.IndicatorOccurrence
        ? dropDowns.IndicatorOccurrence
        : null,
      Latitude: null,
      Longitude: null,
      Radius: 0,
      ResidentCounty: dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
      ArrestedCounty: [],
      Arrest_Type: [],
      CaseNumbers: [],
      SSN: [],
      PlaceOfBirth: [],
      AgeRangeStart: params.AgeRangeStart,
      AgeRangeEnd: params.AgeRangeEnd,
      FTSIDs: params.FTSID ? params.FTSID.split(",") : [],
      Status: params.Status ? params.Status.split(",") : [],
      ReportNum: params.ReportNum ? params.ReportNum.split(",") : [],
    };

    let qRes: getDarInfoResponse = await RunDarQuery(search_query);
    if (qRes && qRes.AnyTable && qRes.AnyTable.length > 0) {
      setDarTbl(qRes.AnyTable);
    }
    // else {
    //     setDarTbl([]);
    // }
  };

  const handleSubmit = async () => {
    if (searchRef.current) {
      searchRef.current?.handleSubmit();
    }
  };

  return (
      <div id="ToolsArrest">
          {isUserPermission('Arrest') ? (
        <>
          <AnalystReportSearch SubmitSearch={SubmitSearch} />
          <SearchResults
            handleSubmit={handleSubmit}
            data={darTbl}
            Persons={personData}
            SubmitSearch={SubmitSearch}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AnalystsReport;
