import React, {useState, useEffect} from "react";
import {Box, Checkbox, Dialog, FormControl, FormControlLabel, FormGroup, IconButton, Tooltip} from "@mui/material";
import {convertUTCtoLocalTimeRange, formatDate} from "../../../services/formatDate.service";
import DownloadIcon from "@mui/icons-material/Download";
import {Person_GetBOLOFile} from "../../../services/getDar.service";
import {RunALPRImageQuery} from "../../../services/getAlpr.service";
import NoVehImg from "../../../assets/Images/NoVehImg.jpg";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";


interface DetailProps {
    files: any[] | null
}
interface DetailProp {
    file: any
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const PanelBoloFile: React.FC<DetailProp> = ({file}) => {
    const [b64, setB64] = React.useState<any>(null);
    const [enlarged, setEnlarged] = React.useState<any>(null)
    const [open, setOpen] = React.useState<boolean>(false)
    
    React.useEffect(() => {
        let isMounted = true;
        Person_GetBOLOFile(file.BOLOAttachmentId).then((res:any) => {
          setB64(res);
           
        });
        return () => { isMounted = false };
    }, [file])
    
    
    return(<>
        {enlarged ?
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                onClose={() => { setEnlarged(null) }}
                open={open}
                TransitionComponent={Transition}
            >
                <Tooltip title={"Click to Shrink"}>
                    <img src={"data:image/jpeg;base64,"+enlarged} width="full" height="full" onClick={() => { setEnlarged(null) }} />
                </Tooltip>
            </Dialog>
            :
            <></>
        }
        
        
        { b64 ? <img src={"data:image/jpeg;base64,"+b64} style={{ "width": "50px", "height": "50px" }} alt={""} onClick={() => { setEnlarged(b64); setOpen(true) }}/>: <></>}
    </>)
}

const PanelBoloFilesSection: React.FC<DetailProps> = ({files}) => {
   
    return (
        <>
            {files && files.length > 0 && files.map((file: any, index: number) => {
                return (<PanelBoloFile file={file} />)
            })}
        </>

    )
}

export default PanelBoloFilesSection;

