import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper, CardHeader, CardMedia, Tooltip, CardActions, IconButton, Dialog, Slide } from "@mui/material";
import { RunNJTraceDetailQuery } from "../../services/detail.service";
import { formatDT, formatDate } from "../../services/formatDate.service"

import './Detail.css'
import { NJTraceObject, NJTraceMainObject, NJTraceIncidentObject, NJTraceWeaponObject, NJTraceReportingOfficerObject, NJTraceVehicleObject, NJTracePersonObject, NJTraceATFStatusObject, FirearmFileOrImage } from "../../interfaces/Detail/NJTraceDetail.interface";
import { GetNJTrace } from "../../services/account.service";
import DetailMap from "./_DetailMap";
import { GetFileContentFirearmNonDownload } from "../../services/getDar.service";
import { TransitionProps } from "@mui/material/transitions";
import HelpIcon from '@mui/icons-material/Help';

interface DetailProps {
    ID: string | null
    department: string | null
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const NJTraceDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<NJTraceObject>({})
    const [pin, setPin] = React.useState<any>({})
    const [img, setImg] = React.useState<FirearmFileOrImage[]>([])
    const [enlarged, setEnlarged] = React.useState<any>(null)
    const [open, setOpen] = React.useState<boolean>(false)

    


    const loadData = async () => {
        if (!DataLoaded) {
            setDataLoaded(true)
            // let res = await RunNJTraceDetailQuery(ID, department);
            let res = await GetNJTrace({ main: { ID: ID, FTS_ID: department } })
            if (res?.ErrorMessages?.length === 0) {
                let obj = {
                    main: {},
                    incidents: [],
                    persons: [],
                    reportingOfficers: [],
                    weapons: [],
                    vehicles: [],
                }
                if (res?.Main) {
                    obj.main = res?.Main
                }
                if (res?.Incidents) {
                    obj.incidents = res?.Incidents
                }
                if (res?.Persons) {
                    obj.persons = res?.Persons
                }
                if (res?.ReportingOfficers) {
                    obj.reportingOfficers = res?.ReportingOfficers
                }
                if (res?.Weapons) {
                    obj.weapons = res?.Weapons
                    let tempImg=[]
                    for(let weapon of res?.Weapons){
                        if(weapon?.FileOrImages?.length > 0){
                            for(let img of weapon?.FileOrImages){
                                let temp = await GetFileContentFirearmNonDownload(img)
                                if(temp){
                                    tempImg.push(temp)
                                }                                    
                            }
                        }
                    }
                    setImg(tempImg)


                }
                if (res?.Vehicles) {
                    obj.vehicles = res?.Vehicles
                }
                setData(obj)
                createPin(obj)
            }
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    const createPin = async (Data: any) => {
        let tPin: any = []
        if (Data && Data.incidents.length > 0) {
            for (let incident of Data.incidents) {
                if (incident.Longitude && incident.Latitude) {
                    tPin.push({
                        incidentID: incident.ID,
                        lat: incident.Latitude,
                        lng: incident.Longitude,
                        address: incident.REC_STREET_NUMBER + " " + incident.REC_STREET_NAME + " " + incident.REC_STREET_SUFFIX,
                        city: incident.REC_CITY,
                        detail: "njtrace"
                    })
                }
            }
        }
        setPin(tPin)
    }

    const crimeCodeList = async()=>{
        //setcr
    }

            return (
                <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
                    <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                        <CardContent>
                            <h3 className="mb-4 font-bold font text-xl text-blue-400">Main Information</h3>
                            <div className ="DetailTableRow" ><span className = "DetailTableLabel">Entry DateTime</span><span className ="DetailTableData">{formatDT(Data.main?.REQUEST_DATE)}</span></div>
                            <div className ="DetailTableRow" ><span className = "DetailTableLabel">Agency Name</span><span className ="DetailTableData">{Data.main?.OFFICE}</span></div>
                            <div className ="DetailTableRow" ><span className = "DetailTableLabel">Agency ORI</span><span className ="DetailTableData">{Data.main?.DEPT}</span></div>
                            <div className ="DetailTableRow" ><span className = "DetailTableLabel">NCIC Crime Code</span><span className ="DetailTableData">{Data.main?.CRIME}</span></div>
                            <div className ="DetailTableRow" ><span className = "DetailTableLabel">Case #</span><span className ="DetailTableData">{Data.main?.CaseNumber}</span></div>
                            <div className ="DetailTableRow" ><span className = "DetailTableLabel">Remark</span><span className ="DetailTableData">{Data.main?.REMARK}</span></div>
                        </CardContent>
                    </Card>
                    
                    {Data.reportingOfficers && Data.reportingOfficers.length > 0 ?
                        Data.reportingOfficers?.map((ReportingOfficer: NJTraceReportingOfficerObject , idx) =>{
                            return(
                                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                                    <CardContent>
                                        <h3 className="mb-5 font-bold font text-xl text-blue-400">Reporting Officer Information</h3>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Badge #</span><span className="DetailTableData">{ReportingOfficer?.ORI_BADGE_NUMBER}</span></div>                              
                                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">First Name</span><span className ="DetailTableData">{ReportingOfficer?.ORI_FIRSTNAME}</span></div>                              
                                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Middle Name</span><span className ="DetailTableData">{ReportingOfficer?.ORI_MIDDLENAME}</span></div>                              
                                        <div className ="DetailTableRow" ><span className = "DetailTableLabel">Last Name</span><span className ="DetailTableData">{ReportingOfficer?.ORI_LASTNAME}</span></div>                              
                                    </CardContent>
                                </Card>
                            )
                        })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">Reporting Officer Information</h3>
                    </CardContent>
                </Card>
            }

            {Data.incidents && Data.incidents.length > 0 ?
                Data.incidents?.map((Incident: NJTraceIncidentObject, idx) => {
                    return (
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-5 font-bold font text-xl text-blue-400">Incident Information</h3>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Recovery Date</span><span className="DetailTableData">{formatDate(Incident?.REC_DATE)}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Street #</span><span className="DetailTableData">{Incident?.REC_STREET_NUMBER}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Street Name</span><span className="DetailTableData">{Incident?.REC_STREET_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Type</span><span className="DetailTableData">{Incident?.REC_STREET_TYPE}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Apt #</span><span className="DetailTableData">{Incident?.REC_APT_NUMBER}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">City</span><span className="DetailTableData">{Incident?.REC_CITY}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">State</span><span className="DetailTableData">{Incident?.REC_STATE_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Zip</span><span className="DetailTableData">{Incident?.REC_ZIP}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Incident County</span><span className="DetailTableData">{Incident?.REC_COUNTY}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Recovery Location Type</span><span className="DetailTableData">{Incident?.REC_LOCATION}</span></div>
                                {pin && pin.length > 0 ? pin.filter((res: any) => res.incidentID === Incident.ID).map((Pin: any) => {
                                    return (
                                        <div className="DetailTableRow border-2 border-black"> <DetailMap data={Pin} detail="njtrace" /> </div>
                                    )
                                }) : <></>}
                            </CardContent>
                        </Card>
                    )
                })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">Incident Information</h3>
                    </CardContent>
                </Card>
            }
                {enlarged ?
                        <Dialog
                            fullWidth={true}
                            maxWidth={'md'}
                            onClose={()=>{setEnlarged(null)}}
                            open={open}
                            TransitionComponent={Transition}

                        >
                            <Tooltip title={"Click to Shrink"}><img src={enlarged} width="full" height="full" onClick={()=>{setEnlarged(null)}}/></Tooltip>
                        </Dialog>
                        :
                        <></>
                    }
            {Data.weapons && Data.weapons.length > 0 ?
                Data.weapons?.map((Weapon: NJTraceWeaponObject, idx) => {
                    return (
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Weapon Information</h3>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Recovery DateTime</span><span className="DetailTableData">{formatDT(Weapon?.RECOVERY_DATE)}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Weapon Caliber</span><span className="DetailTableData">{Weapon?.CALIBER}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Weapon Make</span><span className="DetailTableData">{Weapon?.MANUFACTURER_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Manufacturer Code</span><span className="DetailTableData">{Weapon?.MANUFACTURER}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Weapon Model</span><span className="DetailTableData">{Weapon?.MODEL}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Serial #</span><span className="DetailTableData">{Weapon?.SERIAL_NUMBER}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Weapon Type</span><span className="DetailTableData">{Weapon?.W_TYPE_DESC}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Reported Stolen</span><span className="DetailTableData">{Weapon?.Reported_Stolen === "Y" ? "Yes": "No"}</span></div>
                                {Weapon.Reported_Stolen === "Y" 
                                    ? 
                                    <>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Stolen Date</span><span className="DetailTableData">{formatDate(Weapon.Stolen_Date)}</span></div>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Agency Name</span><span className="DetailTableData">{Weapon.Stolen_AgencyName}</span></div>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Agency ORI</span><span className="DetailTableData">{Weapon.Stolen_AgencyORI}</span></div>                                   
                                    </>
                                    
                                    :
                                    <></>
                                }
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Privately Made</span><span className="DetailTableData">{Weapon?.Privately_made_firearm === "Y" ? "Yes": "No"}</span></div>
                                {Weapon?.Privately_made_firearm === "Y"
                                    ?
                                    <>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">PMF Category</span><span className="DetailTableData">{Weapon?.Privately_made_firearm_category_desc}</span></div>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">PMF Variant</span><span className="DetailTableData">{Weapon?.privately_made_firearm_variant_desc}</span></div>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">PMF Source</span><span className="DetailTableData">{Weapon?.Privately_made_firearm_source_desc}</span></div>                                   
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">NIBIN Reference Number</span><span className="DetailTableData">{Weapon?.NIBIN}</span></div>                                   
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Material Composition</span><span className="DetailTableData">{Weapon?.Privately_made_firearm_material_composition_desc}</span></div>                                   
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Material Color</span><span className="DetailTableData">{Weapon?.Privately_made_firearm_material_color_desc}</span></div>                                   
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Finish</span><span className="DetailTableData">{Weapon?.Finish_desc}</span></div>                                   
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Barrel Manufacturer</span><span className="DetailTableData">{Weapon?.pmf_barrel_manufacturer}</span></div>                                   
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Barrel Serial Number</span><span className="DetailTableData">{Weapon?.pmf_barrel_serial}</span></div>                                   
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Slide Manufacturer</span><span className="DetailTableData">{Weapon?.pmf_slide_manufacturer}</span></div>                                   
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Slide Serial Number</span><span className="DetailTableData">{Weapon?.pmf_slide_serial}</span></div>                                   
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Quantity</span><span className="DetailTableData">{Weapon?.pmf_quantity}</span></div>                                   
                                    </>
                                    :
                                    <></>

                                }
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Machine gun conversion device/Sear Modified</span><span className="DetailTableData">{Weapon?.CONVERSION_DEVICE === "Y" ? "Yes": "No"}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Recovery Description</span><span className="DetailTableData">{Weapon.Agency_Possession_How}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Firearm Recovered</span><span className="DetailTableData">{Weapon?.Not_Recovered === "Y" ? "Yes" : "No"}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Vault Review/Trace Study</span><span className="DetailTableData">{Weapon?.VaultReview_TraceStudy === "Y" ? "Yes" : "No"}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Narcotics Related</span><span className="DetailTableData">{Weapon?.NARCOTICS_RELATED === "Y" ? "Yes" : "No"}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Defaced/Obliterated Gun</span><span className="DetailTableData">{Weapon?.DEFACED_GUN === "Y" ? "Yes" : "No"}</span></div>
                                {Weapon?.DEFACED_GUN === "Y"
                                    ?
                                    <>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Extent of Restoration</span><span className="DetailTableData">{Weapon?.extent_of_restoration_desc}</span></div>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Removal Method</span><span className="DetailTableData">{Weapon?.removal_method_desc}</span></div>
                                        <div className="DetailTableRow" ><span className="DetailTableLabel">Drill Size</span><span className="DetailTableData">{Weapon?.drill_size_desc}</span></div>                                            
                                    </>
                                    :
                                    <></>
                                }
                            </CardContent>
                            <CardContent>
                                {img && img.filter((res:any) => res.Deleted === false && res.WeaponID === Weapon.ID?.toString()).length > 0
                                                    ? <div className={"mb-4 font-bold font text-xl text-blue-400"}>
                                                        Files Attached
                                                    </div>
                                                    : <></>
                                }
                                <div className = "grid grid-cols-6 gap-y-1 gap-x-2">
                                    {img ?
                                        img.filter((res:any) => res?.Deleted === false && res?.WeaponID === Weapon.ID?.toString()).map((file: any, fileIndex: number) => {
                                            return (
                                                <Card sx={{ maxWidth: 345, boxShadow:'none'}} style={{border:'1px solid black', borderRadius:'5px', borderColor:'#bfbfbf'}}>
                                                    
                                                    <CardContent sx={{margin:0, padding:0}}>
                                                        <div className="col-span-6 text-center" style={{fontSize:'14px', fontWeight:'bolder'}}>
                                                            {file?.FileDescription}
                                                        </div>
                                                    </CardContent>
                                                    <CardMedia>
                                                        <Tooltip title={"Click to Enlarge"}>
                                                            <img src={"data:" + file?.FileEncoding + ";base64," + file?.FileContent} style={{height:'100px', width:'100%', objectFit:'contain'}}
                                                                    onClick={()=>{setEnlarged("data:" + file?.FileEncoding + ";base64," + file?.FileContent); setOpen(true)}}/>
                                                        </Tooltip>
                                                    </CardMedia>
                                                </Card>
                                            );
                                        }) : <></>}
                                </div>
                            </CardContent>
                        </Card>
                    )
                })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">Weapon Information</h3>
                    </CardContent>
                </Card>
            }

            {Data.vehicles && Data.vehicles.length > 0 ?
                Data.vehicles?.map((Vehicle: NJTraceVehicleObject, idx) => {
                    return (
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Vehicle Information</h3>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Plate Number</span><span className="DetailTableData">{Vehicle?.PlateNum}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Plate State</span><span className="DetailTableData">{Vehicle?.PlateStName}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Plate Country</span><span className="DetailTableData">{Vehicle?.VehicleCountryName}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Color</span><span className="DetailTableData">{Vehicle?.VehicleColor}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Make</span><span className="DetailTableData">{Vehicle?.VehicleMake}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Model</span><span className="DetailTableData">{Vehicle?.VehicleModel}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Year</span><span className="DetailTableData">{Vehicle?.VehicleYear}</span></div>
                            </CardContent>
                        </Card>
                    )
                })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">Vehicle Information</h3>
                    </CardContent>
                </Card>
            }

            {Data.persons && Data.persons.length > 0 ?
                Data.persons?.map((Possessor: NJTracePersonObject, idx) => {
                    return (
                        <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                            <CardContent>
                                <h3 className="mb-4 font-bold font text-xl text-blue-400">Possessor Information</h3>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Role</span><span className="DetailTableData">{Possessor?.INDIV_ROLE_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">First Name</span><span className="DetailTableData">{Possessor?.INDIV_FIRSTNAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Middle Name</span><span className="DetailTableData">{Possessor?.INDIV_MIDDLENAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Last Name</span><span className="DetailTableData">{Possessor?.INDIV_LASTNAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">DOB</span><span className="DetailTableData">{formatDate(Possessor?.INDIV_DOB)}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Sex</span><span className="DetailTableData">{Possessor?.INDIV_SEX}</span></div> 
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Race</span><span className="DetailTableData">{Possessor?.INDIV_RACE}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">FBI</span><span className="DetailTableData">{Possessor?.FBI}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">SBI</span><span className="DetailTableData">{Possessor?.SBINo}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Street #</span><span className="DetailTableData">{Possessor?.INDIV_STREET_NUMBER}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Street Name</span><span className="DetailTableData">{Possessor?.INDIV_STREET_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Type</span><span className="DetailTableData">{Possessor?.INDIV_STREET_TYPE}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Apt #</span><span className="DetailTableData">{Possessor?.INDIV_APT_NUMBER}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">City</span><span className="DetailTableData">{Possessor?.INDIV_CITY}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">State</span><span className="DetailTableData">{Possessor?.INDIV_STATE_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Zip</span><span className="DetailTableData">{Possessor?.INDIV_ZIP}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">County</span><span className="DetailTableData">{Possessor?.INDIV_COUNTY}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Country</span><span className="DetailTableData">{Possessor?.INDIV_COUNTRY_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Gang Name</span><span className="DetailTableData">{Possessor?.GANG_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Daily Crime Gun</span><span className="DetailTableData">{Possessor?.DailyCrimeGun}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Arrests</span><span className="DetailTableData">{Possessor?.Arrests}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Felony</span><span className="DetailTableData">{Possessor?.Felonys}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Prior Gun</span><span className="DetailTableData">{Possessor?.PriorGun}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">CRUMB</span><span className="DetailTableData">{Possessor?.CRUMB}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">POPHITS</span><span className="DetailTableData">{Possessor?.POPHITS}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Trigger Lock</span><span className="DetailTableData">{Possessor?.INDIV_TRIGGER_LOCK === "Y" ? "Yes": "No"}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Crime History</span><span className="DetailTableData">{Possessor?.INDIV_CRIME_HISTORY === "Y" ? "Yes": "No"}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Notes</span><span className="DetailTableData">{Possessor?.NOTES}</span></div>
                            </CardContent>
                            <CardContent>
                                <div className={"mb-4 font-bold font text-xl text-blue-400"}>Identification</div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID #1 Country of Issuance</span><span className="DetailTableData">{Possessor?.INDIV_ID1_COUNTRY_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID #1 State of Issuance</span><span className="DetailTableData">{Possessor?.INDIV_ID1_STATE_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID #1 Type of ID</span><span className="DetailTableData">{Possessor?.INDIV_ID1_TYPE_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID #1 ID Number</span><span className="DetailTableData">{Possessor?.INDIV_ID1_NUMBER}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID #2 Country of Issuance</span><span className="DetailTableData">{Possessor?.INDIV_ID1_COUNTRY_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID #2 State of Issuance</span><span className="DetailTableData">{Possessor?.INDIV_ID2_STATE_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID #2 Type of ID</span><span className="DetailTableData">{Possessor?.INDIV_ID2_TYPE_NAME}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">ID #2 ID Number</span><span className="DetailTableData">{Possessor?.INDIV_ID2_NUMBER}</span></div>
                            </CardContent>
                            <CardContent>
                                {/*<div className="DetailTableRow" ><span className="DetailTableLabel">Reported Stolen Per NCIC Inquiry <Tooltip title={"This firearm was REPORTED STOLEN PER NCIC INQUIRY"}><HelpIcon/></Tooltip></span><span className="DetailTableData">{Possessor?.STOLEN_NCIC_INQUIRY === "Y" ? "Yes" : "No"}</span></div>*/}
                                {/*<div className="DetailTableRow" ><span className="DetailTableLabel">Narcotics Related <Tooltip title={"The recovery of this firearm was NARCOTICS RELATED"}><HelpIcon/></Tooltip></span><span className="DetailTableData">{Possessor?.NARCOTICS_RELATED === "Y" ? "Yes" : "No"}</span></div>*/}
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Juvenile Related<Tooltip title={"Person involved under 18 years old"}><HelpIcon /></Tooltip></span><span className="DetailTableData">{Possessor?.JUVENILE_RELATED === "Y" ? "Yes" : "No"}</span></div>
                                <div className="DetailTableRow" ><span className="DetailTableLabel">Youth Related<Tooltip title={"Person involved 18-24 years old"}><HelpIcon/></Tooltip></span><span className="DetailTableData">{Possessor?.YOUTH_RELATED === "Y" ? "Yes" : "No"}</span></div>


                            </CardContent>
                        </Card>
                    )
                })
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">Possessor Information</h3>
                    </CardContent>
                </Card>
            }
        </div>
    );
}

export default NJTraceDetailInfo;


