import React, { useState } from "react";
import { GetDarInfo_Query } from "../../interfaces/getDarInfo_interface";
import { Account } from "../../interfaces/auth_interface";
import { getUser, isUserPermission } from "../../services/auth.service";
import { RunDarQuery } from "../../services/getDar.service";
import ArrestSearch from "../../components/tools/arrest/_arrestSearch";
import TowedVehicleSearchInfo from "../../components/tools/towed_vehicle/_towedVehicleSearch";
import SearchResults from "../../components/tools/towed_vehicle/_searchResultsTowedVehicle";
import { handleSearchTime } from "../../services/formatDate.service";

function TowedVehicle() {
    let [darTbl, setDarTbl] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "5",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": "AND",
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames": [],
            "LNames": [],
            "DOBs": [],
            "SEX": dropDowns.CDD.VehicleMake ? dropDowns.CDD.VehicleMake : [],
            "Address": "",
            "Cities": dropDowns.CDD.VehicleYear ? dropDowns.CDD.VehicleYear : [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": dropDowns.CDD.VehicleModel ? dropDowns.CDD.VehicleModel : [],
            "FBIs": [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos": params.Plate ? params.Plate.split(',') : [],
            "VINs": params.VIN ? params.VIN.split(',') : [],
            "AgencyArrested": [],
            "InfocopDept": dropDowns.DD.InfoCopDept ? dropDowns.DD.InfoCopDept : [],
            "Indicators": dropDowns.DD.Indicator ? dropDowns.DD.Indicator : [],
            "IndicatorOccurrence": dropDowns.DD.IndicatorOccurrence ? dropDowns.DD.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.CDD.VehicleColor ? dropDowns.CDD.VehicleColor : [],
            "ArrestedCounty": [],
            "Arrest_Type": [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [""],
            "FTSIDs": [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes: any = await RunDarQuery(search_query)
        setDarTbl([])

        setDarTbl(qRes.AnyTable)
        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }
    return (
        <div>
            {isUserPermission('Arrest') ?
                <>
                    <TowedVehicleSearchInfo SubmitSearch={SubmitSearch} />
                    {!noData ? <SearchResults Persons={darTbl} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
                : <></>}
        </div>
    );
}

export default TowedVehicle;